/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

type ErrorAlertProps = {
  errors: string[];
};

export const ErrorAlert = ({ errors }: ErrorAlertProps) => {
  return (
    <div className="message is-danger">
      <div className="message-body">
        {errors.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
      </div>
    </div>
  );
};

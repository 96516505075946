/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getEvent = `query Event($customer_id: String!, $event_id: String!) {
    event(customer_id: $customer_id, event_id: $event_id) { 
      id
      customer_id
      plan_id
      hash
      pipeline_id
      enable_baseline
      time_zone
      pipeline {
        id
        customer_id
        plan_id
        hash
        name
        description
        window_start_time
        window_end_time
        patching_status
        parallel
        policy
        machines {
          id
          name
          order
          pre_host_hooks {
            type
            source
            method
            status_code
          }
          post_host_hooks {
              type
              source
              method
              status_code
          }
          status
          internal_status
          manual_status
          action_taken
          assignee
          s3_custom_script
        }
        pre_hooks {
          type
          source
          method
          status_code
        }
        post_hooks {
            type
            source
            method
            status_code
        }
        notification_groups
        machines_tag {
          tag_list {
            key
            values
          }          
          iam_role
          pre_host_hooks {
              type
              source
              method
              status_code
          }
          post_host_hooks {
              type
              source
              method
              status_code
          }
          allow_reboot
          reboot_policy
          regions
          s3_custom_script
        }
        dry_run
        reboot_policy
        linux_security_only
        windows_update_category
        windows_update_severity
        scan_event_id
        upcoming_notification_time
        errors
        start_time
        end_time
        created_at
        updated_at
      }
      name
      description
      window_start_time
      window_end_time
      patching_status
      parallel
      policy
      machines {
        id
        name
        order
        pre_host_hooks {
          type
          source
          method
          status_code
        }
        post_host_hooks {
            type
            source
            method
            status_code
        }
        status
        internal_status
        manual_status
        action_taken
        assignee
        s3_custom_script
      }
      pre_hooks {
        type
        source
        method
        status_code
      }
      post_hooks {
          type
          source
          method
          status_code
      }
      notification_groups
      machines_tag {
        tag_list {
          values
          key
        }
        iam_role
        pre_host_hooks {
            type
            source
            method
            status_code
        }
        post_host_hooks {
            type
            source
            method
            status_code
        }
        allow_reboot
        reboot_policy
        regions
        s3_custom_script
      }
      dry_run
      reboot_policy
      linux_security_only
      windows_update_category
      windows_update_severity
      scan_event_id
      upcoming_notification_time
      errors
      start_time
      end_time
      s3_custom_script
      created_at
      updated_at
      owner
      metadata {
        key
        value
      }
      notification_sent
      pipeline_info {
        prev
        next
      }
      report_status
      webhook_outputs {
        trigger_type
        payload
        payload_type
      }
    }
  }
  `;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NotificationManager } from 'react-notifications';

export const showApiError = err => {
  err.errors && err.errors.length > 0 && err.errors[0].message
    ? NotificationManager.error(err.errors[0].message)
    : NotificationManager.error('Something went wrong');
};

export const showErrorMessage = msg => {
  NotificationManager.error(msg);
};

export const showSuccessMessage = msg => {
  NotificationManager.success(msg);
};

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

const initialState = {
  isFetching: false,
  didInvalidate: false,
  dataSent: false,
  totalCount: 0,
  items: [],
  error: null,
};

export const ListReducerFactory = actions => (
  state = {
    ...initialState,
  },
  action,
) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case actions.INIT_ACTION:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case actions.SUCCESS_ACTION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        totalCount: action.totalCount,
        items:
          state.paginationToken && state.paginationToken === action.requestedToken
            ? [...state.items, ...action.items]
            : action.items,
        paginationToken: action.paginationToken,
        error: null,
      });
    case actions.SUCCESS_PREFETCH:
      return Object.assign({}, state, {
        isFetching: true,
        // prevent from wiping out the data if there is something already in state
        items: state.items.length > 0 ? state.items : action.items,
        error: null,
      });
    case actions.FAILURE_ACTION:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: false,
        error: action.error,
      });
    case actions.REMOVE_SUCCESS_ACTION:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.itemsUpdate ? action.itemsUpdate(state.items) : state.items,
        removed: true,
      });
    case actions.EDIT_SUCCESS_ACTION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        dataSent: true,
        error: null,
      });
    case actions.RESET_ERRORS_ACTION:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

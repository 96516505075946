/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { ButtonRemove } from '../../../commonComponents';
import { ROUTES } from '../../../constants/routes';

function ApiKeyRow({ item: apiKey, actions: { removeItem } }) {
  const href = ROUTES.apiKeys.apiKeysDetails(apiKey.id);

  return (
    <tr className="table-row-link" id={apiKey.id}>
      <Link to={href} className="table-cell">
        {apiKey.name}
      </Link>
      <Link to={href} className="table-cell">
        {apiKey.id}
      </Link>
      <Link to={href} className="table-cell">
        {apiKey.description}
      </Link>
      <Link to={href} className="table-cell">
        {apiKey.expires_at}
      </Link>
      <td>
        <ButtonRemove
          removeAction={event => {
            event && event.stopPropagation && event.stopPropagation();
            removeItem(apiKey.id);
          }}
          itemId={apiKey.id}
          itemName={apiKey.name}
          title="an API key named"
        />
      </td>
    </tr>
  );
}

ApiKeyRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    expires_at: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    removeItem: PropTypes.func.isRequired,
  }),
};

export default ApiKeyRow;
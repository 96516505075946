/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';

const { fetchData, removeItem, editItem, actions } = ListActionsFactory({
  namespace: 'ROLES',
  api: 'aws',
  path: '/roles',
});

export { fetchData as fetchRoles, removeItem as removeRole, editItem as editRoles, actions };

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const changePatchingStatus = `mutation ChangePatchingStatus($input: PatchingStatusChangeInput!) {
  changePatchingStatus(input: $input) {
    id
  }
  } 
  `;

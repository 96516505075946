/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

type FieldErrorProps = {
  children: React.ReactNode;
};

const FieldError = ({ children }: FieldErrorProps) => <p className="help is-danger">{children}</p>;

export default FieldError;

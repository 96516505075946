/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import moment from 'moment';

const defaultExpiryDate = moment()
  .add(1, 'year')
  .format('YYYY-MM-DDThh:mm:ssZ');

export const apiKeySchema = {
  type: 'object',
  required: ['name'],
  properties: {
    name: { type: 'string', title: 'Name' },
    description: { type: 'string', title: 'Description' },
    expires_at: {
      type: 'string',
      format: 'date-time',
      title: 'Expires At',
      default: defaultExpiryDate,
    },
  },
};

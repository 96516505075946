/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';

import { sortUtil } from '../../utils';
import { LoadingHOC } from '../loading/Loading';
import { DataTableHeaders } from './DataTableHeader';
import { Pagination, PerPage } from './DataTablePagination';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      desc: false,
      perPage: 10,
      currentPage: 0,
    };
  }

  setOrder = sortBy => {
    const { orderBy, desc } = this.state;
    if (orderBy === sortBy) {
      this.setState({ desc: !desc });
    } else {
      this.setState({ orderBy: sortBy, desc: false });
    }
    this.setState({ currentPage: 0 });
  };

  setPerPage = perPage => {
    this.setState({ perPage, currentPage: 0 });
  };

  setCurrentPage = page => {
    this.setState({ currentPage: page });
  };

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      window.scrollTo(0, 0);
    }
    if (prevProps.data.length !== this.props.data.length) {
      setTimeout(() => {
        this.setState({ currentPage: 0 });
      }, 1);
    }
  }

  render() {
    const { data, pagination, isLoading, headers, row: Row, rowActions, updateViewCount } = this.props;

    const { orderBy = '', desc, perPage, currentPage } = this.state;
    const numberOfPages = Math.ceil(data.length / perPage);
    const startIdx = currentPage * perPage;
    const visibleData = data.sort(sortUtil({ orderBy, desc })).slice(startIdx, startIdx + perPage);
    updateViewCount && updateViewCount(visibleData.length);

    return (
      <>
        {!isLoading && visibleData.length === 0 && (
          <div className="message is-warning">
            <div className="message-body">Oops, nothing to show.</div>
          </div>
        )}
        <LoadingHOC loading={isLoading}>
          <span />
          <div className="box">
            <div className="table-responsive">
              <table className="table is-fullwidth is-hoverable is-striped">
                <thead>
                  <DataTableHeaders
                    headers={headers}
                    orderBy={orderBy}
                    desc={desc}
                    setOrder={this.setOrder}
                  />
                </thead>
                <tbody>
                  {visibleData.map((item, index) => (
                    <Row key={index} item={item} actions={rowActions} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="box">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <PerPage perPage={perPage} setPerPage={this.setPerPage} />
                </div>
              </div>
              <Pagination
                currPage={currentPage}
                numberOfPages={numberOfPages}
                changePage={this.setCurrentPage}
                nextPage={() => this.setCurrentPage(currentPage + 1)}
                prevPage={() => this.setCurrentPage(currentPage - 1)}
                pagination={pagination}
              />
            </nav>
          </div>
        </LoadingHOC>
      </>
    );
  }
}

export { DataTable };

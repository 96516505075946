/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { PipelineStepInput } from '../../graphql/generated-types';
import PipelineStepModal from './PipelineStepModal';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

type ReducerProps = {
  isModalShown: boolean;
  stepId: number | null;
};

type PipelineStepFormInputProps = {
  value?: Partial<Array<PipelineStepInput>>;
  onChange: (steps: Partial<Array<PipelineStepInput>>) => void;
};
type SortableContainerProps = {
  steps: Partial<Array<PipelineStepInput>>;
  editStep: (stepId: number) => void;
  removeStep: (stepId: number) => void;
};

type SortableElementProps = {
  step: string;
  editStep: () => void;
  removeStep: () => void;
};

type SortEndProps = {
  oldIndex: number;
  newIndex: number;
};

const DragHandle = SortableHandle(() => (
  <span className="fa fa-bars pull-left" style={{ cursor: 'pointer', paddingTop: '0.2rem' }} />
));

const StepItem = SortableElement(({ step, editStep, removeStep }: SortableElementProps) => (
  <div className="pipelineFormPlan" style={{ padding: '1rem 1rem' }}>
    <div onClick={editStep}>
      <DragHandle />
      {step}
    </div>
    <i className="fa fa-trash is-pointer is-pulled-right" onClick={removeStep} />
  </div>
));

const StepsList = SortableContainer(({ steps, editStep, removeStep }: SortableContainerProps) => (
  <div>
    {steps.map((pipelineStep: PipelineStepInput | undefined, index: number) => {
      return (
        <StepItem
          step={`Step ${index + 1} - (${pipelineStep?.plans?.length} plan(s))`}
          index={index}
          key={index}
          editStep={() => editStep(index)}
          removeStep={() => removeStep(index)}
        />
      );
    })}
  </div>
));

function PipelineStepFormInput({ value: steps = [], onChange }: PipelineStepFormInputProps) {
  const [{ isModalShown, stepId }, setPopupState] = React.useReducer(
    (prevState: ReducerProps, updatedProperties: Partial<ReducerProps>) => ({
      ...prevState,
      ...updatedProperties,
    }),
    { isModalShown: false, stepId: null },
  );

  const [stateSteps, setStateSteps] = React.useState(steps);

  function addStep() {
    setPopupState({ isModalShown: true, stepId: steps.length });
  }

  function hideModal() {
    setPopupState({ isModalShown: false, stepId: null });
  }

  const handleStepEdit = (stepId: number) => {
    setPopupState({ stepId, isModalShown: true });
  };

  const handleStepRemove = (stepId: number) => {
    steps.splice(stepId, 1);
    hideModal();
    onChange(steps);
  };

  function handlePipelineStepSave(stepsData: Partial<PipelineStepInput>) {
    if (stepId !== null) {
      steps[stepId] = stepsData;
    } else {
      steps.push(stepsData);
    }

    onChange(steps);
    hideModal();
  }

  const onSortEnd = ({ oldIndex, newIndex }: SortEndProps) => {
    if (oldIndex === newIndex || stateSteps.length < 2) {
      return;
    }

    const steps = stateSteps.slice();
    steps.splice(newIndex, 0, ...steps.splice(oldIndex, 1));
    if (steps[0]?.run_immediately) {
      const { run_immediately, step_delay, policy, ...firstStep } = steps[0];
      steps[0] = firstStep;
    }
    setStateSteps(steps);
    onChange(steps);
  };
  return (
    <div className="is-input-like">
      <StepsList
        steps={steps}
        editStep={handleStepEdit}
        removeStep={handleStepRemove}
        useDragHandle
        lockAxis="y"
        onSortEnd={onSortEnd}
      />
      {isModalShown && (
        <PipelineStepModal
          savePipelineStep={handlePipelineStepSave}
          hideModal={hideModal}
          pipelineStep={stepId !== null ? steps[stepId] : undefined}
          firstStep={stepId === null || stepId === 0}
        />
      )}
      <button type="button" className="button" onClick={addStep}>
        Add New
      </button>
    </div>
  );
}

export default PipelineStepFormInput;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

type FieldExtrasWrapperProps = {
  children: React.ReactNode;
};

const FieldExtrasWrapper = ({ children }: FieldExtrasWrapperProps) => (
  <div className="field is-horizontal">
    <div className="field-label is-normal"></div>
    <div className="field-body" style={{ flexDirection: 'column' }}>
      {children}
    </div>
  </div>
);

export default FieldExtrasWrapper;

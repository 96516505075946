/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import DateInput from './DateInput';
import { Select } from './Select';
import { Modal, FormButtonsContainer, FormButtonConfirm } from './Form';
import NotificationEventInput from './NotificationEventInput';
import NotificationLevelsInput from './NotificationLevelsInput';
import {
  CustomCheckboxWidget,
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import Form from 'react-jsonschema-form';
import { stripUndefinedValues } from '../../utils';

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  DateWidget: DateInput,
  SelectWidget: Select,
};

const schema = {
  type: 'object',
  required: ['type'],
  properties: {
    type: {
      type: 'string',
      title: 'Type',
      anyOf: [
        { type: 'string', enum: ['SLACK'], title: 'Slack' },
        { type: 'string', enum: ['PAGER_DUTY'], title: 'Pager Duty' },
        { type: 'string', enum: ['EMAIL'], title: 'Email' },
      ],
    },
    override_settings: {
      title: 'Override Settings',
      type: 'boolean',
      description: 'Set to true if you want to use channel specific settings',
    },
  },
  dependencies: {
    type: {
      oneOf: [
        {
          properties: {
            type: { enum: ['SLACK'] },
            web_hook_url: {
              type: 'string',
              title: 'Web Hook URL',
            },
            channel: {
              type: 'string',
              title: 'Slack Channel',
            },
          },
        },
        {
          properties: {
            type: { enum: ['PAGER_DUTY'] },
            routing_key: {
              type: 'string',
              title: 'Pager Duty Key',
            },
          },
        },
        {
          properties: {
            type: { enum: ['EMAIL'] },
            email_config: {
              type: 'object',
              properties: {
                address: {
                  type: 'string',
                  title: 'Email address',
                },
                is_external: {
                  type: 'boolean',
                  title: 'Is external',
                },
              },
            },
          },
        },
      ],
    },
    override_settings: {
      oneOf: [
        {
          properties: {
            override_settings: { enum: [true] },
            events: { type: 'array', title: 'Events' },
            levels: { type: 'array', title: 'Severity Levels' },
          },
        },
        {
          properties: {
            override_settings: { enum: [false] },
          },
        },
      ],
    },
  },
};

const uiSchema = {
  events: {
    'ui:field': 'events',
    classNames: 'is-horizontal',
  },
  levels: {
    'ui:field': 'levels',
    classNames: 'is-horizontal',
  },
  'ui:order': ['*', 'override_settings'],
};

const fields = {
  events: NotificationEventInput,
  levels: NotificationLevelsInput,
};

class NotificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData ? props.formData : {},
    };
  }

  handleChange = data => {
    this.setState({ formData: data.formData });
  };
  add = () => this.props.addNotification(stripUndefinedValues(this.state.formData));

  render() {
    const { formData } = this.state;
    const uiOrder =
      formData.override_settings === true
        ? ['*', 'override_settings', 'events', 'levels']
        : ['*', 'override_settings'];
    const uiSchemaFinal = { ...uiSchema, 'ui:order': uiOrder };

    return (
      <Modal visible={true} cancelAction={this.props.hideModal} title="Notification">
        <Form
          className=""
          uiSchema={uiSchemaFinal}
          schema={schema}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
          ArrayFieldTemplate={ArrayFieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          fields={fields}
          onChange={this.handleChange}
          formData={formData}
          widgets={widgets}
        >
          <FormButtonsContainer>
            {formData.type !== undefined && (
              <FormButtonConfirm onClick={this.add}>Add</FormButtonConfirm>
            )}
          </FormButtonsContainer>
        </Form>
      </Modal>
    );
  }
}

export { NotificationModal };

/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal, FormControl, Metadata } from '../../../commonComponents';
import JSONPretty from 'react-json-pretty';
import { getPatchDelay } from './PipelineStepDetails';

const Param = ({ label, value, className, children }) => (
  <div className={`column ${className} `}>
    <strong className="is-desc-label">{label}</strong>
    <br />
    {children ? children : value ? value : <i className="is-italic">No data provided</i>}
  </div>
);

const PipelinePlanModal = ({ data, visible, hideModal }) => {
  return (
    <Modal
      title={`Plan ${data.name || ''}(${data.id})`}
      visible={visible}
      cancelAction={hideModal}
      isWide={true}
    >
      <div className="columns">
        <Param label="Id" value={data.id} />
        <Param label="Name" value={data.name} />
        <Param label="Policy" value={data.policy} />
        <Param label="Upcoming notification time" value={data.upcoming_notification_time} />
      </div>
      <hr />
      {data.machines ? (
        <div className="columns">
          <table className="table  is-transparent is-fullwidth is-striped">
            <thead>
              <tr>
                <th>Machine</th>
                <th>Post hooks</th>
                <th>Pre hooks</th>
                <th>Exclude</th>
              </tr>
            </thead>
            {data.machines.map((machine, index) => {
              return (
                <tr key={index}>
                  <td>{machine.name ? machine.name : machine.id}</td>
                  <td>
                    <ul>
                      {machine.post_host_hooks && machine.post_host_hooks.length > 0 ? (
                        machine.post_host_hooks.map((hook, index) => (
                          <li key={index}>
                            {hook.type}: {hook.source}
                          </li>
                        ))
                      ) : (
                        <i className="is-italic">No Post Hooks Provided</i>
                      )}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {machine.pre_host_hooks && machine.pre_host_hooks.length > 0 ? (
                        machine.pre_host_hooks.map((hook, index) => (
                          <li key={index}>
                            {hook.type}: {hook.source}
                          </li>
                        ))
                      ) : (
                        <i className="is-italic">No Pre Hooks Provided</i>
                      )}
                    </ul>
                  </td>
                  <td>{machine.exclude && <span className="tag is-warning">Excluded</span>}</td>
                </tr>
              );
            })}
          </table>
        </div>
      ) : (
        <FormControl type="text" readOnly={true} value="Plan has no machines!" />
      )}

      <div className="column is-half">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <div className="title is-size-4">Machines Tag</div>
            </div>
          </div>
        </div>
        <div>
          {!data.machines_tag ? (
            <FormControl type="text" readOnly={true} value="No Machines tag in this plan!" />
          ) : (
            <JSONPretty id="json-pretty" data={data.machines_tag} />
          )}
        </div>
      </div>
      <hr />
      <div className="columns">
        <Param label="Dry Run">
          {data.dry_run ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </Param>
        <Param label="Linux security only">
          {data.linux_security_only ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
        </Param>
        <Param label="Parallel" value={data.parallel} />
        <Param
          label="Patch delay"
          value={data.patch_delay && data.patch_delay > 0 && getPatchDelay(data.patch_delay)}
        />
      </div>

      <div className="columns">
        <Param label="Description" value={data.description} />
        <Param label="Window length" value={data.window_length} />
        <Param label="Updated at" value={data.updated_at} />
        <Param label="Created at" value={data.created_at} />
      </div>
      <div className="columns">
        <Param label="Custom Update Command (S3 URL)" value={data.s3_custom_script} />
      </div>
      <div className="columns">
        <Param label="Reboot Policy" value={data.reboot_policy} />
        <Param label="Windows Update Category" value={data.windows_update_category} />
        <Param label="Windows Update Severity" value={data.windows_update_severity} />
      </div>
      <div style={{ margin: '-0.75rem' }}>
        <Metadata data={data.metadata} name="Plan Details" />
      </div>
    </Modal>
  );
};

export { PipelinePlanModal };

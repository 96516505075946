/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { postData, resetNewForm } from '../../../actions/createNew';
import { serializeFormInput } from '../../../commonComponents/form2/utils/serializeFormInput';
import { ROUTES } from '../../../constants/routes';
import { MachineInput } from '../../../graphql/generated-types';
import { ErrorAlert, FormHeader } from '../../../commonComponents/form2';
import { createMachine } from '../../../graphql/createMachine';
import { APIContext } from '../../../commonComponents/auth/APIContext';
import MachineForm from '../components/NewOtherMachineForm/MachineForm';

type NewOtherMachineProps = {
  // should be changed to `typeof store.dispatch` once store is properly typed
  dispatch: Dispatch<unknown>;
  errorInfo: Record<string, string> | null;
  errorMessages: string[] | null;
  isSending: boolean;
};

function NewOtherMachine({ dispatch, errorMessages, errorInfo, isSending }: NewOtherMachineProps) {
  const { requester } = React.useContext(APIContext);
  React.useEffect(() => {
    resetNewForm(dispatch);
  }, [dispatch]);

  const onSubmit = (formData: Partial<MachineInput>) => {
    const input = serializeFormInput({
      ...formData,
      customer_id: localStorage.getItem('currentCustomer'),
    });

    const callback = (data: Partial<MachineInput>) => {
      if (data) {
        NotificationManager.success('Machine has been successfully created');
        browserHistory.push(`${ROUTES.machines.machines}?refresh=true`);
      } else {
        NotificationManager.error('Unable to create machine');
      }
    };

    postData(dispatch, createMachine, { input }, requester, callback);
  };

  return (
    <div className="main wide">
      <FormHeader title="New machine" subtitle="Create a new machine" backRoute="/machines/new" />
      {errorMessages && Array.isArray(errorMessages) && <ErrorAlert errors={errorMessages} />}
      <MachineForm onSubmit={onSubmit} isSubmitting={isSending} errorInfo={errorInfo} />
    </div>
  );
}

type CreateNewMachineStoreSlice = {
  error: string[] | null;
  errorInfo: Record<string, string> | null;
  isSending: boolean;
};

const mapStateToProps = (state: { createNew: CreateNewMachineStoreSlice }) => {
  const { createNew } = state;

  return {
    errorMessages: createNew.error,
    errorInfo: createNew.errorInfo,
    isSending: createNew.isSending,
  };
};

export default connect(mapStateToProps)(NewOtherMachine);

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const exportMachines = `query ExportMachines($customer_id: String!, $customer_name: String!, $ids: [String!]!) {
  exportMachines(customer_id: $customer_id, customer_name: $customer_name, ids: $ids) {
    url
  }
}
`

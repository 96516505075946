/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { postData, resetNewForm } from '../../../actions/createNew';
import { createPlan } from '../../../graphql/createPlan';
import PlanForm from '../components/form/PlanForm';
import { serializeFormInput } from '../../../commonComponents/form2/utils/serializeFormInput';
import { ROUTES } from '../../../constants/routes';
import { ErrorAlert, FormHeader } from '../../../commonComponents/form2';
import { APIContext } from '../../../commonComponents/auth/APIContext';

function PlanNew({ dispatch, errorMessages, errorInfo, isSending }) {
  const { requester } = React.useContext(APIContext);

  React.useEffect(() => {
    resetNewForm(dispatch);
  }, [dispatch]);

  const onSubmit = formData => {
    const input = serializeFormInput({
      ...formData,
      customer_id: localStorage.getItem('currentCustomer'),
    });

    const callback = data => {
      if (data) {
        NotificationManager.success('Plan has been successfully created');
        browserHistory.push(`${ROUTES.plans.plans}?refresh=true`);
      } else {
        NotificationManager.error('Unable to create plan');
      }
    };

    postData(dispatch, createPlan, { input }, requester, callback);
  };

  return (
    <div className="main wide">
      <FormHeader title="New plan" subtitle="Create a new plan" backRoute={ROUTES.plans.plans} />
      {errorMessages && Array.isArray(errorMessages) && <ErrorAlert errors={errorMessages} />}
      <PlanForm
        onSubmit={onSubmit}
        isSubmitting={isSending}
        errorMessages={errorMessages}
        errorInfo={errorInfo}
      />
    </div>
  );
}

const mapStateToProps = state => {
  const { createNew } = state;

  return {
    errorMessages: createNew.error,
    errorInfo: createNew.errorInfo,
    isSending: createNew.isSending,
    dataSent: createNew.dataSent,
    response: createNew.response,
  };
};

export default connect(mapStateToProps)(PlanNew);

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import Form from 'react-jsonschema-form';
import {
  FormButtonsContainer,
  FormButtonConfirm,
  CustomCheckboxWidget,
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import { Select } from './Select';
import { movePlanToPipeline } from '../../graphql/movePlanToPipeline';
import { fetchPlans } from '../../views/plans/actions';
import { getPlans } from '../../graphql/getPlans';
import { LoadingHOC } from '../loading/Loading';
import PipelineSortable from './PipelineSortable';
import { showApiError, showErrorMessage, showSuccessMessage } from '../../utils/notifications';
import { pageSizeForPlans } from '../../utils';
import { APIContext } from '../auth/APIContext';

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  SelectWidget: Select,
};

const fields = {
  steps: PipelineSortable,
};

const schema = {
  type: 'object',
  required: ['planId', 'steps', 'stepDelay'],
  properties: {
    planId: {
      type: 'string',
      title: 'Plan',
    },
    steps: {
      type: 'array',
      title: 'Steps',
    },
    stepDelay: {
      type: 'integer',
      title: 'Step delay',
    },
  },
};

let uiSchema = {
  steps: {
    'ui:field': 'steps',
    classNames: 'is-horizontal',
  },
};

class MovePlan extends Component {
  constructor(props) {
    super(props);
    this.state = { insert: true, data: {}, isFetchingMove: false };
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;

    fetchPlans({
      dispatch: this.props.dispatch,
      myInit: { limit: pageSizeForPlans },
      requester,
      allPages: true,
      operation: getPlans,
    });
  }

  handleChange = data => {
    this.setState({ data: data.formData });
  };

  move = async e => {
    const { requester } = this.context;

    if (!e.target.closest('form').checkValidity()) {
      return;
    }

    try {
      const { customerId, pipeline } = this.props;
      const {
        data: { steps, planId, stepDelay },
        insert,
      } = this.state;
      const stepIdx = steps.indexOf(planId);
      const input = {
        customer_id: customerId,
        plan_id: planId.split(' ')[0],
        pipeline_id: pipeline.id,
        step_index: stepIdx === 0 || insert ? stepIdx : stepIdx - 1,
        insert: stepIdx === 0 ? true : insert,
        step_delay: stepIdx === 0 || insert ? stepDelay : null,
      };

      if (!stepDelay && (insert || (stepIdx === 0 && steps.length > 1))) {
        showErrorMessage('Step delay is required');
        return;
      }

      this.setState({ isFetchingMove: true });
      await requester({ query: movePlanToPipeline, input: { input } });
      showSuccessMessage('Plan has been successfully moved to pipeline');
      browserHistory.push('/pipelines?refresh=true');
    } catch (err) {
      showApiError(err);
    }
    this.setState({ isFetchingMove: false });
  };

  render() {
    const { isFetching, pipeline } = this.props;
    const { data, insert, isFetchingMove } = this.state;
    return (
      <div className="box">
        <LoadingHOC loading={isFetching}>
          <Form
            className=""
            schema={schema}
            FieldTemplate={CustomFieldTemplate}
            showErrorList={false}
            ArrayFieldTemplate={ArrayFieldTemplate}
            ObjectFieldTemplate={ObjectFieldTemplate}
            onChange={this.handleChange}
            formData={data}
            uiSchema={uiSchema}
            widgets={widgets}
            fields={fields}
            formContext={{
              planId: data.planId,
              insert: insert,
              toggleInsert: () => this.setState(state => ({ insert: !state.insert })),
              hideFieldId:
                !insert &&
                !(data.steps && data.steps.length > 1 && data.steps.indexOf(data.planId) === 0)
                  ? 'root_stepDelay'
                  : '',
              pipeline,
            }}
          >
            <FormButtonsContainer>
              <FormButtonConfirm
                className="high-order"
                onClick={this.move}
                disabled={isFetchingMove}
              >
                Move
              </FormButtonConfirm>
            </FormButtonsContainer>
          </Form>
        </LoadingHOC>
      </div>
    );
  }
}

const mapStateToProps = ({ plans }) => {
  schema.properties.planId.enum = plans.items.map(
    e => `${e.id} (name:${e.name || 'name missing'})`,
  );
  return {
    customerId: localStorage.getItem('currentCustomer'),
    isFetching: plans.isFetching,
  };
};

export default connect(mapStateToProps)(MovePlan);

/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

const pipelineFormContext = React.createContext({ isBaselineEnabled: false });

export const PipelineFormContextProvider = pipelineFormContext.Provider;

export function usePipelineContext() {
  const context = React.useContext(pipelineFormContext);

  return context;
}

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Modal } from '../../../commonComponents';
import { PipelinePlanModal } from './PipelinePlanModal';

const Param = ({ label, value, className, children }) => (
  <div className={`column ${className} `}>
    <strong className="is-desc-label">{label}</strong>
    <br />
    {children ? children : value ? value : <i className="is-italic">No data provided</i>}
  </div>
);

export const getPatchDelay = delay => {
  const days = Math.floor(delay / 1440);
  const hours = Math.floor((delay - Math.floor(delay / 1440) * 1440) / 60);
  const minutes = delay - Math.floor(delay / 60) * 60;

  return `${days > 0 ? days + 'days' : ''} ${hours > 0 ? hours + 'hours' : ''} ${
    minutes > 0 ? minutes + 'minutes' : ''
  }`;
};

class PipelineStepDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlanModal: false,
    };
  }

  showPlanDetails = plan => {
    this.setState({
      showPlanModal: true,
      modalData: plan,
    });
  };

  render() {
    const { data, visible, hideModal, stepId } = this.props;
    const { showPlanModal, modalData } = this.state;
    return (
      <Modal
        title={`Step ${stepId + 1} - ${data.plans && data.plans.length} plan(s)`}
        visible={visible}
        cancelAction={hideModal}
      >
        {showPlanModal && (
          <PipelinePlanModal
            hideModal={() => this.setState({ showPlanModal: false })}
            data={modalData}
            visible={showPlanModal}
          />
        )}
        <div>
          <h1 className="title is-size-5">Step details</h1>
          <div className="columns">
            <Param className="is-one-quarter" label="Description" value={data.description} />
            <Param
              label="Step delay"
              value={data.step_delay && data.step_delay > 0 && getPatchDelay(data.step_delay)}
            />
            <Param label="Policy" value={data.policy} />
            <Param label="Run immediately">
              {data.run_immediately ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
            </Param>
          </div>
          <div className="pipeline-container">
            <h1 className="title is-size-5">Plans</h1>
            {data.plans.map((plan, index) => (
              <div
                className="pipelineFormPlan"
                key={index}
                onClick={() => this.showPlanDetails(plan)}
              >
                {plan.name ? `${plan.name}(${plan.id})` : plan.id}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export { PipelineStepDetails };

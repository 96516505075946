/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory, Redirect } from 'react-router';
import Amplify from 'aws-amplify';
import configureStore from './store/configureStore';
import './assets/scss/index.scss';
import 'font-awesome/css/font-awesome.css';
import 'react-notifications/lib/notifications.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tippy/dist/tippy.css';

import { PlansPage, PlanDetails, PlanNew } from './views/plans';
import { MachinesPage, MachineDetails, SelectMachineType, NewAWSMachine } from './views/machines';
import {
  NotificationGroupsPage,
  NotificationGroupDetails,
  NotificationGroupNew,
} from './views/notificationGroups';
import { EventsPage, EventDetails } from './views/events';
import { PipelinesPage, PipelineDetails, PipelinesNew } from './views/pipelines';
import { PermissionsPage, PermissionDetails } from './views/permissions';
import { RolesPage, RoleDetails } from './views/roles';
import { ApiKeysPage, ApiKeysDetails, ApiKeysNew } from './views/apiKeys';

import { DashboardPage } from './views/dashboard';
import CreateNewPage from './containers/createNew/CreateNewPage';

import { amplifyConfig, auth0 } from './utils/constants';

import App from './commonComponents/layout/App';
import { Login, NotFound, ForceHttps } from './commonComponents';
import { Auth0Provider } from '@auth0/auth0-react';
import { ROUTES } from './constants/routes';
import APIWrapper from './commonComponents/auth/APIContext';
import NewOtherMachine from './views/machines/containers/NewOtherMachine';

const store = configureStore();
Amplify.configure(amplifyConfig);

const onRedirectCallback = appState => {
  browserHistory.push(appState && appState.targetUrl ? appState.targetUrl : window.location.href);
};

ReactDOM.render(
  <Auth0Provider
    domain={auth0.domain}
    onRedirectCallback={onRedirectCallback}
    clientId={auth0.clientId}
    redirectUri={window.location.origin}
  >
    <Login>
      <APIWrapper>
        <Provider store={store}>
          <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
            <Redirect from="/" to="dashboard" />
            <Route component={ForceHttps}>
              <Route path="/" component={App}>
                <Route path="/dashboard" component={DashboardPage} />
                <Route path={ROUTES.plans.plans} component={PlansPage} />
                <Route path={ROUTES.plans.plansNew} component={PlanNew} />
                <Route path={ROUTES.plans.plansDetails('*')} component={PlanDetails} />
                <Route path="/machines" component={MachinesPage} />
                <Route path="/machines/new" component={SelectMachineType} />
                <Route path={ROUTES.machines.newOtherMachine} component={NewOtherMachine} />
                <Route path={ROUTES.machines.newAWSMachine} component={NewAWSMachine} />
                <Route path={ROUTES.machines.machineDetails('*')} component={MachineDetails} />
                <Route
                  path={ROUTES.notificationGroups.notificationGroups}
                  component={NotificationGroupsPage}
                />
                <Route
                  path={ROUTES.notificationGroups.notificationGroupsNew}
                  component={NotificationGroupNew}
                />
                <Route
                  path={ROUTES.notificationGroups.notificationGroupDetails('*')}
                  component={NotificationGroupDetails}
                />
                <Route path={ROUTES.permissions.assignments} component={PermissionsPage} />
                <Route path={ROUTES.permissions.assignmentsNew} component={CreateNewPage} />
                <Route
                  path={ROUTES.permissions.assignmentsDetails('*')}
                  component={PermissionDetails}
                />
                <Route path={ROUTES.permissions.roles} component={RolesPage} />
                <Route path={ROUTES.permissions.rolesNew} component={CreateNewPage} />
                <Route path={ROUTES.permissions.rolesDetails('*')} component={RoleDetails} />
                <Route path="/events" component={EventsPage} />
                <Route path="/events/new" component={CreateNewPage} />
                <Route path="/events/details/*" component={EventDetails} />
                <Route path={ROUTES.pipelines.pipelines} component={PipelinesPage} />
                <Route path={ROUTES.pipelines.pipelinesNew} component={PipelinesNew} />
                <Route path={ROUTES.pipelines.pipelinesDetails('*')} component={PipelineDetails} />
                <Route path={ROUTES.apiKeys.apiKeysDetails('*')} component={ApiKeysDetails} />
                <Route path={ROUTES.apiKeys.apiKeysNew} component={ApiKeysNew} />
                <Route path={ROUTES.apiKeys.apiKeys} component={ApiKeysPage} />
                <Route path="*" component={NotFound} />
              </Route>
            </Route>
          </Router>
        </Provider>
      </APIWrapper>
    </Login>
  </Auth0Provider>,
  document.getElementById('root'),
);

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { apiKeySchema } from '../apiKeysSchema';
import { postData, resetNewForm } from '../../../actions/createNew';
import { ROUTES } from '../../../constants/routes';
import { createAPIKey } from '../../../graphql/createAPIKey';
import { removeEmptyKeys } from '../../../utils/form';
import FormContainer from '../../../containers/form/FormContainer';
import ApiKeyCreatedModal from '../components/ApiKeyCreatedModal';
import { APIContext } from '../../../commonComponents/auth/APIContext';

function ApiKeysNew({ dispatch, error, isSending }) {
  const { requester } = React.useContext(APIContext);
  const [secret, setSecret] = useState(null);

  useEffect(() => {
    resetNewForm(dispatch);
  }, [dispatch]);

  function onSubmit(formData) {
    const input = removeEmptyKeys({
      ...formData,
      customer_id: localStorage.getItem('currentCustomer'),
    });
    const callback = (data, response) => {
      const secret =
        response &&
        response.data &&
        response.data.createAPIKey &&
        response.data.createAPIKey.secret;

      if (data && secret) {
        setSecret(secret);
        NotificationManager.success('Object has been successfully created');
      } else {
        NotificationManager.error('Unable to create object');
      }
    };

    postData(dispatch, createAPIKey, { input }, requester, callback);
  }

  function onCloseModal() {
    browserHistory.push(`${ROUTES.apiKeys.apiKeys}?refresh=true`);
  }

  return (
    <>
      <div className="main wide">
        <FormContainer
          parent={ROUTES.apiKeys.apiKeys}
          schema={apiKeySchema}
          className="create-new-form"
          type="new"
          onSubmit={onSubmit}
          error={error}
          disabled={isSending}
          onBack={() => browserHistory.goBack()}
        />
      </div>
      {secret && <ApiKeyCreatedModal apiKey={secret} onClose={onCloseModal} />}
    </>
  );
}

const mapStateToProps = state => {
  const { createNew } = state;

  return {
    error: createNew.error,
    isSending: createNew.isSending,
    dataSent: createNew.dataSent,
    response: createNew.response,
  };
};

export default connect(mapStateToProps)(ApiKeysNew);

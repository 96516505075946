/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { ButtonRemove, ButtonWithConfirm } from '../../../commonComponents';
import { Link } from 'react-router';
export const Pipeline = ({ item: pipeline, actions }) => {
  const { removeItem, forcePipeline } = actions;
  const href = `pipelines/details/${pipeline.id}`;

  return (
    <tr>
      <Link to={href} className="table-cell">
        {pipeline.name}
      </Link>
      <Link to={href} className="table-cell">
        {pipeline.cron_window_start}
      </Link>
      <Link to={href} className="table-cell">
        {pipeline.steps ? pipeline.steps.length : 0}
      </Link>
      <td>
        <ButtonRemove
          removeAction={e => {
            e && e.stopPropagation && e.stopPropagation();
            removeItem(pipeline.id);
          }}
          itemId={pipeline.id}
          itemName={pipeline.name}
          title="a pipeline named"
        />
        <ButtonWithConfirm
          action={e => {
            e && e.stopPropagation && e.stopPropagation();
            forcePipeline(pipeline.id);
          }}
          message={`Do you really want to force pipeline ${pipeline.name} (${pipeline.id}) execution? Patching will be started within 5 minutes.`}
        >
          <i className="fa fa-play" />
        </ButtonWithConfirm>
      </td>
    </tr>
  );
};

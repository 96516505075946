/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getNotificationGroups = `query NotificationGroupsBatch($customer_id: String!, $filters: [Filter!], $limit: Int!, $continuation_token: String) {
  notificationGroupsBatch(input: {
      customer_id: $customer_id
      limit: $limit
      continuation_token: $continuation_token
      filters: $filters
    }) {
    notification_groups {
      id
      customer_id
      name
      created_at
      updated_at
      events
      levels
      notifications {
        type
        channel
        web_hook_url
        email_config {
          address
          is_external
        }
        routing_key
        override_settings
        events
        levels
      }
    }
    continuation_token
  }
}`;

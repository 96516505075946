/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const approveEvent = `mutation ApproveEvent($customer_id: String!, $event_id: String!) {
    approveEvent(customer_id: $customer_id, event_id: $event_id) {
      id
    }
  }
  `;

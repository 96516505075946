/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { isEmptyObject } from '../../../utils/form';
import { errorTypeToMessage, genericError } from './errors';

// This should be better typed in the future.
type Error = {
  [x: string]: any;
};

export const extractError = (name: string, errors: Error) => {
  const splitName = name.split('.');

  const error =
    !isEmptyObject(errors) &&
    splitName?.reduce((acc: any, cur: string) => {
      return acc[cur] || '';
    }, errors);

  return error;
};

export const extractErrorMessage = (error: Error, label: string) => {
  const errorType = error?.message || errorTypeToMessage[error?.type]?.({ label });
  const errorMessage = Array.isArray(error) ? errorType : errorType || genericError;

  return errorMessage;
};

/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getPipeline = `query Pipeline($customer_id: String!, $id: String!) {
    pipeline(customer_id: $customer_id, id: $id) { 
      id
      customer_id
      name
      description
      metadata {
        key
        value
      }
      notification_groups
      cron_window_start
      upcoming_notification_time
      enable_baseline
      time_zone
      steps {
        description
        step_delay
        run_immediately
        policy
        stage
        plans {
          id
          window_length
          name
          description
          metadata {
            key
            value
          }
          parallel
          dry_run
          reboot_policy
          linux_security_only
          windows_update_category
          windows_update_severity
          machines {
            id
            name
            order
            pre_host_hooks {
              type
              source
              method
              status_code
            }
            post_host_hooks {
              type
              source
              method
              status_code
            }
            s3_custom_script
            exclude
          }
          pre_hooks {
            type
            source
            method
            status_code
          }
          post_hooks {
            type
            source
            method
            status_code
          }
          machines_tag {
            tag_list {
              key
              values
            }
            tag_condition {
              placeholders {
                key
                value
              }
              expression
            }
            iam_role
            iam_role_list
            pre_host_hooks {
              type
              source
              method
              status_code
            }
            post_host_hooks {
              type
              source
              method
              status_code
            }
            update_existing
            include_stopped
            allow_reboot
            reboot_policy
            regions
            s3_custom_script
          }
          s3_custom_script
        }
      }
    }
  }
  `;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const putMachine = `mutation PutMachine($input: MachinePutInput!) {
  putMachine(input: $input) { 
    id
  }
}
`;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const putAPIKey = `mutation PutAPIKey($input: APIKeyPutInput!) {
  putAPIKey(input: $input) {
    id
  }
}
`;

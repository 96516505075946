/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { getCustomers } from '../../graphql/getCustomers';
import { LoadingHOC } from '../loading/Loading';
import { APIContext } from '../auth/APIContext';

const Customer = ({ changeCustomer }) => {
  const { requester } = React.useContext(APIContext);
  const [data, setData] = React.useState();
  const [error, setError] = React.useState();

  const [isLoading, setIsLoading] = React.useState(false);

  const getData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await requester({ query: getCustomers });
      const sorted = response?.data?.customers?.sort((e1, e2) => {
        if (e1.name < e2.name) {
          return -1;
        } else if (e1.name > e2.name) {
          return 1;
        }
        return 0;
      });
      setData(sorted);
    } catch (err) {
      setError(err?.errors);
    } finally {
      setIsLoading(false);
    }
  }, [requester]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="App">
      <section className="sidebar no-hover" />
      <div className="main wide">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="title is-secondary">Customers list</h2>
              <h2 className="subtitle">Click on customer name to select one</h2>
              <div className="box is-clearfix">
                <LoadingHOC loading={isLoading}>
                  <ul className="customer-menu">
                    {data?.map(el => (
                      <li key={el.name}>
                        <span className="customer-select" onClick={() => changeCustomer(el)}>
                          {el.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {error?.map(er => (
                      <li key={er?.message}>
                        <span>{er?.message}</span>
                      </li>
                    ))}
                  </ul>
                </LoadingHOC>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Customer };

/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import VirtualizedSelect from 'react-virtualized-select';
import { locationList } from './consts';

type Props = {
  onChange: (value: string) => void;
  value: string;
};

type KeyValue = {
  label: string;
  value: string;
};

const timezones = locationList.map(location => ({
  value: location,
  label: location,
}));

export function TimezoneSelector({ onChange, value }: Props) {
  const selectedValue = React.useMemo(
    () => timezones.find(({ value: zoneValue }) => zoneValue === value),
    [value],
  );

  return (
    <VirtualizedSelect
      value={selectedValue ?? ''}
      onChange={(data: KeyValue) => onChange(data?.value)}
      options={timezones}
    />
  );
}

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { RuleModal } from './RuleModal';

class RuleFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.formData || [],
      popup: false,
    };
    this.switchVisibility = this.switchVisibility.bind(this);
    this.addRule = this.addRule.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.formData || [],
    });
  }

  switchVisibility() {
    this.setState(state => ({
      popup: !state.popup,
    }));
  }

  handleChange = data => {
    this.setState({ tempObj: data.formData });
  };

  ruleEdit = index => {
    this.setState(state => ({
      ruleIndex: index,
      rule: state.value[index],
      popup: true,
    }));
  };

  addRule(event) {
    const value = [...this.state.value];

    this.state.ruleIndex >= 0 ? (value[this.state.ruleIndex] = event) : value.push(event);
    this.setState({
      value: value,
      popup: false,
      ruleIndex: -1,
      rule: null,
    });
    this.props.onChange(value);
  }

  removeRule = ruleIndex => event => {
    const value = [...this.state.value];
    value.splice(ruleIndex, 1);
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  };

  render() {
    const { value: values, popup, rule } = this.state;

    return (
      <div className="is-input-like">
        {values.map((el, index) => {
          return (
            <div className="tag" style={{ height: 'auto' }}>
              <div onClick={() => this.ruleEdit(index)}>
                Resources:
                {el.resources && el.resources.map(re => <span> {re}</span>)}
                <br />
                Actions:
                {el.actions && el.actions.map(ac => <span> {ac}</span>)}
                <br />
                {el.resource_ids && (
                  <>
                    <span>Resource Ids:</span>
                    {el.resource_ids.map(id => (
                      <span> {id}</span>
                    ))}
                  </>
                )}
              </div>
              <i className="fa fa-trash is-pointer" onClick={this.removeRule(index)} />
            </div>
          );
        })}

        {popup && (
          <RuleModal
            addRule={this.addRule}
            hideModal={() => {
              this.setState({
                popup: false,
                ruleIndex: -1,
                rule: null,
              });
            }}
            formData={rule}
          />
        )}
        <button
          type="button"
          className="button"
          style={{ marginTop: '5px' }}
          onClick={this.switchVisibility}
        >
          Add New
        </button>
      </div>
    );
  }
}

export { RuleFormInput };

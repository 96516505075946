/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Select } from './Select';
import { Modal, FormButtonsContainer, FormButtonConfirm } from './Form';
import {
  CustomCheckboxWidget,
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import PartialHooksInput from './PartialHooksInput';
import Form from 'react-jsonschema-form';
import { stripUndefinedValues } from '../../utils';

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  SelectWidget: Select,
};

const uiSchema = {
  pre_host_hooks: {
    'ui:field': 'partial_hooks',
    classNames: 'is-horizontal',
  },
  post_host_hooks: {
    'ui:field': 'partial_hooks',
    classNames: 'is-horizontal',
  },
};

const fields = {
  partial_hooks: PartialHooksInput,
};

const schema = {
  type: 'object',
  properties: {
    no_patch: {
      type: 'boolean',
      title: 'Dont patch',
      default: false,
      description: 'Description!',
    },
    pre_host_hooks: {
      type: 'array',
      title: 'Pre Host Hooks',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            title: 'Type',
            anyOf: [
              { type: 'string', enum: ['aws'], title: 'AWS Lambda ARN' },
              { type: 'string', enum: ['azure'], title: 'Azure Functions URL' },
              { type: 'string', enum: ['script'], title: 'Script' },
            ],
          },
          source: {
            type: 'string',
            title: 'Value',
          },
        },
      },
    },
    post_host_hooks: {
      type: 'array',
      title: 'Post Host Hooks',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            title: 'Type',
            anyOf: [
              { type: 'string', enum: ['aws'], title: 'AWS Lambda ARN' },
              { type: 'string', enum: ['azure'], title: 'Azure Functions URL' },
              { type: 'string', enum: ['script'], title: 'Script' },
            ],
          },
          source: {
            type: 'string',
            title: 'Value',
          },
        },
      },
    },
  },
};

class PartialMachineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMachine: props.newMachine,
    };
  }

  handleChange = data => {
    this.setState({ newMachine: data.formData });
  };

  add = () => {
    let machines = this.props.machines;
    const index = machines.map(el => el.id).indexOf(this.state.newMachine.id);
    machines[index >= 0 ? index : this.state.newMachine.order - 1] = stripUndefinedValues(
      this.state.newMachine,
    );
    this.props.addMachine(machines);
  };

  render() {
    const { newMachine } = this.state;

    return (
      <Modal visible={true} cancelAction={this.add} title="Machine">
        <Form
          className=""
          schema={schema}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
          uiSchema={uiSchema}
          fields={fields}
          ArrayFieldTemplate={ArrayFieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          onChange={this.handleChange}
          formData={newMachine}
          widgets={widgets}
        >
          <FormButtonsContainer>
            <FormButtonConfirm onClick={this.add}>Add</FormButtonConfirm>
          </FormButtonsContainer>
        </Form>
      </Modal>
    );
  }
}

export { PartialMachineModal };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getRoles = `query RbacRolesBatch(
      $customer_id: String!
      $filters: [Filter!]
      $limit: Int!
      $continuation_token: String
    ) {
      rbacRolesBatch(
        input: {
          customer_id: $customer_id
          limit: $limit
          continuation_token: $continuation_token
          filters: $filters
        }
      ) {
        rbac_roles {
          id
          name
          description
          rules {
            resources
            actions
            resource_ids
          }
          created_at
          updated_at
        }
        continuation_token
      }
    }
  `;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';

const { fetchData, removeItem, editItem, actions } = ListActionsFactory({
  namespace: 'NOTIFICATION_GROUPS',
  api: 'aws',
  path: '/notification_groups',
});

export {
  fetchData as fetchNotificationGroups,
  removeItem as removeNotificationGroup,
  editItem as editNotificationGroup,
  actions,
};

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { postData, resetNewForm } from '../../actions/createNew';
import FormContainer from '../form/FormContainer';
import { rolesSchema } from '../../views/roles/rolesSchema';
import { permissionsSchema } from '../../views/permissions/permissionsSchema';
import { createActivation } from '../../graphql/createActivation';
import { createRole } from '../../graphql/createRole';
import { createPermission } from '../../graphql/createPermission';
import { ROUTES } from '../../constants/routes';
import { mapFormRoleToId } from '../../utils';
import { removeEmptyKeys } from '../../utils/form';
import { APIContext } from '../../commonComponents/auth/APIContext';

class CreateNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activationModal: false,
      modalContent: null,
      formData: {},
      params: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    resetNewForm(this.props.dispatch);

    let parent = this.props.location.pathname.split('/')[1];
    if (parent === 'permissions') {
      parent = this.props.location.pathname.split('/')[2];
    }
    parent = parent.replace('-', ' ');
    let children = this.props.location.pathname.split('/').pop();

    if (parent === 'machines') {
      this.setState({
        formData: {
          access: {
            type: 'SSM',
          },
        },
      });
    }

    this.setState({
      parent: parent,
      children: children,
      params: this.props.location.query,
    });
  }

  onSubmit(formData) {
    const { requester } = this.context;

    const { dispatch } = this.props;
    const { parent } = this.state;

    formData.customer_id = localStorage.getItem('currentCustomer');
    // remove machine provider, machine os & machines method field before sending
    formData.access && delete formData.access.provider;
    formData.os && delete formData.os;
    delete formData.machines_method;

    if (parent === 'permissions' || parent === 'assignments') {
      const rolesFormData = formData.roles.map(el => {
        const id = mapFormRoleToId(el);
        return { id: id };
      });
      formData.roles = [...rolesFormData];
    }
    const myInit = { input: removeEmptyKeys(formData) };
    const callback = (dataSent, response) => {
      if (dataSent) {
        NotificationManager.success('Object has been successfully created');
        if (response && response.data && response.data.createActivation) {
          this.setState({
            activationModal: true,
            modalContent: response.data.createActivation,
          });
        } else {
          if (parent === 'assignments') {
            return browserHistory.push(ROUTES.permissions.assignments + '?refresh=true');
          }
          if (parent === 'roles') {
            return browserHistory.push(ROUTES.permissions.roles + '?refresh=true');
          }
          browserHistory.push('/' + this.state.parent + '?refresh=true');
        }
      } else {
        NotificationManager.error('Unable to create object');
      }
    };

    postData(dispatch, this.getOperation(parent), myInit, requester, callback);
  }

  getSchema(parent, children) {
    switch (parent) {
      case 'roles':
        return rolesSchema;
      case 'assignments':
        const { type, subject } = this.state.params;
        const schema = JSON.parse(JSON.stringify(permissionsSchema));
        if (type && subject) {
          schema.properties.type.default = type;
          schema.properties.subject.default = subject;
        }
        return schema;
      default:
        return {};
    }
  }

  getOperation(parent) {
    switch (parent) {
      case 'activations':
        return createActivation;
      case 'roles':
        return createRole;
      case 'assignments':
        return createPermission;
      default:
        return {};
    }
  }

  hideActivationsModal(parent) {
    this.setState({ ...this.state, activationModal: false }, () => {
      browserHistory.push('/' + parent + '/?refresh=true');
    });
  }

  render() {
    const { error, isSending } = this.props;
    const { parent, children } = this.state;
    const schema = this.getSchema(parent, children);
    return (
      <div className="main wide">
        <FormContainer
          parent={parent}
          schema={schema}
          className="create-new-form"
          type="new"
          onSubmit={this.onSubmit}
          error={error}
          disabled={isSending}
          onBack={() => browserHistory.goBack()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { createNew } = state;

  return {
    error: createNew.error,
    isSending: createNew.isSending,
    dataSent: createNew.dataSent,
    response: createNew.response,
  };
};

export default connect(mapStateToProps)(CreateNewPage);

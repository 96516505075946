/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { pageSize } from '../../../utils';
import { fetchPermissions } from '../../permissions/actions';
import { getPermissions } from '../../../graphql/getPermissions';
import { ROUTES } from '../../../constants/routes';
import { Loading } from '../../../commonComponents';
import { APIContext } from '../../../commonComponents/auth/APIContext';

function PermissionRow({ id, roles }) {
  return (
    <tr key={id}>
      <td>
        <Link to={ROUTES.permissions.assignmentsDetails(id)}>{id}</Link>
      </td>
      <td>
        {roles.map(({ id, name }) => (
          <button
            className="button is-small is-success"
            onClick={event => {
              event.stopPropagation();
              browserHistory.push(ROUTES.permissions.rolesDetails(id));
            }}
            title={name}
          >
            {name}
          </button>
        ))}
      </td>
    </tr>
  );
}

const PermissionShape = {
  id: PropTypes.string.isRequired,
  roles: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }),
};

PermissionRow.propTypes = PermissionShape;

function EmptyState() {
  return (
    <tr>
      <td colSpan="2">
        <p className="has-text-centered">No Assignments for this API key!</p>
      </td>
    </tr>
  );
}

function ApiKeyDetailsPermissions({ apiKey, dispatch, isFetching, permissions }) {
  const { requester } = React.useContext(APIContext);

  useEffect(() => {
    const params = {
      limit: pageSize,
      customer_id: localStorage.getItem('currentCustomer'),
      filters: {
        operation: 'EQ',
        key: 'subject',
        value: apiKey.id,
      },
    };

    fetchPermissions({
      dispatch,
      myInit: params,
      requester,
      operation: getPermissions,
      allPages: true,
    });
  }, [dispatch, requester, apiKey.id]);

  function renderContent() {
    if (isFetching) {
      return <Loading />;
    }
    if (permissions.length === 0) {
      return <EmptyState />;
    }
    return permissions.map(({ id, roles }) => <PermissionRow id={id} roles={roles} />);
  }

  return (
    <table className="table is-fullwidth is-hoverable is-striped">
      <thead>
        <tr>
          <th>Id</th>
          <th>Roles</th>
        </tr>
      </thead>
      <tbody>{renderContent()}</tbody>
    </table>
  );
}

ApiKeyDetailsPermissions.propTypes = {
  apiKey: PropTypes.shape({
    id: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PermissionShape),
};

const mapStateToProps = state => {
  const {
    permissions = {
      items: [],
      paginationToken: null,
      isFetching: false,
      error: null,
      removed: false,
    },
  } = state;

  return {
    error: permissions.error,
    isFetching: permissions.isFetching,
    permissions: permissions.items,
    paginationToken: permissions.paginationToken,
    removed: permissions.removed,
  };
};

export default connect(mapStateToProps)(ApiKeyDetailsPermissions);

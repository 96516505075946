/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import moment from 'moment';

export const FormattedDate = ({ children }) =>
  moment(children)
    .utc()
    .format('DD-MM-YYYY, HH:mm:ss', 'Europe/Helsinki');

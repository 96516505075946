/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import { stringRequired } from '../validation/string';
import { WebhookTriggerType } from '../../../graphql/generated-types';

export enum WebhookPayloadType {
  ItsmProjectKey = 'itsm_project_key',
}

export const WebHookInputSchema = z.object({
  trigger_type: z.nativeEnum(WebhookTriggerType, {
    errorMap: (error, ctx) => {
      if (error.code === z.ZodIssueCode.invalid_enum_value) {
        return { message: 'Invalid webhook trigger type value' };
      }
      return { message: ctx.defaultError };
    },
  }),
  payload: stringRequired('Payload'),
  payload_type: z.nativeEnum(WebhookPayloadType, {
    errorMap: (error, ctx) => {
      if (error.code === z.ZodIssueCode.invalid_enum_value) {
        return { message: 'Invalid webhook payload type value' };
      }
      return { message: ctx.defaultError };
    },
  }),
});

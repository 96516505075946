/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import Chips from 'react-chips';
import { awsRegions } from '../../constants/regions';

type RegionsInputProps = {
  value: string[];
  onChange: (regions: string[]) => void;
};

function RegionsInput({ value, onChange }: RegionsInputProps) {
  return (
    <Chips
      value={value}
      suggestions={awsRegions}
      onChange={onChange}
      placeholder="Select regions"
      fromSuggestionsOnly={true}
    />
  );
}

export default RegionsInput;

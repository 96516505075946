/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { ButtonRemove, ButtonWithConfirm } from '../../../commonComponents';

const Plan = ({ item: plan, actions = {} }) => {
  const { removeItem, forcePlan } = actions;
  const machinesCount = plan.machines ? plan.machines.length : 0;
  const href = plan.pipeline_id
    ? `pipelines/details/${plan.pipeline_id}`
    : `plans/details/${plan.id}`;
  const onHold =
    plan.on_hold_start && plan.on_hold_end
      ? moment().isBetween(moment(plan.on_hold_start), moment(plan.on_hold_end))
      : false;

  return (
    <tr id={plan.id}>
      <Link to={href} className="table-cell">
        {plan.name}
      </Link>
      <Link to={href} className="table-cell">
        {plan.id}
      </Link>
      <Link to={href} className="table-cell">
        {plan.cron_window_start}
      </Link>
      <Link to={href} className="table-cell">
        {`${plan.window_length}${!plan.pipeline_id ? 'h' : 'm'}`}
      </Link>
      <Link to={href} className="table-cell">
        {plan.dry_run ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
      </Link>
      <Link to={href} className="table-cell">
        {onHold && plan.on_hold_end ? (
          moment(plan.on_hold_end).format('DD-MM-YYYY')
        ) : (
          <i className="fa fa-times" />
        )}
      </Link>
      <Link to={href} className="table-cell">
        {machinesCount}
      </Link>
      {!plan.pipeline_id && (
        <td>
          <ButtonWithConfirm
            action={e => {
              e && e.stopPropagation && e.stopPropagation();
              forcePlan(plan.id);
            }}
            message={`Do you really want to force plan ${plan.name} (${plan.id}) execution? Patching will be started within 5 minutes.`}
          >
            <i className="fa fa-play" />
          </ButtonWithConfirm>
          <ButtonRemove
            removeAction={e => {
              e && e.stopPropagation && e.stopPropagation();
              removeItem(plan.id);
            }}
            itemId={plan.id}
            itemName={plan.name}
            title="a plan named"
          />
        </td>
      )}
    </tr>
  );
};

export default Plan;

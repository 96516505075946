/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { theme } from './NotificationInputTheme';
import { RESOURCES } from '../../utils/constants';
import Chips, { Chip } from 'react-chips';

class RuleResourcesInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      list: this.props.formData || [],
    };
  }

  componentWillReceiveProps(props) {
    if (typeof props.formData === 'object' && props.formData !== null) {
      this.setState({
        list: Object.keys(props.formData).map(key => props.formData[key]),
      });
    }
  }

  onChange(chips) {
    this.setState({
      list: chips,
    });
    this.props.onChange(chips);
  }

  render() {
    const suggestions = Object.keys(RESOURCES);
    return (
      <Chips
        value={this.state.list}
        suggestions={suggestions}
        alwaysRenderSuggestions={this.state.list.length === 0}
        onChange={this.onChange}
        placeholder="Select Resources"
        fromSuggestionsOnly={true}
        theme={{
          ...theme,
          suggestionsList: {
            ...theme.suggestionsList,
            maxHeight: '200px',
            overflow: 'scroll',
          },
        }}
        style={{ cursor: 'pointer' }}
        renderSuggestion={s => RESOURCES[s].name}
        renderChip={s => <Chip>{RESOURCES[s].name}</Chip>}
      />
    );
  }
}

export default RuleResourcesInput;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editRoles, fetchRoles } from '../actions';
import { getRole } from '../../../graphql/getRole';
import { putRole } from '../../../graphql/putRole';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import FormContainer from '../../../containers/form/FormContainer';
import { rolesSchema } from '../rolesSchema';
import { FormattedDate, FormButtonCancel, FormButtonEdit } from '../../../commonComponents';
import { Errors } from '../../../commonComponents/form/Validation';
import diff from 'deep-diff';
import { Loading } from '../../../commonComponents/loading/Loading';
import { ROUTES } from '../../../constants/routes';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const Param = ({ label, value, className, children }) => (
  <div className={`column ${className} `}>
    <strong className="is-desc-label">{label}</strong>
    <br />
    {children ? children : value ? value : <i className="is-italic">No data provided</i>}
  </div>
);

class RoleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: 'details',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.formTypeChange = this.formTypeChange.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;
    const { dispatch, roles, routeParams } = this.props;
    const customer_id = localStorage.getItem('currentCustomer');
    if (!roles) {
      const roleInit = {
        customer_id: customer_id,
        id: routeParams.splat,
      };
      fetchRoles({
        dispatch,
        myInit: roleInit,
        requester,
        operation: getRole,
      });
    }
  }

  formTypeChange() {
    if (this.state.formType === 'details') {
      this.setState({
        formType: 'edit',
      });
    } else {
      this.setState({
        formType: 'details',
      });
    }
  }

  onSubmit(formData) {
    const { requester } = this.context;
    const { dispatch, roles } = this.props;
    formData.id = roles.id;
    formData.customer_id = localStorage.getItem('currentCustomer');

    const changes = diff(formData, roles);
    changes.forEach(
      e => e.kind === 'E' && !e.lhs && e.lhs !== false && e.rhs && (formData[e.path[0]] = null),
    );
    const myInit = { input: formData };

    const callback = () => {
      NotificationManager.success('Successfully updated role [' + roles.id + '] details');
      browserHistory.push(`${ROUTES.permissions.roles}?refresh=true`);
    };

    editRoles({ dispatch, myInit, operation: putRole, requester, callback });
  }

  getFormData(roles) {
    const { id, created_at, updated_at, ...formData } = roles;
    return formData;
  }

  render() {
    const { roles, error, isFetching } = this.props;
    const { formType } = this.state;
    const parent = 'roles';
    const formData = roles ? this.getFormData(roles) : {};
    const errors = { errors: error };

    return (
      <div className="main wide">
        {!roles ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="box">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title">Role Details</h1>
                  <h2 className="subtitle is-size-5">{roles.name ? roles.name : roles.id}</h2>
                </div>
                <Param label="Created At">
                  <FormattedDate>{roles.created_at}</FormattedDate>
                </Param>
                <Param label="Updated At">
                  <FormattedDate>{roles.updated_at}</FormattedDate>
                </Param>
                {formType === 'details' ? (
                  <FormButtonEdit className="pull-right" onClick={this.formTypeChange}>
                    Edit
                  </FormButtonEdit>
                ) : (
                  <FormButtonCancel className="pull-right" onClick={this.formTypeChange}>
                    Cancel
                  </FormButtonCancel>
                )}
              </div>
            </div>

            {errors.errors && errors.errors.length > 0 && Errors(errors)}

            {formType === 'edit' ? (
              <FormContainer
                parent={parent}
                schema={rolesSchema}
                className="details-form"
                type="edit"
                onSubmit={this.onSubmit}
                onBack={this.formTypeChange}
                formData={formData}
                disabled={isFetching}
              />
            ) : (
              <React.Fragment>
                <div className="box">
                  <div className="columns">
                    <Param label="Name" value={roles.name} />
                    <Param label="Description" value={roles.description} />
                  </div>
                </div>

                <div className="box">
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <div className="title is-size-4">Rules</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {roles.rules.map((r, i) => {
                      return (
                        <div className="columns">
                          <Param label="Resources:" value={r.resources?.join(', ')} />
                          <Param label="Actions:" value={r.actions?.join(', ')} />
                          <Param label="Resource Ids:" value={r.resource_ids?.join(', ')} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

RoleDetails.propTypes = {
  roles: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { roles } = state;
  const rolesId = ownProps.routeParams.splat || null;

  return {
    roles: roles.items.find(roles => roles.id === rolesId),
    error: roles.error,
    isFetching: roles.isFetching,
  };
};

export default connect(mapStateToProps)(RoleDetails);

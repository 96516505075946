/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { getMessageFromErrorResponse } from '../../utils/apiUtils';

export function ListActionsFactory({ namespace }) {
  const INIT_ACTION = `${namespace}_REQUEST`;
  const SUCCESS_ACTION = `${namespace}_SUCCESS`;
  const SUCCESS_PREFETCH = `${namespace}_SUCCESS_PREFETCH`;
  const FAILURE_ACTION = `${namespace}_FAILURE`;
  const REMOVE_SUCCESS_ACTION = `${namespace}_REMOVE_SUCCESS`;
  const EDIT_SUCCESS_ACTION = `${namespace}_EDIT_SUCCESS`;
  const RESET_ERRORS_ACTION = `${namespace}_RESET_ERRORS`;

  const init = () => ({ type: INIT_ACTION });

  const success = payload => {
    return {
      type: SUCCESS_ACTION,
      items: payload.items,
      requestedToken: payload.requestedToken,
      paginationToken: payload.paginationToken,
    };
  };

  const prefetch = payload => {
    return {
      type: SUCCESS_PREFETCH,
      items: payload.items,
    };
  };

  const failure = (error, callback) => {
    callback && callback();
    return {
      type: FAILURE_ACTION,
      error,
    };
  };

  const removeSuccess = (callback, itemsUpdate) => {
    callback && callback();
    return { type: REMOVE_SUCCESS_ACTION, itemsUpdate };
  };

  const editSuccess = callback => {
    callback && callback();
    return { type: EDIT_SUCCESS_ACTION };
  };

  return {
    fetchData: async ({ dispatch, myInit, requester, operation, allPages, prefetchData = false }) => {
      myInit = { ...(myInit || {}), customer_id: localStorage.getItem('currentCustomer') };

      dispatch(init());
      try {
        let response = await requester({ query: operation, input: myInit });

        if (response && response.data) {
          let data = response.data[Object.keys(response.data)[0]];
          if (data.continuation_token !== undefined) {
            const requestedToken = myInit.continuation_token;
            const itemsKey = Object.keys(data).filter(k => k !== 'continuation_token')[0];
            let items = data[itemsKey];
            
            if (allPages) {
              if (prefetchData) {
                dispatch(prefetch({ items }));
              }
              
              while (data.continuation_token) {
                myInit.continuation_token = data.continuation_token;
                response = await requester({ query: operation, input: myInit });
                if (response && response.data) {
                  data = response.data[Object.keys(response.data)[0]];
                  items = [...items, ...(data[itemsKey] || [])];
                }
              }
            }
            dispatch(
              success({
                items,
                paginationToken: data.continuation_token,
                requestedToken,
              }),
            );
          } else {
            dispatch(success({ items: Array.isArray(data) ? data : [data] }));
          }
        }
      } catch (err) {
        dispatch(failure(getMessageFromErrorResponse(err)));
      }
    },
    removeItem: async ({ dispatch, myInit, requester, operation, callback, itemsUpdate }) => {
      dispatch(init());

      myInit && (myInit.customer_id = localStorage.getItem('currentCustomer'));

      try {
        const response = await requester({ query: operation, input: myInit });
        response && dispatch(removeSuccess(callback, itemsUpdate));
      } catch (err) {
        dispatch(failure(getMessageFromErrorResponse(err)));
      }
    },
    editItem: async ({ dispatch, myInit, requester, operation, callback }) => {
      dispatch(init());
      try {
        const response = await requester({ query: operation, input: myInit });
        response && dispatch(editSuccess(callback));
      } catch (err) {
        dispatch(failure(getMessageFromErrorResponse(err)));
      }
    },
    resetErrors: ({ dispatch }) => {
      dispatch({ type: RESET_ERRORS_ACTION });
    },
    actions: {
      INIT_ACTION,
      SUCCESS_ACTION,
      SUCCESS_PREFETCH,
      FAILURE_ACTION,
      REMOVE_SUCCESS_ACTION,
      EDIT_SUCCESS_ACTION,
      RESET_ERRORS_ACTION,
    },
  };
}

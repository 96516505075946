/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getActivationCode = `query Activation($customer_id: String!) {
    activation(customer_id: $customer_id) { 
      id
      code
      region
    }
  }
  `;

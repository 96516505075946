/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { amplifyConfig } from '../../utils/constants';
import Amplify from 'aws-amplify';
import { Loading } from '../loading/Loading';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: undefined,
    };
  }

  checkCurrentUser = () => {
    const { isAuthenticated, loginWithRedirect, isLoading } = this.props.auth0;

    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: {
          targetUrl: window.location.pathname,
        },
      });
    }
  };

  render() {
    this.checkCurrentUser();
    const { children } = this.props;

    const { isAuthenticated, getIdTokenClaims } = this.props.auth0;

    if (isAuthenticated && !this.state.authToken) {
      getIdTokenClaims().then(c => {
        amplifyConfig.API.graphql_headers = async () => ({
          Authorization: c.__raw,
        });
        Amplify.configure(amplifyConfig);
        this.setState({ authToken: c.__raw });
      });
    }

    return this.state.authToken ? (
      children
    ) : (
      <div className="displayFlex">
        <Loading />
        Loading...
      </div>
    );
  }
}

export default withAuth0(Login);

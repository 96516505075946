/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal, FormGroup, Panel } from '../../../commonComponents';
import { getActivationCode } from '../../../graphql/getActivationCode';
import { LoadingHOC } from '../../../commonComponents/loading/Loading';
import { APIContext } from '../../../commonComponents/auth/APIContext';
import { showErrorMessage } from '../../../utils/notifications';

const ActivationsModal = ({ show, hideModal }) => {
  const { requester } = React.useContext(APIContext);
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const customer_id = localStorage.getItem('currentCustomer');

  const getData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await requester({ query: getActivationCode, input: { customer_id } });
      setData(response.data);
    } catch (err) {
      err?.errors?.forEach(e => showErrorMessage(e?.message));
    } finally {
      setIsLoading(false);
    }
  }, [customer_id, requester]);

  React.useEffect(() => {
    if (show) {
      getData();
    }
  }, [getData, show]);

  const { id, code, region } = data?.activation || {};
  return (
    <Modal visible={show} cancelAction={hideModal} title={`Activation id: ${id || ''}`} data={data}>
      <LoadingHOC loading={isLoading}>
        <div className="text-center">
          <h2>
            Your activation code is <span className="label">{code}</span>
            <br />
            Activation region: <span className="label">{region}</span>
            <br />
            Your registration scripts are shown below.
          </h2>
          <FormGroup controlId="hash">
            <Panel header="Ubuntu" eventKey="1">
              <h3 className="text-left">
                <p className="terminal">
                  mkdir /tmp/ssm
                  <br />
                  sudo curl
                  https://s3.amazonaws.com/ec2-downloads-windows/SSMAgent/latest/debian_amd64/amazon-ssm-agent.deb
                  -o /tmp/ssm/amazon-ssm-agent.deb
                  <br />
                  sudo dpkg -i /tmp/ssm/amazon-ssm-agent.deb
                  <br />
                  sudo service amazon-ssm-agent stop
                  <br />
                  sudo amazon-ssm-agent -register -code "{code}" -id "{id}" -region "{region}"
                  <br />
                  sudo service amazon-ssm-agent start
                </p>
              </h3>
            </Panel>
          </FormGroup>
          <FormGroup controlId="hash">
            <Panel header="Windows" eventKey="1">
              <h3 className="text-left">
                <p className="terminal">
                  $dir = $env:TEMP + "\ssm"
                  <br />
                  New-Item -ItemType directory -Path $dir
                  <br />
                  cd $dir
                  <br />
                  (New-Object
                  System.Net.WebClient).DownloadFile("https://amazon-ssm-eu-central-1.s3.amazonaws.com/latest/windows_amd64/AmazonSSMAgentSetup.exe",
                  $dir + "\AmazonSSMAgentSetup.exe")
                  <br />
                  Start-Process .\AmazonSSMAgentSetup.exe -ArgumentList @("/q", "/log",
                  "install.log", "CODE={code}", "ID=
                  {id}", "REGION={region}") -Wait
                  <br />
                  Get-Content ($env:ProgramData + "\Amazon\SSM\InstanceData\registration")
                  <br />
                  Get-Service -Name "AmazonSSMAgent"
                  <br />
                  sc.exe config AmazonSSMAgent start= delayed-auto
                </p>
              </h3>
            </Panel>
          </FormGroup>
          <FormGroup controlId="hash">
            <Panel header="Amazon Linux, RHEL 6.x, and CentOS 6.x" eventKey="1">
              <h3 className="text-left">
                <p className="terminal">
                  mkdir /tmp/ssm
                  <br />
                  sudo curl
                  https://s3.amazonaws.com/ec2-downloads-windows/SSMAgent/latest/linux_amd64/amazon-ssm-agent.rpm
                  -o /tmp/ssm/amazon-ssm-agent.rpm
                  <br />
                  sudo yum install -y /tmp/ssm/amazon-ssm-agent.rpm
                  <br />
                  sudo stop amazon-ssm-agent
                  <br />
                  sudo amazon-ssm-agent -register -code "{code}" -id "{id}" -region "{region}"
                  <br />
                  sudo start amazon-ssm-agent
                </p>
              </h3>
            </Panel>
          </FormGroup>
          <FormGroup controlId="hash">
            <Panel header="RHEL 7.x and CentOS 7.x:" eventKey="1">
              <h3 className="text-left">
                <p className="terminal">
                  mkdir /tmp/ssm
                  <br />
                  sudo curl
                  https://s3.amazonaws.com/ec2-downloads-windows/SSMAgent/latest/linux_amd64/amazon-ssm-agent.rpm
                  -o /tmp/ssm/amazon-ssm-agent.rpm
                  <br />
                  sudo yum install -y /tmp/ssm/amazon-ssm-agent.rpm
                  <br />
                  sudo systemctl stop amazon-ssm-agent
                  <br /> sudo amazon-ssm-agent -register -code "{code}" -id "{id}" -region "{region}
                  "
                  <br />
                  sudo systemctl start amazon-ssm-agent
                </p>
              </h3>
            </Panel>
          </FormGroup>
          <FormGroup controlId="hash">
            <Panel header="SLES" eventKey="1">
              <h3 className="text-left">
                <p className="terminal">
                  mkdir /tmp/ssm
                  <br />
                  sudo wget
                  https://s3.amazonaws.com/ec2-downloads-windows/SSMAgent/latest/linux_amd64/amazon-ssm-agent.rpm
                  <br />
                  sudo rpm --install amazon-ssm-agent.rpm
                  <br />
                  sudo systemctl stop amazon-ssm-agent
                  <br />
                  sudo amazon-ssm-agent -register -code "{code}" -id "{id}" -region "{region}"
                  <br />
                  sudo systemctl enable amazon-ssm-agent
                  <br />
                  sudo systemctl start amazon-ssm-agent
                </p>
              </h3>
            </Panel>
          </FormGroup>
        </div>
      </LoadingHOC>
    </Modal>
  );
};

export default ActivationsModal;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FormButtonCancel,
    FormButtonEdit,
    FormattedDate,
  } from '../../../commonComponents';
import { Param } from '../../../commonComponents/layout/Param';

function ApiKeyDetailsHeader({ apiKey, formType, formTypeChange }) {
  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <h1 className="title">API key Details</h1>
          <h2 className="subtitle is-size-5">{apiKey.name}</h2>
        </div>
        <Param label="Created At">
          <FormattedDate>{apiKey.created_at}</FormattedDate>
        </Param>
        <Param label="Updated At">
          <FormattedDate>{apiKey.updated_at}</FormattedDate>
        </Param>
        {formType === 'details' ? (
          <FormButtonEdit className="pull-right" onClick={formTypeChange}>
            Edit
          </FormButtonEdit>
        ) : (
          <FormButtonCancel className="pull-right" onClick={formTypeChange}>
            Cancel
          </FormButtonCancel>
        )}
      </div>
    </div>
  );
}

ApiKeyDetailsHeader.propTypes = {
  apiKey: PropTypes.shape({
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }),
  formType: PropTypes.string.isRequired,
  formTypeChange: PropTypes.func.isRequired,
};

export default ApiKeyDetailsHeader;

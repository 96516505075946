/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { ConfirmModal } from '../modals/Confirm';

class ButtonWithConfirm extends React.Component {
  constructor() {
    super();

    this.state = { showModal: false };
  }
  toggleModal = e => {
    e && e.stopPropagation && e.stopPropagation();

    const { showModal } = this.state;

    this.setState({ showModal: !showModal });
  };
  toggleAndConfirm = e => {
    const { action } = this.props;
    this.setState({ showModal: false });
    action();
  };
  render() {
    const { showModal } = this.state;
    const {
      message = '',
      actionButtonLabel = 'Confirm',
      title = '',
      children,
      className = 'button pull-right is-icononly is-danger',
    } = this.props;

    return (
      <React.Fragment>
        <ConfirmModal
          visible={showModal}
          hideModal={this.toggleModal}
          title={title}
          message={message}
          actionButtonName={actionButtonLabel}
          actionFunc={this.toggleAndConfirm}
        />
        {
          <button onClick={this.toggleModal} className={className}>
            {children}
          </button>
        }
      </React.Fragment>
    );
  }
}

export { ButtonWithConfirm };

/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import { safeStr, stringRequired } from '../../../../commonComponents/form2/validation/string';
import { rebootPolicySchemaValues } from '../../../../commonComponents/form2/schema/rebootPolicy';
import { awsRegionsOptions } from '../../../../constants/regions';
import { Provider } from '../../../../graphql/generated-types';
import { OsType } from '../../consts';

const regions = awsRegionsOptions.map(({ value }) => value) as [string, ...string[]];

export const EditMachineFormSchema = z
  .object({
    // 1st step
    name: stringRequired('Name'),
    provider: z
      .enum([Provider.Aws, Provider.Azure, Provider.Gcp, Provider.Ibm, Provider.Other])
      .optional(),
    os: z.enum([OsType.unknown, OsType.linux, OsType.windows]).optional(),
    access: z.object({
      ssm_machine_id: stringRequired('SSM instance ID'),
      iam_role: safeStr.optional(),
      region: z.enum(regions).optional(),
    }),
    azure_info: z
      .object({
        subscription_id: safeStr.optional(),
        subscription_name: safeStr.optional(),
        resource_group_name: safeStr.optional(),
      })
      .optional(),
    update_category: safeStr.optional(),
    update_severity: safeStr.optional(),
    include_kbs: safeStr.optional(),
    exclude_kbs: safeStr.optional(),

    // 2nd step
    description: safeStr.optional(),
    reboot_policy: z.enum(rebootPolicySchemaValues).optional(),
    published_days_old: z.preprocess(
      value => (value === null || Number.isNaN(value) ? undefined : value),
      z
        .number()
        .int({ message: 'Days since update must be an integer' })
        .nonnegative({ message: 'Days since update must be positive' })
        .optional(),
    ),
    s3_custom_script: safeStr.optional(),
  })
  .superRefine(({ provider, access }, ctx) => {
    if (provider === Provider.Aws) {
      if (!access?.iam_role) {
        return ctx.addIssue({
          path: ['access.iam_role'],
          code: z.ZodIssueCode.custom,
          message: `IAM role is required`,
        });
      }

      if (!access?.region) {
        return ctx.addIssue({
          path: ['access.region'],
          code: z.ZodIssueCode.custom,
          message: `Instance region is required`,
        });
      }
    }
  });

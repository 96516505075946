/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.value,
    });
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
    this.props.options.enumOptions
      ? this.props.onChange(event.target.value)
      : this.props.onChange(event);
  }

  render() {
    const { value } = this.state;
    const { options, id, disabled, readonly } = this.props;

    let opts = options.enumOptions ? options.enumOptions : options;
    opts = opts.map((option, index) => (
      <option value={option.value || option} key={index}>
        {option.label || option}
      </option>
    ));

    return (
      <div className="field-body">
        <div className="control">
          <div className="select is-fullwidth">
            <select
              id={id}
              onChange={this.handleChange}
              value={value}
              disabled={disabled || readonly}
            >
              {!disabled && !readonly && <option value="emptySelectValue">Please select</option>}
              {opts}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export { Select };

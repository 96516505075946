/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import Chips, { Chip } from 'react-chips';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { LoadingHOC } from '../loading/Loading';
import { getMachines } from '../../graphql/getMachines';
import { fetchMachines } from '../../views/machines/actions';
import { MachineModal } from './MachineModal';
import { pageSize } from '../../utils';
import { Loading } from '../loading/Loading';
import { APIContext } from '../auth/APIContext';

const DragHandle = SortableHandle(() => (
  <span>
    <i className="fa fa-bars" />
  </span>
));

const EditHandle = ({ onEdit }) => (
  <span className="edit-button" onClick={onEdit}>
    <i className="fa fa-edit" />
  </span>
);

const MachineItem = SortableElement(({ machine, onRemove, chipTheme, onEdit }) => {
  return (
    <div>
      <DragHandle />
      <EditHandle onEdit={onEdit} />
      <Chip theme={chipTheme} onRemove={onRemove}>
        {machine.name ? machine.name : machine.id}
      </Chip>
    </div>
  );
});

const MachinesList = SortableContainer(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        popup: false,
      };
      this.onChipsChange = this.onChipsChange.bind(this);
      this.renderChip = this.renderChip.bind(this);
      this.hideModal = this.hideModal.bind(this);
    }

    onChipsChange(newValue) {
      const { onChange } = this.props,
        newMachineIndex = newValue.map(el => typeof el).indexOf('string');

      if (newMachineIndex >= 0) {
        this.setState({
          popup: true,
          tempValues: newValue,
          newMachine: { id: newValue[newMachineIndex], order: newValue.length },
          oldIdx: null,
        });
      } else {
        this.setState(
          {
            popup: false,
          },
          onChange(
            newValue.map((machine, index) => {
              const machineInfo = machine.id.split(' ');
              return typeof machine === 'string'
                ? {
                    id: machineInfo[0],
                    name: machineInfo[1] ? machineInfo[1].split(/[:,]/)[1] : '',
                    order: index + 1,
                  }
                : {
                    ...machine,
                    id: machineInfo[0],
                    name: machine.name
                      ? machine.name
                      : machineInfo[1]
                      ? machineInfo[1].split(/[:,]/)[1]
                      : '',
                    order: index + 1,
                  };
            }),
          ),
        );
      }
    }

    renderChip(chip, theme) {
      const { onChipRemove, machines } = this.props;
      const idx = chip.order - 1;
      return (
        <MachineItem
          machine={chip}
          index={idx}
          chipTheme={theme}
          onRemove={() => {
            onChipRemove(chip);
          }}
          onEdit={() => {
            this.setState({
              popup: true,
              tempValues: machines,
              newMachine: chip,
              oldIdx: idx,
            });
          }}
        />
      );
    }

    hideModal() {
      this.setState({ popup: false });
    }

    render() {
      const { machines, suggestions, isFetching } = this.props;
      const { popup, tempValues, newMachine, oldIdx } = this.state;
      return (
        <React.Fragment>
          {popup && (
            <MachineModal
              machines={tempValues}
              newMachine={newMachine}
              addMachine={this.onChipsChange}
              hideModal={this.hideModal}
              oldIdx={oldIdx}
            />
          )}
          <div style={{ position: 'absolute', left: '-9rem', top: '-0.5rem' }}>
            {isFetching && <Loading />}
          </div>
          <Chips
            value={machines}
            suggestions={suggestions}
            onChange={this.onChipsChange}
            renderChip={this.renderChip}
            getChipValue={chip => chip.id}
            placeholder="Start typing a machine id here"
            theme={{
              ...Chips.defaultProps.theme,
              chipsContainer: {
                ...Chips.defaultProps.theme.chipsContainer,
                display: 'block',
                width: '100%',
              },
              suggestionsList: {
                ...Chips.defaultProps.theme.suggestionsList,
                maxHeight: '57vh',
                overflow: 'scroll',
              },
            }}
            chipTheme={{ chip: { display: 'inline-block' } }}
            fromSuggestionsOnly={true}
          />
        </React.Fragment>
      );
    }
  },
);

class MachinesSortable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machines: this.sortOrder(props.formData || []),
    };
    this.onChipRemove = this.onChipRemove.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;

    fetchMachines({
      dispatch: this.props.dispatch,
      myInit: { limit: pageSize },
      requester,
      operation: getMachines,
      allPages: true,
    });
  }

  componentWillReceiveProps(props) {
    this.onChange(this.sortOrder(props.formData || []));
  }

  onChipRemove(chip) {
    this.onChange(oldState => {
      const idx = chip.order - 1;
      const left = oldState.machines.slice(0, idx);
      const right = oldState.machines.slice(idx + 1);
      return [...left, ...right];
    });
  }

  onChange(items) {
    this.setState(
      {
        machines: items,
      },
      () => {
        this.props.onChange(items);
      },
    );
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { machines } = this.state;
    machines.splice(newIndex, 0, ...machines.splice(oldIndex, 1));
    this.onChange(machines.map((e, i) => ({ ...e, order: i + 1 })));
  };

  sortOrder = machines => {
    return machines
      .sort((m1, m2) => {
        if (m1.order !== undefined && m2.order === undefined) {
          return -1;
        }
        if (m1.order === undefined && m2.order !== undefined) {
          return 1;
        }
        if (m1.order === undefined && m2.order === undefined) {
          return 0;
        }
        return m1.order < m2.order ? -1 : m1.order > m2.order ? 1 : 0;
      })
      .map((machine, idx) => {
        return { ...machine, order: idx + 1 };
      });
  };

  render() {
    const { isFetching, suggestions } = this.props;
    const { machines } = this.state;

    return (
      <LoadingHOC isFetching={isFetching}>
        <MachinesList
          machines={machines}
          suggestions={suggestions}
          isFetching={isFetching}
          onChipRemove={this.onChipRemove}
          onChange={this.onChange}
          useDragHandle={true}
          onSortEnd={this.onSortEnd}
          style={{ width: '100%' }}
        />
      </LoadingHOC>
    );
  }
}

const mapStateToProps = state => {
  const { machines = { items: [], isFetching: false, error: null } } = state;
  return {
    error: machines.error,
    isFetching: machines.isFetching,
    suggestions:
      machines.items &&
      machines.items
        .map(machine => ({
          ...machine,
          ssm_machine_id: machine.access ? machine.access.ssm_machine_id : null,
        }))
        .map(({ id, name, ssm_machine_id }) => `${id} (name:${name}, ssm: ${ssm_machine_id})`),
    machines,
  };
};

export default connect(mapStateToProps)(MachinesSortable);

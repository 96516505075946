/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getAPIKey = `query APIKey($customer_id: String!, $id: String!) {
  apiKey(customer_id: $customer_id, id: $id) {
    id
    customer_id
    name
    expires_at
    description
    created_at
    updated_at
  }
}
`;

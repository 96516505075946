/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FieldWrapper } from './index';

type DateInputProps = {
  name?: string;
  value: string;
  onChange: (date: string) => void;
};

function DateInput({ name, value, onChange }: DateInputProps) {
  const date = value ? moment(value).toDate() : undefined;

  function handleChange(date: Date) {
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
    onChange(formattedDate);
  }

  return (
    <FieldWrapper>
      <DatePicker
        id={name}
        className="input"
        placeholderText="Select date..."
        selected={date}
        onChange={handleChange}
        dateFormat="yyyy-MM-dd"
      />
    </FieldWrapper>
  );
}

export default DateInput;

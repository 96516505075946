/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

export const Errors = errors => {
  return Array.isArray(errors.errors) ? (
    <div className="message is-danger">
      <div className="message-body">
        {errors.errors.map((el, index) => (
          <div key={index}>{el}</div>
        ))}
      </div>
    </div>
  ) : (
    <div className="test" />
  );
};

export const ErrorsListing = error => (
  <div className="message is-danger">
    <div className="message-body">
      {typeof error === 'string' && <div key="0">{error}</div>}
      {typeof error !== 'string' &&
        Object.keys(error).map((el, index) => (
          <div key={index}>
            {el.replace(/_/g, ' ').toUpperCase()}:{' '}
            {typeof error[el] === 'string'
              ? error[el]
              : Object.keys(error[el][0]).map(element => error[el][0][element])}
          </div>
        ))}
    </div>
  </div>
);

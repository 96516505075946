/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editPipeline, fetchPipelines } from '../actions';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import {
  FormControl,
  FormButtonEdit,
  FormButtonCancel,
  Loading,
  Metadata,
} from '../../../commonComponents';
import { PipelineStepDetails } from '../components/PipelineStepDetails';
import { Errors } from '../../../commonComponents/form/Validation';
import { getPipeline } from '../../../graphql/getPipeline';
import { putPipeline } from '../../../graphql/putPipeline';
import { FormattedDate } from '../../../commonComponents/date/FormattedDate';
import { fetchNotificationGroups } from '../../notificationGroups/actions';
import { getNotificationGroups } from '../../../graphql/getNotificationGroups';
import MovePlan from '../../../commonComponents/form/MovePlan';
import { removeEmptySelectValues } from '../../../utils/form';
import { pageSize } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';
import PipelineForm from '../components/form/PipelineForm';
import {
  serializeFormInput,
  trimNullValues,
} from '../../../commonComponents/form2/utils/serializeFormInput';

function prepareFormSubmitData(formData, pipelineData) {
  const { id, customer_id } = pipelineData;

  return serializeFormInput({ id, customer_id, ...formData });
}

const Param = ({ label, value, className, children }) => (
  <div className={`column ${className} `}>
    <strong className="is-desc-label">{label}</strong>
    <br />
    {children ? children : value ? value : <i className="is-italic">No data provided</i>}
  </div>
);

const formEdit = 'edit',
  formDetails = 'details',
  formMove = 'move';

class PipelineDetails extends Component {
  static contextType = APIContext;
  state = {
    formType: 'details',
  };

  componentDidMount() {
    const { requester } = this.context;

    const { dispatch, pipeline, routeParams } = this.props;
    if (!pipeline) {
      const id = routeParams.splat;
      fetchPipelines({ dispatch, myInit: { id: id }, requester, operation: getPipeline });
    }

    fetchNotificationGroups({
      dispatch: this.props.dispatch,
      allPages: true,
      myInit: { limit: pageSize },
      requester,
      operation: getNotificationGroups,
    });
  }

  formTypeChange = formType => {
    this.setState({ formType });
  };

  onSubmit = data => {
    const { requester } = this.context;
    const { dispatch, pipeline } = this.props;
    data = removeEmptySelectValues(data);

    const callback = () => {
      NotificationManager.success('Successfully updated pipeline [' + pipeline.id + '] details');
      browserHistory.push('/pipelines?refresh=true');
    };

    editPipeline({
      dispatch,
      myInit: { input: prepareFormSubmitData(data, pipeline) },
      operation: putPipeline,
      requester,
      callback,
    });
  };

  getInitialValues(pipeline) {
    const { id, created_at, updated_at, ...formData } = pipeline;

    return {
      ...formData,
      steps: formData.steps.map(step => ({
        ...step,
        plans: step.plans.map(plan => {
          // Fallback to maintain backward compatibility for plans on pipeline steps
          // with single 'iam_role' defined instead of 'iam_role_list'
          if (!plan?.machines_tag?.iam_role_list && plan?.machines_tag?.iam_role) {
            const { iam_role, ...data } = plan.machines_tag;

            return {
              ...plan,
              machines_tag: {
                ...data,
                iam_role_list: [plan.machines_tag.iam_role],
              },
            };
          }

          return plan;
        }),
      })),
    };
  }

  showStepDetails = (step, id) => {
    this.setState({
      showStepModal: true,
      modalData: step,
      stepId: id,
    });
  };

  render() {
    const { pipeline, error, isFetching, notificationGroups } = this.props;
    const { formType, showStepModal, modalData, stepId } = this.state;
    const formData = pipeline ? this.getInitialValues(pipeline) : {};
    const customerName = localStorage.getItem('currentCustomerName') || '';
    const errors = { errors: error };

    return (
      <div className="main wide">
        {!pipeline ? (
          <Loading />
        ) : (
          <React.Fragment>
            {showStepModal && (
              <PipelineStepDetails
                data={modalData}
                stepId={stepId}
                visible={showStepModal}
                hideModal={() => this.setState({ showStepModal: false })}
              />
            )}
            <div className="box">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title">Pipeline Details</h1>
                  <h2 className="subtitle">{pipeline.name ? pipeline.name : pipeline.id}</h2>
                </div>
                <Param label="Created at">
                  <FormattedDate>{pipeline.created_at}</FormattedDate>
                </Param>

                <Param label="Updated at">
                  <FormattedDate>{pipeline.updated_at}</FormattedDate>
                </Param>
                <Param label="Customer">{customerName ? customerName : pipeline.customer_id}</Param>
                {formType === formDetails ? (
                  <div className="column buttons are-small">
                    <FormButtonEdit
                      className="pull-right"
                      onClick={() => this.formTypeChange(formEdit)}
                    >
                      Edit
                    </FormButtonEdit>
                    <FormButtonEdit
                      className="pull-right"
                      onClick={() => this.formTypeChange(formMove)}
                    >
                      Move plan
                    </FormButtonEdit>
                  </div>
                ) : (
                  <FormButtonCancel
                    className="pull-right"
                    onClick={() => this.formTypeChange(formDetails)}
                  >
                    Cancel
                  </FormButtonCancel>
                )}
              </div>
            </div>

            {errors.errors && errors.errors.length > 0 && Errors(errors)}

            {formType === formMove ? (
              <MovePlan pipeline={pipeline} />
            ) : formType === formEdit && !isFetching ? (
              <PipelineForm
                onSubmit={this.onSubmit}
                initialData={formData}
                isSubmitting={isFetching}
                errorInfo={errors}
                isDetailsView={true}
              />
            ) : (
              <React.Fragment>
                <div className="box">
                  <div className="columns">
                    <Param label="Description" value={pipeline.description} />
                    <Param label="Name" value={pipeline.name} />
                    <Param label="Cron window start" value={pipeline.cron_window_start} />
                    <Param label="Timezone" value={pipeline.time_zone} />
                    <Param
                      label="Upcoming notification time"
                      value={pipeline.upcoming_notification_time}
                    />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <h1 className="title is-size-4">Steps</h1>
                          </div>
                        </div>
                      </div>

                      {pipeline.steps && pipeline.steps.length > 0 ? (
                        <div className="pipeline-container">
                          {pipeline.steps.map((step, index) => (
                            <div
                              className="pipelineFormPlan"
                              key={index}
                              onClick={() => this.showStepDetails(step, index)}
                            >
                              Step {index + 1} - {step.plans && step.plans.length} plan(s)
                            </div>
                          ))}
                        </div>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value="No Steps in this Pipeline!"
                        />
                      )}
                    </div>
                  </div>

                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Notification Groups</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ul>
                          {!pipeline.notification_groups ||
                          pipeline.notification_groups.length <= 0 ? (
                            <li>No Notification Groups in this Pipeline!</li>
                          ) : (
                            pipeline.notification_groups.map(g => (
                              <li index={g}>
                                <Link to={`/notification_groups/details/${g}`} className="button">
                                  {(notificationGroups.find(e => e.id === g) || {}).name || g}
                                </Link>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <Metadata data={pipeline.metadata} name="Pipeline" />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

PipelineDetails.propTypes = {
  pipelines: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  isFetching: PropTypes.bool,
  notificationGroups: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  const { pipelines, notificationGroups } = state;
  const pipelineId = ownProps.routeParams.splat;
  const pipeline = pipelines.items.find(pipeline => pipeline.id === pipelineId);

  return {
    pipeline: pipeline ? trimNullValues(pipeline) : undefined,
    error: pipelines.error,
    isFetching: pipelines.isFetching,
    notificationGroups: notificationGroups.items,
  };
};

export default connect(mapStateToProps)(PipelineDetails);

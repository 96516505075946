/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getHostHookOutput = `query HostHookOutput($customer_id: String!, $machine_id: String!, $event_id: String!, $hook_name: String!) {
    hostHookOutput(customer_id: $customer_id, machine_id: $machine_id, event_id: $event_id, hook_name: $hook_name) { 
      code
      message
      hook {
        type
        source
        method
        status_code
      }
    }
  }
  `;

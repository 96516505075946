/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal } from './Modal';

export const ConfirmModal = ({
  visible,
  hideModal,
  title,
  message,
  actionFunc,
  actionButtonName,
}) => {
  return (
    <Modal
      visible={visible}
      cancelAction={hideModal}
      title={title}
      confirmAction={actionFunc}
      confirmLabel={actionButtonName}
    >
      <h3>{message}</h3>
    </Modal>
  );
};

export const RemovalModal = ({ visible, hideModal, title, itemId, itemName, removeItem }) => {
  return (
    <ConfirmModal
      visible={visible}
      hideModal={hideModal}
      title="Confirm object removal"
      actionButtonName="DELETE"
      actionFunc={e => {
        e.stopPropagation();
        hideModal();
        removeItem();
      }}
      message={`Do you really want to delete ${title} ${itemName} ${
        itemId && itemId.length > 0 ? '(' + itemId + ')' : ''
      }?`}
    />
  );
};

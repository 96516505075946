/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const forceRunPipeline = `mutation ForceRunPipeline($customer_id: String!, $id: String!) {
  forceRunPipeline(customer_id: $customer_id, id: $id) { 
      id
    }
  }
  `;

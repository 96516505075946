/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import moment from 'moment';
import { HookInputSchema } from '../../../../commonComponents/form2/schema/hookInput';
import { MetadataSchema } from '../../../../commonComponents/form2/schema/metadata';
import { WebHookInputSchema } from '../../../../commonComponents/form2/schema/webhookInput';
import { rebootPolicySchemaValues } from '../../../../commonComponents/form2/schema/rebootPolicy';
import { safeStr, stringRequired } from '../../../../commonComponents/form2/validation/string';
import { isAfter } from '../../../../commonComponents/form2/validation/date';
import { isValidCron } from '../../../../utils/validators/isValidCron';

export const PlanMachineInputSchema = z.object({
  id: stringRequired('Id'),
  name: safeStr.optional(),
  order: z
    .preprocess(
      value => (Number.isNaN(value) ? undefined : value),
      z
        .number()
        .int({ message: 'Order must be an integer' })
        .nonnegative({ message: 'Order must be positive' }),
    )
    .optional(),
  exclude: z.boolean().optional(),
  pre_host_hooks: HookInputSchema.array().optional(),
  post_host_hooks: HookInputSchema.array().optional(),
  s3_custom_script: safeStr.optional(),
});

export const DynamicPlanInputSchema = z
  .object({
    tag_list: z
      .object({
        key: stringRequired('Key'),
        values: stringRequired('Value').array(),
      })
      .array()
      .refine(tags => tags.every(({ values }) => values.length > 0), {
        message: 'Some EC2 tag value needs to be specified for EC2 tag key',
      })
      .optional(),
    tag_condition: z
      .object({
        expression: stringRequired('Expression'),
        placeholders: z
          .object({
            key: stringRequired('Key'),
            value: stringRequired('Value'),
          })
          .array()
          .optional(),
      })
      .optional(),
    iam_role_list: stringRequired('IAM Role').array().min(1, { message: 'IAM Role is required' }),
    pre_host_hooks: HookInputSchema.array().optional(),
    post_host_hooks: HookInputSchema.array().optional(),
    reboot_policy: z.enum(rebootPolicySchemaValues).optional(),
    regions: safeStr.array().optional(),
    s3_custom_script: safeStr.optional(),
    update_existing: z.boolean().optional(),
    include_stopped: z.boolean().optional(),
  })
  .refine(({ tag_list, tag_condition }) => tag_list || tag_condition, {
    message: 'One of tag selection method (list or condition) is required',
  });

export const PlanFormSchema = z
  .object({
    // 1st step
    name: stringRequired('Name'),
    metadata: MetadataSchema.optional(),
    cron_window_start: stringRequired('Cron window start').refine(
      value => value && isValidCron(value),
      { message: 'Cron window start is not a valid cron expression' },
    ),
    time_zone: safeStr.optional(),
    window_length: z.preprocess(
      value => (value === null || Number.isNaN(value) ? undefined : value),
      z
        .number({ required_error: 'Patching window duration is required' })
        .min(1, { message: 'Patching window duration must not be smaller than 1h' })
        .max(24, { message: 'Patching window duration must not be greater than 24h' })
        .int({ message: 'Patching window duration must be an integer' }),
    ),
    // 2nd step
    notification_groups: safeStr.array().optional(),
    s3_custom_script: safeStr.optional(),
    machines: PlanMachineInputSchema.array().optional(),
    machines_tag: DynamicPlanInputSchema.optional(),

    // 3rd step
    description: safeStr.optional(),
    on_hold_start: safeStr
      .refine(date => date === '' || moment(date, 'YYYY-MM-DD', true).isValid(), {
        message: 'On hold start date must be a valid date',
      })
      .optional(),
    on_hold_end: safeStr
      .refine(date => date === '' || moment(date, 'YYYY-MM-DD', true).isValid(), {
        message: 'On hold end date must be a valid date',
      })
      .optional(),
    dry_run: z.boolean().optional(),
    reboot_policy: z.enum(rebootPolicySchemaValues).optional(),
    linux_security_only: z.boolean().optional(),
    windows_update_category: safeStr.optional(),
    windows_update_severity: safeStr.optional(),

    upcoming_notification_time: z.preprocess(
      value => (value === null || Number.isNaN(value) ? undefined : value),
      z
        .number()
        .int({ message: 'Patching notification must be an integer' })
        .nonnegative({ message: 'Patching notification must be positive' })
        .optional(),
    ),
    manual_approval: z.boolean().optional(),
    parallel: z.preprocess(
      value => (value === null || Number.isNaN(value) ? undefined : value),
      z
        .number()
        .int({ message: 'Parallel must be an integer' })
        .nonnegative({ message: 'Parallel must be positive' })
        .optional(),
    ),
    pre_hooks: HookInputSchema.array().optional(),
    post_hooks: HookInputSchema.array().optional(),
    webhook_inputs: WebHookInputSchema.array().optional(),
  })
  .superRefine(({ on_hold_start, on_hold_end }, ctx) => {
    if (!on_hold_start && !on_hold_end) {
      return;
    }

    if (!on_hold_start && on_hold_end) {
      return ctx.addIssue({
        path: ['on_hold_start'],
        code: z.ZodIssueCode.custom,
        message: `On hold start date should be defined when on hold end date is specified`,
      });
    }

    if (on_hold_start && !on_hold_end) {
      return ctx.addIssue({
        path: ['on_hold_end'],
        code: z.ZodIssueCode.custom,
        message: `On hold end date should be defined when on hold start date is specified`,
      });
    }

    if (isAfter(on_hold_start!, on_hold_end!)) {
      return ctx.addIssue({
        path: ['on_hold_end'],
        code: z.ZodIssueCode.custom,
        message: `On hold end date should be after the on hold start date`,
      });
    }
  });

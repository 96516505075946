/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { MetadataModal } from './MetadataModal';

class MetadataFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.formData || [],
      popup: false,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.formData || [],
    });
  }

  switchVisibility = () => {
    this.setState(state => ({
      popup: !state.popup,
    }));
  };

  handleChange = data => {
    this.setState({ tempObj: data.formData });
  };

  editRule = index => {
    this.setState(state => ({
      ruleIndex: index,
      rule: state.value[index],
      popup: true,
    }));
  };

  addRule = event => {
    const value = [...this.state.value];

    this.state.ruleIndex >= 0 ? (value[this.state.ruleIndex] = event) : value.push(event);
    this.setState({
      value: value,
      popup: false,
      ruleIndex: -1,
      rule: null,
    });
    this.props.onChange(value);
  };

  removeRule = ruleIndex => {
    const value = [...this.state.value];
    value.splice(ruleIndex, 1);
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  };

  render() {
    const { value: values, popup, rule } = this.state;

    return (
      <div className="is-input-like">
        {values.map((el, index) => {
          return (
            <div className="tag" style={{ height: 'auto' }}>
              <div onClick={() => this.editRule(index)}>
                Key:
                <span> {el.key}</span>
                <br />
                Value:
                <span> {el.value}</span>
              </div>
              <i className="fa fa-trash is-pointer" onClick={() => this.removeRule(index)} />
            </div>
          );
        })}

        {popup && (
          <MetadataModal
            addRule={this.addRule}
            hideModal={() => {
              this.setState({
                popup: false,
                ruleIndex: -1,
                rule: null,
              });
            }}
            formData={rule}
          />
        )}
        <button
          type="button"
          className="button"
          style={{ marginTop: '5px' }}
          onClick={this.switchVisibility}
        >
          Add New
        </button>
      </div>
    );
  }
}

export { MetadataFormInput };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { NotificationChannelInput, NotificationType } from '../../graphql/generated-types';
import NotificationChannelModal, { NotificationTypes } from './NotificationChannelModal';

type ReducerProps = {
  isModalShown: boolean;
  notificationChannelId: number | null;
};

type NotificationChannelFormInputProps = {
  value?: Array<NotificationChannelInput>;
  onChange: (notificationChannel: Array<NotificationChannelInput>) => void;
};

function NotificationChannelFormInput({
  value: notificationChannel = [],
  onChange,
}: NotificationChannelFormInputProps) {
  const [{ isModalShown, notificationChannelId }, setPopupState] = React.useReducer(
    (prevState: ReducerProps, updatedProperties: Partial<ReducerProps>) => ({
      ...prevState,
      ...updatedProperties,
    }),
    { isModalShown: false, notificationChannelId: null },
  );

  function addNotification() {
    setPopupState({ isModalShown: true });
  }

  function hideModal() {
    setPopupState({ isModalShown: false, notificationChannelId: null });
  }

  function handleNotificationEdit(notificationChannelId: number) {
    setPopupState({ notificationChannelId, isModalShown: true });
  }

  function handleNotificationRemove(notificationChannelId: number) {
    notificationChannel.splice(notificationChannelId, 1);
    onChange(notificationChannel);
  }

  function handleNotificationSave(notificationChannelData: NotificationChannelInput) {
    if (notificationChannelId !== null) {
      notificationChannel[notificationChannelId] = notificationChannelData;
    } else {
      notificationChannel.push(notificationChannelData);
    }

    onChange(notificationChannel);
    hideModal();
  }

  function buildLabel(el: NotificationChannelInput) {
    const label = NotificationTypes.find(type => el.type === type.value)?.label;
    if (el.type === NotificationType.Email) {
      return `${label}: ${el?.email_config?.address || '-'}`;
    }
    if (el.type === NotificationType.PagerDuty) {
      return `${label}: ${el?.routing_key || '-'}`;
    }
    if (el.type === NotificationType.Slack) {
      return `${label}: ${el?.web_hook_url || '-'} | ${el?.channel || '-'} `;
    }
  }

  return (
    <div className="is-input-like">
      {notificationChannel?.map((el: NotificationChannelInput, index) => (
        <span className="tag" key={index}>
          <span onClick={() => handleNotificationEdit(index)}>{buildLabel(el)}</span>
          <i className="fa fa-trash is-pointer" onClick={() => handleNotificationRemove(index)} />
        </span>
      ))}
      {isModalShown && (
        <NotificationChannelModal
          saveNotification={handleNotificationSave}
          hideModal={hideModal}
          notification={
            notificationChannelId !== null ? notificationChannel[notificationChannelId] : undefined
          }
        />
      )}
      <button type="button" className="button" onClick={addNotification}>
        Add New
      </button>
    </div>
  );
}

export default NotificationChannelFormInput;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

type FieldWrapperProps = {
  children: React.ReactNode;
};

const FieldWrapper = ({ children }: FieldWrapperProps) => (
  <div className="field-body">
    <div className="field">
      <div className="control">{children}</div>
    </div>
  </div>
);

export default FieldWrapper;

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { NotificationManager } from 'react-notifications';
import { createFilter as createSearchFilter } from 'react-search-input';
import { fetchAPIKeys, removeApiKey } from '../actions';
import { getAPIKeys } from '../../../graphql/getAPIKeys';
import { deleteAPIKey } from '../../../graphql/deleteAPIKey';
import ApiKeyRow from '../components/ApiKeyRow';
import { DataTable, SearchForm } from '../../../commonComponents';
import { ErrorsListing } from '../../../commonComponents/form/Validation';
import { ROUTES } from '../../../constants/routes';
import { pageSize } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const keysToFilter = ['name', 'id'];

class ApiKeysPage extends Component {
  state = {
    apiKeys: this.props.defaultValue || [],
    searchTerm: '',
    viewCount: 0,
  };
  static contextType = APIContext;

  componentDidMount() {
    this.refresh();
  }

  componentWillReceiveProps(props) {
    if (props.location && props.location.query.refresh) {
      this.refresh();
      browserHistory.push(ROUTES.apiKeys.apiKeys);
    }
  }

  refresh = (paginationToken, allPages) => {
    const { requester } = this.context;

    fetchAPIKeys({
      dispatch: this.props.dispatch,
      myInit: {
        limit: pageSize,
        continuation_token: paginationToken,
      },
      requester,
      operation: getAPIKeys,
      allPages,
    });
  };

  searchUpdated = term => {
    this.setState({ searchTerm: term });
  };

  removeItem = apiKeyId => {
    const { requester } = this.context;
    const { dispatch } = this.props;
    const params = {
      customer_id: localStorage.getItem('currentCustomer'),
      id: apiKeyId,
    };

    const onSuccess = () => {
      NotificationManager.success('API key removed');
      browserHistory.push(`${ROUTES.apiKeys.apiKeys}?refresh=true`);
    };

    removeApiKey({
      dispatch,
      myInit: params,
      operation: deleteAPIKey,
      requester,
      callback: onSuccess,
    });
  };

  updateViewCount = viewCount => this.state.viewCount !== viewCount && this.setState({ viewCount });

  render() {
    const { error, apiKeys, paginationToken, isFetching } = this.props;
    const filteredAPIKeys = apiKeys.filter(createSearchFilter(this.state.searchTerm, keysToFilter));

    return (
      <div>
        <div className="main wide">
          <div className="container">
            <div className="box">
              <nav className="level">
                <div className="level-left">
                  <div className="level-item">
                    <Link to={ROUTES.apiKeys.apiKeysNew}>
                      <button className="button is-small is-action  has-besel" type="button">
                        <span>
                          <i className="material-icons ">add</i>
                        </span>
                        New API key
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="level-right">
                  <SearchForm searchUpdated={this.searchUpdated} />
                </div>
              </nav>
              {apiKeys.length > 0 && (
                <nav className="level">
                  <div className="level-left" />
                  <div className="level-right">
                    {`Viewing ${this.state.viewCount}/${apiKeys.length}`}
                    {paginationToken && '+'}
                  </div>
                </nav>
              )}
            </div>

            {error && <ErrorsListing error={error} />}

            <DataTable
              data={filteredAPIKeys}
              pagination={{
                show: !!paginationToken,
                loadNextPage: () => this.refresh(paginationToken),
                loadAllPages: () => this.refresh(paginationToken, true),
              }}
              isLoading={isFetching}
              headers={[
                { label: 'Name', field: 'name' },
                { label: 'Id', field: 'id' },
                { label: 'Description', field: 'description' },
                { label: 'Expires at', field: 'expires_at' },
                { label: '' },
              ]}
              row={ApiKeyRow}
              rowActions={{ removeItem: this.removeItem }}
              updateViewCount={this.updateViewCount}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    apiKeys = {
      items: [],
      paginationToken: null,
      isFetching: false,
      error: null,
      removed: false,
    },
  } = state;

  return {
    error: apiKeys.error,
    isFetching: apiKeys.isFetching,
    apiKeys: apiKeys.items,
    paginationToken: apiKeys.paginationToken,
    removed: apiKeys.removed,
  };
};

export default connect(mapStateToProps)(ApiKeysPage);

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createPlan = `mutation CreatePlan($input: PlanInput!) {
    createPlan(input: $input) { 
      id
    }
  }
  `;

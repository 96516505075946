/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import cronstrue from 'cronstrue';

export const isValidCron = (value: string) => {
  try {
    cronstrue.toString(value);
  } catch (e) {
    return false;
  }

  return true;
};

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { MachineTagModal } from './MachineTagModal';

class MachineTagFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.formData ? props.formData : {},
      showModal: false,
    };
    this.switchVisibility = this.switchVisibility.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.formData || {},
    });
  }

  switchVisibility() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  hideModal = () => this.setState({ showModal: false });

  handleChange = data => {
    this.setState({ value: data.formData });
  };

  machineTagEdit = () => {
    this.setState({
      showModal: true,
    });
  };

  addMachineTag = machineTag => {
    this.setState(
      {
        showModal: false,
        value: machineTag,
      },
      this.props.onChange(machineTag),
    );
  };

  removeMachineTag = e => {
    e.stopPropagation();
    const { value: { iam_role } } = this.state;
    this.setState({
      value: { iam_role },
      showModal: false,
    });
    this.props.onChange({ iam_role });
  };

  render() {
    const { value, showModal } = this.state;

    return (
      <>
        <div className="field-label is-normal">
          <label className="label">Machines Tag</label>
        </div>
        <div className="field-body is-input-like">
          {value &&
            value.tag_list &&
            value.tag_list.length > 0 && (
              <span class="tag " onClick={this.machineTagEdit}>
                {value.tag_list.map(el => `${el.key} `)}
                <i className="fa fa-trash is-pointer" onClick={this.removeMachineTag} />
              </span>
            )}
          {showModal && (
            <MachineTagModal
              addMachineTag={this.addMachineTag}
              visible={showModal}
              hideModal={this.hideModal}
              formData={value}
            />
          )}
          {!value.tag_list && (
            <button type="button" className="button " onClick={this.switchVisibility}>
              Add New
            </button>
          )}
        </div>
      </>
    );
  }
}

export { MachineTagFormInput };

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import { NotificationsChannelSchema } from '../../../../commonComponents/form2/schema/notificationsChannel';
import { stringRequired } from '../../../../commonComponents/form2/validation/string';
import { NotificationEvent, NotificationLevel } from '../../../../graphql/generated-types';

const errorMap: z.ZodErrorMap = (error, ctx) => {
  switch (error.code) {
    case z.ZodIssueCode.invalid_enum_value:
      if (error.path.includes('events')) {
        return { message: 'Invalid events value' };
      }
      if (error.path.includes('levels')) {
        return { message: 'Invalid levels value' };
      }
  }
  return { message: ctx.defaultError };
};

export const NotificationGroupSchema = z.object({
  name: stringRequired('Name'),
  notifications: NotificationsChannelSchema.array().optional(),
  events: z.nativeEnum(NotificationEvent, { errorMap }).array().optional(),
  levels: z.nativeEnum(NotificationLevel, { errorMap }).array().optional(),
});

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createRole = `mutation CreateRbacRole($input: RbacRoleInput!) {
    createRbacRole(input: $input) { 
      id
    }
  }
  `;

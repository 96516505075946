/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import classNames from 'classnames';
import { FieldError, FieldExtrasWrapper } from './index';

type Element = {
  label: string;
  value: string;
};

type SelectProps = {
  name?: string;
  value: string;
  onChange: (value: string) => void;
  elements: ReadonlyArray<Element>;
  disabled?: boolean;
  fullwidth?: boolean;
  error?: string;
};

function Select({
  name,
  value = '',
  elements,
  onChange,
  disabled = false,
  fullwidth = false,
  error,
  ...props
}: SelectProps & Omit<React.HTMLAttributes<HTMLSelectElement>, 'onChange' | 'onBlur' >) {
  function handleOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    onChange(event.target.value);
  }

  const opts = elements.map((option, index) => (
    <option value={option.value} key={index}>
      {option.label}
    </option>
  ));

  return (
    <div className={classNames('select', fullwidth && 'is-fullwidth')}>
      <select id={name} onChange={handleOnChange} value={value} disabled={disabled} {...props}>
        <option value="">Please select</option>
        {opts}
      </select>
      {error && (
        <FieldExtrasWrapper>
          <FieldError>{error}</FieldError>
        </FieldExtrasWrapper>
      )}
    </div>
  );
}

export default Select;

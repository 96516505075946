/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Modal } from '../modals/Modal';

export default class Terminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: JSON.stringify(props.formData, null, 8),
    };
    this.onChange = this.onChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.switchVisibility = this.switchVisibility.bind(this);
    this.sendJson = this.sendJson.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.formData,
      json: JSON.stringify(props.formData, null, 8),
    });
  }

  handleKeyDown(event) {
    if (event.keyCode === 9) {
      // tab press
      event.preventDefault();
      var val = this.state.json,
        start = event.target.selectionStart,
        end = event.target.selectionEnd;
      this.setState(
        {
          json: val.substring(0, start) + '\t' + val.substring(end),
        },
        () => {
          this.refs.terminal.selectionStart = this.refs.terminal.selectionEnd = start + 1;
        },
      );
    } else if (event.keyCode === 27) {
      // escape press, hide terminal
      this.switchVisibility();
    }
  }

  sendJson(event) {
    event.preventDefault();
    try {
      JSON.parse(this.state.json);
    } catch (e) {
      this.setState({
        error: e.message,
      });
      return false;
    }

    const errorMessage = this.props.updateFormData(JSON.parse(this.state.json));

    if (errorMessage) {
      this.setState({ error: errorMessage });
    } else {
      this.switchVisibility();
    }
  }

  onChange(event) {
    let value = event.target.value ? event.target.value : JSON.stringify({});
    this.setState({
      json: value,
    });
  }

  switchVisibility() {
    this.setState(
      {
        visible: !this.state.visible,
        error: '',
      },
      () => {
        this.state.visible && this.refs.terminal.focus(); // Focus terminal on switch
      },
    );
  }

  render() {
    const { visible, json, error } = this.state;
    const { title, formData } = this.props;

    return (
      <React.Fragment>
        <div style={{ flexGrow: 10 }}>
          <button
            className="button is-small is-action is-primary has-besel is-pulled-right"
            type="button"
            onClick={this.switchVisibility}
          >
            <span>
              <i className="fa fa-terminal" />
            </span>
            JSON view
          </button>
        </div>

        <Modal
          title={`JSON source - ${title ? title.replace(/_/g, ' ') : ''}`}
          cancelAction={() => {
            this.setState({ json: JSON.stringify(formData, null, 8) });
            this.switchVisibility();
          }}
          confirmAction={this.sendJson}
          visible={visible}
        >
          <div className="terminal-container">
            <span className="terminal-error">{error}</span>
            <textarea
              ref="terminal"
              autoFocus
              className="terminal-content textarea"
              id="json-input"
              placeholder="Paste your json here..."
              value={json}
              onKeyDown={this.handleKeyDown}
              onChange={this.onChange}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { pageSize } from '../../utils';

const PerPage = ({ perPage, setPerPage }) => (
  <React.Fragment>
    <span className="is-nav-title">Per page:</span>
    <nav className="pagination is-small" role="navigation" aria-label="pagination">
      <ul className="pagination-list">
        <li>
          {[10, 20, 50].map((el, index) => (
            <button
              key={index}
              className={`pagination-link ${el === perPage && `is-current`}`}
              onClick={() => setPerPage(el)}
            >
              {el}
            </button>
          ))}
        </li>
      </ul>
    </nav>
  </React.Fragment>
);

const filterVisible = (allPages, currPage) => allPages.filter(el => Math.abs(currPage - el) < 3);

const Pagination = ({ currPage, numberOfPages, changePage, prevPage, nextPage, pagination }) => {
  const allPages = [...Array(numberOfPages).keys()];
  const visiblePages = allPages.length > 5 ? filterVisible(allPages, currPage) : allPages;

  const showAfterEllipsis = currPage < allPages.length - 2 && allPages !== visiblePages;

  return (
    <div className="">
      <nav className="pagination is-small" role="navigation" aria-label="pagination">
        <ul className="pagination-list">
          <li>
            <button
              onClick={() => changePage(0)}
              onKeyDown={() => changePage(0)}
              type="button"
              className="pagination-previous"
              disabled={currPage === 0}
            >
              First
            </button>
          </li>
          <li>
            <button
              onClick={prevPage}
              onKeyDown={prevPage}
              type="button"
              className="pagination-previous"
              disabled={currPage === 0}
            >
              Previous
            </button>
          </li>

          {visiblePages.map((el, i) => (
            <li key={el}>
              <button
                className={`pagination-link ${el === currPage && `is-current`}`}
                onClick={() => changePage(el)}
                onKeyDown={() => changePage(el)}
                type="button"
              >
                {el + 1}
              </button>
            </li>
          ))}
          {showAfterEllipsis && (
            <React.Fragment>
              <li>...</li>
              <li>
                <button
                  className={`pagination-link`}
                  onClick={() => changePage(allPages.length - 1)}
                  onKeyDown={() => changePage(0)}
                  type="button"
                >
                  {allPages.length}
                </button>
              </li>
            </React.Fragment>
          )}
          <li>
            <button
              onClick={nextPage}
              onKeyDown={nextPage}
              type="button"
              className="pagination-next"
              disabled={currPage === numberOfPages - 1}
            >
              Next
            </button>
          </li>
          <li>
            <button
              onClick={() => changePage(allPages.length - 1)}
              onKeyDown={() => changePage(allPages.length - 1)}
              type="button"
              className="pagination-previous"
              disabled={currPage === numberOfPages - 1}
            >
              Last
            </button>
          </li>
          {pagination && pagination.show && (
            <>
              <li>
                <button
                  onClick={pagination.loadNextPage}
                  onKeyDown={pagination.loadNextPage}
                  type="button"
                  className="pagination-previous"
                >
                  Load next {pageSize}
                </button>
              </li>
              <li>
                <button
                  onClick={pagination.loadAllPages}
                  onKeyDown={pagination.loadAllPages}
                  type="button"
                  className="pagination-previous"
                >
                  Load All
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export { Pagination, PerPage };

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
// @ts-ignore -- temporary ignore until module is fully typed
import { readableCron } from '../../utils/readableCron';
// @ts-ignore -- temporary ignore until module is fully typed
import { CronGenerator } from '../cronGenerator/CronGenerator';
// @ts-ignore -- temporary ignore until module is fully typed
import { Modal } from '../index';
import FieldWrapper from './FieldWrapper';

type CronInputProps = {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
};

function CronInput({ value, onChange, required }: CronInputProps) {
  const [isModalShown, setIsModalShown] = React.useState(false);

  function showModal() {
    setIsModalShown(true);
  }

  function hideModal() {
    setIsModalShown(false);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  function handleCronGenerated(val: string) {
    onChange(val);
    hideModal();
  }

  return (
    <FieldWrapper>
      <div className="cron-input">
        <input
          aria-label="cron-input"
          type="text"
          value={value}
          required={required}
          className="input"
          onChange={handleInputChange}
        />
        <button
          aria-label="Cron input"
          type="button"
          className="button is-icononly"
          onClick={showModal}
        >
          <i className="fa fa-clock-o is-square" />
        </button>
        <span className="info">{readableCron(value)}</span>

        <Modal title="Cron generator" visible={isModalShown} cancelAction={hideModal}>
          <CronGenerator onChange={handleCronGenerated} value={value} />
        </Modal>
      </div>
    </FieldWrapper>
  );
}

export default CronInput;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { combineReducers } from 'redux';
import { ListReducerFactory } from '../../factories';
import { actions } from './actions';
import { EVENT_APPROVE, EVENT_APPROVE_SUCCESS, EVENT_APPROVE_FAILURE } from './actions';

function eventApprove(
  state = {
    isFetching: false,
    error: null,
  },
  action,
) {
  switch (action.type) {
    case EVENT_APPROVE:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case EVENT_APPROVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case EVENT_APPROVE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
}

const list = ListReducerFactory(actions);
const events = combineReducers({ list, eventApprove });

export { events };

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';
import { ROUTES } from '../../constants/routes';

const { fetchData, removeItem, editItem, actions } = ListActionsFactory({
  namespace: 'API_KEYS',
  api: 'aws',
  path: ROUTES.apiKeys.activePrefix,
});

export {
  fetchData as fetchAPIKeys,
  removeItem as removeApiKey,
  editItem as editApiKey,
  actions,
};

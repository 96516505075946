/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { NotificationManager } from 'react-notifications';
import { sendBundlePdfReport } from '../../../graphql/sendBundlePdfReport';
import { useAuth0 } from '@auth0/auth0-react';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const ReportButton = ({ events }) => {
  const { requester } = React.useContext(APIContext);
  const { user } = useAuth0();

  const sendReport = async () => {
    const init = {
      customer_id: localStorage.getItem('currentCustomer'),
      user: user.email,
      events: events,
    };

    try {
      const response = await requester({
        query: sendBundlePdfReport,
        input: {
          input: init,
        },
      });

      if (response && response.data) {
        NotificationManager.success(
          'Bundled report generation successfully started. You will receive email with download link shortly.',
        );
      } else {
        NotificationManager.error('Something went wrong.');
      }
    } catch (err) {
      if (err.errors) {
        NotificationManager.error(err.errors[0].message || 'Something went wrong');
      }
    }
  };

  return (
    <div className="level-item">
      <button className="button" onClick={sendReport}>
        Get Bundled Report
      </button>
    </div>
  );
};

export default ReportButton;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import DateInput from './DateInput';
import { Select } from './Select';
import { Modal, FormButtonsContainer, FormButtonConfirm } from './Form';
import {
  CustomCheckboxWidget,
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import Form from 'react-jsonschema-form';
import { stripUndefinedValues } from '../../utils';

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  DateWidget: DateInput,
  SelectWidget: Select,
};

const schema = {
  type: 'object',
  properties: {
    resource_ids: {
      type: 'string',
      title: 'Resources Ids',
    },
  },
};

const uiSchema = {
  resource_ids: {
    'ui:field': 'resource_ids',
    classNames: 'is-horizontal',
  },
};

class ResourcesIdModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData ? props.formData : {},
    };
  }

  handleChange = data => {
    this.setState({ formData: data.formData });
  };
  add = () => this.props.addResourceId(stripUndefinedValues(this.state.formData));

  render() {
    const { formData } = this.state;

    return (
      <Modal visible={true} cancelAction={this.props.hideModal} title="Resource Id">
        <Form
          className=""
          uiSchema={uiSchema}
          schema={schema}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
          ArrayFieldTemplate={ArrayFieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          onChange={this.handleChange}
          formData={formData}
          widgets={widgets}
        >
          <FormButtonsContainer>
            {formData.resource_ids !== undefined && (
              <FormButtonConfirm onClick={this.add}>Add</FormButtonConfirm>
            )}
          </FormButtonsContainer>
        </Form>
      </Modal>
    );
  }
}

export { ResourcesIdModal };

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { theme } from './NotificationInputTheme';
import { NOTIFICATION_LEVELS_MAPPING } from '../../utils/constants';
import Chips, { Chip } from 'react-chips';

class NotificationLevels extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      list: this.props.formData || [],
    };
  }

  componentWillReceiveProps(props) {
    props.formData &&
      this.setState({
        list: Object.keys(props.formData).map(key => props.formData[key]),
      });
  }

  onChange(chips) {
    this.setState({
      list: chips,
    });
    this.props.onChange(chips);
  }

  render() {
    const suggestions = Object.keys(NOTIFICATION_LEVELS_MAPPING);
    return (
      <Chips
        value={this.state.list}
        suggestions={suggestions}
        alwaysRenderSuggestions={true}
        onChange={this.onChange}
        placeholder="Select notification levels"
        fromSuggestionsOnly={true}
        theme={theme}
        renderSuggestion={s => NOTIFICATION_LEVELS_MAPPING[s].name}
        renderChip={s => <Chip>{NOTIFICATION_LEVELS_MAPPING[s].name}</Chip>}
      />
    );
  }
}

export default NotificationLevels;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { Fieldset, Label, FieldWrapper } from './index';

type UncontrolledFieldProps = {
  name?: string;
  label: string;
  children: React.ReactNode;
};

function UncontrolledField({ name, label, children }: UncontrolledFieldProps) {
  return (
    <>
      <Fieldset>
        <Label id={name}>{label}</Label>
        <FieldWrapper>{children}</FieldWrapper>
      </Fieldset>
    </>
  );
}

export default UncontrolledField;

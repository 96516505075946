/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Link } from 'react-router';
import {ROUTES} from "../../constants/routes";

class Navigation extends React.Component {
  activeRoute = routeName => (window.location.pathname.indexOf(routeName) > -1 ? 'active' : '');

  render() {
    return (
      <section className="sidebar">
        <nav className="navbar-static-side" role="navigation">
          <ul className="nav" id="side-menu" ref="menu">
            <li className={this.activeRoute('events')}>
              <Link to="/events?show_incoming=true">
                <i className="fa fa-calendar" />
                <span className="nav-label">Events</span>
                <span className="fa arrow" />
              </Link>
            </li>
            <li className={this.activeRoute('plans')}>
              <Link to="/plans">
                <i className="fa fa-play" />
                <span className="nav-label">Plans</span>
                <span className="fa arrow" />
              </Link>
            </li>
            <li className={this.activeRoute('pipelines')}>
              <Link to="/pipelines">
                <i className="fa fa-step-forward" />
                <span className="nav-label">Plan pipelines</span>
                <span className="fa arrow" />
              </Link>
            </li>
            <li className={this.activeRoute('machines')}>
              <Link to="/machines">
                <i className="fa fa-tasks" />
                <span className="nav-label">Machines</span>
                <span className="fa arrow" />
              </Link>
            </li>
            <li className={this.activeRoute('notification_groups')}>
              <Link to="/notification_groups">
                <i className="fa fa-comments-o" />
                <span className="nav-label">Notification groups</span>
                <span className="fa arrow" />
              </Link>
            </li>
            <li className={this.activeRoute(ROUTES.permissions.activePrefix)}>
              <Link to={ROUTES.permissions.assignments}>
                <i className="fa fa-lock" />
                <span className="nav-label">Permissions</span>
                <span className="fa arrow" />
              </Link>
            </li>
            <li className={this.activeRoute(ROUTES.apiKeys.activePrefix)}>
              <Link to={ROUTES.apiKeys.apiKeys}>
                <i className="fa fa-key" />
                <span className="nav-label">API keys</span>
                <span className="fa arrow" />
              </Link>
            </li>
            <li>
              <Link to="https://docs.autopatcher.nordcloudapp.com/" target="_blank">
                <i className="fa fa-book" />
                <span className="nav-label">User guide</span>
                <span className="fa arrow" />
              </Link>
            </li>
          </ul>
        </nav>
      </section>
    );
  }
}

export { Navigation };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';

const { fetchData, removeItem, editItem, actions } = ListActionsFactory({
  namespace: 'MACHINES',
});

export {
  fetchData as fetchMachines,
  removeItem as removeMachine,
  editItem as editMachine,
  actions,
};

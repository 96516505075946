/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { z } from 'zod';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { HookMethod, HookType } from '../../graphql/generated-types';
import { FieldWrapper, Label, Input, Select } from './index';

type Hook = {
  type?: HookType;
  source?: string;
  method?: HookMethod;
};

const hookTypeOptions = [
  { value: HookType.Aws, name: 'AWS Lambda ARN' },
  { value: HookType.Azure, name: 'Azure Functions URL' },
  { value: HookType.Script, name: 'Script' },
] as const;

const hookMethodOptions = [
  { value: HookMethod.Get, name: 'GET' },
  { value: HookMethod.Post, name: 'POST' },
] as const;

type HookInputProps = {
  name: string;
  value: Array<Hook>;
  onChange: (hooks: Array<Hook>) => void;
  hookTypes?: Array<HookType>;
};

function HookInput({
  name,
  value: hooks,
  hookTypes = [HookType.Aws, HookType.Azure],
  onChange,
}: HookInputProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error: FieldErrors | undefined = errors[name];

  function handleHookAdded() {
    onChange([...hooks, {}]);
  }

  function handleHookRemoved(hookId: number) {
    hooks.splice(hookId, 1);
    onChange(hooks);
  }

  function handleHookTypeChanged(value: string, hookId: number) {
    const parsedValue = z.nativeEnum(HookType).safeParse(value);

    if (!parsedValue.success) {
      hooks[hookId] = {};
    } else {
      hooks[hookId] = { ...hooks[hookId], type: parsedValue.data };
    }

    onChange(hooks);
  }

  function handleHookMethodChanged(value: string, hookId: number) {
    const parsedValue = z.nativeEnum(HookMethod).safeParse(value);

    if (!parsedValue.success) {
      const { type, source } = hooks[hookId];
      hooks[hookId] = { type, source };
    } else {
      hooks[hookId] = { ...hooks[hookId], method: parsedValue.data };
    }

    onChange(hooks);
  }

  return (
    <FieldWrapper>
      {hooks.map(({ type, method }, index) => (
        <div key={index} className="array-item">
          <button
            type="button"
            className="button is-icononly is-danger is-input-icon"
            onClick={() => handleHookRemoved(index)}
            data-testid={`${name}-remove-${index}`}
          >
            <i className="fa fa-trash is-square"></i>
          </button>
          <div className="form-group field field-object">
            <div className="field is-horizontal">
              <Label required>Type</Label>
              <Select
                value={type || ''}
                onChange={value => handleHookTypeChanged(value, index)}
                elements={hookTypeOptions
                  .filter(({ value }) => hookTypes.includes(value))
                  .map(({ value, name }) => ({ value, label: name }))}
                fullwidth
                error={error?.[index]?.type?.message}
                data-testid={`${name}-type-${index}`}
              />
            </div>
            <div className="field is-horizontal">
              <Label required>Source</Label>
              <Input
                name={`${name}.${index}.source`}
                registerProps={{ required: true }}
                type="text"
                data-testid={`${name}-source-${index}`}
                error={error?.[index]?.source?.message}
              />
            </div>
            {type === 'azure' && (
              <div className="field is-horizontal">
                <Label required>Method</Label>
                <Select
                  value={method || ''}
                  onChange={value => handleHookMethodChanged(value, index)}
                  elements={hookMethodOptions.map(({ value, name }) => ({
                    value,
                    label: name,
                  }))}
                  fullwidth
                  error={error?.[index]?.method?.message}
                  data-testid={`${name}-method-${index}`}
                />
              </div>
            )}
          </div>
        </div>
      ))}
      <button
        type="button"
        className="button array-add is-icononly is-danger is-square"
        onClick={handleHookAdded}
        data-testid={`${name}-add`}
      >
        <i className="fa fa-plus"></i>
      </button>
    </FieldWrapper>
  );
}

export default HookInput;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getRole = `query RbacRole($customer_id: String!, $id: String!) {
    rbacRole(customer_id: $customer_id, id: $id) {
      id
      customer_id
      name
      description
      rules {
        resources 
        actions
        resource_ids
      }
      created_at
      updated_at
    }
  }
  `;

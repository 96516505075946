/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal } from '../../../commonComponents';
import EventHookOutput from './SingleHookOutput';

class HookOutput extends React.Component {
  constructor() {
    super();

    this.state = { details: false };
  }

  showDetails = () => this.setState({ details: true });
  closePopup = () => this.setState({ details: false });

  render() {
    const { hook, event } = this.props;
    const { details } = this.state;

    return (
      <React.Fragment>
        <button className="button is-success" onClick={this.showDetails}>
          Hook output
        </button>
        <Modal visible={details} title="Machine hooks outputs" cancelAction={this.closePopup}>
          <EventHookOutput hook={hook} id={hook.name} event_id={event.id} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default HookOutput;

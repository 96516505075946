/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledField, InputField, Select } from '../../../../commonComponents/form2';
import { awsRegionsOptions } from '../../../../constants/regions';
import { Provider } from '../../../../graphql/generated-types';
import { OsType, osTypesOptions, providerOptions } from '../../consts';

function StepOne() {
  const { watch, setValue } = useFormContext();
  const watcher = watch();

  // "access" field is not cleared once the provider is changed on purpose
  // keeping it in the form state for backward compatibility with old form
  // along with possibility to adjust in json schema
  React.useEffect(() => {
    if (watcher.provider === !Provider.Azure) {
      setValue('azure_info', undefined);
    }
  }, [watcher.provider, setValue]);

  React.useEffect(() => {
    if (watcher.os === !OsType.windows) {
      setValue('update_category', undefined);
      setValue('update_severity', undefined);
      setValue('include_kbs', undefined);
      setValue('exclude_kbs', undefined);
    }
  }, [watcher?.os, setValue]);

  return (
    <>
      <InputField name="name" registerProps={{ required: true }} />
      <ControlledField
        name="provider"
        label="Provider"
        Component={Select}
        elements={providerOptions}
      />
      <ControlledField name="os" label="OS type" Component={Select} elements={osTypesOptions} />
      <InputField
        name="access.ssm_machine_id"
        label="SSM instance ID"
        registerProps={{ required: true }}
      />
      {watcher.provider === Provider.Aws && (
        <>
          <InputField name="access.iam_role" label="IAM role" registerProps={{ required: true }} />
          <ControlledField
            name="access.region"
            label="Instance region"
            rules={{ required: true }}
            Component={Select}
            elements={awsRegionsOptions}
          />
        </>
      )}
      {watcher.provider === Provider.Azure && (
        <>
          <InputField name="azure_info.subscription_id" label="Azure subscription id" />
          <InputField name="azure_info.subscription_name" label="Azure subscription name" />
          <InputField name="azure_info.resource_group_name" label="Azure resource group name" />
        </>
      )}
      {watcher?.os === OsType.windows && (
        <>
          <InputField
            name="update_category"
            label="Update category"
            description="Default is all, Works for Windows, you can specify coma separated list: CriticalUpdates,DefinitionUpdates,Drivers,FeaturePacks,SecurityUpdates,ServicePacks,UpdateRollups,Updates."
          />
          <InputField
            name="update_severity"
            label="Update severity"
            description="Default is: Critical,Important,Moderate, Works for Windows, you can specify coma separated list: Critical,Important,Moderate,Low,Unspecified."
          />
          <InputField
            name="include_kbs"
            label="KBS documents to include"
            description="Comma separated list of KBS documents to include during patching, i.e. KB9876543,KB9876123,KB1234567"
          />
          <InputField
            name="exclude_kbs"
            label="KBS documents to exclude"
            description="Comma separated list of KBS documents to exclude during patching, i.e. KB9876543,KB9876123,KB1234567"
          />
        </>
      )}
    </>
  );
}

export default StepOne;

/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import {
  DateInput,
  HookInput,
  WebhookInput,
  ControlledField,
  Select,
} from '../../../../commonComponents/form2';
import InputField from '../../../../commonComponents/form2/InputField';
import { rebootPolicyOptions } from '../../../../commonComponents/form2/schema/rebootPolicy';

function StepThree() {
  return (
    <>
      <InputField name="description" label="Description" />
      <ControlledField name="on_hold_start" label="On hold start date" Component={DateInput} />
      <ControlledField name="on_hold_end" label="On hold end date" Component={DateInput} />
      <InputField
        name="dry_run"
        label="Dry run"
        description="Set true to list available updated without updating."
        type="checkbox"
      />
      <ControlledField
        name="reboot_policy"
        label="Reboot policy"
        Component={Select}
        elements={rebootPolicyOptions}
        description="This reboot policy will override the one defined on a machine for all machines patched within this plan"
      />
      <InputField
        name="linux_security_only"
        label="Linux security only"
        description="Set true to install only security updates on Linux machines."
        type="checkbox"
      />
      <InputField
        name="windows_update_category"
        label="Windows Update Category"
        description="Default is all, Works for Windows, you can specify coma separated list: CriticalUpdates,DefinitionUpdates,Drivers,FeaturePacks,SecurityUpdates,ServicePacks,UpdateRollups,Updates."
      />
      <InputField
        name="windows_update_severity"
        label="Windows Update Severity"
        description="Default is: Critical,Important,Moderate, Works for Windows, you can specify coma separated list: Critical,Important,Moderate,Low,Unspecified."
      />
      <InputField
        name="upcoming_notification_time"
        type="number"
        label="Patching notification [h]"
        description="How many hours before patching you want to be notified."
        registerProps={{ valueAsNumber: true }}
      />
      <InputField
        name="manual_approval"
        label="Manual approval"
        description="Schedule events from this plan for execution only after they have been manually approved."
        type="checkbox"
      />
      <InputField
        name="parallel"
        type="number"
        label="Parallel"
        description="How many updates in parallel (0-all, 1-in sequence, 2- 2 in parallel etc)."
        registerProps={{ valueAsNumber: true }}
      />
      <ControlledField name="pre_hooks" label="Pre hooks" Component={HookInput} defaultValue={[]} />
      <ControlledField
        name="post_hooks"
        label="Post hooks"
        Component={HookInput}
        defaultValue={[]}
      />
      <ControlledField
        name="webhook_inputs"
        label="Web hooks"
        Component={WebhookInput}
        defaultValue={[]}
      />
    </>
  );
}

export default StepThree;

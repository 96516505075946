/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { MachineTagFormInput } from '../../../../commonComponents/form2/plan';
import {
  ControlledField,
  InputField,
  MetadataFormInput,
  Select,
  MachinesMultiSelect,
  UncontrolledField,
} from '../../../../commonComponents/form2';
import PatchDelay from '../../../../commonComponents/form2/PatchDelay';

export enum MachineAddingMethod {
  List = 'List',
  Dynamic = 'Dynamic',
}

const options = [
  { value: MachineAddingMethod.List, label: MachineAddingMethod.List },
  { value: MachineAddingMethod.Dynamic, label: MachineAddingMethod.Dynamic },
];

function StepOne() {
  const { watch, setValue, reset } = useFormContext();
  const watcher = watch();
  const [option, setOption] = React.useState('');

  React.useEffect(() => {
    if (watcher?.machines_tag) {
      setOption(options[1].value);
    } else if (watcher?.machines?.length > 0) {
      setOption(options[0].value);
    }
  }, [option, setValue, watcher?.machines?.length, watcher?.machines_tag]);

  function handleOptionChanged(value: string) {
    const { machines, machines_tag, ...data } = watcher;
    reset(data);
    setOption(value);
  }

  function resetMachineTagsData() {
    const { machines, machines_tag, ...data } = watcher;
    reset(data);
  }

  return (
    <>
      <InputField name="name" id="namePlan" registerProps={{ required: true }} />
      <ControlledField
        name="window_length"
        label="Patching window duration [min]"
        rules={{ required: true }}
        Component={PatchDelay}
        defaultValue={30}
        description="Length of the patching window in minutes. Minimum 30m, maximum 1440m."
      />
      <UncontrolledField name="machine_adding_method" label="Machines adding method">
        <Select
          name="machine_adding_method"
          value={option}
          onChange={handleOptionChanged}
          elements={options}
        />
      </UncontrolledField>
      {option === MachineAddingMethod.List && (
        <ControlledField name="machines" Component={MachinesMultiSelect} label="Machines" />
      )}
      {option === MachineAddingMethod.Dynamic && (
        <ControlledField
          name="machines_tag"
          Component={MachineTagFormInput}
          label="Machines Tag"
          onMachineTagRemoved={resetMachineTagsData}
        />
      )}
      <ControlledField name="metadata" Component={MetadataFormInput} defaultValue={[]} />
      <InputField name="s3_custom_script" label="Custom Update Command (S3 URL)" />
    </>
  );
}

export default StepOne;

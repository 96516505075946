/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import JSONPretty from 'react-json-pretty';
import { NotificationManager } from 'react-notifications';
import { editMachine } from '../actions';
import MachineStatus from '../components/MachineStatus';
import { getMachine } from '../../../graphql/getMachine';
import { getPlans } from '../../../graphql/getPlans';
import { fetchMachines } from '../actions';
import { fetchPlans } from '../../plans/actions';
import { FormControl, FormButtonEdit, FormButtonCancel, Metadata } from '../../../commonComponents';
import { Errors } from '../../../commonComponents/form/Validation';
import { putMachine } from '../../../graphql/putMachine';
import { FormattedDate } from '../../../commonComponents';
import { pageSizeForPlans } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';
import { Param } from '../../../commonComponents/layout/Param';
import EditMachineForm from '../components/EditMachineForm/Form';
import {
  serializeFormInput,
  trimNullValues,
} from '../../../commonComponents/form2/utils/serializeFormInput';

function prepareInitialFormData(machine = {}) {
  const {
    plans,
    pipelines,
    id,
    metadata,
    customer_id,
    ping_status,
    ssm_metadata,
    last_patching,
    created_at,
    updated_at,
    published_days_old,
    ...formData
  } = machine;

  formData.os = ssm_metadata?.platform_type?.toLowerCase?.() ?? null;

  // transforming the data due to the fact that published_days_old is of type String in the schema
  // however it should be treated as an unsigned integer
  const parsedPublishedDaysOld = Number(published_days_old);
  if (published_days_old && !Number.isNaN(parsedPublishedDaysOld)) {
    formData.published_days_old = parsedPublishedDaysOld;
  }

  return formData;
}

function prepareFormSubmitData(formData, machineData) {
  const { id, customer_id, metadata } = machineData;

  return serializeFormInput({ id, customer_id, metadata, ...formData });
}

class MachineDetails extends Component {
  state = { formType: 'details' };

  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;
    const { machine, dispatch, routeParams } = this.props;

    if (!machine) {
      fetchMachines({
        dispatch,
        myInit: { id: routeParams.splat },
        requester,
        operation: getMachine,
      });
    }

    fetchPlans({
      dispatch,
      myInit: { limit: pageSizeForPlans },
      requester,
      operation: getPlans,
      allPages: true,
    });
  }

  formTypeChange = () => {
    this.setState(prevState => ({
      formType: prevState.formType === 'details' ? 'edit' : 'details',
    }));
  };

  onSubmit = data => {
    const { requester } = this.context;
    const { dispatch, machine } = this.props;

    const callback = () => {
      NotificationManager.success('Successfully updated machine [' + machine.id + '] details');
      browserHistory.push('/machines?refresh=true');
    };

    editMachine({
      dispatch,
      myInit: { input: prepareFormSubmitData(data, machine) },
      requester,
      operation: putMachine,
      callback,
    });
  };

  render() {
    const { machine, error, plans, isFetching, errorInfo } = this.props;
    const { formType } = this.state;

    const filteredPlans =
      machine?.plans && plans ? plans.filter(plan => machine.plans.includes(plan.id)) : null;

    const pingStatus = machine && machine.ping_status === 'Online' ? 'is-success' : 'is-warning';
    const errors = { errors: error };

    return (
      <div className="main wide">
        {machine && (
          <React.Fragment>
            <div className="box">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title">
                    {machine.name ? machine.name : machine.id}
                    {machine.added_dynamically && <div className="tag is-warning">DYNAMIC</div>}
                  </h1>
                  <h2 className="subtitle is-size-5">
                    Last patching: {machine.last_patching && <MachineStatus machine={machine} />}
                  </h2>
                </div>
                <Param label="Created At">
                  <FormattedDate>{machine.created_at}</FormattedDate>
                </Param>
                <Param label="Updated At">
                  <FormattedDate>{machine.updated_at}</FormattedDate>
                </Param>
                <Param label="Customer">{machine.owner}</Param>
                <div className="column">
                  {formType === 'details' ? (
                    <FormButtonEdit className="pull-right" onClick={this.formTypeChange}>
                      Edit
                    </FormButtonEdit>
                  ) : (
                    <FormButtonCancel className="pull-right" onClick={this.formTypeChange}>
                      Cancel
                    </FormButtonCancel>
                  )}
                </div>
              </div>
            </div>

            {errors?.errors?.length > 0 && Errors(errors)}

            {formType === 'edit' ? (
              <EditMachineForm
                initialData={prepareInitialFormData(machine)}
                onSubmit={this.onSubmit}
                isSubmitting={isFetching}
                errorInfo={errorInfo}
              />
            ) : (
              <React.Fragment>
                <div className="box">
                  <div className="columns">
                    <Param label="Description" value={machine.description} />
                    <Param label="Ping Status">
                      {machine.ping_error && (
                        <i
                          className="fa fa-warning icon is-danger fa-pull-left"
                          title={machine.ping_error}
                        />
                      )}

                      {machine.ping_status ? (
                        <div className={`tag ${pingStatus}`}>{machine.ping_status}</div>
                      ) : (
                        <i className="is-italic">No data provided</i>
                      )}
                    </Param>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <h1 className="title is-size-4">Plans</h1>
                          </div>
                        </div>
                      </div>

                      {filteredPlans ? (
                        <table className="table  is-transparent is-fullwidth is-striped">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Id</th>
                            </tr>
                          </thead>
                          {filteredPlans.map((plan, index) => {
                            return (
                              <tr key={index}>
                                <Link to={`/plans/details/${plan.id}`} className="table-cell">
                                  {plan.name}
                                </Link>
                                <Link to={`/plans/details/${plan.id}`} className="table-cell">
                                  {plan.id}
                                </Link>
                              </tr>
                            );
                          })}
                        </table>
                      ) : (
                        <FormControl
                          type="text"
                          readOnly={true}
                          value="This machine is not in a plan!"
                        />
                      )}
                    </div>
                  </div>

                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">SSM Metadata</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!machine.ssm_metadata || Object.keys(machine.ssm_metadata).length === 0 ? (
                          <FormControl
                            type="text"
                            readOnly={true}
                            value="SSM Metadata is unavailable for this machine."
                          />
                        ) : (
                          <JSONPretty id="json-pretty" data={machine.ssm_metadata} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <div className="box">
                      <div className="columns">
                        <Param
                          label="SSM Machine Id"
                          value={machine.access && machine.access.ssm_machine_id}
                        />
                        <Param label="IAM Role" value={machine.access && machine.access.iam_role} />
                        <Param label="Region" value={machine.access && machine.access.region} />
                        <Param label="Provider" value={machine.access && machine.provider} />
                      </div>
                      <div className="columns">
                        <Param
                          label="OS Type"
                          value={machine.ssm_metadata && machine.ssm_metadata.platform_type}
                        />
                        <Param
                          label="Subtype"
                          value={machine.ssm_metadata && machine.ssm_metadata.platform_name}
                        />
                        {!machine.reboot_policy && machine.allow_reboot ? (
                          <Param label="Allow Reboot">
                            <i className="fa fa-check" />
                          </Param>
                        ) : (
                          <Param label="Reboot Policy" value={machine.reboot_policy} />
                        )}
                        <Param
                          label="Custom Update Command (S3 URL)"
                          value={machine.s3_custom_script}
                        />
                      </div>
                      <div className="columns">
                        <Param label="Update Category" value={machine.update_category} />
                        <Param label="Update Severity" value={machine.update_severity} />
                        <Param label="Include Kbs" value={machine.include_kbs} />
                        <Param label="Exclude Kbs" value={machine.exclude_kbs} />
                      </div>
                      {machine.azure_info && (
                        <div className="columns">
                          <Param
                            label="Azure Subscription ID"
                            value={machine.azure_info.subscription_id}
                          />
                          <Param
                            label="Azure Subscription Name"
                            value={machine.azure_info.subscription_name}
                          />
                          <Param
                            label="Azure Resource Group Name"
                            value={machine.azure_info.resource_group_name}
                          />
                          <Param label="" value=" " />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Metadata data={machine.metadata} name="Machine" />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { machines, plans } = state;
  const machineId = ownProps.routeParams.splat || null;
  const machine = machineId && machines.items.find(machine => machine.id === machineId);

  return {
    machine: machine ? trimNullValues(machine) : undefined,
    plans: plans.items || [],
    error: machines.error,
    isFetching: machines.isFetching,
    errorInfo: machines.errorInfo,
  };
};

export default connect(mapStateToProps)(MachineDetails);

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

const requiredSuffix = ' *';

type LabelProps = {
  id?: string;
  children: React.ReactNode;
  required?: boolean;
};

const Label = ({ id, children, required = false }: LabelProps) => (
  <div className="field-label is-normal">
    <label htmlFor={id} className="label">
      {children}
      {required && requiredSuffix}
    </label>
  </div>
);

export default Label;

/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import { MetadataSchema } from '../../../../commonComponents/form2/schema/metadata';
import { rebootPolicySchemaValues } from '../../../../commonComponents/form2/schema/rebootPolicy';
import { safeStr, stringRequired } from '../../../../commonComponents/form2/validation/string';
import { awsRegionsOptions } from '../../../../constants/regions';
import { OsType } from '../../consts';

const regions = awsRegionsOptions.map(({ value }) => value) as [string, ...string[]];

export const NewAWSMachineFormSchema = z.object({
  // 1st step
  name: stringRequired('Name'),
  metadata: MetadataSchema.optional(),
  access: z.object({
    ssm_machine_id: stringRequired('SSM instance ID'),
    iam_role: stringRequired('IAM role'),
    region: z.enum(regions).optional(),
  }),
  // 2nd step
  description: safeStr.optional(),
  reboot_policy: z.enum(rebootPolicySchemaValues).optional(),
  published_days_old: z.preprocess(
    value => (value === null || Number.isNaN(value) ? undefined : value),
    z
      .number()
      .int({ message: 'Days since update must be an integer' })
      .nonnegative({ message: 'Days since update must be positive' })
      .optional(),
  ),
  s3_custom_script: safeStr.optional(),
  os: z.enum([OsType.unknown, OsType.linux, OsType.windows]).optional(),
  update_category: safeStr.optional(),
  update_severity: safeStr.optional(),
  include_kbs: safeStr.optional(),
  exclude_kbs: safeStr.optional(),
});

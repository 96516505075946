/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';

class SearchForm extends Component {
  constructor() {
    super();
    this.state = { term: '' };
    this.updateSearchValue = this.updateSearchValue.bind(this);
  }

  updateSearchValue(e) {
    const { searchUpdated } = this.props;
    this.setState({ term: e.target.value }, () => {
      searchUpdated(this.state.term);
    });
  }

  render() {
    const { term } = this.props;
    return (
      <div className="field">
        <p className="control has-icons-right">
          <input
            className="input"
            onChange={this.updateSearchValue}
            value={term}
            placeholder={'Search'}
          />
          <span className="icon is-small is-right is-search-icon">
            <i className="fa fa-search" />
          </span>
        </p>
      </div>
    );
  }
}

export { SearchForm };

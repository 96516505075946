/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getMachine = `query Machine($customer_id: String!, $id: String!) {
    machine(customer_id: $customer_id, id: $id) { 
      id
      customer_id
      provider
      name
      description
      metadata {
        key
        value
      }
      s3_custom_script
      update_severity
      published_days_old
      update_category
      ping_status
      include_kbs
      exclude_kbs
      created_at
      updated_at
      allow_reboot
      reboot_policy
      plans
      pipelines
      last_patching {
        event_id
        status
        action_taken
      }
      access {
        ssm_machine_id
        iam_role
        region
      }
      azure_info {
        subscription_id
        subscription_name
        resource_group_name
      }
      ssm_metadata {    
        activation_id
        agent_version
        association_status
        computer_name
        ip_address
        iam_role
        instance_id
        is_latest_version
        last_association_execution_date
        last_ping_date_time
        last_successful_association_execution_date
        name
        ping_status
        platform_name
        platform_type
        platform_version
        registration_date
        resource_type
      }
    }
  }
  `;

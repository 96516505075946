/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function downloadFile(content: string, filename: string) {
  const link = document.createElement('a');
  const urlContent = `data:text/yaml;charset=utf-8,${encodeURIComponent(content)}`;
  link.href = urlContent;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import classNames from 'classnames';

const emptyState = <i className="is-italic">No data provided</i>;

type ParamProps = {
  label: string;
  value?: string;
  className?: string;
  children: React.ReactNode;
};

export const Param = ({ label, value, className = '', children }: ParamProps) => (
  <div className={classNames('column', className)}>
    <strong className="is-desc-label">{label}</strong>
    <br />
    {children || value || emptyState}
  </div>
);

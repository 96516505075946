/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getEventScanReport = `query EventScanReport($customer_id: String!, $event_id: String!) {
    eventScanReport(customer_id: $customer_id, event_id: $event_id) { 
      id
      owner
      customer_id
      time_zone
      window_start_time
      window_end_time
      start_time
      end_time
      plan_id
      patching_status
      dry_run
      patched_machines {
        machine_id
        status
        internal_status
        installed
        failed
        removed
        updated
        available
        error
        start_time
        end_time
        action_taken
      }
      not_patched_machines {
        machine_id
        status
        internal_status
        action_taken
        name
      }
      hooks_results {
        pre_hooks
        post_hooks
        pre_host_hooks {
          machine_id
          statuses
        }
        post_host_hooks {
          machine_id
          statuses
        }
      }
    }
  }
  `;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { DynamicPlan } from '../../../graphql/generated-types';
import MachineTagModal from './MachineTagModal';

type MachineTagFormInputProps = {
  value: DynamicPlan;
  onChange: (dynamicPlanConfig: DynamicPlan) => void;
  onMachineTagRemoved: () => void
};

function MachineTagFormInput({ value = {}, onChange, onMachineTagRemoved }: MachineTagFormInputProps) {
  const [isModalShown, setIsModalShown] = React.useState(false);

  const showModal = () => setIsModalShown(true);
  const hideModal = () => setIsModalShown(false);

  function addMachineTag(machineTag: DynamicPlan) {
    onChange(machineTag);
    hideModal();
  }

  function removeMachineTag(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    hideModal();
    onMachineTagRemoved()
  }
  return (
    <>
      <div className="field-body is-input-like">
        {!!value?.tag_list?.length && (
          <span className="tag" onClick={showModal}>
            {value.tag_list.map(({ key }) => key).join(' ')}
            <i className="fa fa-trash is-pointer" onClick={removeMachineTag} />
          </span>
        )}
        {value.tag_condition?.expression && (
          <span className="tag" onClick={showModal}>
            {value.tag_condition.expression}
            <i className="fa fa-trash is-pointer" onClick={removeMachineTag} />
          </span>
        )}
        {isModalShown && (
          <MachineTagModal
            addMachineTag={addMachineTag}
            visible={isModalShown}
            hideModal={hideModal}
            data={value}
          />
        )}
        {!value.tag_list && !value.tag_condition && (
          <button type="button" aria-label="Add new machine" className="button" onClick={showModal}>
            Add New
          </button>
        )}
      </div>
    </>
  );
}

export default MachineTagFormInput;

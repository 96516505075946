/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  REPORT_REQUEST,
  REPORT_SUCCESS,
  REPORT_FAILURE,
  CLEAR_REPORT,
  REPORT_NOT_AVAILABLE,
  UPDATE_LOG_FAILURE,
  UPDATE_LOG_REQUEST,
  UPDATE_LOG_SUCCESS,
  SCAN_REPORT_FAILURE,
  SCAN_REPORT_REQUEST,
  SCAN_REPORT_SUCCESS,
  CLEAR_SCAN_REPORT,
  SCAN_EVENT_FAILURE,
  SCAN_EVENT_REQUEST,
  SCAN_EVENT_SUCCESS,
} from '../actions/report';

export function report(
  state = {
    isFetching: false,
    error: null,
  },
  action,
) {
  switch (action.type) {
    case REPORT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case REPORT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        report: action.report,
        reportError: null,
        error: null,
      });
    case REPORT_FAILURE:
      let props =
        action.error !== 'NoSuchKey'
          ? {
              reportError: action.error,
              report: null,
              isFetching: false,
            }
          : { isFetching: false };
      return Object.assign({}, state, props);
    case CLEAR_REPORT:
      return Object.assign({}, state, {
        report: null,
        reportError: null,
      });
    case REPORT_NOT_AVAILABLE:
      return Object.assign({}, state, {
        reportError: 'Report not available',
      });
    case UPDATE_LOG_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        updateLogError: action.error,
        updateLog: null,
      });
    case UPDATE_LOG_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case UPDATE_LOG_SUCCESS:
      return Object.assign({}, state, {
        updateLog: action.updateLog,
        isFetching: false,
      });
    case SCAN_REPORT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case SCAN_REPORT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        scanReport: action.payload,
        scanReportError: null,
      });
    case SCAN_REPORT_FAILURE:
      return Object.assign({}, state, {
        scanReportError: action.error,
        isFetching: false,
      });
    case CLEAR_SCAN_REPORT:
      return Object.assign({}, state, {
        scanReport: null,
        scanReportError: null,
        scanEventId: null,
        scanEventError: null,
      });
    case SCAN_EVENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case SCAN_EVENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        scanEventId: action.payload,
        scanEventError: null,
      });
    case SCAN_EVENT_FAILURE:
      return Object.assign({}, state, {
        scanEventError: action.error,
        isFetching: false,
      });
    default:
      return state;
  }
}

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal } from '../../../commonComponents';
import EventHookOutput from './SingleHookOutput';

class EventMachineHooksReport extends React.Component {
  constructor() {
    super();

    this.state = { details: false, tab: 0 };
  }
  componentDidMount() {
    const { machine } = this.props;
    const { pre_host_hooks = [] } = machine;

    this.setState({ tab: pre_host_hooks.length > 0 ? 0 : 1 });
  }
  showDetails = () => this.setState({ details: true });
  closePopup = () => this.setState({ details: false });

  setTab = tab => () => this.setState({ tab });

  buttonColor = (post_host_hooks, pre_host_hooks) => {
    const hooks = [post_host_hooks, pre_host_hooks].flat(1);
    if (hooks.filter(hook => hook.status_code && hook.status_code > 299).length > 0)
      return 'button tag is-danger';
    if (hooks.filter(hook => hook.status_code && hook.status_code >= 200).length === hooks.length)
      return 'button tag is-success';
    return 'button';
  };

  render() {
    const { machine, event } = this.props;
    const { post_host_hooks = [], pre_host_hooks = [] } = machine;
    const { details, tab } = this.state;

    return (
      <React.Fragment>
        <button
          className={`${this.buttonColor(post_host_hooks, pre_host_hooks)}`}
          onClick={this.showDetails}
        >
          Hooks output
        </button>
        <Modal visible={details} title="Machine hooks outputs" cancelAction={this.closePopup}>
          <div className="tabs is-toggle">
            <ul>
              {pre_host_hooks &&
                pre_host_hooks.length > 0 && (
                  <li className={tab === 0 && 'is-active'}>
                    <a onClick={this.setTab(0)} href="#0">
                      <span>Pre hooks</span>
                    </a>
                  </li>
                )}
              {post_host_hooks &&
                post_host_hooks.length > 0 && (
                  <li className={tab === 1 && 'is-active'}>
                    <a onClick={this.setTab(1)} href="#1">
                      <span>Post hooks</span>
                    </a>
                  </li>
                )}
            </ul>
          </div>
          <div>
            {tab === 0 &&
              machine.pre_host_hooks &&
              machine.pre_host_hooks.map((hook, idx) => (
                <EventHookOutput
                  hook={hook}
                  id={`pre_host_${idx}`}
                  event_id={event.id}
                  machine_id={machine.id}
                />
              ))}
            {tab === 1 &&
              machine.post_host_hooks &&
              machine.post_host_hooks.map((hook, idx) => (
                <EventHookOutput
                  machine_id={machine.id}
                  hook={hook}
                  id={`post_host_${idx}`}
                  event_id={event.id}
                />
              ))}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const anyHookCompleted = machine => {
  machine.post_host_hooks === null && (machine.post_host_hooks = []);
  machine.pre_host_hooks === null && (machine.pre_host_hooks = []);
  const { post_host_hooks = [], pre_host_hooks = [] } = machine;
  const hooks = [post_host_hooks, pre_host_hooks].flat(1);
  return hooks && hooks.filter(hook => hook.status_code).length > 0;
};

const RenderIfHasHooks = ({ machine, event }) =>
  anyHookCompleted(machine) ? <EventMachineHooksReport machine={machine} event={event} /> : null;

export default RenderIfHasHooks;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="page-404">
            <img className="cloud-logo" alt="cloud-logo" src="./img/404_logo.png" />
            <h1 className="font-bold">Page not found</h1>
          </div>
        </div>
      </div>
    );
  }
}

export { NotFound };

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getCustomers = `query Customer {
    customers { 
      id
      name
    }
  }
  `;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Link } from 'react-router';
import { GetEC2PolicyButton } from './GetEC2PolicyButton';
import ActivationsModal from './ActivationsModal';
import { GetIAMServiceRoleButton } from './GetIAMServiceRoleButton';

export function SelectMachineType() {
  const [showActivationPopup, setShowActivationPopup] = React.useState(false);

  const toggleShowActivationPopup = () => setShowActivationPopup(value => !value);

  return (
    <div className="main wide">
      <div className="container">
        <h1 className="subtitle">What kind of machine would you like to add to AutoPatcher?</h1>
        <div className="columns">
          <div className="column">
            <div className="box is-fullheight">
              <h1 className="title">AWS Instances</h1>
              <hr />
              <p>By default SSM agent is installed on the following AMI's:</p>
              <ul>
                <li>Windows Server (all SKUs),</li>

                <li>Amazon Linux,</li>

                <li>Amazon Linux 2,</li>

                <li>Ubuntu Server 16.04,</li>

                <li>Ubuntu Server 18.04.</li>
              </ul>
              <p>If you are using one of these AMI's, select this option.</p>
              <div className="box-buttons">
                <Link className="button is-primary" to="/machines/new/aws">
                  Register machine
                </Link>
              </div>

              <p className="paragraph">
                Refer to our{' '}
                <a
                  href="https://docs.autopatcher.nordcloudapp.com/setting-up-ssm#setting-up-ssm-in-iam-mode"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  user guide
                </a>{' '}
                for more details on how to setup SSM in IAM mode.
              </p>

              <p>
                Use button below to generate CloudFormation template which creates IAM Policy that
                can be attached to EC2 instance to provide minimal permissions required for
                AutoPatcher to operate.
              </p>
              <GetEC2PolicyButton />

              <p>
                Use button below to generate CloudFormation template which creates IAM Service Role
                that grants AutoPatcher required permissions to operate.
              </p>
              <GetIAMServiceRoleButton />
            </div>
          </div>
          <div className="column">
            <div className="box is-fullheight">
              <h1 className="title">Hybrid Instances</h1>
              <hr />
              <p>
                This method is intended for Azure/GCP/IBM/on-premise machines. Please use this
                method with AWS machines only if IAM mode is not possible. First, run activation
                commands and after that you'll get SSM Instance ID required to register the machine
              </p>
              <div className="box-buttons">
                <button className="button primary" onClick={toggleShowActivationPopup}>
                  Get Activation
                </button>
                <Link className="button is-primary" to="/machines/new/other">
                  Register machine
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ActivationsModal show={showActivationPopup} hideModal={toggleShowActivationPopup} />
      </div>
    </div>
  );
}

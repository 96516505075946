/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListReducerFactory } from '../../factories';
import { actions } from './actions';

const notificationGroups = ListReducerFactory(actions);

export { notificationGroups };

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

function trimValue(value: unknown): unknown {
  if (value === '' || value === null || Number.isNaN(value as number)) {
    return;
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      return;
    }

    return value.map(elem => trimValue(elem));
  }

  return value;
}

export function serializeFormInput(data: unknown) {
  return JSON.parse(JSON.stringify(data, (_key, value) => trimValue(value)));
}

export function trimNullValues(data: unknown) {
  return JSON.parse(JSON.stringify(data), (_key, value) => (value !== null ? value : undefined));
}

/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getPlan = `query Plan($customer_id: String!, $id: String!) {
  plan(customer_id: $customer_id, id: $id) { 
    id
    customer_id
    cron_window_start
    name
    description
    metadata {
      key
      value
    }
    manual_approval
    dry_run
    reboot_policy
    linux_security_only
    windows_update_category
    windows_update_severity
    on_hold_start
    on_hold_end
    upcoming_notification_time
    parallel
    window_length
    created_at
    time_zone
    updated_at
    notification_groups
    machines {
      id
      name
      order
      exclude 
      pre_host_hooks {
        type
        source
        method
        status_code
      }
      post_host_hooks {
        type
        source
        method
        status_code
      }
      s3_custom_script
    }
    pre_hooks {
      type
      source
      method
      status_code
    }
    post_hooks {
      type
      source
      method
      status_code
    }
    machines_tag {
      tag_list {
        key
        values
      }
      tag_condition {
        placeholders {
          key
          value
        }
        expression
      }
      iam_role
      iam_role_list
      pre_host_hooks {
        type
        source
        method
        status_code
      }
      post_host_hooks {
        type
        source
        method
        status_code
      }
      update_existing
      include_stopped
      allow_reboot
      reboot_policy
      regions
      s3_custom_script
    }
    s3_custom_script
    webhook_inputs {
      trigger_type
      payload
      payload_type
    }
  }
}
  `;

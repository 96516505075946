/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const partialPatch = `mutation PartialPatch($input: PartialPatchInput!) {
    partialPatch(input: $input) {
      id
    }
  }
  `;

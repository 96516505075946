/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// @ts-ignore -- temporary ignore until module is fully typed
import {
  Button,
  ButtonsContainer,
  ControlledField,
  FormWrapper,
  InputField,
  MultiSelect,
  NotificationChannelFormInput,
} from '../../../../commonComponents/form2';

// @ts-ignore -- temporary ignore until module is fully typed
import { useErrorInfo } from '../../../../commonComponents/form2/hooks/useErrorInfo';
import { NotificationGroup, NotificationGroupInput } from '../../../../graphql/generated-types';
import { NOTIFICATION_EVENTS_MAPPING, NOTIFICATION_LEVELS_MAPPING } from '../../consts';
import { zodResolver } from '@hookform/resolvers/zod';
import { NotificationGroupSchema } from './schema';
import Terminal from '../../../../commonComponents/terminal/Terminal';

type NotificationGroupFormProps = {
  initialData?: NotificationGroup;
  onSubmit: (notificationGroup: Partial<NotificationGroup>) => void;
  isSubmitting: boolean;
  errorInfo?: { [key: string]: string };
};

export function NotificationGroupSelectors() {
  return (
    <>
      <ControlledField
        name="events"
        label="Events"
        Component={MultiSelect}
        defaultValue={[]}
        elements={NOTIFICATION_EVENTS_MAPPING}
        placeholder="Select notification events"
      />
      <ControlledField
        name="levels"
        label="Severity Levels"
        Component={MultiSelect}
        defaultValue={[]}
        elements={NOTIFICATION_LEVELS_MAPPING}
        placeholder="Select notification levels"
      />
    </>
  );
}

function NotificationGroupForm({
  initialData,
  onSubmit,
  isSubmitting,
  errorInfo,
}: NotificationGroupFormProps) {
  const methods = useForm({
    defaultValues: initialData,
    resolver: zodResolver(NotificationGroupSchema),
  });

  const formData = methods.watch();

  useErrorInfo({ errorInfo, setError: methods.setError });

  function updateFormData(notificationGroupInput: Partial<NotificationGroupInput>) {
    const validationResults = NotificationGroupSchema.safeParse(notificationGroupInput);

    if (!validationResults.success) {
      return validationResults.error?.issues?.[0]?.message;
    } else {
      for (const [key, value] of Object.entries(notificationGroupInput)) {
        methods.setValue(key as keyof Partial<NotificationGroupInput>, value);
      }
    }
  }

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form className="create-new-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <InputField name="name" registerProps={{ required: true }} />
          <ControlledField
            name="notifications"
            Component={NotificationChannelFormInput}
            defaultValue={[]}
          />
          <NotificationGroupSelectors />
          <ButtonsContainer>
            <Button htmlType="submit" icon="send" loading={isSubmitting}>
              Create
            </Button>
            <Terminal formData={formData} updateFormData={updateFormData} title="notification" />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default NotificationGroupForm;

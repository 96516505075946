/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import cronstrue from 'cronstrue';

const incorrectCronFormatMessage = 'Not a correct CRON format';

export const readableCron = (value: string) => {
  try {
    return cronstrue.toString(value);
  } catch (e) {
    return incorrectCronFormatMessage;
  }
};

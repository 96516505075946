/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Dispatch } from 'redux';
import { postData, resetNewForm } from '../../../actions/createNew';
import { APIContext } from '../../../commonComponents/auth/APIContext';
import { ErrorAlert, FormHeader } from '../../../commonComponents/form2';
import { serializeFormInput } from '../../../commonComponents/form2/utils/serializeFormInput';
import { ROUTES } from '../../../constants/routes';
import { createPipeline } from '../../../graphql/createPipeline';
import { Pipeline } from '../../../graphql/generated-types';
import PipelineForm from '../components/form/PipelineForm';

type PipelineNewProps = {
  dispatch: Dispatch<unknown>;
  errorInfo: { [key: string]: string } | null;
  errorMessages?: string[] | null;
  isSending: boolean;
};

function PipelineNew({ dispatch, errorMessages, errorInfo, isSending }: PipelineNewProps) {
  const { requester } = React.useContext(APIContext);
  React.useEffect(() => {
    resetNewForm(dispatch);
  }, [dispatch]);

  const onSubmit = (formData: Partial<Pipeline>) => {
    const input = serializeFormInput({
      ...formData,
      customer_id: localStorage.getItem('currentCustomer'),
    });

    const callback = (data: Partial<Pipeline>) => {
      if (data) {
        NotificationManager.success('Pipeline has been successfully created');
        browserHistory.push(`${ROUTES.pipelines.pipelines}?refresh=true`);
      } else {
        NotificationManager.error('Unable to create pipeline');
      }
    };

    postData(dispatch, createPipeline, { input }, requester, callback);
  };

  return (
    <div className="main wide">
      <FormHeader
        title="New pipeline"
        subtitle="Create a new pipeline"
        backRoute={ROUTES.pipelines.pipelines}
      />
      {errorMessages && Array.isArray(errorMessages) && <ErrorAlert errors={errorMessages} />}
      <PipelineForm
        onSubmit={onSubmit}
        isSubmitting={isSending}
        errorInfo={errorInfo}
        isDetailsView={false}
      />
    </div>
  );
}

type CreateNewPipelineStoreSlice = {
  error: string[] | null;
  errorInfo: Record<string, string> | null;
  isSending: boolean;
};

const mapStateToProps = (state: { createNew: CreateNewPipelineStoreSlice }) => {
  const { createNew } = state;
  return {
    errorMessages: createNew.error,
    errorInfo: createNew.errorInfo,
    isSending: createNew.isSending,
  };
};

export default connect(mapStateToProps)(PipelineNew);

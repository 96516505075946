/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
// @ts-ignore -- temporary ignore until module is fully typed
import { Terminal } from '../../../../commonComponents';
import { Button, ButtonsContainer, FormWrapper } from '../../../../commonComponents/form2';
import { PlanFormSchema } from './schema';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { PlanInput } from '../../../../graphql/generated-types';
import { useErrorInfo } from '../../../../commonComponents/form2/hooks/useErrorInfo';

const steps = [StepOne, StepTwo, StepThree];

type PlanFormProps = {
  initialData?: PlanInput;
  onSubmit: (plan: Partial<PlanInput>) => void;
  isSubmitting: boolean;
  errorInfo?: { [key: string]: string };
};

function PlanForm({ initialData, onSubmit, isSubmitting, errorInfo }: PlanFormProps) {
  const methods = useForm({ defaultValues: initialData, resolver: zodResolver(PlanFormSchema) });
  const formData = methods.watch();

  const [step, setStep] = React.useState(0);

  useErrorInfo({ errorInfo, setError: methods.setError });

  function updateFormData(planInput: Partial<PlanInput>) {
    const validationResults = PlanFormSchema.safeParse(planInput);

    if (!validationResults.success) {
      return validationResults.error?.issues?.[0]?.message;
    } else {
      for (const [key, value] of Object.entries(planInput)) {
        methods.setValue(key as keyof Partial<PlanInput>, value);
      }
    }
  }

  async function handlePrevStep() {
    const isValid = await methods.trigger();

    if (isValid) {
      setStep(step => step - 1);
    }
  }

  async function handleNextStep() {
    const isValid = await methods.trigger();

    if (isValid) {
      setStep(step => step + 1);
    }
  }

  const StepComponent = steps[step];

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form className="create-new-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <StepComponent />

          <ButtonsContainer>
            {step === 0 && (
              <Button type="secondary" htmlType="submit" icon="send" loading={isSubmitting}>
                Create
              </Button>
            )}
            {step > 0 && (
              <Button
                type="secondary"
                icon="navigate_before"
                onClick={handlePrevStep}
                loading={isSubmitting}
              >
                Previous step
              </Button>
            )}
            {step < steps.length - 1 && (
              <Button
                type="primary"
                icon="navigate_next"
                onClick={handleNextStep}
                loading={isSubmitting}
              >
                Next step
              </Button>
            )}
            {step === steps.length - 1 && (
              <Button htmlType="submit" icon="send" loading={isSubmitting}>
                Create
              </Button>
            )}
            <Terminal formData={formData} updateFormData={updateFormData} title="plans" />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default PlanForm;

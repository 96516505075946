/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Machine } from '../components/Machine';
import { getMachines } from '../../../graphql/getMachines';
import { deleteMachine } from '../../../graphql/deleteMachine';
import { exportMachines } from '../../../graphql/exportMachines';
import { fetchMachines, removeMachine } from '../actions';
import {
  getOwnersList,
  getMachineTypes,
  getMachineStatuses,
  getMachinePatchingStatuses,
  modifyFilter,
  createFilter,
  persistFilterState,
  getPersistedFilterState,
  removePersistedFilterState,
} from '../../../utils/filterUtils';
import { NotificationManager } from 'react-notifications';
import { createFilter as createSearchFilter } from 'react-search-input';
import {
  ButtonNew,
  DataTable,
  SearchForm,
  FilterSelectBox,
  CsvExportButton,
  MachinesReportButton,
} from '../../../commonComponents';
import { MACHINE_STATES_MAPPING, pageSize } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const KEYS_TO_FILTERS = [
  'id',
  'name',
  'os_type',
  'ssm_machine_id',
  'dynamic',
  'reboot_policy',
  'patching_status',
];

class MachinesPage extends Component {
  constructor(props) {
    super(props);
    this.removeItem = this.removeItem.bind(this);
    this.state = {
      searchTerm: '',
      filters: getPersistedFilterState('machineFilters') || {},
      showModal: false,
      deleteId: '',
      deleteName: '',
      viewCount: 0,
    };
  }
  static contextType = APIContext;

  componentDidMount() {
    this.refresh();
  }

  componentWillReceiveProps(props) {
    !props.isFetching &&
      props.machines &&
      this.state.filters.owner &&
      !getOwnersList(props.machines).includes(this.state.filters.owner) &&
      this.setState(
        {
          filters: {},
        },
        removePersistedFilterState('machineFilters'),
      );
  }

  refresh = (paginationToken, allPages) => {
    const { requester } = this.context;

    fetchMachines({
      dispatch: this.props.dispatch,
      myInit: {
        limit: pageSize,
        continuation_token: paginationToken,
      },
      requester,
      operation: getMachines,
      allPages: allPages,
    });
  };

  handleSearchInput = term => this.setState({ searchTerm: term });

  handleFilterChange = (key, val) =>
    this.setState(
      { filters: modifyFilter(this.state.filters, key, val) },
      persistFilterState('machineFilters', this.state),
    );

  removeItem = itemId => {
    const { requester } = this.context;

    removeMachine({
      dispatch: this.props.dispatch,
      myInit: { id: itemId },
      requester,
      operation: deleteMachine,
      callback: NotificationManager.success('Machine removed'),
      itemsUpdate: items => items.filter(item => item.id !== itemId),
    });
  };

  updateViewCount = viewCount => this.state.viewCount !== viewCount && this.setState({ viewCount });

  render() {
    const { error, machines, isFetching, paginationToken } = this.props;
    const { filters, searchTerm } = this.state;

    const filteredMachines = machines
      .filter(createFilter(filters))
      .filter(createSearchFilter(searchTerm, KEYS_TO_FILTERS));

    return (
      <div className="main wide">
        <div className="container">
          <div className="box">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <ButtonNew label={'New Machine'} href={'/machines/new'} />
                </div>
                <div className="level-item">
                  {!isFetching && machines && (
                    <FilterSelectBox
                      options={getMachineTypes(machines)}
                      filterKey="os_type"
                      fieldName="OS Type"
                      value={filters.os_type}
                      onChangeHandler={this.handleFilterChange}
                    />
                  )}
                </div>
                <div className="level-item">
                  {!isFetching && machines && (
                    <FilterSelectBox
                      options={getMachinePatchingStatuses(machines)}
                      filterKey="patching_status"
                      fieldName="Last Patching Status"
                      value={filters.patching_status}
                      mapping={MACHINE_STATES_MAPPING}
                      onChangeHandler={this.handleFilterChange}
                    />
                  )}
                </div>
                <div className="level-item">
                  {!isFetching && machines && (
                    <FilterSelectBox
                      options={getMachineStatuses(machines)}
                      filterKey="ping_status"
                      fieldName="Ping Status"
                      value={filters.ping_status}
                      onChangeHandler={this.handleFilterChange}
                    />
                  )}
                </div>
                <div className="level-item">
                  {!isFetching && machines && (
                    <FilterSelectBox
                      options={['true', 'false']}
                      filterKey="dynamic"
                      fieldName="all instances"
                      value={filters.dynamic}
                      onChangeHandler={this.handleFilterChange}
                      mapping={{
                        true: { name: 'Show only dynamic' },
                        false: { name: 'Show only static' },
                      }}
                    />
                  )}
                </div>
                <div className="level-item">
                  {!isFetching && machines && (
                    <FilterSelectBox
                      options={['NEVER', 'IF_NEEDED', 'ALWAYS']}
                      filterKey="reboot_policy"
                      fieldName="reboot policy"
                      value={filters.reboot_policy}
                      onChangeHandler={this.handleFilterChange}
                      mapping={{
                        NEVER: { name: 'Never' },
                        IF_NEEDED: { name: 'If needed' },
                        ALWAYS: { name: 'Always' },
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="level-right">
                <SearchForm searchUpdated={this.handleSearchInput} />
              </div>
            </nav>
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <CsvExportButton
                    ids={filteredMachines.map(m => m.id)}
                    query={exportMachines}
                    queryName="exportMachines"
                  />
                </div>
                <div className="level-item">
                  <MachinesReportButton />
                </div>
              </div>
              {machines.length > 0 && (
                <div className="level-right">
                  {`Viewing ${this.state.viewCount}/${machines.length}`}
                  {paginationToken && '+'}
                </div>
              )}
            </nav>
          </div>
          {error && (
            <div className="message is-danger">
              <div className="message-body">
                {typeof error === 'string' && <div key="0">{error}</div>}
                {typeof error !== 'string' &&
                  Object.keys(error).map((el, index) => (
                    <div key={index}>
                      {el.replace(/_/g, ' ').toUpperCase()}:{' '}
                      {typeof error[el] === 'string'
                        ? error[el]
                        : Object.keys(error[el][0]).map(element => error[el][0][element])}
                    </div>
                  ))}
              </div>
            </div>
          )}

          <DataTable
            data={filteredMachines}
            pagination={{
              show: !!paginationToken,
              loadNextPage: () => this.refresh(paginationToken),
              loadAllPages: () => this.refresh(paginationToken, true),
            }}
            isLoading={isFetching}
            headers={[
              { label: 'Machine name', field: 'name' },
              { label: 'Last patching status' },
              { label: 'Ping status' },
              { label: 'Reboot policy', isNarrow: true },
              { label: 'Type' },
              { label: '' },
            ]}
            row={Machine}
            rowActions={{ removeItem: this.removeItem }}
            updateViewCount={this.updateViewCount}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    machines = {
      items: [],
      paginationToken: null,
      isFetching: false,
      error: null,
    },
  } = state;

  return {
    error: machines.error,
    isFetching: machines.isFetching,
    paginationToken: machines.paginationToken,
    machines: machines.items.map(machine => ({
      ...machine,
      patching_status:
        (machine.plans && machine.plans.length > 0) ||
        (machine.pipelines && machine.pipelines.length > 0)
          ? machine.last_patching
            ? machine.last_patching.status
            : 'Not_available'
          : 'Plan_missing',
      allow_reboot: machine.allow_reboot,
      ssm_machine_id: machine.access ? machine.access.ssm_machine_id : null,
      dynamic: machine.added_dynamically ? machine.added_dynamically.toString() : 'false',
      os_type:
        machine.ssm_metadata && machine.ssm_metadata.platform_type
          ? machine.ssm_metadata.platform_type
          : 'Unknown',
    })),
  };
};

export default connect(mapStateToProps)(MachinesPage);

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import Chips from 'react-chips';

class PartialHooksInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      list: this.props.formData
        ? this.props.formData.map(
            (el, index) =>
              typeof el !== 'string' ? `${index} ${el.type} (source: ${el.source})` : el,
          )
        : [],
      suggestions: this.props.formData
        ? this.props.formData.map(
            (el, index) =>
              typeof el !== 'string' ? `${index} ${el.type} (source: ${el.source})` : el,
          )
        : [],
    };
  }

  onChange(chips) {
    this.setState({
      list: chips,
    });
    this.props.onChange(chips);
  }

  render() {
    return (
      <Chips
        value={this.state.list}
        onChange={this.onChange}
        suggestions={this.state.suggestions}
        fromSuggestionsOnly={true}
        placeholder="Start typing a hook id here"
        id="partialHooks"
      />
    );
  }
}

export default PartialHooksInput;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const putNotificationGroup = `mutation PutNotificationGroup($input: NotificationGroupPutInput!) {
  putNotificationGroup(input: $input) { 
    id
  }
}
`;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const permissionSchema = {
  type: 'object',
  required: ['roles'],
  properties: {
    roles: { type: 'array', title: 'Roles' },
  },
};

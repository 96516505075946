/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

const HookStatus = ({ status }) => {
  const { className, label } =
    status && status >= 200 && status < 300
      ? { className: 'tag is-success', label: 'Success' }
      : status && status >= 300
      ? { className: 'tag is-danger', label: `Failure (${status})` }
      : { className: '', label: '' };

  return <div className={`${className}`}>{label.toUpperCase()}</div>;
};

export default HookStatus;

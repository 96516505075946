/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import Form from 'react-jsonschema-form';
import {
  ObjectFieldTemplate,
  CustomFieldTemplate,
  FormButtonConfirm,
  FormButtonEdit,
  FormButtonsContainer,
  FormButtonCancel,
  Terminal,
  ArrayFieldTemplate,
} from '../../commonComponents';
import { Errors } from '../../commonComponents/form/Validation';
import diff from 'deep-diff';
import { uiSchema, fields, widgets, patterns } from '../../utils/uiSchema';
import { removeEmptySelectValues } from '../../utils/form';

const _handleKeyDown = event => {
  if (event.path && event.path[0] && event.path[0].tagName === 'INPUT' && event.keyCode === 13) {
    event.preventDefault();
  }
};

export default class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customSchema: this.props.schema || {},
      formData: removeEmptySelectValues(this.props.formData) || {},
      changedAfterSubmit: [],
      formStep: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  //ugly hack to prevent submit on enter :(
  componentWillMount() {
    document.addEventListener('keydown', _handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', _handleKeyDown);
  }

  componentWillReceiveProps(props) {
    this.setState({ customSchema: props.schema });
  }

  handleChange(data) {
    const lastChange = diff(data.formData, this.state.formData);
    const lastChangedField = lastChange && lastChange[0] ? lastChange[0].path[0] : undefined;
    const changedAfterSubmit = [...this.state.changedAfterSubmit];

    if (changedAfterSubmit.indexOf(lastChangedField) < 0) {
      changedAfterSubmit.push(lastChangedField);
    }

    this.setState({
      formData: removeEmptySelectValues(data.formData),
      changedAfterSubmit,
    });
  }

  updateFormData(data) {
    this.setState({
      formData: data,
    });
  }

  onSubmit(formData) {
    let buttonType = document.activeElement.className.split(' ');
    buttonType = buttonType[buttonType.length - 1];

    buttonType !== 'form-submit' && this.state.formStep < this.props.schema.length - 1
      ? this.setState({
          formStep: this.state.formStep + 1,
        })
      : this.props.onSubmit(formData);
  }

  stepBack = () => {
    this.setState({
      formStep: this.state.formStep - 1,
    });
  };

  render() {
    const { formData, formStep, customSchema } = this.state;
    const { parent, schema, className, type, disabled, terminalDisabled, error, onBack } = this.props;
    const stepSchema = Array.isArray(schema);

    function transformErrors(errors) {
      return errors.map(error => {
        if (error.name === "pattern") { 
          if (error.params && error.params.pattern === patterns.emailPattern) {
            error.message = 'The entered value is not a valid email.';
          }
          if (error.params && error.params.pattern === patterns.uuidPattern) {
            error.message = 'The entered value is not a valid UUID.';
          }
        }
        return error;
      });
    }

    return (
      <div className="container">
        <div className="box mainBox wide">
          {error && Array.isArray(error) && <Errors errors={error} />}

          <Form
            className={className}
            schema={stepSchema ? schema[formStep] : customSchema}
            uiSchema={uiSchema}
            FieldTemplate={CustomFieldTemplate}
            ArrayFieldTemplate={ArrayFieldTemplate}
            ObjectFieldTemplate={ObjectFieldTemplate}
            showErrorList={false}
            transformErrors={transformErrors}
            fields={fields}
            onSubmit={({ formData, ...rest }) => {
              this.onSubmit(formData);
            }}
            onChange={this.handleChange}
            widgets={widgets}
            formData={formData}
          >
            <FormButtonsContainer>
              {stepSchema && formStep > 0 && (
                <FormButtonEdit onClick={this.stepBack} icon="navigate_before">
                  Previous Step
                </FormButtonEdit>
              )}
              {(!stepSchema || formStep === 0 || formStep === schema.length - 1) && (
                <FormButtonConfirm
                  className={
                    !stepSchema || formStep === schema.length - 1
                      ? 'high-order form-submit'
                      : 'form-submit'
                  }
                  disabled={disabled}
                >
                  {type === 'new' ? 'CREATE' : 'SAVE'}
                </FormButtonConfirm>
              )}
              {!stepSchema && onBack && (
                <FormButtonCancel onClick={onBack}>Cancel</FormButtonCancel>
              )}
              {stepSchema && formStep < schema.length - 1 && (
                <FormButtonConfirm className="high-order" icon="navigate_next">
                  Next Step
                </FormButtonConfirm>
              )}
              {!terminalDisabled && (
                <Terminal formData={formData} updateFormData={this.updateFormData} title={parent} />
              )}
            </FormButtonsContainer>
          </Form>
        </div>
      </div>
    );
  }
}

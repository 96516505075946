/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { Tooltip } from 'react-tippy';
import { FieldDescription, FieldError, FieldExtrasWrapper, Fieldset, FieldWrapper, Label } from '.';
import Input from './Input';
import { capitalize } from './utils/capitalize';
import { extractError, extractErrorMessage } from './utils/extractError';

type InputFieldProps = {
  name: string;
  id?: string;
  label?: string;
  description?: string;
  registerProps?: RegisterOptions;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  disabled?: boolean;
  disabledReason?: string;
};

const InputField = ({
  name,
  id,
  label = capitalize(name),
  description,
  registerProps = {},
  type,
  disabled,
  disabledReason,
  ...props
}: InputFieldProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = extractError(name, errors);
  const errorMessage = extractErrorMessage(error, label);

  const input = <Input {...{ name, id, registerProps, type, disabled, ...props }} />;

  return (
    <>
      <Fieldset>
        <Label id={id || name} required={!!registerProps?.required}>
          {label}
        </Label>
        <FieldWrapper>
          {disabled && disabledReason ? (
            <Tooltip title={disabledReason} position="top" arrow distance={20}>
              {input}
            </Tooltip>
          ) : (
            input
          )}
        </FieldWrapper>
      </Fieldset>
      {(error || description) && (
        <FieldExtrasWrapper>
          {description && <FieldDescription>{description}</FieldDescription>}
          {error && <FieldError>{errorMessage}</FieldError>}
        </FieldExtrasWrapper>
      )}
    </>
  );
};

export default InputField;

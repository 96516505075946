/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { postData, resetNewForm } from '../../../actions/createNew';
import { createNotificationGroup } from '../../../graphql/createNotificationGroup';
import { serializeFormInput } from '../../../commonComponents/form2/utils/serializeFormInput';
import { ROUTES } from '../../../constants/routes';
import { ErrorAlert, FormHeader } from '../../../commonComponents/form2';
import NotificationGroupForm from '../components/form/NotificationGroupForm';
import { NotificationGroup } from '../../../graphql/generated-types';
import { APIContext } from '../../../commonComponents/auth/APIContext';

type CreateNewNotificationGroupProps = {
  dispatch: any;
  errorInfo?: { [key: string]: string };
  errorMessages?: string[];
  isSending: boolean;
};

function CreateNewNotificationGroup({
  dispatch,
  errorMessages,
  errorInfo,
  isSending,
}: CreateNewNotificationGroupProps) {
  const { requester } = React.useContext(APIContext);

  React.useEffect(() => {
    resetNewForm(dispatch);
  }, [dispatch]);

  const onSubmit = (formData: Partial<NotificationGroup>) => {
    const input = serializeFormInput({
      ...formData,
      customer_id: localStorage.getItem('currentCustomer'),
    });

    const callback = (data: Partial<NotificationGroup>) => {
      if (data) {
        NotificationManager.success('Notification group has been successfully created');
        browserHistory.push(`${ROUTES.notificationGroups.notificationGroups}?refresh=true`);
      } else {
        NotificationManager.error('Unable to create notification group');
      }
    };

    postData(dispatch, createNotificationGroup, { input }, requester, callback);
  };

  return (
    <div className="main wide">
      <FormHeader
        title="New notification group"
        subtitle="Create a new notification group"
        backRoute={ROUTES.notificationGroups.notificationGroups}
      />
      {errorMessages && Array.isArray(errorMessages) && <ErrorAlert errors={errorMessages} />}
      <NotificationGroupForm onSubmit={onSubmit} isSubmitting={isSending} errorInfo={errorInfo} />
    </div>
  );
}

// @ts-ignore
const mapStateToProps = state => {
  const { createNew } = state;

  return {
    errorMessages: createNew.error,
    errorInfo: createNew.errorInfo,
    isSending: createNew.isSending,
    dataSent: createNew.dataSent,
    response: createNew.response,
  };
};

export default connect(mapStateToProps)(CreateNewNotificationGroup);

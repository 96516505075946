/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { LoadingHOC } from '../loading/Loading';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { getMachineStateReport } from '../../graphql/getMachineStateReport';
import { APIContext } from '../auth/APIContext';

export class MachinesReportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      endDate: moment(),
      startDate: moment().subtract(7, 'days'),
      datesVisible: false,
    };
  }
  static contextType = APIContext;

  submit() {
    const { requester } = this.context;

    const customerId = localStorage.getItem('currentCustomer') || '';
    this.setState({ loading: true });
    return (async () => {
      try {
        const response = await requester({
          query: getMachineStateReport,
          input: {
            input: {
              customer_id: customerId,
              start_date: this.state.startDate.format('YYYY-MM-DD'),
              end_date: this.state.endDate.format('YYYY-MM-DD'),
            },
          },
        });

        if (response && response.data && response.data.sendMachineStateReport) {
          NotificationManager.success(
            'Machines state report generation successfully started. You will receive email with download link shortly.',
          );
        } else {
          NotificationManager.error('Something went wrong.');
        }
        this.setState({ loading: false, datesVisible: false });
      } catch (err) {
        err.errors &&
          err.errors.length > 0 &&
          NotificationManager.error(err.errors[0].message || 'Something went wrong');
        this.setState({ loading: false });
      }
    })();
  }

  render() {
    const { loading, datesVisible, startDate, endDate } = this.state;
    return (
      <LoadingHOC loading={loading}>
        {datesVisible ? (
          <>
            <div className="level-item">
              <div className="field">
                <div className="control has-icons-left is-narrow-input">
                  <DatePicker
                    className="input"
                    dateFormat="dd/MM/yyyy"
                    todayButton="Today"
                    selected={startDate.toDate()}
                    selectsStart
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    onChange={date => this.setState({ startDate: moment(date) })}
                    placeholderText="Select start date ..."
                  />
                  <span className="icon is-small is-left is-search-icon">
                    <i className="fa fa-calendar" />
                  </span>
                </div>
              </div>
            </div>
            <div className="level-item">
              <div className="field">
                <div className="control has-icons-left is-narrow-input">
                  <DatePicker
                    className="input"
                    dateFormat="dd/MM/yyyy"
                    todayButton="Today"
                    selected={endDate.toDate()}
                    selectsEnd
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    onChange={date => this.setState({ endDate: moment(date) })}
                    minDate={moment().subtract(2, 'years').toDate()}
                    maxDate={moment().toDate()}
                    showDisabledMonthNavigation
                    placeholderText="Select end date ..."
                  />
                  <span className="icon is-small is-left is-search-icon">
                    <i className="fa fa-calendar" />
                  </span>
                </div>
              </div>
            </div>
            <div className="level-item">
              <button onClick={() => this.submit()} className="button">
                GENERATE REPORT
              </button>
            </div>
          </>
        ) : (
          <button className="button" onClick={() => this.setState({ datesVisible: true })}>
            GET STATES REPORT
          </button>
        )}
      </LoadingHOC>
    );
  }
}

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import Chips, { Chip } from 'react-chips';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { LoadingHOC } from '../loading/Loading';
import { getRoles } from '../../graphql/getRoles';
import { fetchRoles } from '../../views/roles/actions';
import { pageSize } from '../../utils';
import { Loading } from '../loading/Loading';
import { APIContext } from '../auth/APIContext';

const RoleItem = SortableElement(({ role, onRemove, chipTheme, onEdit }) => {
  const name = role.split(/[/:(/]/)[1];
  return (
    <div>
      <Chip theme={chipTheme} onRemove={onRemove}>
        {name}
      </Chip>
    </div>
  );
});

const RoleList = SortableContainer(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tempValues: '',
      };
      this.onChipsChange = this.onChipsChange.bind(this);
      this.renderChip = this.renderChip.bind(this);
    }

    onChipsChange(newValue) {
      const { onChange } = this.props;
      const id = newValue.length !== 0 ? newValue[0].split(/[:)]/)[1] : [];

      this.setState({
        tempValues: newValue,
        newRole: { id: id },
        oldIdx: null,
      });
      onChange(newValue);
    }

    renderChip(chip, theme) {
      const { onChipRemove, roles } = this.props;
      const idx = chip.order - 1;
      return (
        <RoleItem
          role={chip}
          index={idx}
          chipTheme={theme}
          onRemove={() => {
            onChipRemove(chip);
          }}
          onEdit={() => {
            this.setState({
              tempValues: roles,
              newRole: chip,
              oldIdx: idx,
            });
          }}
        />
      );
    }

    render() {
      const { roles, suggestions, isFetching } = this.props;

      return (
        <React.Fragment>
          <div style={{ position: 'absolute', left: '-9rem', top: '-0.5rem' }}>
            {isFetching && <Loading />}
          </div>
          <Chips
            value={roles}
            suggestions={suggestions}
            onChange={this.onChipsChange}
            renderChip={this.renderChip}
            getChipValue={chip => chip.id}
            placeholder="Start typing a role id or name here"
            theme={{
              ...Chips.defaultProps.theme,
              chipsContainer: {
                ...Chips.defaultProps.theme.chipsContainer,
                display: 'block',
                width: '100%',
              },
              suggestionsList: {
                ...Chips.defaultProps.theme.suggestionsList,
                maxHeight: '57vh',
                overflow: 'scroll',
              },
            }}
            chipTheme={{ chip: { display: 'inline-block' } }}
            fromSuggestionsOnly={true}
          />
        </React.Fragment>
      );
    }
  },
);

class RolesInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: props.formData,
    };
    this.onChipRemove = this.onChipRemove.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;

    fetchRoles({
      dispatch: this.props.dispatch,
      myInit: { limit: pageSize },
      requester,
      operation: getRoles,
      allPages: true,
    });
  }

  componentWillReceiveProps(props) {
    this.onChange(props.formData);
  }

  onChipRemove(chip) {
    this.onChange(oldState => {
      const idx = chip.order - 1;
      const left = oldState.roles.slice(0, idx);
      const right = oldState.roles.slice(idx + 1);
      return [...left, ...right];
    });
  }

  onChange(items) {
    this.setState(
      {
        roles: items,
      },
      () => {
        this.props.onChange(items);
      },
    );
  }

  render() {
    const { isFetching, suggestions } = this.props;
    const { roles } = this.state;

    const filteredSuggestions = roles ? suggestions.filter(el => !roles.includes(el)) : suggestions;

    return (
      <LoadingHOC isFetching={isFetching}>
        <RoleList
          roles={roles}
          suggestions={filteredSuggestions}
          isFetching={isFetching}
          onChipRemove={this.onChipRemove}
          onChange={this.onChange}
          useDragHandle={true}
          style={{ width: '100%' }}
        />
      </LoadingHOC>
    );
  }
}

const mapStateToProps = state => {
  const { roles = { items: [], isFetching: false, error: null } } = state;
  return {
    error: roles.error,
    isFetching: roles.isFetching,
    suggestions:
      roles.items &&
      roles.items
        .map(role => ({
          ...role,
        }))
        .map(({ id, name }) => `name: ${name} (id:${id})`),
    roles,
  };
};

export default connect(mapStateToProps)(RolesInput);

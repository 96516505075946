/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FieldWrapper, Label } from '../index';
import Input from '../Input';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { TagList } from '../../../graphql/generated-types';

type MachineTagListValuesProps = {
  name: string;
  keyIndex: number;
  value: string[];
  onChange: (value: string[]) => void;
};

function MachineTagListValues({ value, name, keyIndex, onChange }: MachineTagListValuesProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error: FieldErrors | undefined = errors[name]?.[keyIndex]?.values;

  function handleValueAdded() {
    onChange([...value, '']);
  }

  function handleValuesRemoved(index: number) {
    value.splice(index, 1);
    onChange(value);
  }

  return (
    <div className="field is-horizontal">
      <div className="field-body">
        <div className="field">
          <div className="control">
            {value.map((_, index) => {
              return (
                <div className="array-item" key={index}>
                  <button
                    className="button is-icononly is-danger is-input-icon"
                    onClick={() => handleValuesRemoved(index)}
                  >
                    <i className="fa fa-trash is-square"></i>
                  </button>
                  <div className="form-group field is-horizontal">
                    <Label id={`${name}.${keyIndex}.values.${index}`} required>
                      EC2 tag value
                    </Label>
                    <Input
                      name={`${name}.${keyIndex}.values.${index}`}
                      registerProps={{
                        required: true,
                      }}
                      error={error?.[index]?.message}
                    />
                  </div>
                </div>
              );
            })}

            <button
              className="button array-add is-icononly is-danger is-square is-input-icon"
              type="button"
              onClick={handleValueAdded}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

type MachineTagListProps = {
  name: string;
  value: Array<Partial<TagList>>;
  onChange: (value: Array<Partial<TagList>>) => void;
};

function MachineTagList({ name, value, onChange }: MachineTagListProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error: FieldErrors | undefined = errors[name];

  function handleKeyAdded() {
    onChange([...value, { key: '', values: [''] }]);
  }

  function handleKeyRemoved(index: number) {
    value.splice(index, 1);
    onChange(value);
  }

  function handleValuesChanged(newValues: string[], index: number) {
    value[index] = { ...value[index], values: newValues };
    onChange(value);
  }

  return (
    <FieldWrapper>
      {value.map((item, index) => (
        <div className="array-item" key={index}>
          <button
            className="button is-icononly is-danger is-input-icon"
            onClick={() => handleKeyRemoved(index)}
          >
            <i className="fa fa-trash is-square"></i>
          </button>
          <div className="form-group field field-object">
            <div className="field is-horizontal">
              <Label id={`${name}.${index}.key`} required>
                EC2 tag key
              </Label>
              <Input
                name={`${name}.${index}.key`}
                registerProps={{
                  required: true,
                }}
                error={error && error[index]?.key?.message}
              />
            </div>
            <MachineTagListValues
              name={name}
              keyIndex={index}
              value={item.values || []}
              onChange={values => handleValuesChanged(values, index)}
            />
          </div>
        </div>
      ))}
      <button
        aria-label="Add more tag keys"
        className="button array-add is-icononly is-danger is-square"
        type="button"
        onClick={handleKeyAdded}
      >
        <i className="fa fa-plus"></i>
      </button>
    </FieldWrapper>
  );
}

export default MachineTagList;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { TagList } from '../../../../graphql/generated-types';

type TagListTableProps = {
  tagList: TagList[];
};

export const TagListTable = ({ tagList }: TagListTableProps) => (
  <table className="table is-striped is-fullwidth">
    <thead>
      <tr>
        <th>Tag key</th>
        <th>Tag values</th>
      </tr>
    </thead>
    <tbody>
      {tagList.map(({ key, values }) => (
        <tr key={key}>
          <th>{key}</th>
          <td>{values.join(', ')}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chips from 'react-chips';
import { connect } from 'react-redux';
import { fetchNotificationGroups } from '../../views/notificationGroups/actions';
import { getNotificationGroups } from '../../graphql/getNotificationGroups';
import { pageSize } from '../../utils';
import { theme } from './NotificationInputTheme';
import { APIContext } from '../auth/APIContext';

class NotificationGroup extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.buildName = this.buildName.bind(this);
    this.state = {
      list: this.props.formData ? this.props.formData : [],
    };
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;

    fetchNotificationGroups({
      dispatch: this.props.dispatch,
      myInit: { limit: pageSize },
      requester,
      operation: getNotificationGroups,
      allPages: true,
    });
  }

  onChange(chips) {
    this.setState({
      list: chips,
    });
    this.props.onChange(chips.map(chip => chip.split(' ')[0]));
  }

  buildName(notificationGroup) {
    const name = notificationGroup.name || 'name missing';
    return `${notificationGroup.id} (name:${name})`;
  }

  render() {
    const { notificationGroups } = this.props;
    const suggestions = notificationGroups.map(el => this.buildName(el));
    const namesList = this.state.list.map(e => {
      const id = e.split(' ')[0];
      return this.buildName(notificationGroups.find(ee => id === ee.id) || { id: id });
    });
    return (
      <Chips
        value={namesList}
        suggestions={suggestions}
        onChange={this.onChange}
        placeholder="Start typing a notification group id here"
        fromSuggestionsOnly={true}
        theme={theme}
        id="notificationGroup"
      />
    );
  }
}

NotificationGroup.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { notificationGroups } = state;

  if (!notificationGroups) {
    return {
      notificationGroups: [],
    };
  }

  return {
    notificationGroups: notificationGroups.items,
  };
};

export default connect(mapStateToProps)(NotificationGroup);

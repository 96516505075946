/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { RegisterOptions } from 'react-hook-form';
// @ts-ignore -- there are no types available for this package, it requires creating `types.d.ts` internally
import Chips, { Chip } from 'react-chips';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { InputLoadingHoc } from '../loading/Loading';
import { getMachines } from '../../graphql/getMachines';
// @ts-ignore -- temporary ignore until module is fully typed
import { fetchMachines } from '../../views/machines/actions';
import { pageSize } from '../../utils';
import MachineModal from './plan/MachineModal';
import { PlanMachine } from '../../graphql/generated-types';
import { APIContext } from '../auth/APIContext';
import { preventFromSubmitOnEnter } from './utils/preventFromSubmitOnEnter';

const DragHandle = SortableHandle(() => (
  <span>
    <i className="fa fa-bars" />
  </span>
));

type EditHandleProps = {
  onEdit: () => void;
};

const EditHandle = ({ onEdit }: EditHandleProps) => (
  <span className="edit-button" onClick={onEdit}>
    <i className="fa fa-edit" />
  </span>
);

type MachineItemProps = {
  machine: PlanMachine;
  removeHandle: () => void;
  chipTheme: any;
  onEdit: () => void;
};

const MachineItem = SortableElement(
  ({ machine, removeHandle, chipTheme, onEdit }: MachineItemProps) => {
    return (
      <div>
        <DragHandle />
        <EditHandle onEdit={onEdit} />
        <Chip theme={chipTheme} onRemove={removeHandle}>
          {machine.name}
        </Chip>
      </div>
    );
  },
);

type MachineListReducer = {
  popup: boolean;
  edit: boolean;
  machine: PlanMachine;
};

type MachinesListProps = {
  machines: Array<PlanMachine>;
  suggestions: Array<string>;
  isFetching: boolean;
  onChange: (items: Array<PlanMachine>) => void;
  onChipRemove: (chip: PlanMachine) => void;
};

const MachinesList = SortableContainer(
  ({ machines, suggestions, isFetching, onChange, onChipRemove }: MachinesListProps) => {
    const [{ popup, edit, machine }, setMachineListState] = React.useReducer(
      (prevState: MachineListReducer, updatedState: Partial<MachineListReducer>) => ({
        ...prevState,
        ...updatedState,
      }),
      {
        popup: false,
        edit: false,
        machine: { id: '', order: 0 },
      },
    );

    function changeMachines(machine: PlanMachine) {
      if (edit) {
        onChange(machines.map(obj => (obj.id === machine.id ? machine : obj)));
        return setMachineListState({
          popup: false,
          edit: false,
        });
      }

      onChange([
        ...machines,
        {
          ...machine,
          id: machine.id.split(' ')[0],
          name: machine.id.split(/[:,]/)[1],
        },
      ]);

      setMachineListState({
        popup: false,
        edit: false,
      });
    }

    function renderChip(chip: PlanMachine, theme: any) {
      const idx = chip.order! - 1;
      return (
        <MachineItem
          machine={chip}
          index={idx}
          chipTheme={theme}
          removeHandle={() => onChipRemove(chip)}
          onEdit={() => {
            setMachineListState({
              popup: true,
              edit: true,
              machine: chip,
            });
          }}
        />
      );
    }

    function hideModal() {
      setMachineListState({ popup: false });
    }

    return (
      <React.Fragment>
        {popup && (
          <MachineModal
            machine={machine}
            isEdit={edit}
            changeMachines={changeMachines}
            hideModal={hideModal}
          />
        )}
        <Chips
          value={machines}
          suggestions={suggestions}
          renderChip={renderChip}
          onChange={(newValue: Array<string | PlanMachine>) => {
            const newMachineId = newValue.find(elem => typeof elem === 'string');
            if (newMachineId && typeof newMachineId === 'string') {
              setMachineListState({
                popup: true,
                machine: {
                  id: newMachineId,
                  order: newValue.length,
                },
              });
            }
          }}
          getChipValue={(chip: PlanMachine) => chip.id}
          placeholder="Start typing a machine id here"
          theme={{
            ...Chips.defaultProps.theme,
            chipsContainer: {
              ...Chips.defaultProps.theme.chipsContainer,
              display: 'block',
              width: '100%',
            },
            suggestionsList: {
              ...Chips.defaultProps.theme.suggestionsList,
              maxHeight: '57vh',
              overflow: 'scroll',
            },
          }}
          chipTheme={{ chip: { display: 'inline-block' } }}
          fromSuggestionsOnly={true}
        />
      </React.Fragment>
    );
  },
);

const sortOrder = (machines: Array<PlanMachine>) => {
  return machines
    .sort((m1, m2) => {
      if (m1.order && !m2.order) {
        return -1;
      }
      if (!m1.order && m2.order) {
        return 1;
      }
      if (!m1.order && !m2.order) {
        return 0;
      }
      return m1.order! < m2.order! ? -1 : m1.order! > m2.order! ? 1 : 0;
    })
    .map((m, idx) => {
      return { ...m, order: idx + 1 };
    });
};

type MachinesMultiSelectProps = {
  onChange: (event: Array<PlanMachine>) => void;
  value: RegisterOptions;
  isFetching: boolean;
  suggestions: Array<string>;
  dispatch: React.Dispatch<any>;
};

function MachinesMultiSelect({
  onChange,
  dispatch,
  isFetching,
  suggestions,
  value,
}: MachinesMultiSelectProps) {
  const [machines, setMachines] = React.useState<Array<PlanMachine>>(
    Array.isArray(value) ? sortOrder(value) : [],
  );
  const { requester } = React.useContext(APIContext);

  React.useEffect(() => {
    fetchMachines({
      dispatch,
      myInit: { limit: pageSize },
      requester,
      operation: getMachines,
      allPages: true,
      prefetchData: true
    });
  }, [dispatch, requester]);

  const onChipRemove = (chip: PlanMachine) => {
    onChangeHandle(machines.filter(machine => machine !== chip));
  };

  const onChangeHandle = (items: Array<PlanMachine>) => {
    const machines = Array.isArray(items) ? sortOrder(items) : [];

    setMachines(machines);
    onChange(machines);
  };

  return (
    <InputLoadingHoc loading={isFetching}>
      <div onKeyDown={preventFromSubmitOnEnter}>
        <MachinesList
          machines={machines}
          suggestions={suggestions}
          isFetching={isFetching}
          onChipRemove={onChipRemove}
          onChange={onChangeHandle}
          useDragHandle={true}
        />
      </div>
    </InputLoadingHoc>
  );
}

// @ts-ignore -- temporary ignore until module is fully typed
const mapStateToProps = state => {
  const { machines = { items: [], isFetching: false, error: null } } = state;

  return {
    error: machines.error,
    isFetching: machines.isFetching,
    suggestions: machines.items
      // @ts-ignore -- temporary ignore until module is fully typed
      ?.map(machine => ({
        ...machine,
        ssm_machine_id: machine.access ? machine.access.ssm_machine_id : null,
      }))
      // @ts-ignore -- temporary ignore until module is fully typed
      .map(({ id, name, ssm_machine_id }) => `${id} (name:${name}, ssm: ${ssm_machine_id})`),
    machines,
  };
};

export default connect(mapStateToProps)(MachinesMultiSelect);

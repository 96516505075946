/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getNotificationGroup = `query NotificationGroup($customer_id: String!, $id: String!) {
    notificationGroup(customer_id: $customer_id, id: $id) { 
      id
      customer_id
      name
      created_at
      updated_at
      events
      levels
      notifications {
        type
        channel
        web_hook_url
        email_config {
          address
          is_external
        }
        routing_key
        override_settings
        events
        levels
      }
    }
  }
  `;

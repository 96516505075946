/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

enum awsRegionsValues {
  usEast1 = 'us-east-1',
  usEast2 = 'us-east-2',
  usWest1 = 'us-west-1',
  usWest2 = 'us-west-2',
  afSouth1 = 'af-south-1',
  caCentral1 = 'ca-central-1',
  euCentral1 = 'eu-central-1',
  euWest1 = 'eu-west-1',
  euWest2 = 'eu-west-2',
  euWest3 = 'eu-west-3',
  euSouth1 = 'eu-south-1',
  euNorth1 = 'eu-north-1',
  apEast1 = 'ap-east-1',
  apNortheast1 = 'ap-northeast-1',
  apNortheast2 = 'ap-northeast-2',
  apNortheast3 = 'ap-northeast-3',
  apSoutheast1 = 'ap-southeast-1',
  apSoutheast2 = 'ap-southeast-2',
  apSoutheast3 = 'ap-southeast-3',
  apSouth1 = 'ap-south-1',
  meSouth1 = 'me-south-1',
  saEast1 = 'sa-east-1',
  cnNorth1 = 'cn-north-1',
  cnNorthwest1 = 'cn-northwest-1',
}

export const awsRegions = Object.values(awsRegionsValues);

export const awsRegionsOptions = [
  { value: awsRegionsValues.usEast1, label: 'US East (N. Virginia)' },
  { value: awsRegionsValues.usEast2, label: 'US East (Ohio)' },
  { value: awsRegionsValues.usWest1, label: 'US West (N. California)' },
  { value: awsRegionsValues.usWest2, label: 'US West (Oregon)' },
  { value: awsRegionsValues.afSouth1, label: 'Africa (Cape Town)' },
  { value: awsRegionsValues.caCentral1, label: 'Canada (Central)' },
  { value: awsRegionsValues.euCentral1, label: 'Europe (Frankfurt)' },
  { value: awsRegionsValues.euWest1, label: 'Europe (Ireland)' },
  { value: awsRegionsValues.euWest2, label: 'Europe (London)' },
  { value: awsRegionsValues.euWest3, label: 'Europe (Paris)' },
  { value: awsRegionsValues.euSouth1, label: 'Europe (Milan)' },
  { value: awsRegionsValues.euNorth1, label: 'Europe (Stockholm)' },
  { value: awsRegionsValues.apEast1, label: 'Asia Pacific (Hong Kong)' },
  { value: awsRegionsValues.apNortheast1, label: 'Asia Pacific (Tokyo)' },
  { value: awsRegionsValues.apNortheast2, label: 'Asia Pacific (Seoul)' },
  { value: awsRegionsValues.apNortheast3, label: 'Asia Pacific (Osaka)' },
  { value: awsRegionsValues.apSoutheast1, label: 'Asia Pacific (Mumbai)' },
  { value: awsRegionsValues.apSoutheast2, label: 'Asia Pacific (Sydney)' },
  { value: awsRegionsValues.apSoutheast3, label: 'Asia Pacific (Jakarta)' },
  { value: awsRegionsValues.apSouth1, label: 'Asia Pacific (Singapore)' },
  { value: awsRegionsValues.meSouth1, label: 'Middle East (Bahrain)' },
  { value: awsRegionsValues.saEast1, label: 'South America (São Paulo)' },
  { value: awsRegionsValues.cnNorth1, label: 'China (Beijing)' },
  { value: awsRegionsValues.cnNorthwest1, label: 'China (Ningxia)' },
] as const;

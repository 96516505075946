/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Event } from '../components/Event';
import ReportButton from '../components/ReportButton';
import { CsvReportButton } from '../components/CsvReportButton';
import { eventApprove, resetErrors, fetchEvents, removeEvent } from '../actions';
import { deleteEvent } from '../../../graphql/deleteEvent';
import { getEvents } from '../../../graphql/getEvents';
import { exportEvents } from '../../../graphql/exportEvents';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  getOwnersList,
  modifyFilter,
  createFilter,
  persistFilterState,
  getPersistedFilterState,
  removePersistedFilterState,
} from '../../../utils/filterUtils';
import { NotificationManager } from 'react-notifications';
import { PATCHING_STATES_MAPPING, DRY_RUN_STATES, pageSize } from '../../../utils';
import { createFilter as createSearchFilter } from 'react-search-input';

import { Link } from 'react-router';

import {
  SearchForm,
  DataTable,
  FilterSelectBox,
  DateSelect,
  CsvExportButton,
} from '../../../commonComponents';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const KEYS_TO_FILTERS = ['name', 'id', 'window_start_time'];
const PATCHING_AVAILABLE_STATES = [
  'NEW',
  'IN_PROGRESS',
  'DONE',
  'DONE_MANUAL',
  'DONE_PARTIAL',
  'NOT_APPROVED',
  'DONE_ERROR',
  'DELETED',
];

class EventsPage extends Component {
  constructor(props) {
    super(props);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRangeSelected = this.handleRangeSelected.bind(this);
    this.getRange = this.getRange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.refreshEvents = this.refreshEvents.bind(this);

    const incomingRange = {};
    if (!this.props.location.query.show_past) {
      incomingRange.startDate = moment();
      incomingRange.endDate = moment().add(7, 'days');
      incomingRange.showIncoming = true;
    }

    this.state = {
      dateFormat: 'dd/MM/yyyy',
      todayButton: 'Today',
      currDate: moment(),
      startDate: incomingRange.startDate || moment().subtract(7, 'days'),
      endDate: incomingRange.endDate || moment(),
      searchTerm: '',
      showIncoming: incomingRange.showIncoming || false,
      eventsPage: 0,
      filters: getPersistedFilterState('eventFilters') || {},
      showModal: false,
      deleteId: '',
      deleteName: '',
      customDate: false,
      viewCount: 0,
    };
  }
  static contextType = APIContext;
  static offset = new Date().getTimezoneOffset();

  componentWillReceiveProps(props) {
    if (!props.location.query.show_past && !this.state.showIncoming) {
      this.setState(
        {
          startDate: moment(),
          endDate: moment().add(7, 'days'),
          showIncoming: true,
        },
        this.handleRangeSelected,
      );
    }
    if (props.location.query.show_past && this.state.showIncoming) {
      const persistedDate = JSON.parse(localStorage.getItem('eventDate'));
      this.setState(
        {
          startDate:
            persistedDate && persistedDate.startDate
              ? moment(persistedDate.startDate)
              : moment().subtract(7, 'days'),
          endDate:
            persistedDate && persistedDate.endDate ? moment(persistedDate.endDate) : moment(),
          showIncoming: false,
          customDate: persistedDate && persistedDate.customDate ? persistedDate.customDate : false,
        },
        this.handleRangeSelected,
      );
    }

    !props.isFetching &&
      props.events &&
      this.state.filters.owner &&
      !getOwnersList(props.events).includes(this.state.filters.owner) &&
      this.setState(
        {
          filters: {},
        },
        removePersistedFilterState('eventFilters'),
      );
  }

  componentDidMount() {
    const persistedDate = JSON.parse(localStorage.getItem('eventDate'));
    persistedDate && this.props.location.query.show_past
      ? this.setState(
          {
            startDate: moment(persistedDate.startDate),
            endDate: moment(persistedDate.endDate),
            customDate: persistedDate.customDate,
          },
          this.getEvents({
            window_start_time_GTE: moment(persistedDate.startDate).format('YYYY-MM-DD'),
            window_end_time_LTE: moment(persistedDate.endDate).format('YYYY-MM-DD'),
          }),
        )
      : this.getEvents(this.getRange());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    resetErrors({ dispatch });
  }

  getEvents(range, paginationToken, allPages) {
    const { requester } = this.context;
    fetchEvents({
      dispatch: this.props.dispatch,
      myInit: {
        start_date: moment(range.window_start_time_GTE).format(),
        end_date: moment(range.window_end_time_LTE).format(),
        limit: pageSize,
        continuation_token: paginationToken,
      },
      requester,
      operation: getEvents,
      allPages,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  handleChange({ startDate, endDate, custom }) {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;
    if (startDate.isAfter(endDate)) {
      endDate = startDate;
    }
    !this.state.showIncoming &&
      localStorage.setItem(
        'eventDate',
        JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          customDate: custom ? false : this.state.customDate,
        }),
      );
    this.setState({ startDate, endDate }, this.handleRangeSelected);
  }

  handleChangeStart(startDate) {
    this.handleChange({ startDate: moment(startDate) });
  }

  handleChangeEnd(endDate) {
    this.handleChange({ endDate: moment(endDate) });
  }

  handleDateChange(startDate, custom) {
    const endDate = moment();
    custom && this.setState({ customDate: false });
    this.handleChange({ startDate, endDate, custom });
  }

  getRange() {
    const { startDate, endDate } = this.state;

    return {
      window_start_time_GTE: moment(startDate).format('YYYY-MM-DD'),
      window_end_time_LTE: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
    };
  }

  handleRangeSelected() {
    this.getEvents(this.getRange());
  }

  refreshEvents() {
    if (this.props.approveError) {
      NotificationManager.error(
        this.props.approveError ? this.props.approveError : 'Something went wrong.',
      );
    } else {
      this.props.approved && NotificationManager.success('Event approved.');
      this.getEvents(this.getRange());
    }
  }

  handleFilterChange(key, val) {
    this.setState(
      { filters: modifyFilter(this.state.filters, key, val) },
      persistFilterState('eventFilters', this.state),
    );
  }

  removeItem(itemId) {
    const { requester } = this.context;
    const { dispatch, customer_id } = this.props;
    const callback = () => {
      NotificationManager.success('Event deleted');
      this.refreshEvents();
    };
    const eventInit = {
      customer_id: customer_id,
      event_id: itemId,
    };

    removeEvent({ dispatch, myInit: eventInit, requester, operation: deleteEvent, callback });
  }

  approveEvent = eventId => {
    const { requester } = this.context;
    const { dispatch } = this.props;
    const myInit = { customer_id: localStorage.getItem('currentCustomer'), event_id: eventId };
    eventApprove(dispatch, myInit, requester, this.refreshEvents);
  };

  updateViewCount = viewCount => this.state.viewCount !== viewCount && this.setState({ viewCount });

  render() {
    const { error, events, paginationToken, isFetching, approveError } = this.props;
    const filteredEvents = events
      .filter(createFilter(this.state.filters))
      .filter(createSearchFilter(this.state.searchTerm, KEYS_TO_FILTERS));
    const allMachines = events.reduce((a, e) => [...a, ...(e.machines || [])], []);
    const allMachinesCount = allMachines.length;
    const allSucceedMachinesCount = allMachines.filter(
      m => m.status && m.status.startsWith('Success'),
    ).length;

    const isPast = window.location.href.includes('show_past=true');

    return (
      <div className={`main wide`}>
        <div className="container">
          <div className="box">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <div className="tabs is-toggle">
                    <ul>
                      <li className={!isPast ? 'is-active' : ''}>
                        <Link to="/events">Upcoming</Link>
                      </li>
                      <li className={isPast ? 'is-active' : ''}>
                        {' '}
                        <Link to="/events?show_past=true">Past</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {isPast && (
                  <div className="level-item">
                    <div className="field">
                      <DateSelect
                        onChange={this.handleDateChange}
                        toggleCustomDate={custom => this.setState({ customDate: custom })}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        custom={this.state.customDate}
                      />
                    </div>
                  </div>
                )}
                {(!isPast || this.state.customDate) && (
                  <React.Fragment>
                    <div className="level-item">
                      <div className="field">
                        <div className="control has-icons-left is-narrow-input">
                          <DatePicker
                            className="input"
                            dateFormat={this.state.dateFormat}
                            todayButton={this.state.todayButton}
                            selected={this.state.startDate.toDate()}
                            selectsStart
                            startDate={this.state.startDate.toDate()}
                            endDate={this.state.endDate.toDate()}
                            onChange={this.handleChangeStart}
                            placeholderText="Select start date ..."
                          />
                          <span className="icon is-small is-left is-search-icon">
                            <i className="fa fa-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="level-item">
                      <div className="field">
                        <div className="control has-icons-left is-narrow-input">
                          <DatePicker
                            className="input"
                            dateFormat={this.state.dateFormat}
                            todayButton={this.state.todayButton}
                            selected={this.state.endDate.toDate()}
                            selectsEnd
                            startDate={this.state.startDate.toDate()}
                            endDate={this.state.endDate.toDate()}
                            onChange={this.handleChangeEnd}
                            minDate={moment().subtract(2, 'years').toDate()}
                            showDisabledMonthNavigation
                            placeholderText="Select end date ..."
                          />
                          <span className="icon is-small is-left is-search-icon">
                            <i className="fa fa-calendar" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>

              <div className="level-right">
                <div className="level-item">
                  <SearchForm searchUpdated={this.searchUpdated} />
                </div>
              </div>
            </nav>
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <FilterSelectBox
                    options={PATCHING_AVAILABLE_STATES}
                    filterKey="patching_status"
                    fieldName="patching status"
                    value={this.state.filters.patching_status}
                    mapping={PATCHING_STATES_MAPPING}
                    onChangeHandler={this.handleFilterChange}
                  />
                  <FilterSelectBox
                    options={DRY_RUN_STATES}
                    filterKey="dry_run"
                    fieldName="dry run"
                    value={this.state.filters.dry_run}
                    onChangeHandler={this.handleFilterChange}
                  />
                  <ReportButton
                    events={filteredEvents.map(el => el.id)}
                    customer={this.state.filters.owner}
                  />
                  <CsvReportButton
                    events={filteredEvents}
                    customer={localStorage.getItem('currentCustomerName')}
                  />
                  <CsvExportButton
                    ids={filteredEvents.map(e => e.id)}
                    query={exportEvents}
                    queryName="exportEvents"
                  />
                </div>
              </div>
              {events.length > 0 && (
                <div className="level-right">
                  <div className="level-item">
                    {isPast &&
                      allMachinesCount !== 0 &&
                      `Total ${allSucceedMachinesCount}/${allMachinesCount} machines patched
                    (${Math.floor((allSucceedMachinesCount / allMachinesCount) * 100)}%)`}
                  </div>
                  <div className="level-item">
                    {`Viewing ${this.state.viewCount}/${events.length}`}
                    {paginationToken && '+'}
                  </div>
                </div>
              )}
            </nav>
          </div>

          {error && (
            <div className="message is-danger">
              <div className="message-body">
                {typeof error === 'string' && <div key="0">{error}</div>}
                {typeof error !== 'string' &&
                  Object.keys(error).map((el, index) => (
                    <div key={index}>
                      {el.replace(/_/g, ' ').toUpperCase()}:{' '}
                      {typeof error[el] === 'string'
                        ? error[el]
                        : Object.keys(error[el][0]).map(element => error[el][0][element])}
                    </div>
                  ))}
              </div>
            </div>
          )}

          {approveError && (
            <div className="message is-danger">
              <div className="message-body">{approveError || 'Unknown error.'}</div>
            </div>
          )}

          <DataTable
            data={filteredEvents}
            pagination={{
              show: !!paginationToken,
              loadNextPage: () => this.getEvents(this.getRange(), paginationToken),
              loadAllPages: () => this.getEvents(this.getRange(), paginationToken, true),
            }}
            isLoading={isFetching}
            headers={[
              { label: 'Name', field: 'name' },
              { label: 'Id' },
              { label: 'Location' },
              { label: 'Start time (Location)', field: 'window_start_time', date: true },
              { label: 'Start time (UTC)', field: 'window_start_time', date: true },
              { label: 'Status', field: 'patching_status' },
              { label: 'Dry run' },
              { label: 'Machines patched' },
              { label: 'Plan' },
              { label: '' },
            ]}
            row={Event}
            rowActions={{ removeItem: this.removeItem, approveEvent: this.approveEvent }}
            updateViewCount={this.updateViewCount}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    events = { items: [], isFetching: false, error: null, eventApproved: false, removed: false },
  } = state;

  return {
    events: events.list.items,
    paginationToken: events.list.paginationToken,
    isFetching: events.list.isFetching,
    error: events.list.error,
    deleteError: events.error,
    approved: events.eventApproved,
    removed: events.removed,
    approveError: events.eventApprove.error,
  };
};

export default connect(mapStateToProps)(EventsPage);

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

type ButtonsContainerProps = {
  children: React.ReactNode;
};

const ButtonsContainer = ({ children }: ButtonsContainerProps) => (
  <div className="field is-horizontal is-form-spacer">
    <div className="field-label is-normal" />
    <div className="field-body">{children}</div>
  </div>
);

export default ButtonsContainer;

/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MachineInput, Provider } from '../../graphql/generated-types';

export enum OsType {
  windows = 'windows',
  linux = 'linux',
  unknown = '',
}

export const osTypesOptions = [
  { value: OsType.linux, label: 'Linux' },
  { value: OsType.windows, label: 'Windows' },
] as const;

export type NewMachineFormState = MachineInput & { os: OsType };

export const providerOptions = [
  { value: Provider.Aws, label: 'AWS' },
  { value: Provider.Azure, label: 'Azure' },
  { value: Provider.Gcp, label: 'GCP' },
  { value: Provider.Ibm, label: 'IBM' },
  { value: Provider.Other, label: 'Other' },
] as const;

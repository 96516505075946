/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FieldWrapper, Input, Label } from '../../index';
import { useFormContext } from 'react-hook-form';
import { extractError } from '../../utils/extractError';
import { KeyValuePair } from '../../../../graphql/generated-types';

type TagConditionPlaceholdersProps = {
  name: string;
  value: KeyValuePair[];
  onChange: (value: KeyValuePair[]) => void;
};

function TagConditionPlaceholders({ value = [], name, onChange }: TagConditionPlaceholdersProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = extractError(name, errors);

  function handleKeyAdded() {
    onChange([...value, { key: '', value: '' }]);
  }
  function handleKeyRemoved(index: number) {
    value.splice(index, 1);
    onChange(value);
  }

  return (
    <FieldWrapper>
      {value.map((item: KeyValuePair, index: number) => (
        <div className="array-item" key={index}>
          <button
            className="button is-icononly is-danger is-input-icon"
            onClick={() => handleKeyRemoved(index)}
          >
            <i className="fa fa-trash is-square"></i>
          </button>
          <div className="form-group field field-object">
            <div className="field is-horizontal">
              <Label required>Key</Label>
              <Input
                name={`${name}.${index}.key`}
                registerProps={{
                  required: true,
                }}
                error={error?.[index]?.key?.message}
              />
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <div className="array-item">
                      <div className="form-group field is-horizontal">
                        <Label required>Value</Label>
                        <Input
                          name={`${name}.${index}.value`}
                          registerProps={{
                            required: true,
                          }}
                          error={error?.[index]?.value?.message}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <button
        className="button array-add is-icononly is-danger is-square"
        type="button"
        onClick={handleKeyAdded}
      >
        <i className="fa fa-plus"></i>
      </button>
    </FieldWrapper>
  );
}

export default TagConditionPlaceholders;

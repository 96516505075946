/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

export const Metadata = ({ data, name }) => {
  return (
    <div className="columns">
      <div className="column">
        <div className="box is-fullheight">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="title is-size-4">Metadata</div>
              </div>
            </div>
          </div>
          <div>
            {!data || data.length <= 0 ? (
              <tr>No Metadata in this {name}!</tr>
            ) : (
              <table className="table  is-transparent is-fullwidth is-striped">
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(p => {
                    return (
                      <tr index={`${p.value}${p.key}`}>
                        <td> {p.key} </td>
                        <td>{p.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

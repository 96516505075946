/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import MachineStatus from '../../machines/components/MachineStatus';
import { Link } from 'react-router';

export const DashboardMachine = ({ item: machine }) => {
  const machineLink = `machines/details/${machine.id}`;
  return (
    <tr className="table-row-link" id={machine.id}>
      <Link className="table-cell" to={machineLink}>
        {machine.name ? machine.name : machine.id}
      </Link>
      <Link className="table-cell" to={machineLink}>
        {machine.last_patching && <MachineStatus machine={machine} />}
      </Link>
    </tr>
  );
};

/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ButtonsContainer,
  FormWrapper,
  InputField,
  CronInput,
  ControlledField,
  MetadataFormInput,
} from '../../../../commonComponents/form2';
import { Pipeline, PipelineInput } from '../../../../graphql/generated-types';
import { PipelineSchema } from './schema';
import { useErrorInfo } from '../../../../commonComponents/form2/hooks/useErrorInfo';
import Terminal from '../../../../commonComponents/terminal/Terminal';
import NotificationGroupsMultiSelect from '../../../../commonComponents/form2/pipeline/NotificationGroupsMultiSelect';
import PipelineStepFormInput from '../../../../commonComponents/form2/PipelineStepFormInput';
import { PipelineFormContextProvider } from './context';
import { TimezoneSelector } from '../../../../commonComponents';

type PipelineFormProps = {
  initialData?: Pipeline;
  onSubmit: (pipeline: Partial<Pipeline>) => void;
  isSubmitting: boolean;
  isDetailsView: boolean;
  errorInfo?: { [key: string]: string } | null;
};

function PipelineForm({
  initialData,
  onSubmit,
  errorInfo,
  isSubmitting,
  isDetailsView,
}: PipelineFormProps) {
  const methods = useForm({
    defaultValues: initialData,
    resolver: zodResolver(PipelineSchema),
  });

  const formData = methods.watch();
  useErrorInfo({ errorInfo, setError: methods.setError });

  function updateFormData(pipelineInput: Partial<Pipeline>) {
    const validationResults = PipelineSchema.safeParse(pipelineInput);

    if (!validationResults.success) {
      return validationResults.error?.issues?.[0]?.message;
    } else {
      for (const [key, value] of Object.entries(pipelineInput)) {
        methods.setValue(key as keyof Partial<PipelineInput>, value);
      }
    }
  }

  return (
    <FormWrapper>
      <PipelineFormContextProvider value={{ isBaselineEnabled: formData.enable_baseline ?? false }}>
        <FormProvider {...methods}>
          <form className="create-new-form" onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField name="name" registerProps={{ required: true }} />
            <ControlledField
              name="cron_window_start"
              label="Cron window start"
              Component={CronInput}
              rules={{ required: true }}
              defaultValue=""
            />
            <ControlledField
              name="time_zone"
              label="Location"
              Component={TimezoneSelector}
              defaultValue=""
            />
            <ControlledField
              name="steps"
              rules={{ required: true }}
              Component={PipelineStepFormInput}
            />
            <ControlledField name="metadata" Component={MetadataFormInput} defaultValue={[]} />
            <InputField name="description" />
            <InputField
              name="enable_baseline"
              label="Enable baseline"
              type="checkbox"
              disabled={formData?.steps?.some(step => step?.plans?.some(plan => plan.dry_run))}
              disabledReason="Baseline patching with dry run plans is not supported"
            />
            <InputField
              name="upcoming_notification_time"
              type="number"
              label="Upcoming Notification Time [h]"
              description="Time in hours to notify before event starts."
              registerProps={{ valueAsNumber: true }}
            />
            <NotificationGroupsMultiSelect />
            <ButtonsContainer>
              <Button htmlType="submit" icon="send" loading={isSubmitting}>
                {isDetailsView ? 'Save' : 'Create'}
              </Button>
              <Terminal formData={formData} updateFormData={updateFormData} title="notification" />
            </ButtonsContainer>
          </form>
        </FormProvider>
      </PipelineFormContextProvider>
    </FormWrapper>
  );
}

export default PipelineForm;

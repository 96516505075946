/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { ControlledField, HookInput, InputField, Select } from '../../../../commonComponents/form2';
import { usePipelineContext } from '../form/context';
import { rebootPolicyOptions } from '../../../../commonComponents/form2/schema/rebootPolicy';

function StepTwo() {
  const { isBaselineEnabled } = usePipelineContext();

  return (
    <>
      <InputField name="description" label="Description" />
      <InputField
        name="dry_run"
        label="Dry run"
        description="Set true to list available updated without updating."
        type="checkbox"
        disabled={isBaselineEnabled}
        disabledReason="Baseline patching with dry run plans is not supported"
      />
      <ControlledField
        name="reboot_policy"
        label="Reboot policy"
        Component={Select}
        elements={rebootPolicyOptions}
        description="This reboot policy will override the one defined on a machine for all machines patched within this plan"
      />
      <InputField
        name="linux_security_only"
        label="Linux security only"
        description="Set true to install only security updates on Linux machines."
        type="checkbox"
      />
      <InputField
        name="windows_update_category"
        label="Windows Update Category"
        description="Default is all, Works for Windows, you can specify coma separated list: CriticalUpdates,DefinitionUpdates,Drivers,FeaturePacks,SecurityUpdates,ServicePacks,UpdateRollups,Updates."
      />
      <InputField
        name="windows_update_severity"
        label="Windows Update Severity"
        description="Default is: Critical,Important,Moderate, Works for Windows, you can specify coma separated list: Critical,Important,Moderate,Low,Unspecified."
      />
      <InputField
        name="parallel"
        label="Parallel"
        description="How many updates in parallel (0-all, 1-in sequence, 2- 2 in parallel etc)."
        type="number"
        registerProps={{ valueAsNumber: true }}
      />
      <ControlledField name="pre_hooks" label="Pre hooks" Component={HookInput} defaultValue={[]} />
      <ControlledField
        name="post_hooks"
        label="Post hooks"
        Component={HookInput}
        defaultValue={[]}
      />
    </>
  );
}

export default StepTwo;

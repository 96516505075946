/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const putPermission = `mutation PutRbacRoleBinding($input: RbacRoleBindingPutInput!) {
  putRbacRoleBinding(input: $input) { 
    id
  }
}
`;

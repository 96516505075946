/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import {
  Modal,
  FormButtonsContainer,
  FormButtonConfirm,
  FormButtonCancel,
} from '../../../commonComponents';

function ApiKeyCreatedModal({ apiKey, onClose }) {
  const [copyText, setCopyText] = useState("Copy API key")

  function handleCopy() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(apiKey);
      NotificationManager.success('API key copied to clipboard');
      setCopyText("API key copied")
    }
  }

  return (
    <Modal visible={true} cancelAction={onClose} title="API key generated">
      <p>
        This is the <b>only</b> time secret access key can be viewed. You cannot recover them later.
      </p>
      <br />
      <pre>{apiKey}</pre>
      <FormButtonsContainer>
        <FormButtonConfirm className="high-order" onClick={handleCopy}>
          {copyText}
        </FormButtonConfirm>
        <FormButtonCancel onClick={onClose}>Close</FormButtonCancel>
      </FormButtonsContainer>
    </Modal>
  );
}

ApiKeyCreatedModal.propTypes = {
  apiKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ApiKeyCreatedModal;

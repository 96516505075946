/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

export default class EventMachineAssignment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  submit = clear => {
    const { machine, action } = this.props;
    this.setState({ loading: true });
    action(machine.id, clear, () => this.setState({ loading: false }));
  };

  render() {
    const { machine, email } = this.props;
    const { assignee } = machine;
    const { loading } = this.state;
    const assignedToMe = email === assignee;

    return (
      <React.Fragment>
        {assignee ? (
          <span className="tag">
            Assigned to: {'  '}
            <strong>{assignee}</strong>
          </span>
        ) : (
          <></>
        )}
        {assignedToMe || (
          <button
            className="button is-success"
            onClick={() => this.submit(false)}
            disabled={loading}
          >
            Assign to me
          </button>
        )}
        {assignee && (
          <button
            className="button is-warning is-small"
            onClick={() => this.submit(true)}
            disabled={loading}
          >
            clear
          </button>
        )}
      </React.Fragment>
    );
  }
}

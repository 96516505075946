/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { FieldError, FieldExtrasWrapper } from '.';
import { forbiddenCharacters } from './validation/string';

const typeToClassName: { [key: string]: string } = {
  text: 'input',
  checkbox: 'checkbox',
};

type InputProps = {
  name: string;
  id?: string;
  registerProps?: RegisterOptions;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  error?: string;
};

function Input({ name, id, registerProps, error, type = 'text', ...props }: InputProps) {
  const { register } = useFormContext();

  const typeClassName = typeToClassName[type] || 'input';

  return (
    <>
      <input
        id={id || name}
        type={type}
        className={typeClassName}
        required={!!registerProps?.required}
        {...register(name, {
          ...registerProps,
          validate: {
            forbiddenCharacters: forbiddenCharacters,
            ...registerProps?.validate,
          },
        })}
        {...props}
      />
      {error && (
        <FieldExtrasWrapper>
          <FieldError>{error}</FieldError>
        </FieldExtrasWrapper>
      )}
    </>
  );
}

export default Input;

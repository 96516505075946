/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { Param } from '../../../../commonComponents/layout/Param';
import { TagCondition } from '../../../../graphql/generated-types';

type TagConditionTableProps = {
  tagCondition: TagCondition;
};

export const TagConditionTable = ({ tagCondition }: TagConditionTableProps) => {
  const { expression, placeholders } = tagCondition;

  return (
    <>
      <Param label="Expression">{expression}</Param>
      {placeholders && placeholders.length > 0 && (
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Placeholder key</th>
              <th>Placeholder value</th>
            </tr>
          </thead>
          <tbody>
            {placeholders?.map(({ key, value }) => (
              <tr key={key}>
                <th>{key}</th>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useFormContext, Controller, RegisterOptions, Noop } from 'react-hook-form';
import {
  Fieldset,
  Label,
  FieldWrapper,
  FieldExtrasWrapper,
  FieldDescription,
  FieldError,
} from './index';
import { capitalize } from './utils/capitalize';
import { extractError, extractErrorMessage } from './utils/extractError';

interface ComponentProps {
  name?: string;
  value: any;
  onChange: (...event: any[]) => void;
  onBlur?: Noop;
}

type OtherComponentProps<T> = Omit<T, keyof ComponentProps>;

type ControlledFieldProps<T> = {
  name: string;
  Component: React.ComponentType<T>;
  rules?: RegisterOptions;
  defaultValue?: any;
  label?: string;
  description?: string;
};

function ControlledField<T extends ComponentProps>({
  name,
  Component,
  rules,
  defaultValue,
  label = capitalize(name),
  description,
  ...componentProps
}: ControlledFieldProps<T> & OtherComponentProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = extractError(name, errors);
  const errorMessage = extractErrorMessage(error, label);
  return (
    <>
      <Fieldset>
        <Label id={name} required={!!rules?.required}>
          {label}
        </Label>
        <FieldWrapper>
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { name, value, onChange, onBlur } }) => {
              const props = { name, value, onChange, onBlur } as T;
              return <Component {...props} {...componentProps} />;
            }}
          />
        </FieldWrapper>
      </Fieldset>
      {(error || description) && (
        <FieldExtrasWrapper>
          {description && <FieldDescription>{description}</FieldDescription>}
          {error && <FieldError>{errorMessage}</FieldError>}
        </FieldExtrasWrapper>
      )}
    </>
  );
}

export default ControlledField;

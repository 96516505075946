/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const putPlan = `mutation PutPlan($input: PlanPutInput!) {
  putPlan(input: $input) { 
    id
  }
}
`;

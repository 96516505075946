/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editNotificationGroup, fetchNotificationGroups } from '../actions';
import { getNotificationGroup } from '../../../graphql/getNotificationGroup';
import { putNotificationGroup } from '../../../graphql/putNotificationGroup';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import {
  FormControl,
  FormButtonCancel,
  FormButtonEdit,
  FormattedDate,
} from '../../../commonComponents';
import JSONPretty from 'react-json-pretty';
import { Errors } from '../../../commonComponents/form/Validation';
import NotificationGroupForm from '../components/form/NotificationGroupForm';
import {
  serializeFormInput,
  trimNullValues,
} from '../../../commonComponents/form2/utils/serializeFormInput';
import { ROUTES } from '../../../constants/routes';
import { Param } from '../../../commonComponents/layout/Param';
import { APIContext } from '../../../commonComponents/auth/APIContext';

class NotificationGroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: 'details',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.formTypeChange = this.formTypeChange.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;
    const { dispatch, notificationGroup, routeParams } = this.props;
    const customer_id = localStorage.getItem('currentCustomer');
    if (!notificationGroup) {
      const notificationGroupInit = {
        customer_id: customer_id,
        id: routeParams.splat,
      };
      fetchNotificationGroups({
        dispatch,
        myInit: notificationGroupInit,
        requester,
        operation: getNotificationGroup,
      });
    }
  }

  formTypeChange() {
    if (this.state.formType === 'details') {
      this.setState({
        formType: 'edit',
      });
    } else {
      this.setState({
        formType: 'details',
      });
    }
  }

  onSubmit(formData) {
    const { requester } = this.context;
    const { dispatch, notificationGroup } = this.props;

    const input = serializeFormInput({
      id: notificationGroup.id,
      customer_id: localStorage.getItem('currentCustomer'),
      ...formData,
    });

    const callback = () => {
      NotificationManager.success(
        'Successfully updated notification group [' + notificationGroup.id + '] details',
      );
      browserHistory.push(`${ROUTES.notificationGroups.notificationGroups}?refresh=true`);
    };

    editNotificationGroup({
      dispatch,
      myInit: { input },
      requester,
      operation: putNotificationGroup,
      callback,
    });
  }

  getFormData(notificationGroup) {
    const { id, created_at, updated_at, ...formData } = notificationGroup;
    return formData;
  }

  render() {
    const { error, isFetching } = this.props;
    const notificationGroup = this?.props?.notificationGroup
      ? trimNullValues(this?.props?.notificationGroup)
      : {};
    const { formType } = this.state;
    const formData = notificationGroup ? this.getFormData(notificationGroup) : {};
    const errors = { errors: error };

    return (
      <div className="main wide">
        {!notificationGroup ? null : (
          <React.Fragment>
            <div className="box">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title">Notification Group Details</h1>
                  <h2 className="subtitle is-size-5">
                    {notificationGroup.name ? notificationGroup.name : notificationGroup.id}
                  </h2>
                </div>
                <Param label="Created At">
                  <FormattedDate>{notificationGroup.created_at}</FormattedDate>
                </Param>
                <Param label="Updated At">
                  <FormattedDate>{notificationGroup.updated_at}</FormattedDate>
                </Param>
                <Param label="Customer">{notificationGroup.owner}</Param>
                {formType === 'details' ? (
                  <FormButtonEdit className="pull-right" onClick={this.formTypeChange}>
                    Edit
                  </FormButtonEdit>
                ) : (
                  <FormButtonCancel className="pull-right" onClick={this.formTypeChange}>
                    Cancel
                  </FormButtonCancel>
                )}
              </div>
            </div>

            {errors.errors && errors.errors.length > 0 && Errors(errors)}

            {formType === 'edit' ? (
              <NotificationGroupForm
                initialData={formData ? formData : undefined}
                onSubmit={this.onSubmit}
                isSubmitting={isFetching}
                errorInfo={error}
              />
            ) : (
              <React.Fragment>
                <div className="box">
                  <div className="columns">
                    <Param label="Name" value={notificationGroup.name} />
                    <Param label="Level" value={notificationGroup.level} />
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Notifications</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!notificationGroup.notifications ||
                        notificationGroup.notifications.length <= 0 ? (
                          <FormControl
                            type="text"
                            readOnly={true}
                            value="No Notifications added to this group."
                          />
                        ) : (
                          <JSONPretty id="json-pretty" data={notificationGroup.notifications} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Events</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!notificationGroup.events || notificationGroup.events <= 0 ? (
                          <FormControl
                            type="text"
                            readOnly={true}
                            value="No Events specified for this group."
                          />
                        ) : (
                          <JSONPretty id="json-pretty" data={notificationGroup.events} />
                        )}
                      </div>

                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Levels</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!notificationGroup.levels || notificationGroup.levels <= 0 ? (
                          <FormControl
                            type="text"
                            readOnly={true}
                            value="No Severity Levels specified for this group."
                          />
                        ) : (
                          <JSONPretty id="json-pretty" data={notificationGroup.levels} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

NotificationGroupDetails.propTypes = {
  notificationGroups: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  isFetching: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { notificationGroups } = state;
  const notificationGroupId = ownProps.routeParams.splat || null;

  return {
    notificationGroup: notificationGroups.items.find(
      notificationGroup => notificationGroup.id === notificationGroupId,
    ),
    error: notificationGroups.error,
    isFetching: notificationGroups.isFetching,
  };
};

export default connect(mapStateToProps)(NotificationGroupDetails);

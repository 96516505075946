/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Modal, FormButtonsContainer, FormButtonConfirm } from './Form';
import {
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import Form from 'react-jsonschema-form';
import { stripUndefinedValues } from '../../utils';

const widgets = {
  TextWidget: CustomTextWidget,
};

const schema = {
  type: 'object',
  required: ['key', 'value'],
  properties: {
    key: { type: 'string', title: 'Key' },
    value: { type: 'string', title: 'Value' },
  },
};

class MetadataModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData ? props.formData : {},
    };
  }
  componentDidMount() {
    this.state.formData &&
      this.setState({
        formData: this.props.formData,
      });
  }

  handleChange = data => {
    this.setState({ formData: data.formData });
  };
  add = () => this.props.addRule(stripUndefinedValues(this.state.formData));

  render() {
    const { formData } = this.state;
    return (
      <Modal visible={true} cancelAction={this.props.hideModal} title="Metadata">
        <Form
          className=""
          schema={schema}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
          ArrayFieldTemplate={ArrayFieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          onChange={this.handleChange}
          formData={formData}
          widgets={widgets}
        >
          <FormButtonsContainer>
            {formData &&
              (formData.key &&
                formData.key.length !== 0 &&
                formData.value &&
                formData.value.length !== 0) && (
                <FormButtonConfirm onClick={this.add}>Add</FormButtonConfirm>
              )}
          </FormButtonsContainer>
        </Form>
      </Modal>
    );
  }
}

export { MetadataModal };

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

type FormWrapperProps = {
  children: React.ReactNode;
};

const FormWrapper = ({ children }: FormWrapperProps) => (
  <div className="container">
    <div className="box mainBox wide">{children}</div>
  </div>
);

export default FormWrapper;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createActivation = `mutation CreateActivation($input: ActivationInput!) {
    createActivation(input: $input) { 
      activation_id
      activation_code
      region
    }
  }
  `;

/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import { HookInputSchema } from '../../../../commonComponents/form2/schema/hookInput';
import { MetadataSchema } from '../../../../commonComponents/form2/schema/metadata';
import { safeStr, stringRequired } from '../../../../commonComponents/form2/validation/string';
import { EventPolicy } from '../../../../graphql/generated-types';
import { isValidCron } from '../../../../utils/validators/isValidCron';
import {
  DynamicPlanInputSchema,
  PlanMachineInputSchema,
} from '../../../plans/components/form/schema';
import { rebootPolicySchemaValues } from '../../../../commonComponents/form2/schema/rebootPolicy';

export const PipelinePlanSchema = z.object({
  // 1st step
  name: stringRequired('Name'),
  metadata: MetadataSchema.optional(),
  window_length: z
    .number()
    .lte(1440, { message: '1440m is maximum' })
    .gte(30, { message: '30m is minimum' }),
  s3_custom_script: safeStr.optional(),
  machines: PlanMachineInputSchema.array().optional(),
  machines_tag: DynamicPlanInputSchema.optional(),
  // 2nd step
  description: safeStr.optional(),
  dry_run: z.boolean().optional(),
  reboot_policy: z.enum(rebootPolicySchemaValues).optional(),
  linux_security_only: z.boolean().optional(),
  windows_update_category: safeStr.optional(),
  windows_update_severity: safeStr.optional(),
  parallel: z.preprocess(
    value => (value === null || Number.isNaN(value) ? undefined : value),
    z
      .number()
      .int({ message: 'Parallel must be an integer' })
      .nonnegative({ message: 'Parallel must be positive' })
      .optional(),
  ),
  pre_hooks: HookInputSchema.array().optional(),
  post_hooks: HookInputSchema.array().optional(),
});

export const PipelineStepSchema = z.object({
  description: safeStr.optional(),
  stage: safeStr.optional(),
  run_immediately: z.boolean().optional(),
  step_delay: z.number().optional(),
  policy: z
    .enum([
      EventPolicy.SuccessOrApproval,
      EventPolicy.ApprovalRequired,
      EventPolicy.PatchAnyway,
      '',
    ])
    .optional(),
  plans: PipelinePlanSchema.array(),
});

export const PipelineSchema = z
  .object({
    name: stringRequired('Name'),
    description: safeStr.optional(),
    cron_window_start: stringRequired('Cron window start').refine(
      value => value && isValidCron(value),
      {
        message: 'Cron window start is not a valid cron expression',
      },
    ),
    time_zone: safeStr.optional(),
    steps: PipelineStepSchema.array(),
    metadata: MetadataSchema.optional(),
    enable_baseline: z.boolean().optional(),
    upcoming_notification_time: z.preprocess(
      value => (value === null || Number.isNaN(value) ? undefined : value),
      z
        .number()
        .int({ message: 'Upcoming notification time must be an integer' })
        .nonnegative({ message: 'Upcoming notification time must must be positive' })
        .optional(),
    ),
    notification_groups: safeStr.array().optional(),
  })
  .refine(
    ({ enable_baseline, steps }) =>
      !(steps?.some(step => step?.plans?.some(plan => plan.dry_run)) && enable_baseline),
    { message: 'Baseline patching with dry run plans is not supported' },
  );

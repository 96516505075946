/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListReducerFactory } from '../../factories';
import { actions } from './actions';

const roles = ListReducerFactory(actions);

export { roles };

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';

const { fetchData, removeItem, editItem, resetErrors, actions } = ListActionsFactory({
  namespace: 'PLANS',
  api: 'aws',
  path: '/plans',
});

export {
  fetchData as fetchPlans,
  removeItem as removePlan,
  editItem as editPlan,
  resetErrors,
  actions,
};

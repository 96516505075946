/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const rolesSchema = {
  type: 'object',
  required: ['name', 'rules'],
  properties: {
    name: { type: 'string', title: 'Name' },
    description: { type: 'string', title: 'Description' },
    rules: { type: 'array', title: 'Rules' },
  },
};

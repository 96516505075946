/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Select } from './Select';
import { Modal, FormButtonsContainer, FormButtonConfirm } from './Form';
import {
  CustomCheckboxWidget,
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import Form from 'react-jsonschema-form';
import { stripUndefinedValues } from '../../utils';

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  SelectWidget: Select,
};

const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
      title: 'Id',
    },
    order: {
      type: 'number',
      title: 'Order',
    },
    exclude: {
      type: 'boolean',
      title: `Exclude from patching`,
      value: false,
    },
    pre_host_hooks: {
      type: 'array',
      title: 'Pre Host Hooks',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            title: 'Type',
            anyOf: [
              { type: 'string', enum: ['aws'], title: 'AWS Lambda ARN' },
              { type: 'string', enum: ['azure'], title: 'Azure Functions URL' },
              { type: 'string', enum: ['script'], title: 'Script' },
            ],
          },
          source: {
            type: 'string',
            title: 'Value',
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: { enum: ['aws'] },
                  source: {
                    type: 'string',
                    title: 'Value',
                  },
                },
              },
              {
                properties: {
                  type: { enum: ['azure'] },
                  source: {
                    type: 'string',
                    title: 'Value',
                  },
                  method: {
                    type: 'string',
                    title: 'Method',
                    anyOf: [{ type: 'string', enum: ['GET'] }, { type: 'string', enum: ['POST'] }],
                  },
                },
                required: ['method'],
              },
            ],
          },
        },
      },
    },
    post_host_hooks: {
      type: 'array',
      title: 'Post Host Hooks',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            title: 'Type',
            anyOf: [
              { type: 'string', enum: ['aws'], title: 'AWS Lambda ARN' },
              { type: 'string', enum: ['azure'], title: 'Azure Functions URL' },
              { type: 'string', enum: ['script'], title: 'Script' },
            ],
          },
          source: {
            type: 'string',
            title: 'Value',
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: { enum: ['aws'] },
                  source: {
                    type: 'string',
                    title: 'Value',
                  },
                },
              },
              {
                properties: {
                  type: { enum: ['azure'] },
                  source: {
                    type: 'string',
                    title: 'Value',
                  },
                  method: {
                    type: 'string',
                    title: 'Method',
                    anyOf: [{ type: 'string', enum: ['GET'] }, { type: 'string', enum: ['POST'] }],
                  },
                },
                required: ['method'],
              },
            ],
          },
        },
      },
    },
    s3_custom_script: {
      type: 'string',
      title: 'Custom Update Command (S3 URL)',
    },
  },
};

class MachineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMachine: props.newMachine,
    };
  }

  handleChange = data => {
    this.setState({ newMachine: data.formData });
  };

  add = () => {
    const { machines, oldIdx, addMachine } = this.props;
    const { newMachine } = this.state;
    typeof oldIdx === 'number' ? machines.splice(oldIdx, 1) : machines.pop();

    const newIdx = newMachine.order > 0 ? newMachine.order - 1 : 0;
    newIdx < machines.length
      ? machines.splice(newIdx, 0, stripUndefinedValues(newMachine))
      : machines.push(stripUndefinedValues(newMachine));

    addMachine(machines);
  };

  render() {
    const { newMachine } = this.state;

    return (
      <Modal visible={true} cancelAction={this.props.hideModal} title="Machine" isWide>
        <Form
          className=""
          schema={schema}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
          ArrayFieldTemplate={ArrayFieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          onChange={this.handleChange}
          formData={newMachine}
          widgets={widgets}
        >
          <FormButtonsContainer>
            <FormButtonConfirm onClick={this.add}>Add</FormButtonConfirm>
          </FormButtonsContainer>
        </Form>
      </Modal>
    );
  }
}

export { MachineModal };

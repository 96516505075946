/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import Chips from 'react-chips';
import { awsRegions } from '../../constants/regions';

class RegionsInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      list: this.props.formData || awsRegions,
    };
  }

  componentDidMount() {
    !this.props.formData && this.props.onChange(awsRegions);
  }

  onChange(chips) {
    this.setState({
      list: chips,
    });
    this.props.onChange(chips);
  }

  render() {
    return (
      <Chips
        value={this.state.list}
        suggestions={awsRegions}
        onChange={this.onChange}
        placeholder="Select regions"
        fromSuggestionsOnly={true}
      />
    );
  }
}

export { RegionsInput };

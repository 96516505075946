/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';

type FieldDescriptionProps = {
  children: React.ReactNode;
};

const FieldDescription = ({ children }: FieldDescriptionProps) => (
  <p className="help">{children}</p>
);

export default FieldDescription;

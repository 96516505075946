/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import DateInput from './DateInput';
import { Select } from './Select';
import { Modal, FormButtonsContainer, FormButtonConfirm } from './Form';
import RuleResourcesInput from './RuleResourcesInput';
import {
  CustomCheckboxWidget,
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import Form from 'react-jsonschema-form';
import RuleActionsInput from './RuleActionInput';
import { ResourcesIdFormInput } from './ResourcesIdFormInput';
import { stripUndefinedValues } from '../../utils';

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  DateWidget: DateInput,
  SelectWidget: Select,
};

const schema = {
  type: 'object',
  required: ['resources', 'actions'],
  properties: {
    resources: { type: 'array', title: 'Resources' },
    actions: { type: 'array', title: 'Actions' },
    resource_ids: { type: 'array', title: 'Resource Ids' },
  },
};

const flatResourceIds = obj => {
  const flatten =
    obj.resource_ids && obj.resource_ids.map(el => el.resource_ids).filter(v => v !== '');
  return { ...obj, resource_ids: flatten && flatten.length !== 0 ? flatten : null };
};

const uiSchema = {
  resources: {
    'ui:field': 'resources',
    classNames: 'is-horizontal',
  },
  actions: {
    'ui:field': 'actions',
    classNames: 'is-horizontal',
  },
  resource_ids: {
    'ui:field': 'resource_ids',
    classNames: 'is-horizontal',
  },
};

const fields = {
  resources: RuleResourcesInput,
  actions: RuleActionsInput,
  resource_ids: ResourcesIdFormInput,
};
const addIds = formData => {
  try {
    const addIds = formData.resource_ids.map(el => {
      return {
        resource_ids: el,
      };
    });

    return { ...formData, resource_ids: addIds };
  } catch (e) {}
};
class RuleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData ? props.formData : {},
    };
  }
  componentDidMount() {
    this.state.formData &&
      this.setState({
        formData: addIds(this.props.formData),
      });
  }

  handleChange = data => {
    this.setState({ formData: data.formData });
  };
  add = () => this.props.addRule(stripUndefinedValues(flatResourceIds(this.state.formData)));

  render() {
    const { formData } = this.state;
    return (
      <Modal visible={true} cancelAction={this.props.hideModal} title="Rule">
        <Form
          className=""
          uiSchema={uiSchema}
          schema={schema}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
          ArrayFieldTemplate={ArrayFieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          fields={fields}
          onChange={this.handleChange}
          formData={formData}
          widgets={widgets}
        >
          <FormButtonsContainer>
            {formData &&
              (formData.resources &&
                formData.resources.length !== 0 &&
                formData.actions &&
                formData.actions.length !== 0) && (
                <FormButtonConfirm onClick={this.add}>Add</FormButtonConfirm>
              )}
          </FormButtonsContainer>
        </Form>
      </Modal>
    );
  }
}

export { RuleModal };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { MACHINE_STATES_MAPPING } from '../../../utils';
import { Link } from 'react-router';

const MachineStatus = ({ machine, on }) => {
  const { last_patching = {}, plans = [], pipelines = [] } = machine;
  const handled = (plans && plans.length > 0) || (pipelines && pipelines.length > 0);
  const { status: lastPatchingStatus = 'Missing_information' } = last_patching;
  const { name: status, className: statusClassName } = handled
    ? MACHINE_STATES_MAPPING[lastPatchingStatus]
      ? MACHINE_STATES_MAPPING[lastPatchingStatus]
      : { name: lastPatchingStatus.toUpperCase(), className: 'is-warning' }
    : { name: 'PLAN MISSING', className: 'is-danger' };

  return last_patching && last_patching.event_id ? (
    <Link
      to={`/events/details/${last_patching.event_id}`}
      className={`tag ${statusClassName} has-cursor-pointer`}
    >
      {status.toUpperCase()}
    </Link>
  ) : (
    <div className={`tag ${statusClassName}`}>{status.toUpperCase()}</div>
  );
};

export default MachineStatus;

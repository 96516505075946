/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

const FilterSelectBox = ({ options, mapping, onChangeHandler, fieldName, filterKey, value }) => {
  const change = event => {
    onChangeHandler(filterKey, event.target.value);
  };

  const emptyValue = (
    <option value="" key={-1}>
      Select {fieldName.toLowerCase()}
    </option>
  );

  const singleValue = (value, index, label) => (
    <option value={value} key={index}>
      {label}
    </option>
  );

  const buildOptions = () => [
    emptyValue,
    ...options.map((option, index) =>
      singleValue(option, index, mapping && mapping[option] ? mapping[option].name : option),
    ),
  ];

  return (
    <div className="select">
      <select onChange={change} defaultValue={value}>
        {buildOptions()}
      </select>
    </div>
  );
};

export { FilterSelectBox };

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ButtonsContainer,
  ControlledField,
  Select,
  TextareaField,
} from '../../../commonComponents/form2';
import { LoadingHOC, Modal, Terminal } from '../../../commonComponents';
import { ManualStatusType } from '../../../graphql/generated-types';

export const ManualStatus = [
  { value: ManualStatusType.SuccessManual, label: 'Success manual' },
  { value: ManualStatusType.FailedManual, label: 'Failed manual' },
  { value: ManualStatusType.InProgressManual, label: 'In progress manual' },
  { value: ManualStatusType.RebootingManual, label: 'Rebooting manual' },
] as const;

type AnnotateEvent = {
  action_taken?: string;
  manual_status?: string;
};

function EventMachineManual({ eventName, eventId, machine, action }: any) {
  const [loading, setLoading] = React.useState(false);
  const [isModalShown, setIsModalShown] = React.useState(false);

  const methods = useForm<any>({
    defaultValues: {
      action_taken: '',
      manual_status: '',
    },
  });
  const watcher = methods.watch();

  function updateFormData(annotateEventInput: Partial<AnnotateEvent>) {
    for (const [key, value] of Object.entries(annotateEventInput)) {
      methods.setValue(key as keyof Partial<AnnotateEvent>, value);
    }
  }

  const hideModal = () => setIsModalShown(false);
  const showModal = () => setIsModalShown(true);

  const disabledButton = !watcher?.action_taken && !watcher?.manual_status;

  const actionTakenRequired =
    watcher?.manual_status === ManualStatusType.SuccessManual ||
    watcher?.manual_status === ManualStatusType.FailedManual;

  const statusChangeCallback = () => {
    setLoading(false);
    methods.reset({});
  };

  const submit = (formData: AnnotateEvent) => {
    setLoading(true);
    setIsModalShown(false);

    const prepareData = {
      action_taken: formData?.action_taken === '' ? undefined : formData.action_taken,
      manual_status: formData?.manual_status === '' ? undefined : formData.manual_status,
    };
    action(eventId, machine.id, prepareData, statusChangeCallback);
  };
  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();

    return methods.handleSubmit(async () => {
      submit(watcher);
    })(event);
  }

  return (
    <>
      <button onClick={showModal} className="button is-success">
        <LoadingHOC loading={loading}>
          <>Annotate</>
        </LoadingHOC>
      </button>
      <Modal visible={isModalShown} cancelAction={hideModal} title="Annotate">
        <p className="mb-4">
          You are about to change machine status or add comment about manual actions taken in the
          {machine.name} ({machine.id}) for event {eventName} ({eventId})
        </p>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="form-group field field-object">
              <TextareaField
                name="action_taken"
                label="Action taken"
                registerProps={{ required: actionTakenRequired }}
              />
              <ControlledField
                name="manual_status"
                label="Manual status"
                Component={Select}
                elements={ManualStatus}
              />
            </div>
            <ButtonsContainer>
              <Button type="secondary" htmlType="submit" icon="send" disabled={disabledButton}>
                Save
              </Button>
              <Terminal formData={watcher} updateFormData={updateFormData} title="plans" />
            </ButtonsContainer>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}
export { EventMachineManual };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const putRole = `mutation PutRbacRole($input: RbacRolePutInput!) {
  putRbacRole(input: $input) { 
    id
  }
}
`;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { readableCron } from '../../utils/readableCron';
import { CRON_PRESETS } from '../../utils/constants';

const { minutesSelect, hoursSelect, monthsSelect, hours, days, months, weekdays } = CRON_PRESETS;

const generateButtons = (data, label) => ({ onChange, active }) => (
  <div className="tabs is-toggle is-toggle-rounded">
    <ul>
      {data.map((option, index) => (
        <li className={`${option.value === active ? 'is-active' : ''}`} key={index}>
          <a href={`#${option.value}`} onClick={onChange(option.value)}>
            {option.label}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const generateSelect = (data, label) => ({ onChange, value, part }) => {
  return (
    <div className="field is-horizontal">
      <div className="cron-select-label">
        <label htmlFor={label}>{label}</label>
      </div>
      <div className="field-body">
        <div className="control">
          <div className="select is-fullwidth">
            <select
              id={label}
              onChange={event => onChange(event.target.value, part)}
              defaultValue={value}
            >
              {data.map((option, index) => (
                <option value={option.value || option} key={index}>
                  {option.label || option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MinutesSelect = generateSelect(minutesSelect, 'Minutes');
export const HoursSelect = generateSelect(hoursSelect, 'Hours');
export const DaysSelect = generateSelect(Array.from(Array(32).keys()), 'Days');
export const MonthsSelect = generateSelect(monthsSelect, 'Months');
export const Hours = generateButtons(hours, 'Hours');
export const Days = generateButtons(days, 'Days');
export const Months = generateButtons(months, 'Months');
export const Weekdays = generateButtons(weekdays, 'Weekdays');

class CronGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value.split(/\s+/) : ['0', '0', '*', '*', '*'],
    };
  }

  setSelect = (val, part) => {
    const { value } = this.state;
    const updateValue = [...value];
    updateValue[part] = val;
    this.setState({ value: updateValue });
  };

  setValue = part => val => e => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = this.state;
    const updateValue = [...value];
    updateValue[part] = val;
    this.setState({ value: updateValue });
  };
  commit = e => {
    e.preventDefault();
    const { value } = this.state;
    const { onChange } = this.props;
    onChange(value.join(' '));
  };
  render() {
    const { value } = this.state;
    return (
      <>
        <div className="cron-select tabs">
          <MinutesSelect onChange={this.setSelect} value={value[0]} part={0} />
          <HoursSelect onChange={this.setSelect} value={value[1]} part={1} />
          <DaysSelect onChange={this.setSelect} value={value[2]} part={2} />
          <MonthsSelect onChange={this.setSelect} value={value[3]} part={3} />
        </div>
        <Hours onChange={this.setValue(1)} active={value[1]} />
        <Days onChange={this.setValue(2)} active={value[2]} />
        <Months onChange={this.setValue(3)} active={value[3]} />
        <Weekdays onChange={this.setValue(4)} active={value[4]} />
        <hr />
        {readableCron(value.join(' '))}
        <hr />
        <button className="button is-small is-action is-primary has-besel " onClick={this.commit}>
          <span>
            <i className="material-icons">send</i>
          </span>
          Confirm
        </button>
      </>
    );
  }
}

export { CronGenerator };

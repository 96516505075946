/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

const Loading = () => <div className="loader element is-loading" />;

type LoadingHOCProps = {
  loading: boolean;
  children: React.ReactElement;
};

const LoadingHOC = ({ loading, children }: LoadingHOCProps) =>
  loading ? <div className="loader element is-loading" /> : children;

const InputLoadingHoc = ({ loading, children }: LoadingHOCProps) => (
  <div className="input-loader-wrapper">
    {children}
    {loading && <span data-testid="spinner" className="input-loader element is-loading" />}
  </div>
);

export { Loading, LoadingHOC, InputLoadingHoc };

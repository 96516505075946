/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from '../index';
import ButtonsContainer from './ButtonsContainer';
import Button from './Button';
import InputField from './InputField';

export type Metadata = {
  key?: string;
  value?: string;
};

type MetadataModalProps = {
  saveMetadata: (metadata: Metadata) => void;
  hideModal: () => void;
  metadata?: Metadata;
};

function MetadataModal({ saveMetadata, hideModal, metadata }: MetadataModalProps) {
  const methods = useForm<Metadata>({ defaultValues: metadata });
  const { key, value } = methods.watch();

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();

    return methods.handleSubmit(async values => {
      saveMetadata(values);
    })(event);
  }

  return (
    <Modal visible={true} cancelAction={hideModal} title="Metadata">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className="form-group field field-object">
            <InputField name="key" registerProps={{ required: true }} />
            <InputField name="value" registerProps={{ required: true }} />
          </div>
          <ButtonsContainer>
            <Button type="secondary" htmlType="submit" icon="send" disabled={!key || !value}>
              Save
            </Button>
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default MetadataModal;

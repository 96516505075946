/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { FormattedDate, TZFormattedDate } from '../../../commonComponents';
import EventStatus from './EventStatus';
import { browserHistory, Link } from 'react-router';
import { ButtonRemove } from '../../../commonComponents';

const Event = ({ item: event, actions }) => {
  const { removeItem, approveEvent } = actions;
  const href = `events/details/${event.id}`;

  const allMachines = event.machines ? event.machines.length : 0;
  const succeedMachines =
    allMachines > 0
      ? event.machines.filter(m => m.status && m.status.startsWith(`Success`)).length
      : 0;
  return (
    <tr className="table-row-link">
      <Link to={href} className="table-cell">
        {event.name}
      </Link>
      <Link to={href} className="table-cell is-narrow-td">
        {event.id}
      </Link>
      <Link to={href} className="table-cell">
        <p>{event.time_zone}</p>
      </Link>
      <Link to={href} className="table-cell">
        <TZFormattedDate timezone={event.time_zone}>{event.window_start_time}</TZFormattedDate>
      </Link>
      <Link to={href} className="table-cell">
        <FormattedDate>{event.window_start_time}</FormattedDate>
      </Link>
      <Link to={href} className="table-cell">
        <EventStatus event={event} />
      </Link>
      <Link to={href} className="table-cell">
        {event.dry_run ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
      </Link>
      <Link to={href} className="table-cell">
        {succeedMachines} / {allMachines}
      </Link>
      <td>
        {event.pipeline_id ? (
          <button
            className="button is-success is-small is-small-table-button"
            onClick={e => {
              e.stopPropagation();
              browserHistory.push(`pipelines/details/${event.pipeline_id}`);
            }}
            title={event.plan_id}
          >
            Pipeline: {event.pipeline_id}
          </button>
        ) : (
          <button
            className="button is-success is-small is-small-table-button"
            onClick={e => {
              e.stopPropagation();
              browserHistory.push(`plans/details/${event.plan_id}`);
            }}
            title={event.plan_id}
          >
            {event.plan_id}
          </button>
        )}
      </td>
      <td className="is-nowrap">
        <ButtonRemove
          title="an event named"
          itemId={event.id}
          itemName={event.name || event.id}
          removeAction={e => {
            e && e.stopPropagation && e.stopPropagation();
            removeItem(event.id);
          }}
        />
        {event.patching_status === 'NOT_APPROVED' && !event.pipeline_id && (
          <button
            title="Event approve"
            onClick={e => {
              e.stopPropagation();
              approveEvent(event.id);
            }}
            className="button is-icononly is-success"
          >
            <i className="fa fa-play-circle" />
          </button>
        )}
      </td>
    </tr>
  );
};

export { Event };

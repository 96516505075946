/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import {
  ControlledField,
  InputField,
  MetadataFormInput,
  Select,
} from '../../../../commonComponents/form2';
import { awsRegionsOptions } from '../../../../constants/regions';

function StepOne() {
  return (
    <>
      <InputField name="name" registerProps={{ required: true }} />
      <ControlledField name="metadata" Component={MetadataFormInput} defaultValue={[]} />
      <InputField
        name="access.ssm_machine_id"
        label="SSM instance ID"
        registerProps={{ required: true }}
      />
      <InputField name="access.iam_role" label="IAM role" registerProps={{ required: true }} />
      <ControlledField
        name="access.region"
        label="Instance region"
        Component={Select}
        elements={awsRegionsOptions}
      />
    </>
  );
}

export default StepOne;

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  ControlledField,
  InputField,
  MultiSelect,
  MachinesMultiSelect,
  Select,
  UncontrolledField,
} from '../../../../commonComponents/form2';
import { fetchNotificationGroups } from '../../../notificationGroups/actions';
import { getNotificationGroups } from '../../../../graphql/getNotificationGroups';
import { pageSize } from '../../../../utils';
import { MachineTagFormInput } from '../../../../commonComponents/form2/plan';
import { Element } from '../../../../commonComponents/form2/MultiSelect';
import { NotificationGroup } from '../../../../graphql/generated-types';
import { APIContext } from '../../../../commonComponents/auth/APIContext';

const options = [
  { value: 'List', label: 'List' },
  { value: 'Dynamic', label: 'Dynamic' },
];

type StepTwoProps = {
  dispatch: any;
  notificationGroups: Array<Element>;
  isFetching: boolean;
};

function StepTwo({ dispatch, notificationGroups, isFetching }: StepTwoProps) {
  const { requester } = React.useContext(APIContext);
  const { watch, setValue, reset } = useFormContext();
  const watcher = watch();

  React.useEffect(() => {
    fetchNotificationGroups({
      dispatch: dispatch,
      allPages: true,
      myInit: { limit: pageSize },
      requester,
      operation: getNotificationGroups,
    });
  }, [dispatch, requester]);

  const [option, setOption] = React.useState('');

  React.useEffect(() => {
    if (watcher?.machines_tag) {
      setOption(options[1].value);
    } else if (watcher?.machines?.length > 0) {
      setOption(options[0].value);
    }
  }, [option, setValue, watcher?.machines?.length, watcher?.machines_tag]);

  function handleOptionChanged(value: string) {
    const { machines, machines_tag, ...data } = watcher;
    reset(data);
    setOption(value);
  }

  function resetMachineTagsData() {
    const { machines, machines_tag, ...data } = watcher;
    reset(data);
  }

  return (
    <>
      <ControlledField
        name="notification_groups"
        label="Notification Groups"
        Component={MultiSelect}
        defaultValue={[]}
        elements={notificationGroups}
        placeholder="Start typing a notification group id here"
        isLoading={isFetching}
      />
      <UncontrolledField name="machine_adding_method" label="Machines adding method">
        <Select
          name="machine_adding_method"
          value={option}
          onChange={handleOptionChanged}
          elements={options}
        />
      </UncontrolledField>
      <InputField name="s3_custom_script" label="Custom Update Command (S3 URL)" />
      {option === options[0].value && (
        <ControlledField name="machines" Component={MachinesMultiSelect} label="Machines" />
      )}
      {option === options[1].value && (
        <ControlledField
          name="machines_tag"
          Component={MachineTagFormInput}
          label="Machines Tag"
          onMachineTagRemoved={resetMachineTagsData}
        />
      )}
    </>
  );
}

// @ts-ignore
const mapStateToProps = state => {
  const { notificationGroups = { items: [], isFetching: false } } = state;

  return {
    notificationGroups: (notificationGroups.items as Array<NotificationGroup>).map(
      ({ id, name }) => ({
        label: `${id} (name:${name})`,
        value: id,
      }),
    ),
    isFetching: notificationGroups.isFetching,
  };
};

export default connect(mapStateToProps)(StepTwo);

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
// @ts-ignore -- temporary ignore until module is fully typed
import { Terminal } from '../../../../commonComponents';
import { Button, ButtonsContainer, FormWrapper } from '../../../../commonComponents/form2';
import { NewOtherMachineFormSchema } from './schema';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { MachineInput } from '../../../../graphql/generated-types';
import { useErrorInfo } from '../../../../commonComponents/form2/hooks/useErrorInfo';
import { NewMachineFormState } from '../../consts';

const steps = [StepOne, StepTwo];

type NewOtherMachineFormProps = {
  onSubmit: (plan: Partial<MachineInput>) => void;
  isSubmitting: boolean;
  errorInfo?: any;
};

function NewOtherMachineForm({ onSubmit, isSubmitting, errorInfo }: NewOtherMachineFormProps) {
  const methods = useForm({ resolver: zodResolver(NewOtherMachineFormSchema) });
  const formData = methods.watch();

  const [step, setStep] = React.useState(0);

  useErrorInfo({ errorInfo, setError: methods.setError });

  function updateFormData(machineInput: Partial<NewMachineFormState>) {
    const validationResults = NewOtherMachineFormSchema.safeParse(machineInput);

    if (!validationResults.success) {
      return validationResults.error?.issues?.[0]?.message;
    } else {
      for (const [key, value] of Object.entries(machineInput)) {
        methods.setValue(key as keyof Partial<MachineInput>, value);
      }
    }
  }

  async function handlePrevStep() {
    const isValid = await methods.trigger();

    if (isValid) {
      setStep(step => step - 1);
    }
  }

  async function handleNextStep() {
    const isValid = await methods.trigger();
    if (isValid) {
      setStep(step => step + 1);
    }
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();

    return methods.handleSubmit(async (data: Partial<NewMachineFormState>) => {
      const { os, published_days_old, ...machineData } = data;
      const formData: Partial<NewMachineFormState> = machineData

      // transforming the data due to the fact that published_days_old is of type String in the schema 
      // however it should be treated as an unsigned integer
      if (published_days_old !== null) {
        formData.published_days_old = published_days_old?.toString()
      }

      onSubmit(formData);
    })(event);
  }

  const StepComponent = steps[step];

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form className="create-new-form" onSubmit={handleSubmit}>
          <StepComponent />

          <ButtonsContainer>
            {step === 0 && (
              <>
                <Button type="secondary" htmlType="submit" icon="send" loading={isSubmitting}>
                  Create
                </Button>
                <Button
                  type="primary"
                  icon="navigate_next"
                  onClick={handleNextStep}
                  loading={isSubmitting}
                >
                  Next step
                </Button>
              </>
            )}
            {step > 0 && (
              <>
                <Button
                  type="secondary"
                  icon="navigate_before"
                  onClick={handlePrevStep}
                  loading={isSubmitting}
                >
                  Previous step
                </Button>
                <Button htmlType="submit" icon="send" loading={isSubmitting}>
                  Create
                </Button>
              </>
            )}
            <Terminal formData={formData} updateFormData={updateFormData} title="plans" />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default NewOtherMachineForm;

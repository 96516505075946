/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import {
  NotificationEvent,
  NotificationLevel,
  NotificationType,
} from '../../../graphql/generated-types';
import { safeStr } from '../validation/string';

const errorMap: z.ZodErrorMap = (error, ctx) => {
  switch (error.code) {
    case z.ZodIssueCode.invalid_enum_value:
      if (error.path.includes('type')) {
        return { message: 'Invalid type value' };
      }
      if (error.path.includes('events')) {
        return { message: 'Invalid events value' };
      }
      if (error.path.includes('levels')) {
        return { message: 'Invalid levels value' };
      }
  }
  return { message: ctx.defaultError };
};

export const NotificationsChannelSchema = z
  .object({
    type: z.nativeEnum(NotificationType, { errorMap }),
    email_config: z
      .object({
        address: safeStr.email().optional(),
        is_external: z.boolean().optional(),
      })
      .optional(),
    routing_key: safeStr.optional(),
    web_hook_url: safeStr.optional(),
    channel: safeStr.optional(),
    override_settings: z.boolean().optional(),
    events: z.nativeEnum(NotificationEvent, { errorMap }).array().optional(),
    levels: z.nativeEnum(NotificationLevel, { errorMap }).array().optional(),
  })
  .refine(
    ({ type, email_config }) => {
      if (type === NotificationType.Email) {
        return !!email_config?.address;
      }
      return true;
    },
    {
      path: ['email_config.address'],
      message: "Email address is required when type is set to 'Email'",
    },
  )
  .refine(
    ({ type, routing_key }) => {
      if (type === NotificationType.PagerDuty) {
        return !!routing_key;
      }
      return true;
    },
    {
      path: ['routing_key'],
      message: "Pager Duty Key is required when type is set to 'Pager Duty'",
    },
  )
  .refine(
    ({ type, web_hook_url, channel }) => {
      if (type === NotificationType.Slack) {
        return !!channel;
      }
      return true;
    },
    {
      path: ['channel'],
      message: "Slack Channel is required when type is set to 'Slack'",
    },
  )
  .refine(
    ({ type, web_hook_url }) => {
      if (type === NotificationType.Slack) {
        return !!web_hook_url;
      }
      return true;
    },
    {
      path: ['web_hook_url'],
      message: "Web Hook URL is required when type is set to 'Slack'",
    },
  )
  .refine(
    ({ override_settings, events, levels }) => {
      if (!override_settings) {
        return !(Array.isArray(events) || Array.isArray(levels));
      }
      return true;
    },
    {
      path: ['override_settings'],
      message: "Cannot set the 'events' or 'levels' when 'Override setting' is false",
    },
  );

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from '../index';
import { ControlledField, InputField, Button, ButtonsContainer } from './';
import { zodResolver } from '@hookform/resolvers/zod';
import PlanFormInput from './PlanFromInput';
import { PipelineStepSchema } from '../../views/pipelines/components/form/schema';
import { PipelinePolicy, PipelineStepInput } from '../../graphql/generated-types';
import PatchDelay from './PatchDelay';
import { Select } from '../form2';

const options = [
  { value: PipelinePolicy.SuccessOrApproval, label: 'Success or approval' },
  { value: PipelinePolicy.ApprovalRequired, label: 'Approval required' },
  { value: PipelinePolicy.PatchAnyway, label: 'Patch anyway' },
];

type PipelineStepModalProps = {
  savePipelineStep: (step: PipelineStepInput) => void;
  hideModal: () => void;
  pipelineStep?: PipelineStepInput;
  firstStep: boolean;
};

function PipelineStepModal({
  savePipelineStep,
  hideModal,
  pipelineStep,
  firstStep,
}: PipelineStepModalProps) {
  const methods = useForm({
    defaultValues: pipelineStep,
    resolver: zodResolver(PipelineStepSchema),
  });
  const watcher = methods.watch();

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();

    return methods.handleSubmit(async values => {
      savePipelineStep(values);
    })(event);
  }

  const stepDelayDescription = watcher.run_immediately
    ? 'Step execution policy.'
    : 'Execution delay between each step.';
  const stepDelayLabel = watcher.run_immediately ? 'Time to approve' : 'Step Delay';

  const isRunImmediately =
    watcher.run_immediately &&
    (watcher.policy === PipelinePolicy.SuccessOrApproval ||
      watcher.policy === PipelinePolicy.ApprovalRequired);

  const isStepDelay = isRunImmediately || !watcher.run_immediately;

  return (
    <Modal visible={true} cancelAction={hideModal} title="Pipeline step">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className="form-group field field-object">
            <InputField name="description" />
            <InputField name="stage" />
            <ControlledField name="plans" rules={{ required: true }} Component={PlanFormInput} />
            {!firstStep && (
              <>
                <InputField name="run_immediately" label="Run immediately" type="checkbox" />
                {isStepDelay && (
                  <ControlledField
                    name="step_delay"
                    label={stepDelayLabel}
                    Component={PatchDelay}
                    description={stepDelayDescription}
                    defaultValue={0}
                  />
                )}
                <ControlledField
                  name="policy"
                  label="Policy"
                  Component={Select}
                  elements={options}
                  description="Step execution policy."
                />
              </>
            )}
          </div>
          <ButtonsContainer>
            <Button
              type="secondary"
              htmlType="submit"
              icon="send"
              disabled={watcher?.plans?.length === 0}
            >
              Add
            </Button>

            <Button
              className="button is-small is-action has-besel"
              type="secondary"
              icon="close"
              onClick={hideModal}
            >
              Cancel
            </Button>
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default PipelineStepModal;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { amplifyConfig } from '../../utils/constants';
import Amplify from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { useAuth0 } from '@auth0/auth0-react';

export const APIContext = React.createContext();

const APIProvider = APIContext.Provider;

const APIWrapper = ({ children }) => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const requester = async ({ query, input }) => {
    try {
      return await API.graphql(graphqlOperation(query, input));
    } catch (err) {
      const getToken = async () => {
        await getAccessTokenSilently();
        const token = await getIdTokenClaims();
        amplifyConfig.API.graphql_headers = () => ({
          Authorization: token.__raw,
        });
        Amplify.configure(amplifyConfig);
      };

      const isUnauthorized = err.errors
        .map(({ errorType }) => errorType === 'UnauthorizedException')
        .some(Boolean);

      if (isUnauthorized) {
        await getToken();
        return API.graphql(graphqlOperation(query, input));
      } else {
        throw err;
      }
    }
  };

  return <APIProvider value={{ requester }}>{children}</APIProvider>;
};

export default APIWrapper;

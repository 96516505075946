/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { WebhookTriggerType } from '../../graphql/generated-types';
import { WebhookPayloadType } from './schema/webhookInput';
import { FieldWrapper, Input, Label, Select } from './index';

type Webhook = {
  trigger_type?: WebhookTriggerType;
  payload?: string;
  payload_type?: string;
};

const webhookTypeOptions = [
  { value: WebhookTriggerType.Event, name: 'Event' },
  { value: WebhookTriggerType.Report, name: 'Report' },
] as const;

const webhookPayloadTypeOptions = [
  { value: WebhookPayloadType.ItsmProjectKey, name: 'Itsm project key' },
] as const;

type WebHookInputProps = {
  name: string;
  value: Webhook[];
  onChange: (webhooks: Webhook[]) => void;
};

function WebhookInput({ name, value: webhooks, onChange }: WebHookInputProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const error: FieldErrors | undefined = errors[name];

  function handleWebhookAdded() {
    onChange([...webhooks, {}]);
  }

  function handleWebhookRemoved(webhookId: number) {
    webhooks.splice(webhookId, 1);
    onChange(webhooks);
  }

  function handleWebhookTypeChanged(value: string, webhookId: number) {
    const parsedValue = z.nativeEnum(WebhookTriggerType).safeParse(value);

    if (!parsedValue.success) {
      webhooks[webhookId] = {};
    } else {
      webhooks[webhookId] = { ...webhooks[webhookId], trigger_type: parsedValue.data };
    }

    onChange(webhooks);
  }

  function handleWebhookPayloadTypeChanged(value: string, webhookId: number) {
    const parsedValue = z.nativeEnum(WebhookPayloadType).safeParse(value);

    if (!parsedValue.success) {
      const { trigger_type, payload } = webhooks[webhookId];
      webhooks[webhookId] = { trigger_type, payload };
    } else {
      webhooks[webhookId] = { ...webhooks[webhookId], payload_type: value };
    }

    onChange(webhooks);
  }

  return (
    <FieldWrapper>
      {webhooks.map(({ trigger_type, payload_type }, index) => (
        <div key={index} className="array-item">
          <button
            type="button"
            className="button is-icononly is-danger is-input-icon"
            onClick={() => handleWebhookRemoved(index)}
            data-testid={`${name}-remove-{index}`}
          >
            <i className="fa fa-trash is-square"></i>
          </button>
          <div className="form-group field field-object">
            <div className="field is-horizontal">
              <Label required>Type</Label>
              <Select
                value={trigger_type || ''}
                onChange={value => handleWebhookTypeChanged(value, index)}
                elements={webhookTypeOptions.map(({ value, name }) => ({ value, label: name }))}
                fullwidth
                error={error?.[index]?.trigger_type?.message}
                data-testid={`${name}-type-${index}`}
              />
            </div>
            <div className="field is-horizontal">
              <Label required>Payload</Label>
              <Input
                name={`${name}.${index}.payload`}
                registerProps={{ required: true }}
                type="text"
                data-testid={`${name}-payload-${index}`}
                error={error?.[index]?.payload?.message}
              />
            </div>

            <div className="field is-horizontal">
              <Label required>Payload type</Label>
              <Select
                value={payload_type || ''}
                onChange={value => handleWebhookPayloadTypeChanged(value, index)}
                elements={webhookPayloadTypeOptions.map(({ value, name }) => ({
                  value,
                  label: name,
                }))}
                fullwidth
                error={error?.[index]?.payload_type?.message}
                data-testid={`${name}-payload-type-${index}`}
              />
            </div>
          </div>
        </div>
      ))}
      <button
        type="button"
        className="button array-add is-icononly is-danger is-square"
        onClick={handleWebhookAdded}
        data-testid={`${name}-add`}
      >
        <i className="fa fa-plus"></i>
      </button>
    </FieldWrapper>
  );
}

export default WebhookInput;

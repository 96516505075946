/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import Chips from 'react-chips';
import { InputLoadingHoc } from '../index';
import { preventFromSubmitOnEnter } from './utils/preventFromSubmitOnEnter';

export type Element = {
  label: string;
  value: string;
};

type MultiSelectProps = {
  elements: ReadonlyArray<Element>;
  placeholder?: string;
  value: Array<string>;
  onChange: (...event: any[]) => void;
  isLoading?: boolean;
};

function MultiSelect({
  elements,
  placeholder,
  value,
  onChange,
  isLoading = false,
}: MultiSelectProps) {
  function onChangeHandle(chips: Array<string>) {
    onChange(chips.map(el => elements.find(e => e.label === el)?.value));
  }

  const getLabels = React.useMemo(() => elements.map(el => el.label), [elements]);

  const getValues = React.useMemo(
    () => value?.map(el => elements.find(e => e.value === el)?.label),
    [elements, value],
  );

  return (
    <InputLoadingHoc loading={isLoading}>
      <div onKeyDown={preventFromSubmitOnEnter}>
        <Chips
          focusInputOnSuggestionClick={true}
          value={getValues}
          suggestions={getLabels}
          onChange={onChangeHandle}
          placeholder={placeholder}
          fromSuggestionsOnly={true}
        />
      </div>
    </InputLoadingHoc>
  );
}

export default MultiSelect;

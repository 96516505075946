/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  InputField,
  MetadataFormInput,
  Select,
  ControlledField,
} from '../../../../commonComponents/form2';
import { Provider } from '../../../../graphql/generated-types';

const options = [
  { value: Provider.Azure, label: 'Azure' },
  { value: Provider.Gcp, label: 'GCP' },
  { value: Provider.Ibm, label: 'IBM' },
  { value: Provider.Other, label: 'Other' },
];

function StepOne() {
  const { watch, setValue } = useFormContext();
  const watcher = watch();

  React.useEffect(() => {
    if (watcher?.provider !== options[0].value) {
      setValue('azure_info', undefined);
    }
  }, [watcher?.provider, setValue]);

  return (
    <>
      <InputField name="name" registerProps={{ required: true }} />
      <ControlledField name="metadata" Component={MetadataFormInput} defaultValue={[]} />
      <InputField
        name="access.ssm_machine_id"
        label="SSM instance id"
        registerProps={{ required: true }}
      />
      <ControlledField name="provider" label="Provider" Component={Select} elements={options} />
      {watcher.provider === Provider.Azure && (
        <>
          <InputField name="azure_info.subscription_id" label="Azure subscription id" />
          <InputField name="azure_info.subscription_name" label="Azure subscription name" />
          <InputField name="azure_info.resource_group_name" label="Azure resource group name" />
        </>
      )}
    </>
  );
}

export default StepOne;

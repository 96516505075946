/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { FieldDescription, FieldError, FieldExtrasWrapper, Fieldset, FieldWrapper, Label } from '.';
import Textarea from './Textarea';
import { capitalize } from './utils/capitalize';
import { extractError, extractErrorMessage } from './utils/extractError';

type TextareaFieldProps = {
  name: string;
  label?: string;
  description?: string;
  registerProps?: RegisterOptions;
};

const TextareaField = ({
  name,
  label = capitalize(name),
  description,
  registerProps = {},
  ...props
}: TextareaFieldProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = extractError(name, errors);
  const errorMessage = extractErrorMessage(error, label);

  return (
    <>
      <Fieldset>
        <Label id={name} required={!!registerProps?.required}>
          {label}
        </Label>
        <FieldWrapper>
          <Textarea {...{ name, registerProps, ...props }} />
        </FieldWrapper>
      </Fieldset>
      {(error || description) && (
        <FieldExtrasWrapper>
          {description && <FieldDescription>{description}</FieldDescription>}
          {error && <FieldError>{errorMessage}</FieldError>}
        </FieldExtrasWrapper>
      )}
    </>
  );
};

export default TextareaField;

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import Input from './Input';
import { FieldWrapper, Label } from './index';

type IAMRoleProps = {
  value: string[];
  onChange: (value: string[]) => void;
  name: string;
};

function IAMRole({ value, onChange, name }: IAMRoleProps) {
  function handleKeyAdded() {
    onChange([...value, '']);
  }
  function handleKeyRemoved(index: number) {
    value.splice(index, 1);
    onChange(value);
  }

  const {
    formState: { errors },
  } = useFormContext();

  const error: FieldErrors | undefined = errors[name];

  return (
    <FieldWrapper>
      {value.map((item: string, index: number) => {
        return (
          <div className="array-item" key={index}>
            <button
              className="button is-icononly is-danger is-input-icon"
              onClick={() => handleKeyRemoved(index)}
            >
              <i className="fa fa-trash is-square"></i>
            </button>
            <div className="form-group">
              <div className="form-group is-horizontal">
                <Label id={`${name}.${index}`} required>
                  IAM Role
                </Label>
                <Input
                  name={`${name}.${index}`}
                  registerProps={{ required: true }}
                  error={error?.[index]?.message}
                />
              </div>
            </div>
          </div>
        );
      })}

      <button
        aria-label="Add IAM Role"
        className="button array-add is-icononly is-danger is-square"
        type="button"
        onClick={handleKeyAdded}
      >
        <i className="fa fa-plus"></i>
      </button>
    </FieldWrapper>
  );
}

export default IAMRole;

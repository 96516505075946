/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Navigation } from './Navigation';
import NavigationHeader from './NavigationHeader';
import { NotificationContainer } from 'react-notifications';
import { Customer } from './Customer';
import { withAuth0 } from '@auth0/auth0-react';


class App extends Component {
  constructor() {
    super();
    this.state = {
      customer: false,
      loading: true,
    };
  }

  componentDidMount() {
    const cachedCustomer = localStorage.getItem('currentCustomer');
    cachedCustomer && cachedCustomer.length > 0
      ? this.setState({
          customer: cachedCustomer,
          loading: false,
        })
      : this.setState({ loading: false });
  }

  changeCustomer = customer => {
    localStorage.setItem('currentCustomer', customer.id);
    localStorage.setItem('currentCustomerName', customer.name);
    this.setState({ customer: customer });
  };

  resetCustomer = () => {
    localStorage.removeItem('currentCustomer');
    localStorage.removeItem('currentCustomerName');
    this.setState({ customer: false });
    window.location.href = '/dashboard'; // Reload to reset store
  };

  render() {
    const {
      logout
    } = this.props.auth0;
  
    return (
      <React.Fragment>
        {!this.state.customer && !this.state.loading ? (
          <Customer changeCustomer={this.changeCustomer} />
        ) : (
          <React.Fragment>
            <NavigationHeader
              onLogout={logout}
              router={this.props.router}
              resetCustomer={this.resetCustomer}
            />
            <Navigation locationPathname={this.props.location.pathname} onLogout={logout} />
            <NotificationContainer />
            {this.props.children}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withAuth0(App) ;

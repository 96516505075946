/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  NotificationGroupInput,
  NotificationEventInput,
  NotificationFormInput,
  MachineTagFormInput,
  NotificationLevelsInput,
  MachinesSortable,
  RolesInput,
  DateInput,
  CustomCheckboxWidget,
  CustomTextWidget,
  Select,
  CronInput,
  PartialHooksInput,
  PartialMachineInput,
} from '../commonComponents';
import RuleActionsInput from '../commonComponents/form/RuleActionInput';
import { RuleFormInput } from '../commonComponents/form/RuleFormInput';
import { MetadataFormInput } from '../commonComponents/form/MetadataFormInput';
import RuleResourcesInput from '../commonComponents/form/RuleResourcesInput';
import DateTimeInput from '../commonComponents/form/DateTimeInput';

const uiSchema = {
  notification_groups: {
    'ui:field': 'notification_groups',
    classNames: 'is-horizontal',
  },
  notifications: {
    'ui:field': 'notifications',
    classNames: 'is-horizontal',
  },
  rules: {
    'ui:field': 'rules',
    classNames: 'is-horizontal',
  },
  metadata: {
    'ui:field': 'metadata',
    classNames: 'is-horizontal',
  },
  resource_ids: {
    'ui:field': 'resource_ids',
    classNames: 'is-horizontal',
  },
  resources: {
    'ui:field': 'resources',
    classNames: 'is-horizontal',
  },
  actions: {
    'ui:field': 'actions',
    classNames: 'is-horizontal',
  },
  events: {
    'ui:field': 'events',
    classNames: 'is-horizontal',
  },
  levels: {
    'ui:field': 'levels',
    classNames: 'is-horizontal',
  },
  machines: {
    'ui:field': 'machines',
    classNames: 'is-horizontal',
  },
  roles: {
    'ui:field': 'roles',
    classNames: 'is-horizontal',
  },
  cron_window_start: {
    'ui:field': 'cron',
    classNames: 'is-horizontal',
  },
  plans: {
    'ui:field': 'plans',
    classNames: 'is-horizontal',
  },
  machines_tag: {
    'ui:field': 'machines_tag',
    classNames: 'is-horizontal',
  },
  partial_post_hooks: {
    'ui:field': 'partial_hooks',
    classNames: 'is-horizontal',
  },
  partial_pre_hooks: {
    'ui:field': 'partial_hooks',
    classNames: 'is-horizontal',
  },
  partial_machines: {
    'ui:field': 'partial_machines',
    classNames: 'is-horizontal',
  },
  steps: {
    'ui:field': 'steps',
    classNames: 'is-horizontal',
  },
  type: {},
};

const fields = {
  notification_groups: NotificationGroupInput,
  notifications: NotificationFormInput,
  rules: RuleFormInput,
  metadata: MetadataFormInput,
  resources: RuleResourcesInput,
  actions: RuleActionsInput,
  events: NotificationEventInput,
  levels: NotificationLevelsInput,
  machines: MachinesSortable,
  roles: RolesInput,
  cron: CronInput,
  machines_tag: MachineTagFormInput,
  partial_hooks: PartialHooksInput,
  partial_machines: PartialMachineInput,
};

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  DateWidget: DateInput,
  DateTimeWidget: DateTimeInput,
  SelectWidget: Select,
};

const patterns = {
  emailPattern:
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  uuidPattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$',
};

export { uiSchema, fields, widgets, patterns };

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Plan from '../components/Plans';

import { Link } from 'react-router';
import { fetchPlans, removePlan } from '../actions';
import { getPlans } from '../../../graphql/getPlans';
import { deletePlan } from '../../../graphql/deletePlan';
import { forceRunPlan } from '../../../graphql/forceRunPlan';
import { exportPlans } from '../../../graphql/exportPlans';
import { getPipelines } from '../../../graphql/getPipelines';
import { fetchPipelines } from '../../pipelines/actions';
import {
  getOwnersList,
  modifyFilter,
  createFilter,
  persistFilterState,
  getPersistedFilterState,
  removePersistedFilterState,
} from '../../../utils/filterUtils';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { createFilter as createSearchFilter } from 'react-search-input';
import { DataTable, SearchForm, CsvExportButton, FilterSelectBox } from '../../../commonComponents';
import { DRY_RUN_STATES, pageSize, pageSizeForPlans } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const KEYS_TO_FILTERS = ['name', 'id'];

class PlansPage extends Component {
  constructor(props) {
    super(props);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showForceRunModal = this.showForceRunModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.forceRunPlan = this.forceRunPlan.bind(this);
    this.state = {
      searchTerm: '',
      filters: getPersistedFilterState('planFilters') || {},
      showModal: false,
      showForceRunModal: false,
      deleteId: '',
      deleteName: '',
      forceRunId: '',
      viewCount: 0,
    };
    if (this.state.filters.includePipelinePlans === undefined) {
      this.setState(state => ({ filters: { ...state.filters, includePipelinePlans: false } }));
    }
  }
  static contextType = APIContext;

  componentDidMount() {
    this.refreshPlans();
  }

  componentWillReceiveProps(props) {
    if (props.location && props.location.query.refresh) {
      this.refreshPlans();
      browserHistory.push('/plans');
    }

    !props.isFetching &&
      props.plans &&
      this.state.filters.owner &&
      !getOwnersList(props.plans).includes(this.state.filters.owner) &&
      this.setState(
        {
          filters: {},
        },
        removePersistedFilterState('planFilters'),
      );
  }

  refreshPlans = (paginationToken, allPages) => {
    const { requester } = this.context;

    fetchPlans({
      dispatch: this.props.dispatch,
      operation: getPlans,
      allPages,
      myInit: {
        limit: pageSizeForPlans,
        continuation_token: paginationToken,
      },
      requester,
    });
  };

  refreshPipelines = (paginationToken, allPages) => {
    const { requester } = this.context;
    fetchPipelines({
      dispatch: this.props.dispatch,
      myInit: {
        limit: pageSize,
        continuation_token: paginationToken,
      },
      requester,
      operation: getPipelines,
      allPages,
    });
  };

  loadNextPage = (plansPaginationToken, pipelinesPaginationToken, allPages) => {
    if (plansPaginationToken) {
      this.refreshPlans(plansPaginationToken, allPages);
    }
    if (pipelinesPaginationToken) {
      this.refreshPipelines(pipelinesPaginationToken, allPages);
    }
  };

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  handleFilterChange(key, val) {
    this.setState(
      { filters: modifyFilter(this.state.filters, key, val) },
      persistFilterState('planFilters', this.state),
    );
  }

  showModal(id, name) {
    this.setState({
      showModal: true,
      deleteId: id,
      deleteName: name,
    });
  }

  showForceRunModal(event) {
    event.stopPropagation();
    this.setState({
      showForceRunModal: true,
      forceRunId: event.currentTarget.dataset.plan,
    });
  }

  hideModal() {
    this.setState({
      showModal: false,
      showForceRunModal: false,
      deleteId: '',
      deleteName: '',
      forceRunId: '',
    });
  }

  removeItem(itemId) {
    const { dispatch } = this.props;
    const { requester } = this.context;

    const callback = () => {
      NotificationManager.success('Plan removed');

      browserHistory.push('/plans?refresh=true');
    };

    const myInit = {
      customer_id: localStorage.getItem('currentCustomer'),
      id: itemId,
    };
    removePlan({ dispatch, myInit, requester, operation: deletePlan, callback });
  }

  forceRunPlan(planId) {
    const { requester } = this.context;

    return (async () => {
      try {
        const response = await requester({
          query: forceRunPlan,
          input: {
            customer_id: localStorage.getItem('currentCustomer'),
            id: planId,
          },
        });
        if (
          response &&
          response.data &&
          response.data.forceRunPlan &&
          response.data.forceRunPlan.id
        ) {
          NotificationManager.success(
            `Successfully executed plan. event_id: ${response.data.forceRunPlan.id}. It'll be started within 5 minutes.`,
          );
          browserHistory.push('/events/details/' + response.data.forceRunPlan.id);
        } else {
        }
      } catch (err) {
        err && err.errors
          ? NotificationManager.error(err.errors[0].message)
          : NotificationManager.error('Network error');
      }
    })();
  }

  updateViewCount = viewCount => this.state.viewCount !== viewCount && this.setState({ viewCount });

  render() {
    const {
      error,
      plans,
      isFetching,
      pipelinePlans,
      plansPaginationToken,
      pipelinesPaginationToken,
    } = this.props;
    const { filters, searchTerm, viewCount } = this.state;

    if (filters.includePipelinePlans && !pipelinePlans) {
      this.refreshPipelines();
    }

    const allPlans = [
      ...plans,
      ...(filters.includePipelinePlans && pipelinePlans ? pipelinePlans : []),
    ];
    const filteredPlans = allPlans
      .filter(createFilter(filters))
      .filter(createSearchFilter(searchTerm, KEYS_TO_FILTERS));

    return (
      <div className="main wide">
        <div className="container">
          <div className="box">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <Link to="/plans/new">
                    <button className="button is-small is-action  has-besel" type="button">
                      <span>
                        <i className="material-icons ">add</i>
                      </span>
                      New Plan
                    </button>
                  </Link>
                </div>
                <CsvExportButton
                  ids={filteredPlans.map(p => p.id)}
                  query={exportPlans}
                  queryName="exportPlans"
                />
                <label>
                  <input
                    type="checkbox"
                    checked={filters.includePipelinePlans || false}
                    onChange={() =>
                      this.handleFilterChange('includePipelinePlans', !filters.includePipelinePlans)
                    }
                    style={{ transform: 'translateY(0)', marginRight: '0.2rem' }}
                  />
                  Include pipeline plans
                </label>
              </div>
              <div className="level-right">
                <SearchForm searchUpdated={this.searchUpdated} />
              </div>
            </nav>
            <nav className="level">
              <div className="level-left">
                <FilterSelectBox
                  options={DRY_RUN_STATES}
                  filterKey="dry_run"
                  fieldName="dry run"
                  value={filters.dry_run}
                  onChangeHandler={this.handleFilterChange}
                />
              </div>
              {allPlans.length > 0 && (
                <div className="level-right">
                  {`Viewing ${viewCount}/${allPlans.length}`}
                  {(plansPaginationToken || pipelinesPaginationToken) && '+'}
                </div>
              )}
            </nav>
          </div>

          {error && (
            <div className="message is-danger">
              <div className="message-body">
                {typeof error === 'string' && <div key="0">{error}</div>}
                {typeof error !== 'string' &&
                  Object.keys(error).map((el, index) => (
                    <div key={index}>
                      {el.replace(/_/g, ' ').toUpperCase()}:{' '}
                      {typeof error[el] === 'string'
                        ? error[el]
                        : Object.keys(error[el][0]).map(element => error[el][0][element])}
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className="box">
            <DataTable
              data={filteredPlans}
              pagination={{
                show: plansPaginationToken || pipelinesPaginationToken,
                loadNextPage: () =>
                  this.loadNextPage(plansPaginationToken, pipelinesPaginationToken),
                loadAllPages: () =>
                  this.loadNextPage(plansPaginationToken, pipelinesPaginationToken, true),
              }}
              isLoading={isFetching}
              headers={[
                { label: 'Name', field: 'name' },
                { label: 'Id', field: 'id' },
                { label: 'Cron start' },
                { label: 'Window duration', isNarrow: true },
                { label: 'Dry run', isNarrow: true },
                { label: 'On hold expires', isNarrow: true },
                { label: 'Machines number', isNarrow: true },
                {},
              ]}
              row={Plan}
              rowActions={{ removeItem: this.removeItem, forcePlan: this.forceRunPlan }}
              updateViewCount={this.updateViewCount}
            />
          </div>
        </div>
      </div>
    );
  }
}

PlansPage.propTypes = {
  plans: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  removed: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = state => {
  const {
    plans = { items: [], paginationToken: null, isFetching: false, error: null, removed: false },
    pipelines = { items: [], paginationToken: null },
  } = state;

  return {
    error: plans.error,
    isFetching: plans.isFetching,
    plans: plans.items,
    plansPaginationToken: plans.paginationToken,
    removed: plans.removed,
    pipelinePlans:
      pipelines.items.length > 0 &&
      pipelines.items.reduce((a, c) => {
        const plans = c.steps
          ? c.steps
              .reduce((aa, cc) => [...aa, ...cc.plans], [])
              .map(e => ({ ...e, pipeline_id: c.id }))
          : [];
        return [...a, ...plans];
      }, []),
    pipelinesPaginationToken: pipelines.paginationToken,
  };
};

export default connect(mapStateToProps)(PlansPage);

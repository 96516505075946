/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { NotificationModal } from './NotificationModal';

const FORM_FIELDS = ['email_config', 'type', 'channel', 'web_hook_url', 'routing_key'];
class NotificationFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.formData || [],
      popup: false,
    };
    this.switchVisibility = this.switchVisibility.bind(this);
    this.addNotification = this.addNotification.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.formData || [],
    });
  }

  switchVisibility() {
    this.setState(state => ({
      popup: !state.popup,
    }));
  }

  handleChange = data => {
    this.setState({ tempObj: data.formData });
  };

  notificationEdit = index => {
    this.setState(state => ({
      notificationIndex: index,
      notification: state.value[index],
      popup: true,
    }));
  };

  addNotification(event) {
    const value = [...this.state.value];

    this.state.notificationIndex >= 0
      ? (value[this.state.notificationIndex] = event)
      : value.push(event);
    this.setState({
      value: value,
      popup: false,
      notificationIndex: -1,
      notification: null,
    });
    this.props.onChange(value);
  }

  removeNotification = notificationIndex => event => {
    const value = [...this.state.value];
    value.splice(notificationIndex, 1);
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  };

  buildLabel = notification => (label, key) => {
    const value = notification[key]
      ? key === FORM_FIELDS[0]
        ? notification[key].address
        : notification[key]
      : null;
    if (!value) return label;
    return FORM_FIELDS.includes(key) ? `${label} ${value} ` : label;
  };

  render() {
    const { value: values, popup, notification } = this.state;

    return (
      <div className="is-input-like">
        {values.map((el, index) => (
          <span className="tag">
            <span onClick={() => this.notificationEdit(index)}>
              {Object.keys(el)
                .filter(el => ['events', 'levels'].indexOf(el) === -1)
                .reduce(this.buildLabel(el), '')}
            </span>
            <i className="fa fa-trash is-pointer" onClick={this.removeNotification(index)} />
          </span>
        ))}
        {popup && (
          <NotificationModal
            addNotification={this.addNotification}
            hideModal={() => {
              this.setState({
                popup: false,
                notificationIndex: -1,
                notification: null,
              });
            }}
            formData={notification}
          />
        )}
        <button type="button" className="button " onClick={this.switchVisibility}>
          Add New
        </button>
      </div>
    );
  }
}

export { NotificationFormInput };

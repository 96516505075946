/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import classNames from 'classnames';

const typeToClassName = {
  primary: 'high-order',
  secondary: '',
};

type ButtonProps = {
  children: React.ReactNode;
  icon?: string;
  type?: 'primary' | 'secondary';
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  htmlType?: 'button' | 'submit';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Button = ({
  children,
  icon,
  type = 'primary',
  className = '',
  disabled = false,
  loading = false,
  htmlType = 'button',
  ...props
}: ButtonProps) => {
  const typeClassName = typeToClassName[type] ?? typeToClassName['primary'];

  function Icon() {
    if (disabled) {
      return <i className="material-icons disabled">block</i>;
    }
    if (loading) {
      return <div className="loader element is-loading" />;
    }
    return <i className="material-icons">{icon}</i>;
  }

  return (
    <button
      className={classNames(
        'button',
        'is-small',
        'is-action',
        'has-besel',
        typeClassName,
        className,
      )}
      type={htmlType}
      disabled={disabled || loading}
      {...props}
    >
      <span>
        <Icon />
      </span>
      {children}
    </button>
  );
};

export default Button;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';

const { fetchData, removeItem, editItem, actions } = ListActionsFactory({
  namespace: 'PERMISSIONS',
  api: 'aws',
  path: '/permissions',
});

export {
  fetchData as fetchPermissions,
  removeItem as removePermission,
  editItem as editPermission,
  actions,
};

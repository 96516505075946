/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import moment from 'moment';

export default class DateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        props.endDate && props.startDate
          ? props.custom ? 'custom' : moment(this.props.endDate).diff(moment(this.props.startDate), 'days')
          : '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(props) {
    props.startDate &&
      props.endDate &&
      this.props.startDate !== props.startDate &&
      this.setState({
        value: props.custom
          ? 'custom'
          : moment(props.endDate).diff(moment(props.startDate), 'days'),
      });
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
    event.target.value === 'custom'
      ? this.props.toggleCustomDate(true)
      : this.props.onChange(moment().subtract(event.target.value, 'days'), true);
  }

  render() {
    const { value } = this.state;
    return (
      <div className="field-body">
        <div className="control">
          <div className="select is-fullwidth">
            <select onChange={this.handleChange} value={value}>
              <option value="">Show</option>
              <option value={1}>Past day</option>
              <option value={3}>Past 3 days</option>
              <option value={7}>Past week</option>
              <option value={30}>Past month</option>
              <option value={90}>Past 3 months</option>
              <option value="custom">Custom range</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from '../../form/Form';
import Button from '../Button';
import InputField from '../InputField';
import ButtonsContainer from '../ButtonsContainer';
import HookInput from '../HookInput';
import ControlledField from '../ControlledField';
import { HookType, PlanMachine } from '../../../graphql/generated-types';
import { PlanMachineInputSchema } from '../../../views/plans/components/form/schema';

type MachineModalProps = {
  machine: Partial<PlanMachine>;
  isEdit: boolean;
  changeMachines: (machine: PlanMachine) => void;
  hideModal: () => void;
};

function MachineModal({ machine, isEdit, changeMachines, hideModal }: MachineModalProps) {
  const methods = useForm<PlanMachine>({
    defaultValues: { ...machine, id: machine.id?.split(' ')[0] },
    resolver: zodResolver(PlanMachineInputSchema),
  });

  methods.watch(['pre_host_hooks', 'post_host_hooks']);

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();

    return methods.handleSubmit(async values => {
      changeMachines({ ...values, id: machine.id! });
    })(event);
  }

  return (
    <Modal visible={true} cancelAction={hideModal} title="Machine" size="wide">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className="form-group field field-object">
            <InputField name="id" label="Id" registerProps={{ required: true }} />
            <InputField
              name="order"
              type="number"
              label="Order"
              registerProps={{ valueAsNumber: true }}
            />
            <InputField name="exclude" label="Exclude from patching" type="checkbox" />
            <ControlledField
              name="pre_host_hooks"
              label="Pre Host Hooks"
              Component={HookInput}
              hookTypes={[HookType.Aws, HookType.Azure, HookType.Script]}
              defaultValue={[]}
            />
            <ControlledField
              name="post_host_hooks"
              label="Post Host Hooks"
              Component={HookInput}
              hookTypes={[HookType.Aws, HookType.Azure, HookType.Script]}
              defaultValue={[]}
            />
            <InputField name="s3_custom_script" label="Custom Update Command (S3 URL)" />
          </div>
          <ButtonsContainer>
            <Button type="secondary" htmlType="submit" icon="send">
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default MachineModal;

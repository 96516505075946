/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createAPIKey = `mutation CreateAPIKey($input: APIKeyInput!) {
  createAPIKey(input: $input) {
    id
    secret
  }
}
`;

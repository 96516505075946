/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';

const DataTableHeader = ({ header, orderBy, desc, setOrder }) => {
  const size = header.isNarrow ? 'is-narrow-th' : '';
  return header.field ? (
    <th
      onClick={() => setOrder(header.field)}
      className={header.field === orderBy ? `is-active-filter ${size}` : `is-filter ${size}`}
    >
      {header.label}
      {header.field === orderBy && <span className="is-filter-icon"> {desc ? '⌟' : '⌝'}</span>}
    </th>
  ) : (
    <th className={`${size}`}>{header.label}</th>
  );
};

export const DataTableHeaders = ({ headers, orderBy, desc, setOrder }) => (
  <tr>
    {headers.map(header => (
      <DataTableHeader key={`header-${(header.label || "").replace(/ /g,"_")}`} header={header} orderBy={orderBy} desc={desc} setOrder={setOrder} />
    ))}
  </tr>
);

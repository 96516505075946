/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';

const { fetchData, removeItem, editItem, resetErrors, actions } = ListActionsFactory({
  namespace: 'PIPELINES',
  api: 'aws',
  path: '/pipelines',
});

export {
  fetchData as fetchPipelines,
  removeItem as removePipeline,
  editItem as editPipeline,
  resetErrors,
  actions,
};

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const forceRunPlan = `mutation ForceRunPlan($customer_id: String!, $id: String!) {
  forceRunPlan(customer_id: $customer_id, id: $id) { 
      id
    }
  }
  `;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  CREATE_NEW_SEND,
  CREATE_NEW_SUCCESS,
  CREATE_NEW_FAILURE,
  CREATE_NEW_RESET,
} from '../actions/createNew';

const initialState = {
  isSending: false,
  didInvalidate: false,
  accounts: [],
  error: null,
  errorInfo: null,
};

export function createNew(
  state = {
    ...initialState,
  },
  action,
) {
  switch (action.type) {
    case CREATE_NEW_SEND:
      return Object.assign({}, state, {
        isSending: true,
        didInvalidate: false,
      });
    case CREATE_NEW_SUCCESS:
      return Object.assign({}, state, {
        isSending: false,
        didInvalidate: false,
        response: action.response,
        dataSent: true,
        error: null,
      });
    case CREATE_NEW_FAILURE:
      return Object.assign({}, state, {
        isSending: false,
        didInvalidate: false,
        error: action.error,
        errorInfo: action.errorInfo,
      });
    case CREATE_NEW_RESET:
      return { ...initialState };
    default:
      return state;
  }
}

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { PATCHING_STATES_MAPPING } from '../../../utils';

const EventStatus = ({ event, showEventDetails }) => {
  const {
    name: status = event.patching_status,
    className: statusClassName = '',
  } = PATCHING_STATES_MAPPING[event.patching_status]
    ? PATCHING_STATES_MAPPING[event.patching_status]
    : {};

  return (
    <div className={`tag ${statusClassName}`} onClick={showEventDetails}>
      {status.toUpperCase()}
    </div>
  );
};

export default EventStatus;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from '../index';
import { NotificationChannelInput, NotificationType } from '../../graphql/generated-types';
import { ControlledField, InputField, Button, Select, ButtonsContainer } from './';
import { NotificationGroupSelectors } from '../../views/notificationGroups/components/form/NotificationGroupForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { NotificationsChannelSchema } from './schema/notificationsChannel';

type NotificationChannelModalProps = {
  saveNotification: (notification: NotificationChannelInput) => void;
  hideModal: () => void;
  notification?: NotificationChannelInput;
};

export const NotificationTypes = [
  { value: NotificationType.Email, label: 'Email' },
  { value: NotificationType.PagerDuty, label: 'Pager duty' },
  { value: NotificationType.Slack, label: 'Slack' },
] as const;

function SlackType() {
  return (
    <>
      <InputField name="web_hook_url" label="Web Hook URL" registerProps={{ required: true }} />
      <InputField name="channel" label="Slack Channel" registerProps={{ required: true }} />
    </>
  );
}

function PagerDutyType() {
  return (
    <InputField name="routing_key" label="Pager Duty Key" registerProps={{ required: true }} />
  );
}

function EmailType() {
  return (
    <>
      <InputField
        name="email_config.address"
        label="Email address"
        registerProps={{ required: true }}
      />
      <InputField name="email_config.is_external" label="Is external" type="checkbox" />
    </>
  );
}

function renderSwitch(type?: NotificationType) {
  switch (type) {
    case NotificationType.Slack:
      return <SlackType />;
    case NotificationType.PagerDuty:
      return <PagerDutyType />;
    case NotificationType.Email:
      return <EmailType />;
    default:
      return null;
  }
}

function NotificationChannelModal({
  saveNotification,
  hideModal,
  notification,
}: NotificationChannelModalProps) {
  const methods = useForm<NotificationChannelInput>({
    defaultValues: notification,
    resolver: zodResolver(NotificationsChannelSchema),
  });
  const watcher = methods.watch();

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();

    return methods.handleSubmit(async values => {
      saveNotification(values);
    })(event);
  }

  React.useEffect(() => {
    if (watcher?.type !== NotificationType.Email) {
      methods.setValue('email_config', undefined);
    }
    if (watcher?.type !== NotificationType.PagerDuty) {
      methods.setValue('routing_key', undefined);
    }
    if (watcher?.type !== NotificationType.Slack) {
      methods.setValue('channel', undefined);
      methods.setValue('web_hook_url', undefined);
    }
  }, [methods, watcher?.type]);

  React.useEffect(() => {
    if (!watcher?.override_settings) {
      methods.setValue('events', undefined);
      methods.setValue('levels', undefined);
    }
  }, [methods, watcher?.override_settings]);

  return (
    <Modal visible={true} cancelAction={hideModal} title="Notification">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className="form-group field field-object">
            <ControlledField
              name="type"
              label="Type"
              Component={Select}
              defaultValue=""
              elements={NotificationTypes}
              rules={{ required: true }}
            />
            {renderSwitch(watcher?.type)}
            <InputField name="override_settings" label="Override settings" type="checkbox" />
            {watcher?.override_settings && <NotificationGroupSelectors />}
          </div>
          <ButtonsContainer>
            <Button type="secondary" htmlType="submit" icon="send" disabled={!watcher?.type}>
              Save
            </Button>
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default NotificationChannelModal;

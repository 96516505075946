/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { RebootPolicy } from '../../../graphql/generated-types';

export const rebootPolicyOptions = [
  { value: RebootPolicy.Never, label: 'Never' },
  { value: RebootPolicy.IfNeeded, label: 'If needed' },
  { value: RebootPolicy.Always, label: 'Always' },
];

export const rebootPolicySchemaValues = [
  '',
  ...rebootPolicyOptions.map(({ value }) => value as string),
] as [string, ...string[]];

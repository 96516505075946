/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { UseFormSetError } from 'react-hook-form';

type useErrorInfoArgs<T> = {
  errorInfo?: Partial<{ [key in keyof T]: string | undefined }> | null;
  setError: UseFormSetError<T>;
};

export function useErrorInfo<T>({ errorInfo, setError }: useErrorInfoArgs<T>) {
  React.useEffect(() => {
    if (errorInfo) {
      Object.entries(errorInfo).forEach(([key, message]) => {
        // @ts-ignore -- use zod schema to validate and cast the type
        setError(key, { message });
      });
    }
  }, [errorInfo, setError]);
}

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NotificationGroup } from '../components/NotificationGroup';

import { fetchNotificationGroups, removeNotificationGroup } from '../actions';
import { getNotificationGroups } from '../../../graphql/getNotificationGroups';
import { deleteNotificationGroup } from '../../../graphql/deleteNotificationGroup';
import {
  modifyFilter,
  createFilter,
  persistFilterState,
  getPersistedFilterState,
} from '../../../utils/filterUtils';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { createFilter as createSearchFilter } from 'react-search-input';
import { Link } from 'react-router';
import { DataTable, SearchForm } from '../../../commonComponents';
import { pageSize } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const KEYS_TO_FILTERS = ['name', 'id'];

class NotificationGroupsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: this.props.defaultValue || [],
      searchTerm: '',
      filters: getPersistedFilterState('notificationGroupFilters') || {},
      viewCount: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.searchUpdated = this.searchUpdated.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    this.refresh();
  }

  componentWillReceiveProps(props) {
    if (props.location && props.location.query.refresh) {
      this.refresh();
      browserHistory.push('notification_groups');
    }
  }

  refresh = (paginationToken, allPages) => {
    const { requester } = this.context;

    fetchNotificationGroups({
      dispatch: this.props.dispatch,
      myInit: {
        limit: pageSize,
        continuation_token: paginationToken,
      },
      requester,
      operation: getNotificationGroups,
      allPages,
    });
  };

  handleChange(group, add) {
    let groups = this.state.groups;
    if (add) {
      groups.push(group.id);
    } else {
      groups = groups.filter(element => element !== group.id);
    }
    this.setState({
      groups: groups,
    });
    this.props.onChange(null, 'notification_groups', groups);
  }

  handleFilterChange(key, val) {
    this.setState(
      { filters: modifyFilter(this.state.filters, key, val) },
      persistFilterState('notificationGroupFilters', this.state),
    );
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  removeItem(itemId) {
    const { requester } = this.context;

    const { dispatch } = this.props;
    const myInit = {
      customer_id: localStorage.getItem('currentCustomer'),
      id: itemId,
    };

    const callback = () => {
      NotificationManager.success('Notification group removed');
      browserHistory.push('/notification_groups?refresh=true');
    };

    removeNotificationGroup({
      dispatch,
      myInit,
      requester,
      operation: deleteNotificationGroup,
      callback,
    });
  }

  updateViewCount = viewCount => this.state.viewCount !== viewCount && this.setState({ viewCount });

  render() {
    const { error, notificationGroups, paginationToken, isFetching } = this.props;
    const filteredNotificationGroups = notificationGroups
      .filter(createFilter(this.state.filters))
      .filter(createSearchFilter(this.state.searchTerm, KEYS_TO_FILTERS));

    return (
      <div>
        <div className="main wide">
          <div className="container">
            <div className="box">
              <nav className="level">
                <div className="level-left">
                  <div className="level-item">
                    <Link to="/notification_groups/new">
                      <button className="button is-small is-action  has-besel" type="button">
                        <span>
                          <i className="material-icons ">add</i>
                        </span>
                        New Group
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="level-right">
                  <SearchForm searchUpdated={this.searchUpdated} />
                </div>
              </nav>
              {notificationGroups.length > 0 && (
                <nav className="level">
                  <div className="level-left" />
                  <div className="level-right">
                    {`Viewing ${this.state.viewCount}/${notificationGroups.length}`}
                    {paginationToken && '+'}
                  </div>
                </nav>
              )}
            </div>

            {error && (
              <div className="message is-danger">
                <div className="message-body">
                  {typeof error === 'string' && <div key="0">{error}</div>}
                  {typeof error !== 'string' &&
                    Object.keys(error).map((el, index) => (
                      <div key={index}>
                        {el.replace(/_/g, ' ').toUpperCase()}:{' '}
                        {typeof error[el] === 'string'
                          ? error[el]
                          : Object.keys(error[el][0]).map(element => error[el][0][element])}
                      </div>
                    ))}
                </div>
              </div>
            )}

            <DataTable
              data={filteredNotificationGroups}
              pagination={{
                show: !!paginationToken,
                loadNextPage: () => this.refresh(paginationToken),
                loadAllPages: () => this.refresh(paginationToken, true),
              }}
              isLoading={isFetching}
              headers={[
                { label: 'Name', field: 'name' },
                { label: 'Id', field: 'id' },
                { label: 'Notifications' },
                { label: '' },
              ]}
              row={NotificationGroup}
              rowActions={{ removeItem: this.removeItem }}
              updateViewCount={this.updateViewCount}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    notificationGroups = {
      items: [],
      paginationToken: null,
      isFetching: false,
      error: null,
      removed: false,
    },
  } = state;

  return {
    error: notificationGroups.error,
    isFetching: notificationGroups.isFetching,
    notificationGroups: notificationGroups.items,
    paginationToken: notificationGroups.paginationToken,
    removed: notificationGroups.removed,
  };
};

export default connect(mapStateToProps)(NotificationGroupsPage);

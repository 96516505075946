/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createNotificationGroup = `mutation CreateNotificationGroup($input: NotificationGroupInput!) {
    createNotificationGroup(input: $input) { 
      id
    }
  }
  `;

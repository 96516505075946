/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Link } from 'react-router';
import Button from './Button';

type FormHeaderProps = {
  title: string;
  subtitle: string;
  backRoute: string;
};

function FormHeader({ title, subtitle, backRoute }: FormHeaderProps) {
  return (
    <div className="box">
      <div className="field is-horizontal">
        <div>
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
        </div>
        <div className="field-label">
          <Link to={backRoute}>
            <Button type="secondary" icon="close" className="is-pulled-right">
              Close
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FormHeader;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import classNames from 'classnames';

type FieldsetProps = {
  children: React.ReactNode;
  className?: string;
};

const Fieldset = ({ children, className = '' }: FieldsetProps) => (
  <div className={classNames('field', 'is-horizontal', className)}>{children}</div>
);

export default Fieldset;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { LoadingHOC } from '../../../commonComponents';
import HookStatus from './EventHookStatus';
import { getHostHookOutput } from '../../../graphql/getHostHookOutput';
import { getHookOutput } from '../../../graphql/getHookOutput';
import { APIContext } from '../../../commonComponents/auth/APIContext';

export default class HookOutput extends React.Component {
  constructor() {
    super();
    this.state = { loading: false, content: '', error: false };
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;
    const { machine_id, id, event_id } = this.props;

    this.setState({ loading: true });

    return (async () => {
      try {
        const response = await requester({
          query: machine_id ? getHostHookOutput : getHookOutput,
          input: {
            customer_id: localStorage.getItem('currentCustomer'),
            event_id: event_id,
            hook_name: id,
            machine_id: machine_id,
          },
        });

        if (response && response.data && response.data.hostHookOutput) {
          this.setState({ loading: false, content: response.data.hostHookOutput });
        }
        if (response && response.data && response.data.hookOutput) {
          this.setState({ loading: false, content: response.data.hookOutput });
        }
      } catch (err) {
        err && err.errors && this.setState({ loading: false, error: err.errors[0].message });
      }
    })();
  }

  render() {
    const { content, error, loading } = this.state;
    const { hook } = this.props;

    return (
      <div className="hookOutput">
        <ul>
          {Object.keys(hook)
            .filter(key => ['type', 'source'].indexOf(key) > -1)
            .map(key => (
              <li>
                <strong>Hook {key}:</strong> {hook[key]}
              </li>
            ))}
          <li>
            <strong> Hook status:</strong> <HookStatus status={hook.status_code} />
          </li>
        </ul>

        <LoadingHOC loading={loading}>
          {error && (
            <div className="message is-danger">
              <div className="message-body">Couldn't get the output from API</div>
            </div>
          )}

          {!error && content?.message && (
            <textarea className="report-preview" value={content.message} readonly />
          )}
        </LoadingHOC>
      </div>
    );
  }
}

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';

const forbiddenCharactersRegexp = /^((?![<>]).)*$/;
const forbiddenCharactersErrorMessage = 'Forbidden characters entered';
export const forbiddenCharacters = (value: string | number | boolean) => {
  if (typeof value === 'string' && !value?.match(forbiddenCharactersRegexp)) {
    return forbiddenCharactersErrorMessage;
  }

  return true;
};

export const safeStr = z
  .string()
  .regex(forbiddenCharactersRegexp, { message: forbiddenCharactersErrorMessage });
export const stringRequired = (name: string) => safeStr.min(1, { message: `${name} is required` });

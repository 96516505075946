/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { FormattedDate } from '../../../commonComponents';
import EventStatus from '../../events/components/EventStatus';
import { Link } from 'react-router';

export const DashboardEvent = ({ item: event }) => {
  const eventLink = `events/details/${event.id}`;
  return (
    <tr className="table-row-link" id={event.id}>
      <Link className="table-cell" to={eventLink}>
        {event.name ? event.name : event.id}
      </Link>
      <Link className="table-cell" to={eventLink}>
        {event.patching_status === 'NEW' ? (
          <FormattedDate>{event.window_start_time}</FormattedDate>
        ) : (
          <EventStatus event={event} />
        )}
      </Link>
    </tr>
  );
};

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getPermission = `query RbacRoleBinding($customer_id: String!, $id: String!) {
    rbacRoleBinding(customer_id: $customer_id, id: $id) {
      id
      subject
      type
      roles {
        id
        name
      }
      created_at
      updated_at
    }
  }
`;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getAPIKeys = `query APIKeysBatch(
  $customer_id: String!
  $limit: Int!
  $continuation_token: String
) {
  apiKeysBatch(
    input: { customer_id: $customer_id, limit: $limit, continuation_token: $continuation_token }
  ) {
    api_keys {
      id
      customer_id
      name
      expires_at
      description
      created_at
      updated_at
    }
    continuation_token
  }
}
`;

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const ROUTES = {
  home: '/',
  machines: {
    machines: '/machines',
    newAWSMachine: '/machines/new/aws',
    newOtherMachine: '/machines/new/other',
    machineDetails: (id: string) => `/machines/details/${id}`,
  },
  plans: {
    activePrefix: '/plans',
    plans: '/plans',
    plansNew: '/plans/new',
    plansDetails: (id: string) => `/plans/details/${id}`,
  },
  notificationGroups: {
    notificationGroups: '/notification_groups',
    notificationGroupsNew: '/notification_groups/new',
    notificationGroupDetails: (id: string) => `/notification_groups/details/${id}`,
  },
  permissions: {
    activePrefix: '/permissions',
    assignments: '/permissions/assignments',
    assignmentsNew: '/permissions/assignments/new',
    assignmentsDetails: (id: string) => `/permissions/assignments/details/${id}`,
    roles: '/permissions/roles',
    rolesNew: '/permissions/roles/new',
    rolesDetails: (id: string) => `/permissions/roles/details/${id}`,
  },
  apiKeys: {
    activePrefix: '/api_keys',
    apiKeys: '/api_keys',
    apiKeysNew: '/api_keys/new',
    apiKeysDetails: (id: string) => `/api_keys/details/${id}`,
  },
  pipelines: {
    pipelinesPrefix: '/pipelines',
    pipelines: '/pipelines',
    pipelinesNew: '/pipelines/new',
    pipelinesDetails: (id: string) => `/pipelines/details/${id}`,
  },
} as const;

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import {
  InputField,
  MetadataFormInput,
  CronInput,
  ControlledField,
} from '../../../../commonComponents/form2';
import { TimezoneSelector } from '../../../../commonComponents';

function StepOne() {
  return (
    <>
      <InputField name="name" registerProps={{ required: true }} />
      <ControlledField name="metadata" Component={MetadataFormInput} defaultValue={[]} />
      <ControlledField
        name="cron_window_start"
        label="Cron window start"
        Component={CronInput}
        rules={{ required: true }}
        defaultValue=""
      />
      <ControlledField
        name="time_zone"
        label="Location"
        Component={TimezoneSelector}
        defaultValue=""
      />
      <InputField
        name="window_length"
        type="number"
        label="Patching window duration [h]"
        description="Length of the patching window in hours. Min = 1h, Max = 24h"
        registerProps={{ valueAsNumber: true, required: true }}
      />
    </>
  );
}

export default StepOne;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

function DateInput({ value, onChange }) {
  const date = value && moment(value).toDate();

  function handleChange(date) {
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : date;
    onChange(formattedDate);
  }

  return (
    <DatePicker
      className="input"
      placeholderText="Select date..."
      selected={date}
      onChange={handleChange}
      dateFormat="yyyy-MM-dd"
    />
  );
}

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DateInput;

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import { stringRequired } from '../validation/string';
import { HookMethod, HookType } from '../../../graphql/generated-types';

export const HookInputSchema = z
  .object({
    type: z.nativeEnum(HookType, {
      errorMap: (error, ctx) => {
        if (error.code === z.ZodIssueCode.invalid_enum_value) {
          return { message: 'Invalid hook type value' };
        }
        return { message: ctx.defaultError };
      },
    }),
    source: stringRequired('Source'),
    method: z
      .nativeEnum(HookMethod, {
        errorMap: (error, ctx) => {
          if (error.code === z.ZodIssueCode.invalid_enum_value) {
            return { message: 'Invalid hook method value' };
          }
          return { message: ctx.defaultError };
        },
      })
      .optional(),
  })
  .refine(
    ({ type, method }) => {
      if (type === HookType.Azure) {
        return !!method;
      }

      return true;
    },
    {
      path: ['method'],
      message: "Method is required when type is set to 'Azure Functions URL'",
    },
  );

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const CREATE_NEW_SEND = 'CREATE_NEW_SEND';
export const CREATE_NEW_SUCCESS = 'CREATE_NEW_SUCCESS';
export const CREATE_NEW_FAILURE = 'CREATE_NEW_FAILURE';
export const CREATE_NEW_RESET = 'CREATE_NEW_RESET';

const createNewSend = () => ({ type: CREATE_NEW_SEND });

const createNewSuccess = payload => ({
  type: CREATE_NEW_SUCCESS,
  payload,
});

const createNewFailure = ({ errorMessages, errorInfo }) => ({
  type: CREATE_NEW_FAILURE,
  error: errorMessages,
  errorInfo,
});

export const resetNewForm = dispatch => {
  dispatch({ type: CREATE_NEW_RESET });
};

export function postData(dispatch, operation, myInit, requester, callback) {
  dispatch(createNewSend());
  return (async () => {
    try {
      const response = await requester({ query: operation, input: myInit });
      response && dispatch(createNewSuccess(response));
      callback(true, response);
    } catch (err) {
      const { errors } = err;
      if (!(errors && Array.isArray(errors))) {
        return dispatch(createNewFailure({ errorMessages: ['Network error'] }));
      }

      const errorMessages = errors.map(error => error.message);
      const errorInfo = errors.reduce((acc, curError) => {
        if (!curError?.errorInfo) {
          return acc;
        }

        const errors = Object.fromEntries(
          Object.entries(curError?.errorInfo).map(([key, value]) => [key, value[0]]),
        );

        return { ...acc, ...errors };
      }, {});

      dispatch(
        createNewFailure({
          errorMessages,
          errorInfo,
        }),
      );
    }
  })();
}

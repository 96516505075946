/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import _ from 'lodash';

const CsvReportButton = ({ events, customer }) => {
  const report = _.flatMap(events, event => {
    return event.machines
      ? event.machines.map(machine => {
          return machine.id
            ? {
                plan_id: event.plan_id,
                id: machine.id,
                patching_status: machine.status || 'Status missing',
                time_zone: event?.time_zone,
                created_at: event.created_at,
              }
            : {};
        })
      : [];
  });

  const headers = [
    { label: 'Machine name', key: 'id' },
    { label: 'Plan id', key: 'plan_id' },
    { label: 'Patching status', key: 'patching_status' },
    { label: 'Location', key: 'time_zone' },
    { label: 'Patching date', key: 'created_at' },
  ];

  const filename = `${customer ? customer.toUpperCase() : ''} ${moment().format(
    'DD MM YYYY',
    'Europe/Helsinki',
  )}.csv`;

  return (
    <div className="level-item">
      {report && report.length > 0 && (
        <button className="button">
          <CSVLink filename={filename} data={report} headers={headers}>
            Generate CSV Report
          </CSVLink>
        </button>
      )}
    </div>
  );
};

export { CsvReportButton };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const putPipeline = `mutation PutPipeline($input: PipelinePutInput!) {
  putPipeline(input: $input) { 
    id
  }
}
`;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router';
import { fetchPipelines, removePipeline } from '../actions';
import { Pipeline } from '../components/Pipeline';

import {
  getOwnersList,
  modifyFilter,
  createFilter,
  persistFilterState,
  getPersistedFilterState,
  removePersistedFilterState,
} from '../../../utils/filterUtils';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { createFilter as createSearchFilter } from 'react-search-input';
import { DataTable, SearchForm } from '../../../commonComponents';
import { getPipelines } from '../../../graphql/getPipelines';
import { deletePipeline } from '../../../graphql/deletePipeline';
import { forceRunPipeline } from '../../../graphql/forceRunPipeline';
import { pageSize } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const KEYS_TO_FILTERS = ['name', 'id'];

const handleNestedError = obj => {
  const errObj = typeof obj[0] === 'object' ? obj[0] : obj;
  return Object.keys(errObj)
    .map(element => errObj[element])
    .join(' ');
};
class PlansPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      filters: getPersistedFilterState('planFilters') || {},
      showModal: false,
      showForceRunModal: false,
      deleteId: '',
      deleteName: '',
      viewCount: 0,
    };
  }
  static contextType = APIContext;

  componentDidMount() {
    this.refresh();
  }

  searchUpdated = term => {
    this.setState({ searchTerm: term });
  };

  componentWillReceiveProps(props) {
    if (props.location && props.location.query.refresh) {
      this.refresh();
      browserHistory.push('/pipelines');
    }

    !props.isFetching &&
      props.plans &&
      !getOwnersList(props.plans).includes(this.state.filters.owner) &&
      this.setState(
        {
          filters: {},
        },
        removePersistedFilterState('planFilters'),
      );
  }

  refresh = (paginationToken, allPages) => {
    const { requester } = this.context;

    fetchPipelines({
      dispatch: this.props.dispatch,
      myInit: {
        limit: pageSize,
        continuation_token: paginationToken,
      },
      operation: getPipelines,
      requester,
      allPages,
    });
  };

  handleFilterChange = (key, val) => {
    this.setState(
      { filters: modifyFilter(this.state.filters, key, val) },
      persistFilterState('planFilters', this.state),
    );
  };

  forceRunPipeline = async pipelineId => {
    const { requester } = this.context;
    try {
      const response = await requester({
        query: forceRunPipeline,
        input: {
          customer_id: localStorage.getItem('currentCustomer'),
          id: pipelineId,
        },
      });

      if (response?.data?.forceRunPipeline?.[0]?.id) {
        NotificationManager.success(
          `Successfully executed pipeline. First event ID: ${response.data.forceRunPipeline[0].id}. It'll be started within 5 minutes.`,
        );
        browserHistory.push('/events/details/' + response.data.forceRunPipeline[0].id);
      } else {
      }
    } catch (err) {
      err && err.errors
        ? NotificationManager.error(err.errors[0].message)
        : NotificationManager.error('Network error');
    }
  };

  removeItem = itemId => {
    const { requester } = this.context;
    const { dispatch } = this.props;

    const callback = () => {
      NotificationManager.success('Pipeline removed');

      browserHistory.push('/pipelines?refresh=true');
    };

    const myInit = {
      customer_id: localStorage.getItem('currentCustomer'),
      id: itemId,
    };

    removePipeline({ dispatch, myInit, requester, operation: deletePipeline, callback });
  };

  updateViewCount = viewCount => this.state.viewCount !== viewCount && this.setState({ viewCount });

  render() {
    const { error, pipelines, isFetching, paginationToken } = this.props;
    const filteredPipelines = pipelines
      .filter(createFilter(this.state.filters))
      .filter(createSearchFilter(this.state.searchTerm, KEYS_TO_FILTERS));

    return (
      <div className="main wide">
        <div className="container">
          <div className="box">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <Link to="/pipelines/new">
                    <button className="button is-small is-action  has-besel" type="button">
                      <span>
                        <i className="material-icons ">add</i>
                      </span>
                      New Pipeline
                    </button>
                  </Link>
                </div>
              </div>
              <div className="level-right">
                <SearchForm searchUpdated={this.searchUpdated} />
              </div>
            </nav>
            {pipelines.length > 0 && (
              <nav className="level">
                <div className="level-left" />
                <div className="level-right">
                  {`Viewing ${this.state.viewCount}/${pipelines.length}`}
                  {paginationToken && '+'}
                </div>
              </nav>
            )}
          </div>

          {error && (
            <div className="message is-danger">
              <div className="message-body">
                {typeof error === 'string' && <div key="0">{error}</div>}
                {typeof error !== 'string' &&
                  Object.keys(error).map((el, index) => (
                    <div key={index}>
                      {el.replace(/_/g, ' ').toUpperCase()}:{' '}
                      {typeof error[el] === 'string' ? error[el] : handleNestedError(error[el])}
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className="box">
            <DataTable
              data={filteredPipelines}
              pagination={{
                show: !!paginationToken,
                loadNextPage: () => this.refresh(paginationToken),
                loadAllPages: () => this.refresh(paginationToken, true),
              }}
              isLoading={isFetching}
              headers={[
                { label: 'Name', field: 'name' },
                { label: 'Cron start' },
                { label: 'Steps number', isNarrow: true },
                {},
              ]}
              row={Pipeline}
              rowActions={{ removeItem: this.removeItem, forcePipeline: this.forceRunPipeline }}
              updateViewCount={this.updateViewCount}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    pipelines = {
      items: [],
      paginationToken: null,
      isFetching: false,
      error: null,
      removed: false,
    },
  } = state;

  return {
    error: pipelines.error,
    isFetching: pipelines.isFetching,
    pipelines: pipelines.items,
    paginationToken: pipelines.paginationToken,
    removed: pipelines.removed,
  };
};

export default connect(mapStateToProps)(PlansPage);

/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from 'zod';
import { MetadataSchema } from '../../../../commonComponents/form2/schema/metadata';
import { rebootPolicySchemaValues } from '../../../../commonComponents/form2/schema/rebootPolicy';
import { safeStr, stringRequired } from '../../../../commonComponents/form2/validation/string';
import { Provider } from '../../../../graphql/generated-types';
import { OsType } from '../../consts';

export const NewOtherMachineFormSchema = z.object({
  // 1st step
  name: stringRequired('Name'),
  metadata: MetadataSchema.optional(),
  access: z.object({ ssm_machine_id: stringRequired('SSM instance ID') }),
  provider: z.enum([Provider.Azure, Provider.Gcp, Provider.Ibm, Provider.Other]).optional(),
  azure_info: z
    .object({
      subscription_id: safeStr.optional(),
      subscription_name: safeStr.optional(),
      resource_group_name: safeStr.optional(),
    })
    .optional(),
  // 2nd step
  description: safeStr.optional(),
  reboot_policy: z.enum(rebootPolicySchemaValues).optional(),
  published_days_old: z.preprocess(
    value => (value === null || Number.isNaN(value) ? undefined : value),
    z
      .number()
      .int({ message: 'Days since update must be an integer' })
      .nonnegative({ message: 'Days since update must be positive' })
      .optional(),
  ),
  s3_custom_script: safeStr.optional(),
  os: z.enum([OsType.unknown, OsType.linux, OsType.windows]).optional(),
  update_category: safeStr.optional(),
  update_severity: safeStr.optional(),
  include_kbs: safeStr.optional(),
  exclude_kbs: safeStr.optional(),
});

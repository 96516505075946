/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const emptySelect = 'emptySelectValue';

const DragHandle = SortableHandle(() => (
  <span className="fa fa-bars pull-left" style={{ cursor: 'pointer', paddingTop: '0.2rem' }} />
));

const StepItem = SortableElement(({ step, idx, planIdx, insert, toggleInsert }) => {
  const isNew = planIdx === idx;
  return (
    <div
      className={`box has-background-light pipelineFormPlan ${
        planIdx - 1 === idx && !insert && 'is-marginless'
      }`}
      style={{ cursor: 'auto', padding: '1rem 1rem' }}
    >
      {isNew && <DragHandle />}
      {step}
      {isNew && idx !== 0 && (
        <button
          onClick={e => {
            e.preventDefault();
            toggleInsert();
          }}
          className="button pull-right"
        >
          {insert ? 'Append to above' : 'Split'}
        </button>
      )}
    </div>
  );
});

const StepsList = SortableContainer(({ steps, planIdx, insert, toggleInsert }) => {
  return (
    <div>
      {steps.map((e, i) => (
        <StepItem
          step={e}
          idx={i}
          planIdx={planIdx}
          insert={insert}
          toggleInsert={toggleInsert}
          index={i}
          key={i}
        />
      ))}
    </div>
  );
});

class PipelineSortable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planIdx: -1,
      steps: (props.formContext.pipeline.steps || []).map((e, i) => `Step ${i + 1}`),
    };
  }

  componentDidUpdate() {
    const {
      formContext: { planId },
      onChange,
    } = this.props;
    const { planIdx, steps } = this.state;

    if ((!planId || planId === emptySelect) && planIdx !== -1) {
      steps.splice(planIdx, 1);
      onChange(steps);
      this.setState({ planIdx: -1 });
      return;
    }

    if (planId && planId !== emptySelect && planIdx === -1) {
      steps.push(planId);
      onChange(steps);
      this.setState({ planIdx: steps.length - 1 });
      return;
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onChange } = this.props;
    const { steps } = this.state;
    steps.splice(newIndex, 0, ...steps.splice(oldIndex, 1));
    onChange(steps);
    this.setState({ planIdx: newIndex });
  };

  render() {
    const {
      formContext: { planId, insert, toggleInsert },
    } = this.props;
    const { planIdx, steps } = this.state;

    if (planIdx !== -1) {
      steps[planIdx] = planId;
    }

    return (
      <StepsList
        steps={steps}
        planIdx={planIdx}
        insert={insert}
        toggleInsert={toggleInsert}
        useDragHandle={true}
        lockAxis="y"
        onSortEnd={this.onSortEnd}
      />
    );
  }
}
export default PipelineSortable;

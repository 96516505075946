/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getPermissions = `query RbacRoleBindingsBatch(
    $customer_id: String!
    $filters: [Filter!]
    $limit: Int!
    $continuation_token: String
  ) {
    rbacRoleBindingsBatch(
      input: {
        customer_id: $customer_id
        limit: $limit
        continuation_token: $continuation_token
        filters: $filters
      }
    ) {
      rbac_role_bindings {
        id
        subject
        type
        created_at
        updated_at
        roles {
          id
          name
        }
      }
      continuation_token
    }
  }  
`;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchEvents } from '../../events/actions';
import { getMachines } from '../../../graphql/getMachines';
import { getEvents } from '../../../graphql/getEvents';
import { fetchMachines } from '../../machines/actions';
import { DataTable } from '../../../commonComponents/dataTable/DataTable';
import { DashboardEvent } from '../components/DashboardEvent';
import { DashboardMachine } from '../components/DashboardMachine';
import { pageSize } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';

class DashboardPage extends Component {
  static contextType = APIContext;
  componentDidMount() {
    const { requester } = this.context;
    const { dispatch } = this.props;
    const eventsInit = {
      limit: pageSize,
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment().add(7, 'days').format('YYYY-MM-DD'),
    };
    fetchEvents({ dispatch, myInit: eventsInit, requester, operation: getEvents, allPages: true });
    fetchMachines({
      dispatch,
      myInit: { limit: pageSize },
      requester,
      operation: getMachines,
      allPages: true,
    });
  }

  render() {
    const { failedEvents, incomingEvents, eventsFetching, machines, machinesFetching } = this.props;
    return (
      <div className="main wide">
        <div className="container">
          <div className="columns">
            <div className="box-container is-half column">
              <h6 className="title condensed has-text-primary is-7">Recently Failed Events</h6>
              <DataTable
                data={failedEvents.slice(0, 10)}
                row={DashboardEvent}
                headers={[{ label: 'Name' }, { label: 'Patching Status' }]}
                isLoading={eventsFetching}
              />
            </div>
            <div className="box-container is-half column">
              <h6 className="title condensed has-text-primary is-7">Incoming Events</h6>
              <DataTable
                data={incomingEvents.slice(0, 10)}
                row={DashboardEvent}
                headers={[{ label: 'Name' }, { label: 'Start Time' }]}
                isLoading={eventsFetching}
              />
            </div>
          </div>
          <div className="columns">
            <div className="box-container is-half column">
              <h6 className="title condensed has-text-primary is-7">Machines not in a plan</h6>
              <DataTable
                data={machines.slice(0, 10)}
                row={DashboardMachine}
                headers={[{ label: 'Name' }, { label: 'Status' }]}
                isLoading={machinesFetching}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    events = { items: [], isFetching: false, error: null },
    machines = { items: [], isFetching: false, error: null },
  } = state;

  return {
    failedEvents: events.list.items.filter(element => element.patching_status === 'DONE_ERROR'),
    incomingEvents: events.list.items.filter(element => element.patching_status === 'NEW'),
    eventsFetching: events.list.isFetching,
    eventsError: events.list.error,
    machines: machines.items.filter(element => !element.plans || element.plans.length === 0),
    machinesFetching: machines.isFetching,
    machinesError: machines.error,
  };
};

export default connect(mapStateToProps)(DashboardPage);

/**
 * Copyright 2021-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DynamicPlanInputSchema } from '../../../views/plans/components/form/schema';
import { DynamicPlan, HookType } from '../../../graphql/generated-types';
import { rebootPolicyOptions } from '../schema/rebootPolicy';
import { Modal } from '../../modals/Modal';
import {
  Button,
  ButtonsContainer,
  InputField,
  ControlledField,
  HookInput,
  RegionsInput,
  Select,
  UncontrolledField,
} from '../index';
import TagConditionPlaceholders from './TagConditions/TagConditionPlaceholders';
import TagList from './MachineTagList';
import IAMRole from '../IAMRole';

const tagSelectionMethodOptions = [
  { value: 'conditions', label: 'Condition (recommended)' },
  { value: 'list', label: 'List' },
];

type MachineTagModalProps = {
  visible: boolean;
  hideModal: () => void;
  addMachineTag: (machineTag: DynamicPlan) => void;
  data: DynamicPlan;
};

function MachineTagModal({ visible, hideModal, addMachineTag, data }: MachineTagModalProps) {
  const methods = useForm<DynamicPlan>({
    defaultValues: data,
    resolver: zodResolver(DynamicPlanInputSchema),
  });
  const formValues = methods.watch();

  const [option, setOption] = React.useState('');

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    return methods.handleSubmit(async values => {
      addMachineTag(values);
    })(event);
  }

  React.useEffect(() => {
    if (formValues?.tag_condition?.expression) {
      setOption(tagSelectionMethodOptions[0].value);
    } else if (formValues?.tag_list) {
      setOption(tagSelectionMethodOptions[1].value);
    }
  }, [formValues?.tag_condition?.expression, formValues?.tag_list, option]);

  function handleOptionChanged(value: string) {
    if (value === tagSelectionMethodOptions[0].value) {
      methods.setValue('tag_list', undefined);
    } else if (value === tagSelectionMethodOptions[1].value) {
      methods.setValue('tag_condition', undefined);
    } else {
      methods.setValue('tag_condition', undefined);
      methods.setValue('tag_list', undefined);
    }

    setOption(value);
  }

  return (
    <Modal visible={visible} cancelAction={hideModal} title="Add Machines tag" size="regular">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className="form-group field field-object">
            <UncontrolledField name="Tag selection method" label="Tag selection method *">
              <Select
                name="Tag selection method"
                value={option}
                onChange={handleOptionChanged}
                elements={tagSelectionMethodOptions}
              />
            </UncontrolledField>
            {option === tagSelectionMethodOptions[0].value && (
              <>
                <InputField
                  name="tag_condition.expression"
                  label="Expression"
                  registerProps={{ required: true }}
                />
                <ControlledField
                  name="tag_condition.placeholders"
                  label="Placeholders"
                  defaultValue={[]}
                  Component={TagConditionPlaceholders}
                />
              </>
            )}
            {option === tagSelectionMethodOptions[1].value && (
              <ControlledField
                name="tag_list"
                label="Tag list"
                defaultValue={[]}
                Component={TagList}
                rules={{
                  required: true,
                }}
              />
            )}
            <ControlledField
              name="reboot_policy"
              label="Reboot Policy"
              Component={Select}
              elements={rebootPolicyOptions}
            />
            {/* mock field used to display AWS as a provider */}
            <UncontrolledField label="Provider">
              <Select
                value="aws"
                elements={[{ value: 'aws', label: 'AWS' }]}
                onChange={() => {}}
                disabled
              />
            </UncontrolledField>
            <InputField name="s3_custom_script" label="Custom Update Command (S3 URL)" />
            <InputField
              name="update_existing"
              label="Update existing"
              description="Set true to update existing machines."
              type="checkbox"
            />
            <InputField
              name="include_stopped"
              label="Include stopped"
              description="Set true to fetch stopping/stopped EC2 instances."
              type="checkbox"
            />
            <ControlledField
              name="iam_role_list"
              label="IAM Role"
              Component={IAMRole}
              defaultValue={[]}
              rules={{ required: true }}
            />
            <ControlledField name="regions" Component={RegionsInput} defaultValue={[]} />
            <ControlledField
              name="pre_host_hooks"
              label="Pre hooks"
              Component={HookInput}
              hookTypes={[HookType.Aws, HookType.Azure, HookType.Script]}
              defaultValue={[]}
            />
            <ControlledField
              name="post_host_hooks"
              label="Post hooks"
              Component={HookInput}
              hookTypes={[HookType.Aws, HookType.Azure, HookType.Script]}
              defaultValue={[]}
            />
          </div>
          <ButtonsContainer>
            <Button
              type="secondary"
              htmlType="submit"
              icon="send"
              disabled={!formValues?.iam_role_list?.length || option === ''}
            >
              Save
            </Button>
          </ButtonsContainer>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default MachineTagModal;

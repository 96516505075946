/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

type TextareaProps = {
  name: string;
  registerProps?: RegisterOptions;
  error?: string;
};

function Textarea({ name, registerProps, ...props }: TextareaProps) {
  const { register } = useFormContext();

  return (
    <>
      <div className="control">
        <div className="is-fullwidth">
          <textarea
            id={name}
            className="textarea"
            required={!!registerProps?.required}
            {...register(name, registerProps)}
            {...props}
          />
        </div>
      </div>
    </>
  );
}

export default Textarea;

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';

import { NotificationManager } from 'react-notifications';
import { APIContext } from '../auth/APIContext';
import { LoadingHOC } from '../loading/Loading';

export class CsvExportButton extends Component {
  static contextType = APIContext;
  state = { loading: false };

  async submit() {
    const { requester } = this.context;
    const { ids, query, queryName } = this.props;
    const customerId = localStorage.getItem('currentCustomer') || '';
    const customerName = localStorage.getItem('currentCustomerName') || '';
    this.setState({ loading: true });

    try {
      const response = await requester({
        query,
        input: {
          customer_id: customerId,
          customer_name: customerName,
          ids: ids,
        },
      });

      if (response?.data[queryName] && response?.data[queryName].url) {
        window.open(response.data[queryName].url, '_blank');
      } else {
        NotificationManager.error('Something went wrong.');
      }
      this.setState({ loading: false });
    } catch (err) {
      err.errors &&
        err.errors.length > 0 &&
        NotificationManager.error(err.errors[0].message || 'Something went wrong');
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="level-item">
        <LoadingHOC loading={loading}>
          <button className="button" onClick={() => this.submit()}>
            Export CSV
          </button>
        </LoadingHOC>
      </div>
    );
  }
}

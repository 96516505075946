/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { getUpdateLogOutput } from '../graphql/getUpdateLogOutput';
import { getEventReport } from '../graphql/getEventReport';
import { getEventScanReport } from '../graphql/getEventScanReport';
import { eventScan } from '../graphql/eventScan';

export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_FAILURE = 'REPORT_FAILURE';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const REPORT_NOT_AVAILABLE = 'REPORT_NOT_AVAILABLE';

export const UPDATE_LOG_REQUEST = 'UPDATE_LOG_REQUEST';
export const UPDATE_LOG_FAILURE = 'UPDATE_LOG_FAILURE';
export const UPDATE_LOG_SUCCESS = 'UPDATE_LOG_SUCCESS';

export const SCAN_REPORT_REQUEST = 'SCAN_REPORT_REQUEST';
export const SCAN_REPORT_SUCCESS = 'SCAN_REPORT_SUCCESS';
export const SCAN_REPORT_FAILURE = 'SCAN_REPORT_FAILURE';
export const CLEAR_SCAN_REPORT = 'CLEAR_SCAN_REPORT';

export const SCAN_EVENT_REQUEST = 'SCAN_EVENT_REQUEST';
export const SCAN_EVENT_SUCCESS = 'SCAN_EVENT_SUCCESS';
export const SCAN_EVENT_FAILURE = 'SCAN_EVENT_FAILURE';

const reportRequest = () => ({ type: REPORT_REQUEST });

const reportSuccess = payload => {
  return typeof payload.error === 'string'
    ? { type: REPORT_FAILURE, error: payload.error }
    : { type: REPORT_SUCCESS, report: payload };
};

const reportFailure = error => ({
  type: REPORT_FAILURE,
  error,
});

export function getReport(dispatch, myInit, requester) {
  dispatch(reportRequest());

  return (async () => {
    try {
      const response = await requester({ query: getEventReport, input: myInit });

      dispatch(reportSuccess(response.data.eventReport));
    } catch (err) {
      err && err.errors && err.errors[0]
        ? dispatch(
            reportFailure(
              err.errors[0].message === 'Request failed with status code 404'
                ? 'There is no report for this event'
                : err.errors[0].message,
            ),
          )
        : dispatch(reportFailure('Network error'));
    }
  })();
}

export const clearReport = () => ({ type: CLEAR_REPORT });

export const informNoReport = () => ({ type: REPORT_NOT_AVAILABLE });

const updateLogRequest = () => ({ type: UPDATE_LOG_REQUEST });

const updateLogSuccess = payload => ({
  type: UPDATE_LOG_SUCCESS,
  payload,
});

const updateLogFailure = error => ({
  type: UPDATE_LOG_FAILURE,
  error,
});

export function getUpdateLog(dispatch, myInit, requester, callback) {
  dispatch(updateLogRequest());

  return (async () => {
    try {
      const response = await requester({ query: getUpdateLogOutput, input: myInit });

      if (response && response.data && response.data.updateLogOutputWithPartials) {
        callback(response.data.updateLogOutputWithPartials.output);
        dispatch(updateLogSuccess(response.data.updateLogOutputWithPartials.output));
      }
    } catch (err) {
      callback();
      err && err.errors && err.errors[0]
        ? dispatch(updateLogFailure(err.errors[0].message))
        : dispatch(updateLogFailure('Network error'));
    }
  })();
}

const scanReportRequest = () => ({ type: SCAN_REPORT_REQUEST });

const scanReportSuccess = payload => ({
  type: SCAN_REPORT_SUCCESS,
  payload,
});

const scanReportFailure = error => ({
  type: SCAN_REPORT_FAILURE,
  error,
});

export function getScanEventReport(dispatch, myInit, requester, callback) {
  dispatch(scanReportRequest());

  return (async () => {
    try {
      const response = await requester({ query: getEventScanReport, input: myInit });

      if (response && response.data && response.data.eventScanReport) {
        callback(response.data.eventScanReport);
        dispatch(scanReportSuccess(response.data.eventScanReport));
      }
    } catch (err) {
      callback(null, err);
      err && err.errors && err.errors[0]
        ? dispatch(scanReportFailure(err.errors[0].message))
        : dispatch(scanReportFailure('Network error'));
    }
  })();
}

export const clearScanReport = () => ({ type: CLEAR_SCAN_REPORT });

const scanEventRequest = () => ({ type: SCAN_EVENT_REQUEST });

const scanEventSuccess = payload => ({
  type: SCAN_EVENT_SUCCESS,
  scanEventId: payload.id,
});

const scanEventFailure = error => ({
  type: SCAN_EVENT_FAILURE,
  error,
});

export function createScanEventReport(dispatch, myInit, requester, callback) {
  dispatch(scanEventRequest());

  return (async () => {
    try {
      const response = await requester({ query: eventScan, input: myInit });
      if (response && response.data && response.data.eventScan) {
        callback(true);
        dispatch(scanEventSuccess(response.data.eventScan));
      }
    } catch (err) {
      callback(false, err);
      err && err.errors && err.errors[0]
        ? dispatch(scanEventFailure(err.errors[0].message))
        : dispatch(scanEventFailure('Network error'));
    }
  })();
}

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal } from '../../../commonComponents';
import FormContainer from '../../../containers/form/FormContainer';
import { NotificationManager } from 'react-notifications';
import { partialPatch } from '../../../graphql/partialPatch';
import { APIContext } from '../../../commonComponents/auth/APIContext';
import { showErrorMessage } from '../../../utils/notifications';

export default class EventPartialPatching extends React.Component {
  constructor() {
    super();

    this.state = {
      manualStatuses: ['Success_manual', 'Failed_manual'],
      show: false,
      isLoading: false,
    };
  }
  static contextType = APIContext;

  showPopup = () => this.setState({ show: true });
  closePopup = () => this.setState({ show: false });

  submit = async formData => {
    const { requester } = this.context;
    this.setState({ isLoading: true });

    const { event } = this.props;
    let partialPatchInput = {
      event_id: event.id,
      customer_id: localStorage.getItem('currentCustomer'),
      pre_hooks:
        formData.partial_pre_hooks &&
        formData.partial_pre_hooks.map((el, index) =>
          parseInt(typeof el !== 'string' ? index : el.split(' ')[0]),
        ),
      post_hooks:
        formData.partial_post_hooks &&
        formData.partial_post_hooks.map((el, index) =>
          parseInt(typeof el !== 'string' ? index : el.split(' ')[0]),
        ),
      machines:
        formData.partial_machines &&
        formData.partial_machines.map(el => ({
          id: el.id,
          no_patch: el.no_patch || false,
          pre_host_hooks:
            el.pre_host_hooks &&
            el.pre_host_hooks.map((el, index) =>
              parseInt(typeof el !== 'string' ? index : el.split(' ')[0]),
            ),
          post_host_hooks:
            el.post_host_hooks &&
            el.post_host_hooks.map((el, index) =>
              parseInt(typeof el !== 'string' ? index : el.split(' ')[0]),
            ),
        })),
    };
    try {
      const response = await requester({
        query: partialPatch,
        input: { input: partialPatchInput },
      });

      if (response?.data) {
        NotificationManager.success('Partial patch started!');
        this.closePopup();
        this.props.getEvent(event.id);
      }
    } catch (err) {
      err?.errors?.forEach(e => showErrorMessage(e?.message));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { event } = this.props;

    return (
      <React.Fragment>
        <button className="button tag" onClick={this.showPopup}>
          Run Partial Patching
        </button>
        <Modal visible={this.state.show} title="Partial patching" cancelAction={this.closePopup}>
          You are about to run partial patching for event {event.id}
          <FormContainer
            parent={'events'}
            disabled={this.state.isLoading}
            schema={[
              {
                type: 'object',
                properties: {
                  partial_pre_hooks: { type: 'array', title: 'Pre hooks' },
                  partial_post_hooks: { type: 'array', title: 'Post hooks' },
                  partial_machines: {
                    type: 'array',
                    title: 'Machines',
                  },
                },
              },
            ]}
            type="edit"
            formData={{
              partial_pre_hooks: event.pre_hooks === null ? [] : event.pre_hooks,
              partial_post_hooks: event.post_hooks === null ? [] : event.post_hooks,
              partial_machines: event.machines === null ? [] : event.machines,
            }}
            onSubmit={this.submit}
            onBack={this.closePopup}
            terminalDisabled={true}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Component } from 'react';

class ForceHttps extends Component {
  isProduction() {
    return (
      process.env.NODE_ENV === 'production' && window.location.host === 'REPLACE_WITH_YOUR_HOST'
    );
  }

  forceHttps() {
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.protocol === 'http:' &&
      this.isProduction()
    ) {
      window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
    }
  }

  componentWillMount() {
    this.forceHttps();
  }

  render() {
    return this.props.children;
  }
}

export { ForceHttps };

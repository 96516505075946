/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

type ErrorProps = { label: string };
type ErrorTypeToMessageMap = { [key: string]: (props: ErrorProps) => string };

export const genericError = 'Invalid input';
export const errorTypeToMessage: ErrorTypeToMessageMap = {
  required: ({ label }: ErrorProps) => `${label} is required`,
};

export const capitalize = (str: string) => str && str[0].toUpperCase() + str.slice(1);

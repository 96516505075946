/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ListActionsFactory } from '../../factories';
import { approveEvent } from '../../graphql/approveEvent';

export const EVENT_APPROVE = 'EVENT_APPROVE';
export const EVENT_APPROVE_SUCCESS = 'EVENT_APPROVE_SUCCESS';
export const EVENT_APPROVE_FAILURE = 'EVENT_APPROVE_FAILURE';
export const EVENT_PIPELINE = 'EVENT_PIPELINE';
export const EVENT_PIPELINE_SUCCESS = 'EVENT_PIPELINE_SUCCESS';
export const EVENT_PIPELINE_FAILURE = 'EVENT_PIPELINE_FAILURE';

const { fetchData, removeItem, actions, resetErrors } = ListActionsFactory({
  namespace: 'EVENTS',
  api: 'aws',
  path: '/events',
});

const eventApproveInit = () => ({ type: EVENT_APPROVE });

const eventApproveSuccess = () => ({ type: EVENT_APPROVE_SUCCESS });

const eventApproveFailure = error => ({
  type: EVENT_APPROVE_FAILURE,
  error,
});

export function eventApprove(dispatch, myInit, requester, refreshEvents) {
  eventApproveInit();
  return (async () => {
    try {
      await requester({ query: approveEvent, input: myInit });

      refreshEvents();
      dispatch(eventApproveSuccess());
    } catch (err) {
      err && err.errors && err.errors[0]
        ? dispatch(eventApproveFailure(err.errors[0].message))
        : dispatch(eventApproveFailure('Network error'));
    }
  })();
}

export { fetchData as fetchEvents, removeItem as removeEvent, resetErrors, actions };

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const getHookOutput = `query HookOutput($customer_id: String!, $event_id: String!, $hook_name: String!) {
    hookOutput(customer_id: $customer_id, event_id: $event_id, hook_name: $hook_name) { 
      code
      message
      hook {
        type
        source
        method
        status_code
      }
    }
  }
  `;

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { NotificationManager } from 'react-notifications';
import diff from 'deep-diff';
import { fetchAPIKeys, editApiKey } from '../actions';
import { apiKeySchema } from '../apiKeysSchema';
import ApiKeyDetailsHeader from '../components/ApiKeyDetailsHeader';
import FormContainer from '../../../containers/form/FormContainer';
import { ROUTES } from '../../../constants/routes';
import { getAPIKey } from '../../../graphql/getAPIKey';
import { putAPIKey } from '../../../graphql/putAPIKey';
import { Errors } from '../../../commonComponents/form/Validation';
import ApiKeyDetailsPermissions from '../components/ApiKeyDetailsPermissions';
import { ButtonNew } from '../../../commonComponents';
import { Param } from '../../../commonComponents/layout/Param';
import { APIContext } from '../../../commonComponents/auth/APIContext';

class ApiKeysDetails extends Component {
  state = {
    formType: 'details',
  };
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;

    const { dispatch, apiKey, routeParams } = this.props;
    const customer_id = localStorage.getItem('currentCustomer');

    if (!apiKey) {
      const params = {
        customer_id: customer_id,
        id: routeParams.splat,
      };

      fetchAPIKeys({
        dispatch,
        myInit: params,
        requester,
        operation: getAPIKey,
      });
    }
  }

  formTypeChange = () => {
    if (this.state.formType === 'details') {
      this.setState({ formType: 'edit' });
    } else {
      this.setState({ formType: 'details' });
    }
  };

  onSubmit = formData => {
    const { requester } = this.context;
    const { dispatch, apiKey } = this.props;
    formData.id = apiKey.id;

    const changes = diff(formData, apiKey);
    changes.forEach(
      e => e.kind === 'E' && !e.lhs && e.lhs !== false && e.rhs && (formData[e.path[0]] = null),
    );

    const callback = () => {
      NotificationManager.success(`Successfully updated API key [${apiKey.id}] details`);
      browserHistory.push(`${ROUTES.apiKeys.apiKeys}?refresh=true`);
    };

    editApiKey({
      dispatch,
      myInit: { input: formData },
      requester,
      operation: putAPIKey,
      callback,
    });
  };

  getFormData(apiKey) {
    const { id, created_at, updated_at, ...formData } = apiKey;
    return formData;
  }

  render() {
    const { apiKey, error, isFetching } = this.props;
    const { formType } = this.state;
    const formData = apiKey ? this.getFormData(apiKey) : {};
    apiKeySchema.properties.id = {};

    return (
      <div className="main wide">
        {apiKey ? (
          <React.Fragment>
            <ApiKeyDetailsHeader
              apiKey={apiKey}
              formType={formType}
              formTypeChange={this.formTypeChange}
            />

            {error && error.length > 0 && Errors({ errors: error })}

            {formType === 'edit' ? (
              <FormContainer
                parent={ROUTES.apiKeys.apiKeys}
                schema={apiKeySchema}
                className="details-form"
                type="edit"
                onSubmit={this.onSubmit}
                onBack={this.formTypeChange}
                formData={formData}
                disabled={isFetching}
              />
            ) : (
              <React.Fragment>
                <div className="box">
                  <div className="columns">
                    <Param label="Name" value={apiKey.name} />
                    <Param label="Description" value={apiKey.description} />
                    <Param label="Expires At" value={apiKey.expires_at} />
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Assignments</div>
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="level-item">
                            <ButtonNew
                              className="is-pulled-right"
                              href={`${ROUTES.permissions.assignmentsNew}?type=API_KEY&subject=${apiKey.id}`}
                              label="New Assignment"
                            />
                          </div>
                        </div>
                      </div>
                      <ApiKeyDetailsPermissions apiKey={apiKey} />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

ApiKeysDetails.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  isFetching: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { apiKeys } = state;
  const apiKeyId = ownProps.routeParams.splat || null;

  return {
    apiKey: apiKeys.items.find(apiKey => apiKey.id === apiKeyId),
    error: apiKeys.error,
    isFetching: apiKeys.isFetching,
  };
};

export default connect(mapStateToProps)(ApiKeysDetails);

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import Chips, { Chip } from 'react-chips';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { PartialMachineModal } from './PartialMachineModal';

const EditHandle = ({ onEdit }) => (
  <span className="edit-button" onClick={onEdit}>
    <i className="fa fa-edit" />
  </span>
);

const MachineItem = SortableElement(({ machine, onRemove, chipTheme, onEdit }) => {
  return (
    <div>
      <EditHandle onEdit={onEdit} />
      <Chip theme={chipTheme} onRemove={onRemove}>
        {machine.id}
      </Chip>
    </div>
  );
});

const MachinesList = SortableContainer(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        popup: false,
      };
      this.onChipsChange = this.onChipsChange.bind(this);
      this.renderChip = this.renderChip.bind(this);
    }

    onChipsChange(newValue) {
      const { onChange, initMachines } = this.props,
        newMachineIndex = newValue.map(el => typeof el).indexOf('string');

      if (newMachineIndex >= 0) {
        const newMachine =
          initMachines[
            initMachines.map(el => el.id).indexOf(newValue[newMachineIndex].split(' ')[0])
          ];

        this.setState({
          popup: true,
          tempValues: newValue,
          newMachine: { ...newMachine, order: newValue.length },
        });
      } else {
        this.setState(
          {
            popup: false,
          },
          onChange(newValue),
        );
      }
    }

    renderChip(chip, theme) {
      const { onChipRemove, machines } = this.props;
      return (
        <MachineItem
          machine={chip}
          index={chip.order - 1}
          chipTheme={theme}
          onRemove={() => {
            onChipRemove(chip);
          }}
          onEdit={() => {
            this.setState({
              popup: true,
              tempValues: machines,
              newMachine: chip,
            });
          }}
        />
      );
    }

    render() {
      const { machines, suggestions } = this.props;
      const { popup, tempValues, newMachine } = this.state;
      return (
        <React.Fragment>
          {popup && (
            <PartialMachineModal
              machines={tempValues}
              newMachine={newMachine}
              addMachine={this.onChipsChange}
            />
          )}
          <Chips
            value={machines}
            suggestions={suggestions}
            onChange={this.onChipsChange}
            renderChip={this.renderChip}
            getChipValue={chip => chip.id}
            placeholder="Start typing a machine id here"
            theme={{
              ...Chips.defaultProps.theme,
              chipsContainer: {
                ...Chips.defaultProps.theme.chipsContainer,
                display: 'block',
                width: '100%',
              },
            }}
            chipTheme={{ chip: { display: 'inline-block' } }}
            fromSuggestionsOnly={true}
          />
        </React.Fragment>
      );
    }
  },
);

class MachinesSortable extends Component {
  constructor(props) {
    super(props);
    let order = 1;
    this.state = { machines: [] };
    const currentList = props.formData || [];
    this.state = {
      initMachines: currentList.map(machine => {
        return { ...machine, order: order++ };
      }),
      machines: currentList.map(machine => {
        return { ...machine, order: order++ };
      }),
      suggestions: props.formData
        .map(machine => ({
          ...machine,
          ssm_machine_id: machine.access ? machine.access.ssm_machine_id : null,
        }))
        .map(({ id, name, ssm_machine_id }) => `${id} (name:${name}, ssm: ${ssm_machine_id})`),
    };
    this.onChipRemove = this.onChipRemove.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(props) {
    this.onChange(props.formData);
  }

  onChipRemove(chip) {
    this.onChange(oldState => {
      let idx = chip.order - 1;
      let left = oldState.machines.slice(0, idx);
      let right = oldState.machines.slice(idx + 1);
      return [...left, ...right];
    });
  }

  onChange(items) {
    this.setState(
      {
        machines: items,
      },
      () => {
        this.props.onChange(items);
      },
    );
  }

  render() {
    const { machines, initMachines } = this.state;
    return (
      <MachinesList
        machines={machines}
        initMachines={initMachines}
        suggestions={this.state.suggestions}
        fromSuggestionsOnly={true}
        onChipRemove={this.onChipRemove}
        onChange={this.onChange}
        useDragHandle={true}
        style={{ width: '100%' }}
      />
    );
  }
}

export default MachinesSortable;

import { NotificationEvent, NotificationLevel } from '../../graphql/generated-types';

export const NOTIFICATION_EVENTS_MAPPING = [
  { label: 'Error', value: NotificationEvent.Error },
  { label: 'New event created', value: NotificationEvent.EventNew },
  { label: 'Patching hook finished', value: NotificationEvent.HookFinish },
  { label: 'Patching hook started', value: NotificationEvent.HookStart },
  { label: 'Instance hook finished', value: NotificationEvent.HostHookFinish },
  { label: 'Instance hook started', value: NotificationEvent.HostHookStart },
  { label: 'Incoming patching', value: NotificationEvent.IncomingPatching },
  { label: 'Instance access error', value: NotificationEvent.MachineAccessErr },
  { label: 'Instance not in time window', value: NotificationEvent.MachineNotInWindow },
  { label: 'Instance update finished', value: NotificationEvent.MachinePatchingFinish },
  { label: 'Instance update started', value: NotificationEvent.MachinePatchingStart },
  { label: 'Not approved patching', value: NotificationEvent.NotApprovedPatching },
  { label: 'Partial patching finished', value: NotificationEvent.PartialPatchingFinish },
  { label: 'Partial patching started', value: NotificationEvent.PartialPatchingStart },
  { label: 'Patching finished', value: NotificationEvent.PatchingFinish },
  { label: 'Patching started', value: NotificationEvent.PatchingStart },
  { label: 'Deleted Pipeline', value: NotificationEvent.PipelineDeleted },
  { label: 'Plan modification', value: NotificationEvent.PipelineModification },
  { label: 'New pipeline created', value: NotificationEvent.PipelineNew },
  { label: 'Deleted Plan', value: NotificationEvent.PlanDeleted },
  { label: 'Pipeline modification', value: NotificationEvent.PlanModification },
  { label: 'New plan created', value: NotificationEvent.PlanNew },
] as const;

export const NOTIFICATION_LEVELS_MAPPING = [
  { label: 'Debug', value: NotificationLevel.Debug },
  { label: 'Error', value: NotificationLevel.Error },
  { label: 'Info', value: NotificationLevel.Info },
  { label: 'Success', value: NotificationLevel.Success },
  { label: 'Warn', value: NotificationLevel.Warn },
] as const;

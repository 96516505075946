/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { plans } from '../views/plans/reducer';
import { machines } from '../views/machines/reducer';
import { createNew } from '../reducers/createNew';
import { notificationGroups } from '../views/notificationGroups/reducer';
import { events } from '../views/events';
import { report } from '../reducers/report';
import { pipelines } from '../views/pipelines/reducer';
import { permissions } from '../views/permissions/reducer';
import { roles } from '../views/roles/reducer';
import { apiKeys } from '../views/apiKeys/reducer';

const logger = createLogger();
const rootReducer = combineReducers({
  createNew,
  plans,
  machines,
  notificationGroups,
  events,

  report,
  pipelines,
  permissions,
  roles,
  apiKeys
});

const initialState = {};

export default function configureStore() {
  let store;

  if (module.hot) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(thunkMiddleware, logger),
        window.devToolsExtension ? window.devToolsExtension() : f => f,
      ),
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(thunkMiddleware),
        f => f,
      ),
    );
  }

  return store;
}

/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Text from 'react-format-text';
import JSONPretty from 'react-json-pretty';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import {
  clearReport,
  getUpdateLog,
  getScanEventReport,
  createScanEventReport,
  clearScanReport,
} from '../../../actions/report';
import { fetchEvents } from '../actions';
import { getEvent } from '../../../graphql/getEvent';
import {
  PATCHING_STATES_MAPPING,
  ERR_MSG_NOT_FOUND,
  getMessageFromErrorResponse,
} from '../../../utils';
import {
  FormGroup,
  Modal,
  Panel,
  ConfirmModal,
  FormattedDate,
  TZFormattedDate,
  Metadata,
} from '../../../commonComponents';
import { Parallel } from '../../plans/components/ParallelEl';
import { EventMachineItem } from '../components/EventMachine';
import EventReport from '../components/EventReport';
import HookStatus from '../components/EventHookStatus';
import HookOutput from '../components/HookOutput';
import { LoadingHOC } from '../../../commonComponents/loading/Loading';
import { approveEvent } from '../../../graphql/approveEvent';
import { changePatchingStatus } from '../../../graphql/changePatchingStatus';
import { assignMachine } from '../../../graphql/assignMachine';
import EventPartialPatching from '../components/EventPartialPatching';
import { removeEmptySelectValues } from '../../../utils/form';
import { showApiError } from '../../../utils/notifications';
import { withAuth0 } from '@auth0/auth0-react';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const Param = ({ label, value, className, children }) => (
  <div className={`column ${className} `}>
    <strong className="is-desc-label">{label}</strong>
    <br />
    {children ? children : value ? value : <i className="is-italic">No data provided</i>}
  </div>
);

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportVisible: false,
      machineReportId: '',
      fetchingReport: false,
      showLogDisabled: false,
      updateLogs: {},
      visibleMachineLog: null,
      showCreateScanEvent: false,
      showScanReport: false,
      open: false,
      approving: false,
    };
    this.getMachineReport = this.getMachineReport.bind(this);
    this.getUpdateLog = this.getUpdateLog.bind(this);
    this.hideReport = this.hideReport.bind(this);
    this.hideEvent = this.hideEvent.bind(this);
    this.showLog = this.showLog.bind(this);
    this.hideLog = this.hideLog.bind(this);
    this.getEvent = this.getEvent.bind(this);

    this.createScanEvent = this.createScanEvent.bind(this);
    this.scanReportHandler = this.scanReportHandler.bind(this);
  }
  static contextType = APIContext;
  static offset = new Date().getTimezoneOffset();

  loadData = props => {
    const { event, routeParams, dispatch } = props;
    dispatch(clearReport());
    dispatch(clearScanReport());
    (!event || (!!event.pipeline_id && !event.pipeline)) && this.getEvent(routeParams.splat);
  };

  componentDidMount() {
    this.loadData(this.props);
    const { user } = this.props.auth0;
    this.setState({ email: user.email });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.routeParams.splat !== this.props.routeParams.splat) {
      this.loadData(newProps);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearReport());
    dispatch(clearScanReport());
    if (this.reportTimeoutId !== undefined) {
      clearTimeout(this.reportTimeoutId);
    }
  }

  getEvent(id) {
    const { requester } = this.context;
    const { dispatch } = this.props;
    const eventInit = {
      customer_id: localStorage.getItem('currentCustomer'),
      event_id: id,
    };

    fetchEvents({ dispatch, myInit: eventInit, requester, operation: getEvent });
  }

  approveEvent = eventId => {
    const { requester } = this.context;
    const { routeParams } = this.props;
    const id = eventId || routeParams.splat;
    this.setState({ approving: true });

    return (async () => {
      try {
        const response = await requester({
          query: approveEvent,
          input: {
            customer_id: localStorage.getItem('currentCustomer'),
            event_id: id,
          },
        });

        if (response && response.data && response.data.approveEvent.id) {
          this.getEvent(id);
          this.setState({ approving: false });
        } else {
          NotificationManager.error('Something went wrong.');
          this.setState({ approving: false });
        }
      } catch (err) {
        showApiError(err);
        this.setState({ approving: false });
      }
    })();
  };

  goBack() {
    browserHistory.push('/events');
  }

  hideReport() {
    this.setState({
      reportVisible: false,
      machineReportId: '',
    });
  }

  hideEvent() {
    const { dispatch } = this.props;

    this.setState({
      showLogDisabled: false,
    });
    dispatch(clearReport());
    this.props.hide();
  }

  checkMachineInReport(machineId) {
    let { report } = this.props;
    if (!report) {
      return false;
    }
    if (report.patched_machines !== undefined && report.not_patched_machines !== undefined) {
      // new report format
      report = report.patched_machines;
    }

    return (
      report.hasOwnProperty(machineId) &&
      typeof report[machineId] === 'object' &&
      Object.keys(report[machineId]).length > 0 &&
      report[machineId]
    );
  }

  getMachineReport(event) {
    if (event) {
      event.persist();
      event.preventDefault();

      this.setState({
        machineReportId: event.target.id,
      });
    }

    const { report, reportError } = this.props;

    if (reportError) {
      NotificationManager.error(reportError);
    } else if (!report) {
      NotificationManager.error('There is no report available for this event.');
    } else if (event && this.checkMachineInReport(event.target.id)) {
      this.setState({
        reportVisible: true,
      });
    } else {
      NotificationManager.error('This machine was not included in report.');
    }
  }

  getUpdateLog(event) {
    const { requester } = this.context;
    const { dispatch } = this.props;

    if (!event) return;
    event.persist();
    event.preventDefault();

    let machine_id = event.target.id;

    if (this.state.updateLogs[machine_id]) {
      this.showLog(machine_id);
    } else {
      const myInit = {
        customer_id: localStorage.getItem('currentCustomer'),
        machine_id: machine_id,
        event_id: this.props.event.id,
      };
      const callback = updateLog => {
        if (updateLog) {
          this.setState(
            {
              updateLogs: {
                ...this.state.updateLogs,
                [machine_id]: updateLog,
              },
            },
            this.showLog(machine_id),
          );
        } else {
          NotificationManager.error('Unable to get update log.');
        }
      };

      getUpdateLog(dispatch, myInit, requester, callback);
    }
  }

  showLog(machine_id) {
    this.setState({ visibleMachineLog: machine_id });
  }

  hideLog() {
    this.setState({ visibleMachineLog: null });
  }

  createScanEvent() {
    const { requester } = this.context;

    const {
      dispatch,
      event: { id },
    } = this.props;
    const myInit = {
      customer_id: localStorage.getItem('currentCustomer'),
      event_id: id,
    };
    const callback = (created, err) => {
      if (created) {
        NotificationManager.success(`Created event ${id}`);
      } else if (err) {
        NotificationManager.error(getMessageFromErrorResponse(err)[0]);
      }
      this.setState({ showCreateScanEvent: false });
    };

    createScanEventReport(dispatch, myInit, requester, callback);
  }

  scanReportHandler(e) {
    const { requester } = this.context;

    e && e.preventDefault();
    const {
      dispatch,
      event: { id, patching_status },
    } = this.props;
    const myInit = {
      event_id: id,
      customer_id: localStorage.getItem('currentCustomer'),
    };
    const callback = (scanReport, err) => {
      this.setState({ isLoadingScanReport: false });
      if (scanReport) {
        this.setState({ showScanReport: true });
        return;
      }

      const errMsg = getMessageFromErrorResponse(err)[0];
      if (errMsg !== ERR_MSG_NOT_FOUND || patching_status !== 'NEW') {
        NotificationManager.error(errMsg);
        return;
      }

      this.setState({ showCreateScanEvent: true });
    };
    this.setState({ isLoadingScanReport: true });
    getScanEventReport(dispatch, myInit, requester, callback);
  }

  manualStatusChange = (eventId, machineId, formData, cb) => {
    const { requester } = this.context;
    this.setState({ approving: true });
    const init = {
      event_id: eventId,
      machine_id: machineId,
      customer_id: localStorage.getItem('currentCustomer'),
      ...formData,
    };

    return (async () => {
      try {
        const response = await requester({
          query: changePatchingStatus,
          input: {
            input: init,
          },
        });

        if (response && response.data && response.data.changePatchingStatus.id) {
          this.getEvent(eventId);
          NotificationManager.success('Machine status changed');
        } else {
          NotificationManager.error('Failed to manually change status');
        }
      } catch (err) {
        showApiError(err);
      }
      cb();
    })();
  };

  assignMachine = (machineId, clear, cb) => {
    const { requester } = this.context;
    const input = {
      event_id: this.props.event.id,
      machine_id: machineId,
      customer_id: localStorage.getItem('currentCustomer'),
      clear: clear,
    };

    return (async () => {
      try {
        const response = await requester({ query: assignMachine, input: { input } });

        if (response && response.data && response.data.assignMachine.id) {
          this.getEvent(this.props.event.id);
        } else {
          NotificationManager.error('Failed to assign machine');
        }
      } catch (err) {
        NotificationManager.error('Failed to assign machine');
      }
      cb();
    })();
  };

  render() {
    const { event = { name: '', patching_status: '' }, isFetching } = this.props;

    const { name: status = event.patching_status || '', className: statusClassName = '' } =
      PATCHING_STATES_MAPPING[event.patching_status] || {};
    const {
      approving,
      assigneeVisible,
      email,
      machineReportId,
      showScanReport,
      showCreateScanEvent,
      reportVisible,
      visibleMachineLog,
      updateLogs,
      showLogDisabled,
    } = this.state;
    const machineReport = this.checkMachineInReport(machineReportId);
    const { pre_hooks = [], post_hooks = [] } = event;
    const hooks = [
      pre_hooks &&
        pre_hooks.map((hook, index) => ({ ...hook, timeType: 'pre', name: `pre_${index}` })),
      post_hooks &&
        post_hooks.map((hook, index) => ({ ...hook, timeType: 'post', name: `post_${index}` })),
    ].flat(1);
    const checkPatchesMsg = `This will create dry-run event based on ${event.id} event.
    When dry-run event will finish, this window will show report from scanning`;
    const jiraWebHook = event.webhook_outputs
      ? event.webhook_outputs.find(hook => hook.payload_type === 'itsm_link')
      : null;
    const isLoadingScanEvent = this.state.isLoadingScanReport || this.state.showCreateScanEvent;

    return (
      <div className="main wide">
        <Modal
          title="Report from scanning event"
          visible={showScanReport}
          cancelAction={() => this.setState({ showScanReport: false })}
        >
          <div>
            <JSONPretty id="json-pretty" data={this.props.scanReport} />
          </div>
        </Modal>

        <ConfirmModal
          visible={showCreateScanEvent}
          hideModal={() =>
            this.setState({
              showCreateScanEvent: false,
            })
          }
          actionFunc={this.createScanEvent}
          actionButtonName="Check patches"
          title="Are you sure?"
          message={checkPatchesMsg}
        />

        {event.errors && (
          <div className="message is-danger">
            <div className="message-body">
              {typeof event.errors === 'string' && <div key="0">{event.errors}</div>}
              {typeof event.errors !== 'string' &&
                event.errors.map((el, index) => (
                  <div key={index}>
                    {el.replace(/_/g, ' ').toUpperCase()}: {el}
                  </div>
                ))}
            </div>
          </div>
        )}

        <>
          {event.pipeline && event.pipeline.length > 0 ? (
            <div className="box is-fullheight">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title">Pipeline</h1>
                  <Link to={`/pipelines/details/${event.pipeline_id}`}>{event.pipeline_id}</Link>
                </div>
                <Param label="Created At">
                  <FormattedDate>{event.created_at}</FormattedDate>
                </Param>
                <Param label="Updated At">
                  <FormattedDate>{event.updated_at}</FormattedDate>
                </Param>
              </div>
              <div className="pipeline-container">
                {event.pipeline.map((event, index) => {
                  const { className: statusClass, name: statusName } =
                    event.patching_status && PATCHING_STATES_MAPPING[event.patching_status];

                  return (
                    <div
                      className={`pipeline-step `}
                      key={index}
                      onClick={e => {
                        e.stopPropagation();
                        browserHistory.push(`/events/details/${event.id}`);
                      }}
                    >
                      {event.name ? event.name : event.id} <br />
                      <span className={`tag ${statusClass}`}>{statusName.toUpperCase()}</span>
                      {event.patching_status === 'NOT_APPROVED' &&
                        (event.policy === 'approval_required' ||
                          event.policy === 'success_or_approval' ||
                          !event.policy) && (
                          <div>
                            <LoadingHOC loading={approving || isFetching}>
                              <button
                                className="button is-primary is-small"
                                onClick={() => this.approveEvent(event.id)}
                              >
                                Approve
                              </button>
                            </LoadingHOC>
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
        <div className="box">
          <div className="columns">
            <div className="column is-three-quarters">
              <h1 className="title"> {event.name ? event.name : event.id}</h1>
              <h2 className="subtitle is-size-5">
                Event status:{' '}
                <div className={`tag ${statusClassName}`} onClick={this.showEventDetails}>
                  {status.toUpperCase()}
                </div>
                {jiraWebHook && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tag is-info"
                    href={jiraWebHook.payload}
                  >
                    {jiraWebHook.payload}
                  </a>
                )}
                {event.patching_status &&
                  event.patching_status !== 'IN_PROGRESS' &&
                  event.patching_status !== 'NEW' &&
                  event.patching_status !== 'NOT_APPROVED' && (
                    <EventPartialPatching
                      event={removeEmptySelectValues(event)}
                      getEvent={this.getEvent}
                    />
                  )}
              </h2>
            </div>
            <Param label="Customer">{event.owner}</Param>
          </div>
        </div>

        <div className="box">
          <div className="columns">
            <Param label="Description" value={event.description} />
            <Param label="Plan">
              {!!event.pipeline_id ? (
                <Link to={`/pipelines/details/${event.pipeline_id}`}>{event.plan_id}</Link>
              ) : (
                <Link to={`/plans/details/${event.plan_id}`}>{event.plan_id}</Link>
              )}
            </Param>
            <Param label="Location" value={event.time_zone} />
            <Param label="Start Time (Location)">
              <TZFormattedDate timezone={event.time_zone}>
                {event.window_start_time}
              </TZFormattedDate>
            </Param>
            <Param label="End Time (Location)">
              <TZFormattedDate timezone={event.time_zone}>{event.window_end_time}</TZFormattedDate>
            </Param>
            <Param label="Start Time (UTC)">
              <FormattedDate>{event.window_start_time}</FormattedDate>
            </Param>
            <Param label="End Time (UTC)">
              <FormattedDate>{event.window_end_time}</FormattedDate>
            </Param>
          </div>
        </div>

        {machineReportId && machineReport && (
          <Modal
            visible={reportVisible}
            cancelAction={this.hideReport}
            title={visibleMachineLog ? 'Update Log Output' : 'Update Log Report'}
          >
            <form>
              {machineReport.installed && (
                <FormGroup controlId="hash">
                  <Panel collapsible defaultExpanded={true} header="Installed" eventKey="1">
                    <JSONPretty id="json-pretty" data={machineReport.installed} />
                  </Panel>
                </FormGroup>
              )}
              {machineReport.available && (
                <FormGroup controlId="hash">
                  <Panel collapsible defaultExpanded={true} header="Available" eventKey="1">
                    <JSONPretty id="json-pretty" data={machineReport.available} />
                  </Panel>
                </FormGroup>
              )}
              {machineReport.removed && (
                <FormGroup controlId="hash">
                  <Panel collapsible defaultExpanded={true} header="Removed" eventKey="1">
                    <JSONPretty id="json-pretty" data={machineReport.removed} />
                  </Panel>
                </FormGroup>
              )}
              {machineReport.updated && (
                <FormGroup controlId="hash">
                  <Panel collapsible defaultExpanded={true} header="Updated" eventKey="1">
                    <JSONPretty id="json-pretty" data={machineReport.updated} />
                  </Panel>
                </FormGroup>
              )}
              {machineReport.failed && (
                <FormGroup controlId="hash">
                  <Panel collapsible defaultExpanded={true} header="Failed" eventKey="1">
                    <JSONPretty id="json-pretty" data={machineReport.failed} />
                  </Panel>
                </FormGroup>
              )}
            </form>
          </Modal>
        )}

        {visibleMachineLog && (
          <Modal
            visible={visibleMachineLog != null}
            cancelAction={this.hideLog}
            title="Update Log Output"
          >
            <form>
              <FormGroup>
                <Text className="terminal">{updateLogs[visibleMachineLog]}</Text>
              </FormGroup>
            </form>
          </Modal>
        )}

        <div className="columns">
          <div className="column">
            <div className="box is-fullheight">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title is-size-4">Machines</h1>
                  </div>
                </div>
                <div className="level-right">
                  {['NEW', 'IN_PROGRESS'].includes(event.patching_status) &&
                    event.dry_run !== true && (
                      <button
                        className="button is-small is-action is-primary has-besel "
                        onClick={this.scanReportHandler}
                        disabled={isLoadingScanEvent}
                      >
                        {isLoadingScanEvent && <span className="loader element is-loading" />}
                        Check available patches
                      </button>
                    )}
                </div>
              </div>

              {event.machines && (
                <table className="table  is-transparent is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  {event.machines.map(machine => {
                    return (
                      <EventMachineItem
                        inProgress={event.patching_status === 'IN_PROGRESS'}
                        machine={machine}
                        showLogDisabled={showLogDisabled}
                        getMachineReport={this.getMachineReport}
                        getUpdateLog={this.getUpdateLog}
                        eventStatus={event.patching_status}
                        eventId={event.id}
                        eventName={event.name}
                        report={this.checkMachineInReport(machine.id)}
                        manualStatusChange={this.manualStatusChange}
                        assignMachine={this.assignMachine}
                        assigneeVisible={assigneeVisible}
                        email={email}
                      />
                    );
                  })}
                </table>
              )}

              {hooks && hooks.length > 0 && (
                <>
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <h1 className="title is-size-4">Hooks</h1>
                      </div>
                    </div>
                  </div>
                  <table className="table  is-transparent is-fullwidth is-striped">
                    <thead>
                      <tr>
                        <th>Pre/post</th>
                        <th>Type</th>
                        <th>Source</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    {hooks.map(
                      (hook, index) =>
                        hook && (
                          <tr key={index}>
                            <td>{hook.timeType}</td>
                            <td>{hook.type}</td>
                            <td>{hook.source}</td>
                            <td>
                              <HookStatus status={hook.status_code} />
                            </td>
                            <td>
                              {hook.status_code && (
                                <HookOutput hook={hook} event={{ id: event.id }} />
                              )}
                            </td>
                          </tr>
                        ),
                    )}
                  </table>
                </>
              )}
            </div>
          </div>
          <EventReport {...this.props} />
        </div>

        <div className="columns">
          <div className="column">
            <div className="box is-fullheight">
              <div className="columns">
                <Param label="Dry Run">
                  {event.dry_run ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
                </Param>
                <Param label="Linux security only">
                  {event.linux_security_only ? (
                    <i className="fa fa-check" />
                  ) : (
                    <i className="fa fa-times" />
                  )}
                </Param>
                <Param label="Parallel">
                  <Parallel value={event.parallel ? event.parallel : 0} />
                </Param>
                <Param label="Reboot Policy" value={event.reboot_policy} />
              </div>

              <div className="columns">
                <Param label="Custom Update Command (S3 URL)" value={event.s3_custom_script} />
              </div>

              <div className="columns">
                <Param label="Windows Update Category" value={event.windows_update_category} />
                <Param label="Windows Update Severity" value={event.windows_update_severity} />
              </div>
            </div>
          </div>
        </div>
        <Metadata data={event.metadata} name="Event" />
      </div>
    );
  }
}

EventDetails.propTypes = {
  events: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const { events, report } = state;

  const eventId = ownProps.routeParams.splat || null;

  return {
    event: events.list.items.find(event => event.id === eventId),
    events: events.list.items,
    report: report.report,
    updateLog: report.updateLog,
    reportError: report.reportError,
    scanReport: report.scanReport,
    scanReportError: report.scanReportError,
    scanEventId: report.scanEventId,
    scanEventError: report.scanEventError,
    isFetching: events.list.isFetching,
  };
};

export default connect(mapStateToProps)(withAuth0(EventDetails));

/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { ControlledField, MultiSelect } from '..';
import { Element } from '../MultiSelect';
import { getNotificationGroups } from '../../../graphql/getNotificationGroups';
import { APIContext } from '../../auth/APIContext';
import { pageSize } from '../../../utils/constants';
import { fetchNotificationGroups } from '../../../views/notificationGroups/actions';
import { NotificationGroup } from '../../../graphql/generated-types';

type NotificationGroupsMultiSelectProps = {
  dispatch: any;
  notificationGroups: Array<Element>;
  isFetching: boolean;
};

function NotificationGroupsMultiSelect({
  notificationGroups,
  dispatch,
  isFetching,
}: NotificationGroupsMultiSelectProps) {
  const { requester } = React.useContext(APIContext);

  React.useEffect(() => {
    fetchNotificationGroups({
      dispatch: dispatch,
      allPages: true,
      myInit: { limit: pageSize },
      requester,
      operation: getNotificationGroups,
    });
  }, [dispatch, requester]);

  return (
    <ControlledField
      name="notification_groups"
      label="Notification Groups"
      Component={MultiSelect}
      defaultValue={[]}
      elements={notificationGroups}
      placeholder="Start typing a notification group id here"
      isLoading={isFetching}
    />
  );
}

type NotificationGroupStoreSlice = {
  items: Array<NotificationGroup>;
  isFetching: boolean;
};

const mapStateToProps = (state: { notificationGroups: NotificationGroupStoreSlice }) => {
  const { notificationGroups } = state;

  return {
    notificationGroups: notificationGroups.items.map(({ id, name }) => ({
      label: `${id} (name:${name})`,
      value: id,
    })),
    isFetching: notificationGroups.isFetching,
  };
};

export default connect(mapStateToProps)(NotificationGroupsMultiSelect);

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createPermission = `mutation CreateRbacRoleBinding($input: RbacRoleBindingInput!) {
    createRbacRoleBinding(input: $input) { 
      id
    }
  }
  `;

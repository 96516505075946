/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import MachineStatus from './MachineStatus';
import MachineType from './MachineType';
import { Link } from 'react-router';
import { ButtonRemove } from '../../../commonComponents';

const Machine = ({ item: machine, actions }) => {
  const { removeItem } = actions;
  const href = `machines/details/${machine.id}`;

  return (
    <tr>
      <Link to={href} className="table-cell">
        {machine.name ? machine.name : machine.id}
      </Link>
      <Link to={href} className="table-cell">
        {machine.last_patching && <MachineStatus machine={machine} />}
      </Link>
      <Link to={href} className="table-cell">
        {machine.ping_error && (
          <i className="fa fa-warning icon is-danger fa-pull-left" title={machine.ping_error} />
        )}
        {machine.ping_status || 'Missing information'}
      </Link>
      <Link to={href} className="table-cell">
        {!machine.reboot_policy ? (
          <span>Legacy allow reboot {machine.allow_reboot ? "on" : "off"}</span>
        ) : (
          machine.reboot_policy
        )}
      </Link>
      <Link to={href} className="table-cell">
        <MachineType machine={machine} />
      </Link>
      <td>
        <ButtonRemove
          title="a machine (and remove it from all linked plans) named"
          itemId={machine.id}
          itemName={machine.name || machine.id}
          removeAction={e => {
            e && e.stopPropagation && e.stopPropagation();
            removeItem(machine.id);
          }}
        />
      </td>
    </tr>
  );
};

export { Machine };

/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ButtonsContainer, FormWrapper } from '../../../../commonComponents/form2';
// @ts-ignore -- until Terminal is migrated to TypeScript
import { useErrorInfo } from '../../../../commonComponents/form2/hooks/useErrorInfo';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { PipelinePlanSchema } from '../form/schema';
import { PlanInput } from '../../../../graphql/generated-types';

const steps = [StepOne, StepTwo];

type PlansFormProps = {
  onSubmit: (plan: Partial<PlanInput>) => void;
  errorInfo?: Record<string, string> | null;
  plan?: PlanInput;
  hideModal: () => void;
};

function PlansForm({ onSubmit, errorInfo, plan, hideModal }: PlansFormProps) {
  const methods = useForm({ defaultValues: plan, resolver: zodResolver(PipelinePlanSchema) });

  const [step, setStep] = React.useState(0);

  useErrorInfo({ errorInfo, setError: methods.setError });

  async function handlePrevStep() {
    const isValid = await methods.trigger();

    if (isValid) {
      setStep(step => step - 1);
    }
  }

  async function handleNextStep() {
    const isValid = await methods.trigger();

    if (isValid) {
      setStep(step => step + 1);
    }
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    return methods.handleSubmit(async (data: Partial<PlanInput>) => {
      onSubmit(data);
    })(event);
  }

  const StepComponent = steps[step];
  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form className="create-new-form" onSubmit={handleSubmit}>
          <StepComponent />

          <ButtonsContainer>
            {step === 0 && (
              <>
                <Button type="primary" icon="navigate_next" onClick={handleNextStep}>
                  Next step
                </Button>
                <Button type="secondary" htmlType="submit" icon="send">
                  Add
                </Button>
              </>
            )}
            {step === 1 && (
              <>
                <Button type="secondary" icon="navigate_before" onClick={handlePrevStep}>
                  Previous step
                </Button>
                <Button htmlType="submit" icon="send">
                  Add
                </Button>
                <Button
                  className="button is-small is-action has-besel"
                  type="secondary"
                  icon="close"
                  onClick={hideModal}
                >
                  Cancel
                </Button>
              </>
            )}
          </ButtonsContainer>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default PlansForm;

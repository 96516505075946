/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { ResourcesIdModal } from './ResourcesIdModal';

class ResourcesIdFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.formData || [],
      popup: false,
    };
    this.switchVisibility = this.switchVisibility.bind(this);
    this.addResourceId = this.addResourceId.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.formData || [],
    });
  }

  switchVisibility() {
    this.setState(state => ({
      popup: !state.popup,
    }));
  }

  handleChange = data => {
    this.setState({ tempObj: data.formData });
  };

  resourceIdEdit = index => {
    this.setState(state => ({
      resourceIdIndex: index,
      resourceId: state.value[index],
      popup: true,
    }));
  };

  addResourceId(event) {
    const value = [...this.state.value];
    this.state.resourceIdIndex >= 0
      ? (value[this.state.resourceIdIndex] = event)
      : value.push(event);
    this.setState({
      value: value,
      popup: false,
      resourceIdIndex: -1,
      resourceId: null,
    });
    this.props.onChange(value);
  }

  removeResourceId = resourceIdIndex => event => {
    const value = [...this.state.value];
    value.splice(resourceIdIndex, 1);
    this.setState({
      value: value,
    });
    this.props.onChange(value);
  };

  render() {
    const { value: values, popup, resourceId } = this.state;

    return (
      <div className="is-input-like">
        {values.map((el, index) => {
          return (
            <span className="tag">
              <span onClick={() => this.resourceIdEdit(index)}>{el.resource_ids}</span>
              <i className="fa fa-trash is-pointer" onClick={this.removeResourceId(index)} />
            </span>
          );
        })}
        {popup && (
          <ResourcesIdModal
            addResourceId={this.addResourceId}
            hideModal={() => {
              this.setState({
                popup: false,
                resourceIdIndex: -1,
                resourceId: null,
              });
            }}
            formData={resourceId}
          />
        )}
        <button type="button" className="button " onClick={this.switchVisibility}>
          Add New
        </button>
      </div>
    );
  }
}

export { ResourcesIdFormInput };

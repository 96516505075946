/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import FieldWrapper from './FieldWrapper';

type PatchDelayProps = {
  value?: number;
  onChange: (patchDelay: number) => void;
};

const getMinutes = (d: number, h: number, m: number) => +d * 1440 + +h * 60 + +m;

function PatchDelay({ value: patchDelay = 0, onChange }: PatchDelayProps) {
  const [windowState, setWindowState] = React.useState({
    days: patchDelay > 0 ? Math.floor(patchDelay / 1440) : 0,
    hours:
      patchDelay > 0 ? Math.floor((patchDelay - Math.floor(patchDelay / 1440) * 1440) / 60) : 0,
    minutes: patchDelay > 0 ? patchDelay - Math.floor(patchDelay / 60) * 60 : 0,
  });

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { days, hours, minutes } = windowState;
    const { value, min, max, id } = e.target;

    const parsedValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
    if (id === 'days') {
      setWindowState(prevState => ({
        ...prevState,
        days: parsedValue,
      }));
      onChange(getMinutes(parsedValue, hours, minutes));
    }
    if (id === 'hours') {
      setWindowState(prevState => ({
        ...prevState,
        hours: parsedValue,
      }));
      onChange(getMinutes(days, parsedValue, minutes));
    }
    if (id === 'minutes') {
      setWindowState(prevState => ({
        ...prevState,
        minutes: parsedValue,
      }));
      onChange(getMinutes(days, hours, parsedValue));
    }
  }

  return (
    <FieldWrapper>
      <div className="is-input-like">
        <div className="columns">
          <div className="column is-third">
            <label htmlFor="days">Days</label>
            <input
              className="input"
              onChange={handleOnChange}
              value={windowState.days}
              min="0"
              max="365"
              type="number"
              id="days"
            ></input>
          </div>
          <div className="column is-third">
            <label htmlFor="hours">Hours</label>
            <input
              className="input"
              onChange={handleOnChange}
              value={windowState.hours}
              min="0"
              max="24"
              type="number"
              id="hours"
            ></input>
          </div>
          <div className="column is-third">
            <label>Minutes</label>
            <input
              className="input"
              onChange={handleOnChange}
              value={windowState.minutes}
              max="60"
              type="number"
              id="minutes"
            ></input>
          </div>
        </div>
      </div>
    </FieldWrapper>
  );
}

export default PatchDelay;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const Parallel = ({ value = 0 }) => {
  switch (value) {
    case 0:
      return 'All';
    case 1:
      return 'In sequence';
    default:
      return `${value} in parallel`;
  }
};

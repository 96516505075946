/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { RemovalModal } from '../modals/Confirm';

class ButtonRemove extends React.Component {
  constructor() {
    super();

    this.state = { showModal: false };
  }
  toggleModal = e => {
    e && e.stopPropagation && e.stopPropagation();

    const { showModal } = this.state;

    this.setState({ showModal: !showModal });
  };
  toggleAndRemove = e => {
    const { removeAction } = this.props;
    this.setState({ showModal: false });
    removeAction();
  };
  render() {
    const { showModal } = this.state;
    const { itemId, itemName = '', title } = this.props;

    return (
      <React.Fragment>
        <RemovalModal
          visible={showModal}
          title={title}
          itemId={itemId}
          itemName={itemName}
          hideModal={this.toggleModal}
          removeItem={this.toggleAndRemove}
        />
        <button onClick={this.toggleModal} className="button pull-right is-icononly is-danger">
          <i className="fa fa-trash" />
        </button>
      </React.Fragment>
    );
  }
}

export { ButtonRemove };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

const getUniqueValues = arr => arr.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

export const getOwnersList = list =>
  getUniqueValues(
    list.filter(element => element.owner).map(element => element.owner.toLowerCase()),
  );

export const getMachineTypes = list =>
  getUniqueValues(
    list.map(element =>
      element.ssm_metadata && element.ssm_metadata.platform_type
        ? element.ssm_metadata.platform_type
        : 'Unknown',
    ),
  );

export const getMachineStatuses = list =>
  getUniqueValues(list.filter(element => element.ping_status).map(element => element.ping_status));

export const getMachinePatchingStatuses = list =>
  getUniqueValues(
    list.filter(element => element.patching_status).map(element => element.patching_status),
  );

export const getSSMList = list =>
  getUniqueValues(list.map(element => element.ssm_machine_id).filter(el => el && el.length > 0));

export function modifyFilter(filters, key, val) {
  if (val) filters[key] = val;
  else delete filters[key];
  return filters;
}

export function createFilter(filterMap) {
  return data => {
    for (let key in filterMap) {
      if (
        (data[key] && data[key].toString().toLowerCase() !== filterMap[key].toLowerCase()) ||
        (filterMap[key] === 'true' && !data[key])
      )
        return false;
    }
    return true;
  };
}

export const sortUtil = ({ orderBy, desc }) => (a, b) => {
  let val1 = '';
  let val2 = '';

  if (Array.isArray(a[orderBy]) && Array.isArray(b[orderBy])) {
    val1 = a[orderBy].map(e => e.name)[0];
    val2 = b[orderBy].map(e => e.name)[0];
  } else {
    val1 = a[orderBy] || '';
    val2 = b[orderBy] || '';
  }

  if (orderBy.length < 1) {
    return 0;
  }
  if (val1.toLowerCase() < val2.toLowerCase()) {
    return !desc ? -1 : 1;
  }
  if (val1.toLowerCase() > val2.toLowerCase()) {
    return !desc ? 1 : -1;
  }
  return 0;
};

export const persistFilterState = (filter, state) =>
  localStorage.setItem(filter, JSON.stringify(state.filters));

export const getPersistedFilterState = filter => JSON.parse(localStorage.getItem(filter));

export const removePersistedFilterState = filter => localStorage.removeItem(filter);

export const stripUndefinedValues = obj =>
  Object.keys(obj).reduce((stripped, key) => {
    if (obj[key] !== undefined) {
      return { ...stripped, [key]: obj[key] };
    } else {
      return stripped;
    }
  }, {});

export function mapFormRoleToId(formRole) {
  const idSegment = formRole.split(' ').pop();
  return idSegment.replace('(id:', '').replace(')', '');
}

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createMachine = `mutation CreateMachine($input: MachineInput!) {
    createMachine(input: $input) { 
      id
    }
  }
  `;

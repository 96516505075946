/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { MACHINE_STATES_MAPPING } from '../../../utils';
import { Link } from 'react-router';
import EventMachineHooksReport from './EventMachineHooksOutput';
import EventMachineAssignment from './EventMachineAssignment';
import { EventMachineManual } from './EventMachineManual';

const EventMachineItem = ({
  machine,
  showLogDisabled,
  getMachineReport,
  getUpdateLog,
  report = false,
  eventStatus,
  eventId,
  eventName,
  manualStatusChange,
  assignMachine,
  assigneeVisible,
  email,
}) => {
  const status = machine.status ? machine.status : 'Upcoming';
  const canChangeStatus =
    ['NEW', 'DELETED', 'NOT_APPROVED', 'APPROVED', 'BASELINE_BUILDING'].indexOf(eventStatus) === -1;
  const isMachineFinished = machine.status && machine.status !== 'In_progress';
  const showUpdateLog =
    isMachineFinished &&
    ['Not_available', 'Access_error', 'Omitted', 'Not_in_time_window'].indexOf(eventStatus) === -1;

  const { className: stateClassName } = MACHINE_STATES_MAPPING[status]
    ? MACHINE_STATES_MAPPING[status]
    : { className: 'is-danger' };

  return (
    <tr key={machine.id} className="form-control-container">
      <td>
        <Link to={`/machines/details/${machine.id}`}>
          {machine.name ? machine.name : machine.id}
        </Link>
      </td>
      <td>
        <div className={`tag ${stateClassName}`}>{status.replace('_', ' ').toUpperCase()}</div>
      </td>

      <td className="has-text-right">
        <div className="is-flex">
          <EventMachineAssignment machine={machine} action={assignMachine} email={email} />
          {['ERROR'].indexOf(status.toUpperCase()) === -1 && (
            <React.Fragment>
              {report && (
                <button
                  className="button is-warning"
                  disabled={showLogDisabled}
                  id={machine.id}
                  onClick={getMachineReport}
                >
                  Report
                </button>
              )}
              {showUpdateLog && (
                <button className="button is-success" id={machine.id} onClick={getUpdateLog}>
                  Update log
                </button>
              )}
              <EventMachineHooksReport
                event={{ id: eventId, status: eventStatus }}
                machine={machine}
              />
              {canChangeStatus && (
                <EventMachineManual
                  eventName={eventName}
                  eventId={eventId}
                  machine={machine}
                  action={manualStatusChange}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </td>
    </tr>
  );
};

export { EventMachineItem };

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Link } from 'react-router';
import { ButtonRemove } from '../../../commonComponents';
import { FormattedDate } from '../../../commonComponents';

export const Permission = ({ item: permission, actions }) => {
  const { removeItem } = actions;

  const href = `/permissions/assignments/details/${permission.id}`;

  return (
    <tr className="table-row-link" id={permission.id}>
      <td>
        <Link to={href} className="table-cell">
          {permission.subject}
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          {permission.id}
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          {permission.roles.map(r => (<div key={r.name}>{r.name}</div>))}
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          <FormattedDate>{permission.created_at}</FormattedDate>
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          <FormattedDate>{permission.updated_at}</FormattedDate>
        </Link>
      </td>
      <td>
        <ButtonRemove
          removeAction={e => {
            e && e.stopPropagation && e.stopPropagation();
            removeItem(permission.id);
          }}
          itemId={permission.id}
          itemName={permission.subject}
          title="a permission created for"
        />
      </td>
    </tr>
  );
};

/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editPermission, fetchPermissions } from '../actions';
import { getPermission } from '../../../graphql/getPermission';
import { getRoles } from '../../../graphql/getRoles';
import { putPermission } from '../../../graphql/putPermission';
import { NotificationManager } from 'react-notifications';
import { browserHistory, Link } from 'react-router';
import FormContainer from '../../../containers/form/FormContainer';
import { permissionSchema } from '../permissionSchema';
import { FormattedDate, FormButtonCancel, FormButtonEdit } from '../../../commonComponents';
import { Errors } from '../../../commonComponents/form/Validation';
import diff from 'deep-diff';
import { Loading } from '../../../commonComponents/loading/Loading';
import { fetchRoles } from '../../roles/actions';
import { ROUTES } from '../../../constants/routes';
import { mapFormRoleToId } from '../../../utils';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const Param = ({ label, value, className, children }) => (
  <div className={`column ${className} `}>
    <strong className="is-desc-label">{label}</strong>
    <br />
    {children ? children : value ? value : <i className="is-italic">No data provided</i>}
  </div>
);

class PermissionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: 'details',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.formTypeChange = this.formTypeChange.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    const { requester } = this.context;
    const { dispatch, permissions, routeParams } = this.props;
    const customer_id = localStorage.getItem('currentCustomer');
    if (!permissions) {
      const permissionsInit = {
        customer_id: customer_id,
        id: routeParams.splat,
      };
      fetchPermissions({
        dispatch,
        myInit: permissionsInit,
        requester,
        operation: getPermission,
      });
    }
    fetchRoles({
      dispatch,
      myInit: {
        limit: 1000,
      },
      requester,
      operation: getRoles,
    });
  }

  formTypeChange() {
    if (this.state.formType === 'details') {
      this.setState({
        formType: 'edit',
      });
    } else {
      this.setState({
        formType: 'details',
      });
    }
  }

  onSubmit(formData) {
    const { requester } = this.context;
    const { dispatch, permissions } = this.props;
    const changes = diff(formData, permissions);
    changes.forEach(
      e => e.kind === 'E' && !e.lhs && e.lhs !== false && e.rhs && (formData[e.path[0]] = null),
    );

    const input = {
      id: permissions.id,
      customer_id: localStorage.getItem('currentCustomer'),
      type: permissions.type,
      subject: permissions.subject,
      roles: formData.roles.map(formRole => ({ id: mapFormRoleToId(formRole) })),
    };

    const callback = () => {
      NotificationManager.success(
        'Successfully updated permission [' + permissions.id + '] details',
      );
      browserHistory.push(`${ROUTES.permissions.assignments}?refresh=true`);
    };

    editPermission({ dispatch, myInit: { input }, requester, operation: putPermission, callback });
  }

  getFormData(permissions) {
    const { id, created_at, subject, updated_at, ...formData } = permissions;
    return formData;
  }

  render() {
    const { permissions, roles, error, isFetching } = this.props;
    const { formType } = this.state;
    const parent = 'permissions';
    const formData = permissions ? this.getFormData(permissions) : {};
    const errors = { errors: error };

    const flatRoles = (formData.roles && formData.roles.map(r => r.id)) || [];

    const filterRole = roles.filter(({ id }) => flatRoles.includes(id));

    const mappedRoles = filterRole
      .map(role => ({
        ...role,
      }))
      .map(({ id, name }) => `name: ${name} (id:${id})`);

    formData.roles = mappedRoles;
    return (
      <div className="main wide">
        {!permissions ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="box">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title">Permission Details</h1>
                  <h2 className="subtitle is-size-5">
                    {permissions.name ? permissions.name : permissions.id}
                  </h2>
                </div>
                <Param label="Created At">
                  <FormattedDate>{permissions.created_at}</FormattedDate>
                </Param>
                <Param label="Updated At">
                  <FormattedDate>{permissions.updated_at}</FormattedDate>
                </Param>
                {formType === 'details' ? (
                  <FormButtonEdit className="pull-right" onClick={this.formTypeChange}>
                    Edit
                  </FormButtonEdit>
                ) : (
                  <FormButtonCancel className="pull-right" onClick={this.formTypeChange}>
                    Cancel
                  </FormButtonCancel>
                )}
              </div>
            </div>

            {errors.errors && errors.errors.length > 0 && Errors(errors)}

            {formType === 'edit' ? (
              <FormContainer
                parent={parent}
                schema={permissionSchema}
                className="details-form"
                type="edit"
                onSubmit={this.onSubmit}
                onBack={this.formTypeChange}
                formData={formData}
                disabled={isFetching}
              />
            ) : (
              <React.Fragment>
                <div className="box">
                  <div className="columns is-half">
                    <Param label="Subject" value={permissions.subject} />
                    <Param label="Roles">
                      {permissions &&
                        permissions.roles.map(r => {
                          return (
                            <div key={r.id} className="level-left">
                              <div className="level-item">
                                <div className="level-item">
                                  <div
                                    className="title is-size-6"
                                    style={{ paddingRight: '0.3rem' }}
                                  >
                                    <Link
                                      to={ROUTES.permissions.rolesDetails(r.id)}
                                      className="table-cell"
                                    >
                                      {r.name}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Param>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

PermissionDetails.propTypes = {
  permissions: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { permissions, roles } = state;
  const permissionsId = ownProps.routeParams.splat || null;

  return {
    roles: roles.items,
    permissions: permissions.items.find(permissions => permissions.id === permissionsId),
    error: permissions.error,
    isFetching: permissions.isFetching,
  };
};

export default connect(mapStateToProps)(PermissionDetails);

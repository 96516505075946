/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

const isObjNotArr = el => !!el && typeof el === 'object' && !Array.isArray(el);

export const removeEmptySelectValues = (o = {}) => {
  if (typeof o === 'string') {
    return o;
  }
  return Object.keys(o).reduce((data, key) => {
    return {
      ...data,
      [key]: isObjNotArr(o[key])
        ? removeEmptySelectValues(o[key])
        : o[key] === 'emptySelectValue'
        ? undefined
        : Array.isArray(o[key]) && typeof o[key] !== 'string'
        ? o[key].map(obj => removeEmptySelectValues(obj))
        : o[key] !== null
        ? o[key]
        : undefined,
    };
  }, {});
};

export const isEmptyObject = obj => Object.entries(obj).length === 0 && obj.constructor === Object;

export const removeEmptyKeys = obj => {
  const newObj = JSON.parse(JSON.stringify(obj));

  Object.keys(obj).forEach(k => {
    if (obj[k] == null) {
      delete newObj[k];
    }
    if (typeof obj[k] === 'object' && obj[k] != null && isEmptyObject(obj[k])) {
      delete newObj[k];
    }
  });
  return newObj;
};

export function objToFlatKeyMap(obj) {
  const toReturn = {};

  for (const prop in obj) {
    if (!obj.hasOwnProperty(prop)) continue;

    if (typeof obj[prop] == 'object' && obj[prop] !== null) {
      const flatObj = objToFlatKeyMap(obj[prop]);
      for (const nestedProp in flatObj) {
        if (!flatObj.hasOwnProperty(nestedProp)) continue;

        toReturn[prop + '.' + nestedProp] = flatObj[nestedProp];
      }
    } else {
      toReturn[prop] = obj[prop];
    }
  }
  return toReturn;
}

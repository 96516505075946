/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import JSONPretty from 'react-json-pretty';
import { clearReport, getReport, informNoReport } from '../../../actions/report';
import { Loading } from '../../../commonComponents';
import { NotificationManager } from 'react-notifications';
import { generateEventReport } from '../../../graphql/generateEventReport';
import { sendPdfReport } from '../../../graphql/sendPdfReport';
import { ERR_MSG_NOT_FOUND } from '../../../utils';
import { withAuth0 } from '@auth0/auth0-react';
import { APIContext } from '../../../commonComponents/auth/APIContext';

class EventReport extends React.Component {
  static contextType = APIContext;

  componentDidMount() {
    const { event, report } = this.props;
    if (event && !report) {
      this.getEventReport(false, event);
    }
  }

  componentWillReceiveProps({ event, report }) {
    if (event && !report) {
      this.getEventReport(false, event);
    }
  }

  generateReport() {
    const { requester } = this.context;
    const { dispatch, event } = this.props;

    return (async () => {
      try {
        await requester({
          query: generateEventReport,
          input: {
            customer_id: localStorage.getItem('currentCustomer'),
            event_id: event.id,
          },
        });

        const retriesLimit = 24,
          timeInterval = 5000;
        let retriesCount = 0;
        let retry = () => {
          if (retriesCount++ >= retriesLimit) {
            dispatch(informNoReport());
          } else if (!this.props.report && !this.props.reportError) {
            this.getEventReport(false, event);
            this.reportTimeoutId = setTimeout(retry, timeInterval);
          }
        };
        if (!this.props.report && !this.props.reportError) {
          retry();
        }
      } catch (err) {
        err.errors && NotificationManager.error(err.errors[0].message || 'Something went wrong');
      }
    })();
  }

  getEventReport = (regenerate = false, event) => {
    const { requester } = this.context;
    const { dispatch } = this.props;

    if (['IN_PROGRESS', 'NEW', 'DELETED'].indexOf(event.patching_status) > -1) {
      return;
    }

    const myInit = {
      customer_id: localStorage.getItem('currentCustomer'),
      event_id: event.id,
    };

    if (regenerate) {
      dispatch(clearReport());
      this.generateReport();
    } else {
      getReport(dispatch, myInit, requester);
    }
  };

  regenerateReport = event => {
    if (event) {
      event.persist();
      event.preventDefault();
    }
    this.getEventReport(true, this.props.event);
  };

  generatePdf = async e => {
    const { requester } = this.context;
    const { event } = this.props;
    const { user } = this.props.auth0;

    const init = {
      customer_id: localStorage.getItem('currentCustomer'),
      user: user.email,
      event_id: event.id,
    };

    try {
      const response = await requester({
        query: sendPdfReport,
        input: {
          input: init,
        },
      });

      if (response?.data) {
        NotificationManager.success(
          'Report generation successfully started. You will receive email with download link shortly.',
        );
      } else {
        NotificationManager.error('Something went wrong.');
      }
    } catch (err) {
      if (err.errors) {
        NotificationManager.error(err.errors[0].message || 'Something went wrong');
      }
    }
  };

  render() {
    const { report, reportError, event } = this.props;

    return (
      <div className="column is-half">
        <div className="box is-fullheight">
          {event && (
            <React.Fragment>
              {!['NEW', 'IN_PROGRESS', 'DELETED'].includes(event.patching_status) ? (
                <React.Fragment>
                  {reportError ? (
                    <React.Fragment>
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <h1 className="title is-size-4">
                              {reportError.header
                                ? reportError.header
                                : reportError === ERR_MSG_NOT_FOUND
                                ? 'Report'
                                : 'Report error'}
                            </h1>
                          </div>
                        </div>
                      </div>
                      <pre>
                        {reportError.msg || reportError === ERR_MSG_NOT_FOUND
                          ? event.report_status && event.report_status === 'PENDING'
                            ? 'Report generation is currently in progress.'
                            : 'Not available yet'
                          : reportError}
                        <br />
                        <br />
                        <button className="button is-single-button" onClick={this.regenerateReport}>
                          Re-generate
                        </button>
                      </pre>
                      <div />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <h1 className="title is-size-4">Report</h1>
                          </div>
                        </div>
                        <div className="level-right">
                          {report && (
                            <React.Fragment>
                              <button className="button" onClick={this.generatePdf}>
                                GET PDF REPORT
                              </button>
                              <button
                                className="button is-single-button"
                                onClick={this.regenerateReport}
                              >
                                Re-generate
                              </button>
                            </React.Fragment>
                          )}
                        </div>
                      </div>

                      {report ? (
                        <div>
                          <JSONPretty id="json-pretty" data={report} />
                        </div>
                      ) : (
                        <Loading />
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <h1 className="title is-size-4">Report</h1>
                      </div>
                    </div>
                  </div>
                  <p>No report available</p>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default withAuth0(EventReport);

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { patterns } from "../../utils/uiSchema"

export const permissionsSchema = {
  type: 'object',
  required: ['type', 'roles', 'subject'],
  properties: {
    type: {
      type: 'string',
      title: 'Type',
      enum: ['USER', 'API_KEY'],
      default: 'USER',
    },
    subject: {
      type: 'string',
      title: 'Subject',
    },
    roles: { type: 'array', title: 'Roles' },
  },
  dependencies: {
    type: {
      oneOf: [
        {
          properties: {
            type: {
              enum: ["USER"]
            },
            subject: {
              errorMessage: "email",
              pattern: patterns.emailPattern,
            }
          }
        },
        {
          properties: {
            type: {
              enum: ["API_KEY"]
            },
            subject: {
              errorMessage: "uuid",
              pattern: patterns.uuidPattern,
            }
          }
        },
      ]
    }
  }
};

/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import FieldWrapper from './FieldWrapper';
import MetadataModal, { Metadata } from './MetadataModal';

type ReducerProps = {
  isModalShown: boolean;
  metadataId: number | null;
};

type MetadataFormInputProps = {
  value?: Metadata[];
  onChange: (metadata: Metadata[]) => void;
};

function MetadataFormInput({ value: metadata = [], onChange }: MetadataFormInputProps) {
  const [{ isModalShown, metadataId }, setPopupState] = React.useReducer(
    (prevState: ReducerProps, updatedProperties: Partial<ReducerProps>) => ({
      ...prevState,
      ...updatedProperties,
    }),
    { isModalShown: false, metadataId: null },
  );

  function addMetadata() {
    setPopupState({ isModalShown: true });
  }

  function hideModal() {
    setPopupState({ isModalShown: false, metadataId: null });
  }

  function handleMetadataSaved(updateMetadata: Metadata) {
    if (metadataId !== null) {
      metadata[metadataId] = updateMetadata;
    } else {
      metadata.push(updateMetadata);
    }

    hideModal();
    onChange(metadata);
  }

  function editMetadata(metadataId: number) {
    setPopupState({ metadataId, isModalShown: true });
  }

  function handleMetadataRemoved(metadataId: number) {
    metadata.splice(metadataId, 1);
    onChange(metadata);
  }

  return (
    <FieldWrapper>
      <div className="is-input-like">
        {metadata.map(({ key, value }, index) => (
          <div key={key} className="tag" style={{ height: 'auto' }}>
            <div onClick={() => editMetadata(index)}>
              Key:
              <span> {key}</span>
              <br />
              Value:
              <span> {value}</span>
            </div>
            <i className="fa fa-trash is-pointer" onClick={() => handleMetadataRemoved(index)} />
          </div>
        ))}

        {isModalShown && (
          <MetadataModal
            saveMetadata={handleMetadataSaved}
            hideModal={hideModal}
            metadata={metadataId !== null ? metadata[metadataId] : undefined}
          />
        )}

        <button type="button" className="button" style={{ marginTop: '5px' }} onClick={addMetadata}>
          Add New
        </button>
      </div>
    </FieldWrapper>
  );
}

export default MetadataFormInput;

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal } from '../modals/Modal';
import { CronGenerator } from '../cronGenerator/CronGenerator';
import { readableCron } from '../../utils/readableCron';

class CronInput extends React.Component {
  constructor() {
    super();
    this.state = { modal: false };
  }
  onInput = e => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };
  generatedCron = val => {
    const { onChange } = this.props;
    this.setState({ modal: false });
    onChange(val);
  };
  showModal = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ modal: false });
    this.setState({ modal: true });
  };
  hideModal = e => {
    e.preventDefault();
    this.setState({ modal: false });
  };
  render() {
    const { formData } = this.props;
    const { modal: showModal } = this.state;
    return (
      <div className="cron-input">
        <input type="text" value={formData} className="input" onChange={this.onInput} />
        <button className="button is-icononly " onClick={this.showModal}>
          <i className="fa fa-clock-o is-square" />
        </button>
        <span className="info">{readableCron(formData)}</span>

        <Modal title="Cron generator" visible={showModal} cancelAction={this.hideModal}>
          <CronGenerator onChange={this.generatedCron} value={formData} />
        </Modal>
      </div>
    );
  }
}

export { CronInput };

/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledField, InputField, Select } from '../../../../commonComponents/form2';
import { rebootPolicyOptions } from '../../../../commonComponents/form2/schema/rebootPolicy';
import { OsType, osTypesOptions } from '../../consts';

function StepTwo() {
  const { watch, setValue } = useFormContext();
  const watcher = watch();

  React.useEffect(() => {
    if (watcher?.os === !OsType.windows) {
      setValue('update_category', undefined);
      setValue('update_severity', undefined);
      setValue('include_kbs', undefined);
      setValue('exclude_kbs', undefined);
    }
  }, [watcher?.os, setValue]);

  return (
    <>
      <InputField name="description" />
      <ControlledField
        name="reboot_policy"
        label="Reboot policy"
        Component={Select}
        elements={rebootPolicyOptions}
      />
      <InputField
        type="number"
        name="published_days_old"
        label="Days since update"
        registerProps={{ valueAsNumber: true }}
      />
      <InputField name="s3_custom_script" label="Custom Update Command (S3 URL)" />
      <ControlledField name="os" label="OS type" Component={Select} elements={osTypesOptions} />
      {watcher?.os === OsType.windows && (
        <>
          <InputField
            name="update_category"
            label="Update category"
            description="Default is all, Works for Windows, you can specify coma separated list: CriticalUpdates,DefinitionUpdates,Drivers,FeaturePacks,SecurityUpdates,ServicePacks,UpdateRollups,Updates."
          />
          <InputField
            name="update_severity"
            label="Update severity"
            description="Default is: Critical,Important,Moderate, Works for Windows, you can specify coma separated list: Critical,Important,Moderate,Low,Unspecified."
          />
          <InputField
            name="include_kbs"
            label="KBS documents to include"
            description="Comma separated list of KBS documents to include during patching, i.e. KB9876543,KB9876123,KB1234567"
          />
          <InputField
            name="exclude_kbs"
            label="KBS documents to exclude"
            description="Comma separated list of KBS documents to exclude during patching, i.e. KB9876543,KB9876123,KB1234567"
          />
        </>
      )}
    </>
  );
}

export default StepTwo;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const createPipeline = `mutation CreatePipeline($input: PipelineInput!) {
    createPipeline(input: $input) { 
      id
    }
  }
  `;

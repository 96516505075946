/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { LoadingHOC, Modal } from '../../../commonComponents';
import { APIContext } from '../../../commonComponents/auth/APIContext';
import { getCustomerEC2Policy } from '../../../graphql/getCustomerEC2Policy';
import { downloadFile } from '../../../utils/download';
import { GraphQLError } from '../../../utils/error';
import { showErrorMessage } from '../../../utils/notifications';

type PolicyReducerState = { template: string | null; isLoading: boolean };
type PolicyReducer = (
  prevState: PolicyReducerState,
  newState: Partial<PolicyReducerState>,
) => PolicyReducerState;

export function GetEC2PolicyButton() {
  const { requester } = React.useContext(APIContext);

  const [showModal, setShowModal] = React.useState(false);
  const [{ template, isLoading }, setFetchPolicyState] = React.useReducer<PolicyReducer>(
    (prevState, newState) => ({ ...prevState, ...newState }),
    { template: null, isLoading: false },
  );

  const toggleShowModal = () => setShowModal(value => !value);

  async function fetchEC2PolicyTemplate() {
    const customer_id = localStorage.getItem('currentCustomer');

    setFetchPolicyState({ isLoading: true });
    try {
      const response = await requester({ query: getCustomerEC2Policy, input: { customer_id } });
      setFetchPolicyState({ isLoading: false, template: response.data.customerEC2Policy });
    } catch (error) {
      setFetchPolicyState({ isLoading: false });
      if (error instanceof GraphQLError) {
        error?.errors?.forEach(err => showErrorMessage(err?.message));
      }
    }
  }

  function handleOpenModal() {
    if (!template) {
      fetchEC2PolicyTemplate();
    }
    setShowModal(true);
  }

  function downloadTemplate() {
    if (template) {
      downloadFile(template, 'autopatcher_ec2_patching_policy.yml');
    }
  }

  return (
    <>
      <div className="box-buttons" onClick={handleOpenModal}>
        <button className="button is-primary">Get IAM Policy for EC2</button>
      </div>
      <Modal
        title="IAM Policy for EC2"
        visible={showModal}
        size="regular"
        confirmLabel="Download template"
        confirmAction={downloadTemplate}
        cancelAction={toggleShowModal}
      >
        <LoadingHOC loading={isLoading}>
          <div className="terminal-container">
            {template && (
              <textarea className="terminal-content textarea" value={template} readOnly />
            )}
          </div>
        </LoadingHOC>
      </Modal>
    </>
  );
}

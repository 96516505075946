/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { PlanInput } from '../../graphql/generated-types';
import PlanModal from './PlanModal';

type ReducerProps = {
  isModalShown: boolean;
  planId: number | null;
};

type PlanFormInputProps = {
  value?: Array<PlanInput>;
  onChange: (plans: Array<PlanInput>) => void;
};

function PlanFormInput({ value: plans = [], onChange }: PlanFormInputProps) {
  const [{ isModalShown, planId }, setPopupState] = React.useReducer(
    (prevState: ReducerProps, updatedProperties: Partial<ReducerProps>) => ({
      ...prevState,
      ...updatedProperties,
    }),
    { isModalShown: false, planId: null },
  );

  function addPlan() {
    setPopupState({ isModalShown: true });
  }

  function hideModal() {
    setPopupState({ isModalShown: false, planId: null });
  }

  function handlePlanEdit(planId: number) {
    setPopupState({ planId, isModalShown: true });
  }

  function handlePlanRemove(planId: number) {
    plans.splice(planId, 1);
    onChange(plans);
  }

  function handlePlanSave(plansData: PlanInput) {
    if (planId !== null) {
      plans[planId] = plansData;
    } else {
      plans.push(plansData);
    }

    onChange(plans);
    hideModal();
  }
  return (
    <div className="is-input-like">
      {plans?.map((el: PlanInput, index: number) => (
        <div className="pipelineFormPlan" key={index}>
          <div onClick={() => handlePlanEdit(index)}>{el.name}</div>
          <i
            className="fa fa-trash is-pointer is-pulled-right"
            onClick={() => handlePlanRemove(index)}
          />
        </div>
      ))}
      {isModalShown && (
        <PlanModal
          savePlan={handlePlanSave}
          hideModal={hideModal}
          plan={planId !== null ? plans[planId] : undefined}
        />
      )}
      <button type="button" className="button" onClick={addPlan}>
        Add New
      </button>
    </div>
  );
}

export default PlanFormInput;

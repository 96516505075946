/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function isBefore(d1: string, d2: string): boolean {
    return new Date(d1) < new Date(d2);
  }
  
  export function isAfter(d1: string, d2: string): boolean {
    return new Date(d1) > new Date(d2);
  }
  
/**
 * Copyright 2021-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { Modal } from '../index';
import PlansForm from '../../views/pipelines/components/plans/Form';
import { PlanInput } from '../../graphql/generated-types';

type PlanModalProps = {
  savePlan: (plan: Partial<PlanInput>) => void;
  hideModal: () => void;
  plan?: PlanInput;
};

function PlanModal({ savePlan, hideModal, plan }: PlanModalProps) {
  return (
    <Modal visible={true} cancelAction={hideModal} title="Pipeline plan">
      <PlansForm onSubmit={savePlan} plan={plan} hideModal={hideModal} />
    </Modal>
  );
}

export default PlanModal;

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Link } from 'react-router';

const ButtonNew = ({ label, href }) => (
  <Link to={href}>
    <button className="button is-small is-action  has-besel" type="button">
      <span>
        <i className="material-icons ">add</i>
      </span>
      {label}
    </button>
  </Link>
);

export { ButtonNew };

/**
 * Copyright 2020-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editPlan, fetchPlans } from '../actions';
import { getPlan } from '../../../graphql/getPlan';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import {
  FormControl,
  FormButtonEdit,
  FormButtonCancel,
  FormattedDate,
  Metadata,
} from '../../../commonComponents';

import { Errors } from '../../../commonComponents/form/Validation';
import { putPlan } from '../../../graphql/putPlan';
import { fetchNotificationGroups } from '../../notificationGroups/actions';
import { getNotificationGroups } from '../../../graphql/getNotificationGroups';
import { pageSize } from '../../../utils';
import PlanForm from '../components/form/PlanForm';
import { ROUTES } from '../../../constants/routes';
import {
  serializeFormInput,
  trimNullValues,
} from '../../../commonComponents/form2/utils/serializeFormInput';
import { Param } from '../../../commonComponents/layout/Param';
import { TagListTable } from '../components/details/TagList';
import { TagConditionTable } from '../components/details/TagCondition';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const Hook = ({ type, source }) => (
  <div className="tag hook">
    {type}
    <span className="tooltip hook">{source}</span>
  </div>
);

class PlanDetails extends Component {
  state = {
    formType: 'details',
    loading: false,
  };
  static contextType = APIContext;

  componentDidMount() {
    const { dispatch, plan, routeParams } = this.props;
    const { requester } = this.context;
    if (!plan) {
      const planInit = {
        customer_id: localStorage.getItem('currentCustomer'),
        id: routeParams.splat,
      };
      fetchPlans({ dispatch, myInit: planInit, requester, operation: getPlan });
    }

    fetchNotificationGroups({
      dispatch: this.props.dispatch,
      allPages: true,
      myInit: { limit: pageSize },
      requester,
      operation: getNotificationGroups,
    });
  }
  formTypeChange = () => {
    if (this.state.formType === 'details') {
      this.setState({
        formType: 'edit',
      });
    } else {
      this.setState({
        formType: 'details',
      });
    }
  };

  onSubmit = formData => {
    const { dispatch, plan } = this.props;

    const input = serializeFormInput({
      id: plan.id,
      customer_id: localStorage.getItem('currentCustomer'),
      ...formData,
    });

    // split any tag_list items into chunks of max 5 values per key (AWS allows a maximum of 5 values per key)
    if (input.machines_tag && input.machines_tag.tag_list) {
      const tagList = input.machines_tag.tag_list;
      for (const key in input.machines_tag.tag_list) {
        if (tagList[key].values && tagList[key].values.length > 5) {
          while (tagList[key].values.length > 5) {
            input.machines_tag.tag_list.push({
              key: tagList[key].key,
              values: tagList[key].values.splice(5, 5),
            });
          }
        }
      }
    }

    this.setState({ loading: true });

    const callback = () => {
      NotificationManager.success(`Successfully updated plan [${plan.id}] details`);
      browserHistory.push(`${ROUTES.plans.plans}?refresh=true`);
      this.setState({ loading: false });
    };
    const { requester } = this.context;

    editPlan({ dispatch, myInit: { input }, requester, operation: putPlan, callback });
  };

  getInitialValues = () => {
    const { plan } = this.props;

    if (plan) {
      const { created_at, updated_at, id, ...initialData } = plan;

      // Fallback to maintain backward compatibility for plans with single 'iam_role' defined
      // instead of 'iam_role_list'
      if (!initialData?.machines_tag?.iam_role_list && initialData?.machines_tag?.iam_role) {
        const { iam_role, ...data } = initialData.machines_tag;

        initialData.machines_tag = {
          ...data,
          iam_role_list: [initialData.machines_tag.iam_role],
        };
      }

      return initialData;
    }

    return plan;
  };

  render() {
    const { plan, errorMessages, isFetching, notificationGroups, errorInfo } = this.props;
    const { formType } = this.state;

    const formData = this.getInitialValues();

    return (
      <div className="main wide">
        {!plan ? null : (
          <React.Fragment>
            <div className="box">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="title">Plan Details</h1>
                  <h2 className="subtitle">{plan.name ? plan.name : plan.id}</h2>
                </div>
                <Param label="Created at">
                  <FormattedDate>{plan.created_at}</FormattedDate>
                </Param>
                <Param label="Updated at">
                  <FormattedDate>{plan.updated_at}</FormattedDate>
                </Param>
                <Param label="Customer">{plan.owner}</Param>
                {formType === 'details' ? (
                  <FormButtonEdit className="pull-right" onClick={this.formTypeChange}>
                    Edit
                  </FormButtonEdit>
                ) : (
                  <FormButtonCancel className="pull-right" onClick={this.formTypeChange}>
                    Cancel
                  </FormButtonCancel>
                )}
              </div>
            </div>

            {errorMessages && errorMessages.length > 0 && Errors({ errors: errorMessages })}

            {formType === 'edit' ? (
              <PlanForm
                initialData={formData}
                onSubmit={this.onSubmit}
                isSubmitting={isFetching}
                errorInfo={errorInfo}
              />
            ) : (
              <React.Fragment>
                <div className="box">
                  <div className="columns">
                    <Param label="Description" value={plan.description} />
                    <Param label="Name" value={plan.name} />
                    <Param label="Cron Window Start" value={plan.cron_window_start} />
                    <Param label="Timezone" value={plan.time_zone} />
                    <Param label="Patching Window Duration" value={plan.window_length} />
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <h1 className="title is-size-4">Machines</h1>
                          </div>
                        </div>
                      </div>

                      {plan.machines ? (
                        <table className="table  is-transparent is-fullwidth is-striped">
                          <thead>
                            <tr>
                              <th>Machine name</th>
                              <th>Pre hooks</th>
                              <th>Post hooks</th>
                              <th />
                            </tr>
                          </thead>
                          {plan.machines.map((machine, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/machines/details/${machine.id}`}
                                    className={`${!!machine.exclude ? `has-text-grey` : ``}`}
                                  >
                                    {machine.name ? machine.name : machine.id}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/machines/details/${machine.id}`}
                                    className="table-cell"
                                  >
                                    {machine.pre_host_hooks ? (
                                      machine.pre_host_hooks.map((hook, index) => (
                                        <Hook {...hook} />
                                      ))
                                    ) : (
                                      <>-</>
                                    )}
                                  </Link>
                                </td>
                                <td>
                                  <Link to={`/machines/details/${machine.id}`}>
                                    {machine.post_host_hooks ? (
                                      machine.post_host_hooks.map((hook, index) => (
                                        <Hook {...hook} />
                                      ))
                                    ) : (
                                      <>-</>
                                    )}
                                  </Link>
                                </td>
                                <td>
                                  {!!machine.exclude && (
                                    <span className="tag is-warning">Excluded</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      ) : (
                        <span>Plan has no static machines added!</span>
                      )}
                      {!!plan.machines_tag && (
                        <>
                          <div className="level">
                            <div className="level-left">
                              <div className="level-item">
                                <div className="title is-size-4">Dynamic machines setup</div>
                              </div>
                            </div>
                          </div>
                          {plan.machines_tag.tag_list && (
                            <TagListTable tagList={plan.machines_tag.tag_list} />
                          )}
                          {plan.machines_tag.tag_condition && (
                            <TagConditionTable tagCondition={plan.machines_tag.tag_condition} />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Notification Groups</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ul>
                          {!plan.notification_groups || plan.notification_groups.length <= 0 ? (
                            <li>No Notification Groups in this Plan!</li>
                          ) : (
                            plan.notification_groups.map(g => (
                              <li index={g}>
                                <Link to={`/notification_groups/details/${g}`} className="button">
                                  {(notificationGroups.find(e => e.id === g) || {}).name || g}
                                </Link>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Post Hooks</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!plan.post_hooks ? (
                          <FormControl
                            type="text"
                            readOnly={true}
                            value="No Post Hooks provided for this Plan!"
                          />
                        ) : (
                          plan.post_hooks.map(hook => <Hook {...hook} />)
                        )}
                      </div>
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Pre Hooks</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!plan.pre_hooks ? (
                          <FormControl
                            type="text"
                            readOnly={true}
                            value="No Pre Hooks provided for this Plan!"
                          />
                        ) : (
                          plan.pre_hooks.map(hook => <Hook {...hook} />)
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="column is-half">
                    <div className="box is-fullheight">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <div className="title is-size-4">Web Hooks</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {!plan.webhook_inputs ? (
                          <FormControl
                            type="text"
                            readOnly={true}
                            value="No Web Hooks provided for this Plan!"
                          />
                        ) : (
                          <table className="table  is-transparent is-fullwidth is-striped">
                            <thead>
                              <tr>
                                <th>Trigger type</th>
                                <th>Payload</th>
                                <th>Payload type</th>
                              </tr>
                            </thead>
                            {plan.webhook_inputs.map((webHook, index) => {
                              return (
                                <tr key={index}>
                                  <td>{webHook.trigger_type}</td>
                                  <td>{webHook.payload}</td>
                                  <td>{webHook.payload_type}</td>
                                </tr>
                              );
                            })}
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="box is-fullheight">
                      <div className="columns">
                        <Param label="On Hold Start" value={plan.on_hold_start} />
                        <Param label="On Hold End" value={plan.on_hold_end} />
                        <Param label="Dry Run">
                          {plan.dry_run ? (
                            <i className="fa fa-check" />
                          ) : (
                            <i className="fa fa-times" />
                          )}
                        </Param>
                        <Param label="Linux security only">
                          {plan.linux_security_only ? (
                            <i className="fa fa-check" />
                          ) : (
                            <i className="fa fa-times" />
                          )}
                        </Param>
                      </div>
                      <div className="columns">
                        <Param label="Parallel" value={plan.parallel} />
                        <Param
                          label="Upcoming Notification Time"
                          value={plan.upcoming_notification_time}
                        />
                        <Param label="Manual Approval">
                          {plan.manual_approval ? (
                            <i className="fa fa-check" />
                          ) : (
                            <i className="fa fa-times" />
                          )}
                        </Param>
                        <Param
                          label="Custom Update Command (S3 URL)"
                          value={plan.s3_custom_script}
                        />
                      </div>

                      <div className="columns">
                        <Param label="Reboot Policy" value={plan.reboot_policy} />
                        <Param
                          label="Windows Update Category"
                          value={plan.windows_update_category}
                        />
                        <Param
                          label="Windows Update Severity"
                          value={plan.windows_update_severity}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Metadata data={plan.metadata} name="Plan" />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

PlanDetails.propTypes = {
  plans: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  isFetching: PropTypes.bool,
  notificationGroups: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  const { plans, notificationGroups } = state;
  const planId = ownProps.routeParams.splat;
  const plan = plans.items.find(plan => plan.id === planId);

  return {
    plan: plan ? trimNullValues(plan) : undefined,
    isFetching: plans.isFetching,
    notificationGroups: notificationGroups.items,
    errorMessages: plans.error,
    errorInfo: plans.errorInfo,
  };
};

export default connect(mapStateToProps)(PlanDetails);

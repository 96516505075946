/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import moment from 'moment-timezone';

export const TZFormattedDate = ({ children, timezone }) => {
  return !timezone
    ? moment.utc(children).format('DD-MM-YYYY, HH:mm:ss')
    : moment.tz(children, timezone).format('DD-MM-YYYY, HH:mm:ss');
};

/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Link } from 'react-router';
import { ButtonRemove } from '../../../commonComponents';

export const NotificationGroup = ({ item: notificationGroup, actions }) => {
  const { removeItem } = actions;

  const notificationsCount = notificationGroup.notifications
    ? notificationGroup.notifications.length
    : 0;
  const href = `notification_groups/details/${notificationGroup.id}`;

  return (
    <tr className="table-row-link" id={notificationGroup.id}>
      <Link to={href} className="table-cell">
        {notificationGroup.name}
      </Link>
      <Link to={href} className="table-cell">
        {notificationGroup.id}
      </Link>
      <Link to={href} className="table-cell">
        {notificationsCount}
      </Link>
      <td>
        <ButtonRemove
          removeAction={e => {
            e && e.stopPropagation && e.stopPropagation();
            removeItem(notificationGroup.id);
          }}
          itemId={notificationGroup.id}
          itemName={notificationGroup.name}
          title="a notification group named"
        />
      </td>
    </tr>
  );
};

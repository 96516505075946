/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { MACHINE_TYPES_MAPPING } from '../../../utils';

const MachineType = ({ machine }) => {
  const { added_dynamically } = machine || false;
  const { platform_type, platform_name } = machine.ssm_metadata || {};
  const { icon = '' } = MACHINE_TYPES_MAPPING[platform_type] ? MACHINE_TYPES_MAPPING[platform_type] : {};
  return (
    <div className="machine-type">
      <i className={`fa ${icon}`} />
      {platform_name}
      { added_dynamically && (
          <div className="tag is-warning fa-pull-right">DYNAMIC</div>
        )
      }
    </div>
  );
};

export default MachineType;

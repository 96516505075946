/**
 * Copyright 2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";
import { stringRequired } from "../validation/string";

export const MetadataSchema = z
  .object({
    key: stringRequired('Key'),
    value: stringRequired('Value'),
  })
  .array();
/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from 'react';
import { ControlledField, InputField, Select } from '../../../../commonComponents/form2';
import { rebootPolicyOptions } from '../../../../commonComponents/form2/schema/rebootPolicy';

function StepTwo() {
  return (
    <>
      <InputField name="description" />
      <ControlledField
        name="reboot_policy"
        label="Reboot policy"
        Component={Select}
        elements={rebootPolicyOptions}
      />
      <InputField
        type="number"
        name="published_days_old"
        label="Days since update"
        registerProps={{ valueAsNumber: true }}
      />
      <InputField name="s3_custom_script" label="Custom Update Command (S3 URL)" />
    </>
  );
}

export default StepTwo;

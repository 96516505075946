/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Modal } from '../modals/Modal';

const FormGroup = ({ children, className = '' }) => (
  <div className={`field is-horizontal ${className}`}>{children}</div>
);

const ControlLabel = ({ children }) => (
  <div className="field-label is-normal">
    <label className="label">{children}</label>
  </div>
);

const FormControl = props => (
  <FormBody>
    <div className="field ">
      <div className="control">
        <input
          className={props.type === 'checkbox' ? 'checkbox' : 'input'}
          {...props}
          onKeyPress={e => e.preventDefault}
        />
      </div>
    </div>
  </FormBody>
);

const FormBody = ({ children }) => <div className="field-body">{children}</div>;

const Panel = ({ header, id, children, ...props }) => (
  <React.Fragment>
    <div className="field-label is-normal">
      {header && (
        <label className="label" htmlFor={id}>
          {header}
        </label>
      )}
    </div>
    <div className="field-body">
      <div className="field ">
        <div className="control">{children}</div>
      </div>
    </div>
  </React.Fragment>
);

const FormButtonsContainer = ({ children }) => (
  <div className="field is-horizontal is-form-spacer">
    <div className="field-label is-normal" />
    <div className="field-body">{children}</div>
  </div>
);

const FormButtonConfirm = ({ children, icon, className = '', disabled, ...props }) => (
  <button
    className={'button is-small is-action is-primary has-besel ' + className}
    type="submit"
    disabled={disabled}
    {...props}
  >
    <span>
      {disabled ? (
        <div className="loader element is-loading" />
      ) : (
        <i className="material-icons ">{icon ? icon : 'send'}</i>
      )}
    </span>
    {children}
  </button>
);

const FormButtonEdit = ({ children, icon, className = '', ...props }) => (
  <button
    className={'button is-small is-action is-primary has-besel ' + className}
    type="button"
    {...props}
  >
    <span>
      <i className="material-icons ">{icon ? icon : 'edit'}</i>
    </span>
    {children}
  </button>
);

const FormButtonCancel = ({ children, className = '', ...props }) => (
  <button className={'button is-small is-action has-besel ' + className} type="button" {...props}>
    <span>
      <i className="material-icons ">close</i>
    </span>
    {children}
  </button>
);

const CustomFieldTemplate = ({
  id,
  label,
  rawHelp,
  required,
  classNames,
  rawDescription,
  rawErrors,
  children,
  schema,
  formContext: { hideFieldId },
  ...restProps
}) => {
  return schema.type !== 'object' ? (
    hideFieldId === id ? (
      <></>
    ) : (
      <React.Fragment>
        <FormGroup className={rawErrors ? 'is-error' : ''}>
          <Panel header={label + (required ? ' *' : '')} id={id}>
            {children}
          </Panel>
        </FormGroup>
        {(rawDescription || rawHelp || rawErrors) && (
          <FormGroup className={rawErrors ? 'is-additional' : ''}>
            <div className="field-label is-normal" />
            <FormBody>
              <p className="help">
                {rawDescription}
                {rawHelp}
                {rawErrors &&
                  rawErrors.map((element, index) => (
                    <p className="help is-danger" key={index}>
                      {element}
                    </p>
                  ))}
              </p>
            </FormBody>
          </FormGroup>
        )}
      </React.Fragment>
    )
  ) : (
    <div className={classNames}>{children}</div>
  );
};

const ObjectFieldTemplate = ({ description, properties }) => (
  <React.Fragment>{properties.map(element => element.content)}</React.Fragment>
);

const ArrayFieldTemplate = ({ items, canAdd, onAddClick }) => {
  return (
    <React.Fragment>
      {items.map(element => (
        <div className={element.className}>
          {element.hasRemove && (
            <button
              onClick={element.onDropIndexClick(element.index)}
              className="button is-icononly is-danger"
            >
              <i className="fa fa-trash is-square" />
            </button>
          )}
          {element.children}
        </div>
      ))}
      {canAdd && (
        <button
          className="button array-add is-icononly is-danger is-square"
          onClick={onAddClick}
          type="button"
        >
          <i className="fa fa-plus " />
        </button>
      )}
    </React.Fragment>
  );
};

const CustomTextWidget = ({ required, onChange, schema, value }) => (
  <FormControl
    type="text"
    defaultValue={schema.default}
    value={value}
    required={required}
    onChange={event => onChange(event.target.value)}
  />
);

const CustomCheckboxWidget = ({ id, disabled, onChange, required, value }) => (
  <FormControl
    type="checkbox"
    id={id}
    disabled={disabled}
    onChange={() => onChange(!value)}
    required={required}
    value={value}
    checked={value}
  />
);

export {
  FormGroup,
  FormBody,
  ControlLabel,
  FormControl,
  Panel,
  Modal,
  FormButtonsContainer,
  FormButtonConfirm,
  FormButtonCancel,
  FormButtonEdit,
  CustomFieldTemplate,
  ObjectFieldTemplate,
  CustomTextWidget,
  CustomCheckboxWidget,
  ArrayFieldTemplate,
};

/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import classNames from 'classnames';
import React from 'react';

type ModalProps = {
  visible: boolean;
  cancelAction: () => void;
  title: string;
  confirmAction?: () => void;
  confirmLabel?: string;
  children: React.ReactNode;
  size?: 'wide' | 'regular';
};

const sizeToClass = {
  regular: 'modal-card-regular',
  wide: 'modal-card-wide',
} as const;

export const Modal = ({
  visible,
  cancelAction,
  title,
  confirmAction,
  confirmLabel = 'Save changes',
  children,
  size
}: ModalProps) =>
  visible ? (
    <div role="dialog" aria-label={title} className="modal is-active has-text-left">
      <div className="modal-background" />
      <div className={classNames("modal-card", size && sizeToClass[size])}>
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <div onClick={cancelAction} className="modal-close-btn">
            <i className="fa fa-times" />
          </div>
        </header>
        <section className="modal-card-body">{children}</section>
        {confirmAction && (
          <footer className="modal-card-foot">
            <button className="button is-primary" onClick={confirmAction}>
              {confirmLabel}
            </button>
            <button className="button" onClick={cancelAction}>
              Cancel
            </button>
          </footer>
        )}
      </div>
    </div>
  ) : null;

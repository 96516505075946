/**
 * Copyright 2020-2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Role } from '../components/Role';
import { fetchRoles, removeRole } from '../actions';
import { getRoles } from '../../../graphql/getRoles';
import { deleteRole } from '../../../graphql/deleteRole';
import { createFilter } from '../../../utils/filterUtils';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';
import { createFilter as createSearchFilter } from 'react-search-input';
import { Link } from 'react-router';
import { DataTable, SearchForm } from '../../../commonComponents';
import { pageSize } from '../../../utils';
import { ROUTES } from '../../../constants/routes';
import { APIContext } from '../../../commonComponents/auth/APIContext';

const KEYS_TO_FILTERS = ['name', 'id', 'description'];

class RolesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: this.props.defaultValue || [],
      searchTerm: '',
      viewCount: 0,
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }
  static contextType = APIContext;

  componentDidMount() {
    this.refresh();
  }

  componentWillReceiveProps(props) {
    if (props.location && props.location.query.refresh) {
      this.refresh();
      browserHistory.push(ROUTES.permissions.roles);
    }
  }

  refresh = (paginationToken, allPages) => {
    const { requester } = this.context;

    fetchRoles({
      dispatch: this.props.dispatch,
      myInit: {
        limit: pageSize,
        continuation_token: paginationToken,
      },
      requester,
      operation: getRoles,
      allPages,
    });
  };

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  removeItem(itemId) {
    const { requester } = this.context;
    const { dispatch } = this.props;
    const myInit = {
      customer_id: localStorage.getItem('currentCustomer'),
      id: itemId,
    };

    const callback = () => {
      NotificationManager.success('Role removed');
      browserHistory.push(`${ROUTES.permissions.roles}?refresh=true`);
    };

    removeRole({ dispatch, myInit, operation: deleteRole, requester, callback });
  }

  updateViewCount = viewCount => this.state.viewCount !== viewCount && this.setState({ viewCount });

  render() {
    const { error, roles, paginationToken, isFetching } = this.props;
    const filteredRoles = roles
      .filter(createFilter(this.state.filters))
      .filter(createSearchFilter(this.state.searchTerm, KEYS_TO_FILTERS));

    return (
      <div>
        <div className="main wide">
          <div className="container">
            <div className="box">
              <nav className="level">
                <div className="level-left">
                  <div className="level-item">
                    <Link to={ROUTES.permissions.rolesNew}>
                      <button className="button is-small is-action  has-besel" type="button">
                        <span>
                          <i className="material-icons ">add</i>
                        </span>
                        New Role
                      </button>
                    </Link>
                  </div>
                  <div className="level-item">
                    <Link to={ROUTES.permissions.assignments}>
                      <button className="button is-small has-besel" type="button">
                        Assignments
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="level-right">
                  <SearchForm searchUpdated={this.searchUpdated} />
                </div>
              </nav>
              {roles.length > 0 && (
                <nav className="level">
                  <div className="level-left" />
                  <div className="level-right">
                    {`Viewing ${this.state.viewCount}/${roles.length}`}
                    {paginationToken && '+'}
                  </div>
                </nav>
              )}
            </div>

            {error && (
              <div className="message is-danger">
                <div className="message-body">
                  {typeof error === 'string' && <div key="0">{error}</div>}
                  {typeof error !== 'string' &&
                    Object.keys(error).map((el, index) => (
                      <div key={index}>
                        {el.replace(/_/g, ' ').toUpperCase()}:{' '}
                        {typeof error[el] === 'string'
                          ? error[el]
                          : Object.keys(error[el][0]).map(element => error[el][0][element])}
                      </div>
                    ))}
                </div>
              </div>
            )}

            <DataTable
              data={filteredRoles}
              pagination={{
                show: !!paginationToken,
                loadNextPage: () => this.refresh(paginationToken),
                loadAllPages: () => this.refresh(paginationToken, true),
              }}
              isLoading={isFetching}
              headers={[
                { label: 'Name', field: 'name' },
                { label: 'Description', field: 'description' },
                { label: 'Id', field: 'id' },
                { label: 'Created At', field: 'created_at' },
                { label: 'Updated At', field: 'updated_at' },
                { label: '' },
              ]}
              row={Role}
              rowActions={{ removeItem: this.removeItem }}
              updateViewCount={this.updateViewCount}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    roles = {
      items: [],
      paginationToken: null,
      isFetching: false,
      error: null,
      removed: false,
    },
  } = state;

  return {
    error: roles.error,
    isFetching: roles.isFetching,
    roles: roles.items,
    paginationToken: roles.paginationToken,
    removed: roles.removed,
  };
};

export default connect(mapStateToProps)(RolesPage);

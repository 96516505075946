/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ApiKey = {
  __typename?: 'APIKey';
  created_at?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** @deprecated API Keys are entities independent from users */
  email?: Maybe<Scalars['String']>;
  expires_at: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type ApiKeyInput = {
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ApiKeyPutInput = {
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ApiKeyResults = {
  __typename?: 'APIKeyResults';
  api_keys: Array<ApiKey>;
  continuation_token?: Maybe<Scalars['String']>;
};

export type ApiKeyUpdateInput = {
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type ApiKeyWithSecret = {
  __typename?: 'APIKeyWithSecret';
  created_at?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** @deprecated API Keys are entities independent from users */
  email?: Maybe<Scalars['String']>;
  expires_at: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type Access = {
  __typename?: 'Access';
  iam_role?: Maybe<Scalars['String']>;
  proxy_iam_role?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  ssm_machine_id: Scalars['String'];
};

export type AccessInput = {
  iam_role?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  ssm_machine_id: Scalars['String'];
};

export type ActionLinkInput = {
  customer_id: Scalars['String'];
  lifetime_seconds: Scalars['Int'];
  query: Scalars['String'];
  variables?: Maybe<Scalars['String']>;
};

export type Activation = {
  __typename?: 'Activation';
  code: Scalars['String'];
  id: Scalars['String'];
  region: Scalars['String'];
};

export type AzureMetadata = {
  __typename?: 'AzureMetadata';
  instance_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resource_group_name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  subscription_name?: Maybe<Scalars['String']>;
};

export type AzureMetadataInput = {
  name?: Maybe<Scalars['String']>;
  resource_group_name?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  subscription_name?: Maybe<Scalars['String']>;
};

export type BasicTagCondition = {
  __typename?: 'BasicTagCondition';
  key: Scalars['String'];
  negate?: Maybe<Scalars['Boolean']>;
  values: Array<Scalars['String']>;
};

export type BatchInput = {
  continuation_token?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
  limit: Scalars['Int'];
};

export type BundleReportInput = {
  customer_id: Scalars['String'];
  events: Array<Scalars['String']>;
  user: Scalars['String'];
};

export type CsvReport = {
  __typename?: 'CsvReport';
  url: Scalars['String'];
};

export type CurrentApiKeyUpdateInput = {
  customer_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CustomerOnboarding = {
  __typename?: 'CustomerOnboarding';
  customer_id: Scalars['String'];
  customer_name: Scalars['String'];
  logs_encryption?: Maybe<Scalars['Boolean']>;
  logs_region?: Maybe<Scalars['String']>;
  status: OnboardingStatus;
  status_details?: Maybe<Scalars['String']>;
};

export type DynamicPlan = {
  __typename?: 'DynamicPlan';
  /** @deprecated Use `reboot_policy`. */
  allow_reboot?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `iam_role_list` instead */
  iam_role?: Maybe<Scalars['String']>;
  iam_role_list?: Maybe<Array<Scalars['String']>>;
  include_stopped?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `tag_list` instead */
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<KeyValuePair>>;
  post_host_hooks?: Maybe<Array<Hook>>;
  pre_host_hooks?: Maybe<Array<Hook>>;
  reboot_policy?: Maybe<RebootPolicy>;
  regions?: Maybe<Array<Scalars['String']>>;
  s3_custom_script?: Maybe<Scalars['String']>;
  tag_condition?: Maybe<TagCondition>;
  tag_list?: Maybe<Array<TagList>>;
  update_existing?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `tag_list` instead */
  value?: Maybe<Scalars['String']>;
};

export type DynamicPlanInput = {
  allow_reboot?: Maybe<Scalars['Boolean']>;
  iam_role?: Maybe<Scalars['String']>;
  iam_role_list?: Maybe<Array<Scalars['String']>>;
  include_stopped?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  post_host_hooks?: Maybe<Array<HookInput>>;
  pre_host_hooks?: Maybe<Array<HookInput>>;
  reboot_policy?: Maybe<RebootPolicy>;
  regions?: Maybe<Array<Scalars['String']>>;
  s3_custom_script?: Maybe<Scalars['String']>;
  tag_condition?: Maybe<TagConditionInput>;
  tag_list?: Maybe<Array<TagListInput>>;
  update_existing?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type EmailConfig = {
  __typename?: 'EmailConfig';
  address: Scalars['String'];
  is_external?: Maybe<Scalars['Boolean']>;
};

export type EmailConfigInput = {
  address: Scalars['String'];
  is_external?: Maybe<Scalars['Boolean']>;
};

export type Event = {
  __typename?: 'Event';
  created_at?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  enable_baseline?: Maybe<Scalars['Boolean']>;
  end_time?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  hash: Scalars['String'];
  id: Scalars['String'];
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<EventMachine>>;
  machines_tag?: Maybe<DynamicPlan>;
  metadata?: Maybe<Array<KeyValuePair>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  notification_sent?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use customer_id instead */
  owner?: Maybe<Scalars['String']>;
  parallel?: Maybe<Scalars['Int']>;
  patching_status?: Maybe<EventPatchingStatus>;
  /** @deprecated `pipeline_steps` should be used instead */
  pipeline?: Maybe<Array<Event>>;
  pipeline_id?: Maybe<Scalars['String']>;
  /** @deprecated Use pipeline_step_info instead */
  pipeline_info?: Maybe<PipelineInfo>;
  pipeline_step_id?: Maybe<Scalars['String']>;
  pipeline_step_info?: Maybe<PipelineStepInfo>;
  pipeline_steps?: Maybe<Array<EventPipelineStep>>;
  plan_id: Scalars['String'];
  policy?: Maybe<EventPolicy>;
  post_hooks?: Maybe<Array<Hook>>;
  pre_hooks?: Maybe<Array<Hook>>;
  reboot_policy?: Maybe<RebootPolicy>;
  report_status?: Maybe<Scalars['String']>;
  run_immediately?: Maybe<Scalars['Boolean']>;
  s3_custom_script?: Maybe<Scalars['String']>;
  scan_event_id?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  webhook_inputs?: Maybe<Array<WebhookParam>>;
  webhook_outputs?: Maybe<Array<WebhookParam>>;
  window_end_time: Scalars['String'];
  window_start_time: Scalars['String'];
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type EventHookInput = {
  cmdline_params?: Maybe<Scalars['String']>;
  method?: Maybe<HookMethod>;
  name?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  status_code?: Maybe<Scalars['Int']>;
  type: HookType;
};

export type EventMachine = {
  __typename?: 'EventMachine';
  action_taken?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  internal_status?: Maybe<Scalars['String']>;
  manual_status?: Maybe<ManualStatusType>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  post_host_hooks?: Maybe<Array<Hook>>;
  pre_host_hooks?: Maybe<Array<Hook>>;
  s3_custom_script?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type EventMachineInput = {
  action_taken?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  post_host_hooks?: Maybe<Array<EventHookInput>>;
  pre_host_hooks?: Maybe<Array<EventHookInput>>;
  s3_custom_script?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum EventPatchingStatus {
  Approved = 'APPROVED',
  BaselineBuilding = 'BASELINE_BUILDING',
  Deleted = 'DELETED',
  Done = 'DONE',
  DoneError = 'DONE_ERROR',
  DoneErrorBaseline = 'DONE_ERROR_BASELINE',
  DoneErrorManual = 'DONE_ERROR_MANUAL',
  DoneManual = 'DONE_MANUAL',
  DonePartial = 'DONE_PARTIAL',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  NotApproved = 'NOT_APPROVED'
}

export type EventPipelineStep = {
  __typename?: 'EventPipelineStep';
  events?: Maybe<Array<Event>>;
};

export enum EventPolicy {
  ApprovalRequired = 'approval_required',
  PatchAnyway = 'patch_anyway',
  SuccessOrApproval = 'success_or_approval'
}

export type EventResults = {
  __typename?: 'EventResults';
  continuation_token?: Maybe<Scalars['String']>;
  events: Array<Event>;
};

export type EventUpdateInput = {
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  enable_baseline?: Maybe<Scalars['Boolean']>;
  end_time?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  hash?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<EventMachineInput>>;
  machines_tag?: Maybe<DynamicPlanInput>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  notification_sent?: Maybe<Scalars['Boolean']>;
  patching_status?: Maybe<EventPatchingStatus>;
  pipeline_id?: Maybe<Scalars['String']>;
  pipeline_info?: Maybe<PipelineInfoInput>;
  pipeline_step_id?: Maybe<Scalars['String']>;
  pipeline_step_info?: Maybe<PipelineStepInfoInput>;
  plan_id?: Maybe<Scalars['String']>;
  post_hooks?: Maybe<Array<EventHookInput>>;
  pre_hooks?: Maybe<Array<EventHookInput>>;
  reboot_policy?: Maybe<RebootPolicy>;
  report_status?: Maybe<Scalars['String']>;
  run_immediately?: Maybe<Scalars['Boolean']>;
  s3_custom_script?: Maybe<Scalars['String']>;
  scan_event_id?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
  webhook_inputs?: Maybe<Array<WebhookParamInput>>;
  webhook_outputs?: Maybe<Array<WebhookParamInput>>;
  window_end_time?: Maybe<Scalars['String']>;
  window_start_time?: Maybe<Scalars['String']>;
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type EventsBatchInput = {
  continuation_token?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  end_date: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
  limit: Scalars['Int'];
  start_date: Scalars['String'];
};

export type EventsDashboardInput = {
  customer_ids?: Maybe<Array<Scalars['String']>>;
  end_date: Scalars['String'];
  start_date: Scalars['String'];
  status?: Maybe<EventPatchingStatus>;
};

export type Filter = {
  key: Scalars['String'];
  operation: FilterOperation;
  value: Scalars['String'];
};

export enum FilterOperation {
  Con = 'CON',
  Eq = 'EQ',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
  Ne = 'NE'
}

export type Hook = {
  __typename?: 'Hook';
  cmdline_params?: Maybe<Scalars['String']>;
  method?: Maybe<HookMethod>;
  name?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  status_code?: Maybe<Scalars['Int']>;
  type: HookType;
};

export type HookInput = {
  cmdline_params?: Maybe<Scalars['String']>;
  method?: Maybe<HookMethod>;
  source: Scalars['String'];
  type: HookType;
};

export enum HookMethod {
  Get = 'GET',
  Post = 'POST'
}

export type HookOutput = {
  __typename?: 'HookOutput';
  code?: Maybe<Scalars['Int']>;
  hook?: Maybe<Hook>;
  message?: Maybe<Scalars['String']>;
};

export enum HookType {
  Aws = 'aws',
  Azure = 'azure',
  Script = 'script'
}

export type InstallerKey = {
  __typename?: 'InstallerKey';
  customer_id: Scalars['String'];
  key_id: Scalars['String'];
  secret: Scalars['String'];
};

export type InstallerKeyMetadata = {
  __typename?: 'InstallerKeyMetadata';
  customer_id: Scalars['String'];
  key_id: Scalars['String'];
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LastPackageScan = {
  __typename?: 'LastPackageScan';
  error?: Maybe<Scalars['String']>;
  s3_location?: Maybe<Scalars['String']>;
  time: Scalars['String'];
};

export type LastPatching = {
  __typename?: 'LastPatching';
  action_taken?: Maybe<Scalars['String']>;
  event_id: Scalars['String'];
  internal_status?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Machine = {
  __typename?: 'Machine';
  access: Access;
  added_dynamically?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `reboot_policy` instead */
  allow_reboot?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<Scalars['String']>;
  azure_info?: Maybe<AzureMetadata>;
  created_at?: Maybe<Scalars['String']>;
  custom_document_arn?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  exclude_kbs?: Maybe<Scalars['String']>;
  host_collector_invoked?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  include_kbs?: Maybe<Scalars['String']>;
  instance_id?: Maybe<Scalars['String']>;
  last_package_scan?: Maybe<LastPackageScan>;
  last_patching?: Maybe<LastPatching>;
  metadata?: Maybe<Array<KeyValuePair>>;
  name?: Maybe<Scalars['String']>;
  ping_error?: Maybe<Scalars['String']>;
  ping_status?: Maybe<Scalars['String']>;
  pipelines?: Maybe<Array<Scalars['String']>>;
  plans?: Maybe<Array<Scalars['String']>>;
  provider?: Maybe<Provider>;
  published_days_old?: Maybe<Scalars['String']>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  ssm_metadata?: Maybe<SsmMetadata>;
  update_category?: Maybe<Scalars['String']>;
  update_severity?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type MachineAssignmentInput = {
  clear?: Maybe<Scalars['Boolean']>;
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  machine_id: Scalars['String'];
};

export type MachineInput = {
  access: AccessInput;
  added_dynamically?: Maybe<Scalars['Boolean']>;
  allow_reboot?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<Scalars['String']>;
  azure_info?: Maybe<AzureMetadataInput>;
  custom_document_arn?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  exclude_kbs?: Maybe<Scalars['String']>;
  include_kbs?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name: Scalars['String'];
  pipelines?: Maybe<Array<Scalars['String']>>;
  plans?: Maybe<Array<Scalars['String']>>;
  provider?: Maybe<Provider>;
  published_days_old?: Maybe<Scalars['String']>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  ssm_metadata?: Maybe<SsmMetadataInput>;
  update_category?: Maybe<Scalars['String']>;
  update_severity?: Maybe<Scalars['String']>;
};

export type MachinePutInput = {
  access: AccessInput;
  assignee?: Maybe<Scalars['String']>;
  azure_info?: Maybe<AzureMetadataInput>;
  custom_document_arn?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  exclude_kbs?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  include_kbs?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name: Scalars['String'];
  provider?: Maybe<Provider>;
  published_days_old?: Maybe<Scalars['String']>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  update_category?: Maybe<Scalars['String']>;
  update_severity?: Maybe<Scalars['String']>;
};

export type MachineResults = {
  __typename?: 'MachineResults';
  continuation_token?: Maybe<Scalars['String']>;
  machines: Array<Machine>;
};

export type MachineStateReportInput = {
  customer_id: Scalars['String'];
  end_date: Scalars['String'];
  start_date: Scalars['String'];
};

export type MachineUpdateInput = {
  access?: Maybe<AccessInput>;
  allow_reboot?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<Scalars['String']>;
  azure_info?: Maybe<AzureMetadataInput>;
  custom_document_arn?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  exclude_kbs?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  include_kbs?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  published_days_old?: Maybe<Scalars['String']>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  update_category?: Maybe<Scalars['String']>;
  update_severity?: Maybe<Scalars['String']>;
};

export enum ManualStatusType {
  FailedManual = 'Failed_manual',
  InProgressManual = 'In_progress_manual',
  RebootingManual = 'Rebooting_manual',
  SuccessManual = 'Success_manual'
}

export type MovePlanToPipelineInput = {
  customer_id: Scalars['String'];
  insert?: Maybe<Scalars['Boolean']>;
  pipeline_id: Scalars['String'];
  plan_id: Scalars['String'];
  step_delay?: Maybe<Scalars['Int']>;
  step_index: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveEvent: Event;
  assignMachine: Event;
  changePatchingStatus: Event;
  createAPIKey: ApiKeyWithSecret;
  createMachine: Machine;
  createNotificationGroup: NotificationGroup;
  createPipeline: Pipeline;
  createPlan: Plan;
  createRbacRole: RbacRole;
  createRbacRoleBinding: RbacRoleBinding;
  customersHostsReport: Scalars['Boolean'];
  deleteAPIKey: Scalars['Boolean'];
  deleteCurrentAPIKey: Scalars['Boolean'];
  deleteEvent: Scalars['Boolean'];
  deleteInstallerKey: Scalars['Boolean'];
  deleteMachine: Scalars['Boolean'];
  deleteNotificationGroup?: Maybe<Scalars['Boolean']>;
  deletePipeline?: Maybe<Scalars['Boolean']>;
  deletePlan: Scalars['Boolean'];
  deleteRbacRole: Scalars['Boolean'];
  deleteRbacRoleBinding: Scalars['Boolean'];
  eventScan: Event;
  forceRunPipeline: Array<Event>;
  forceRunPlan: Event;
  generateActionLink: Scalars['String'];
  generateEventReport: Scalars['Boolean'];
  generateInstallerKey: InstallerKey;
  movePlanToPipeline: Pipeline;
  onboardCustomer: CustomerOnboarding;
  partialPatch: Event;
  putAPIKey: ApiKey;
  putMachine: Machine;
  putNotificationGroup: NotificationGroup;
  putPipeline: Pipeline;
  putPlan: Plan;
  putRbacRole: RbacRole;
  putRbacRoleBinding: RbacRoleBinding;
  removeMachineFromPlan: Scalars['Boolean'];
  sendBundlePdfReport: Scalars['Boolean'];
  sendMachineStateReport: Scalars['Boolean'];
  sendPdfReport: Scalars['Boolean'];
  updateAPIKey: ApiKey;
  updateCurrentAPIKey: ApiKey;
  updateEvent: Event;
  updateMachine: Machine;
  updateNotificationGroup: NotificationGroup;
  updatePipeline: Pipeline;
  updatePlan: Plan;
  updateRbacRole: RbacRole;
  updateRbacRoleBinding: RbacRoleBinding;
};


export type MutationApproveEventArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
};


export type MutationAssignMachineArgs = {
  input: MachineAssignmentInput;
};


export type MutationChangePatchingStatusArgs = {
  input: PatchingStatusChangeInput;
};


export type MutationCreateApiKeyArgs = {
  input: ApiKeyInput;
};


export type MutationCreateMachineArgs = {
  input: MachineInput;
};


export type MutationCreateNotificationGroupArgs = {
  input: NotificationGroupInput;
};


export type MutationCreatePipelineArgs = {
  input: PipelineInput;
};


export type MutationCreatePlanArgs = {
  input: PlanInput;
};


export type MutationCreateRbacRoleArgs = {
  input: RbacRoleInput;
};


export type MutationCreateRbacRoleBindingArgs = {
  input: RbacRoleBindingInput;
};


export type MutationDeleteApiKeyArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteCurrentApiKeyArgs = {
  customer_id?: Maybe<Scalars['String']>;
};


export type MutationDeleteEventArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
};


export type MutationDeleteInstallerKeyArgs = {
  customer_id: Scalars['String'];
  key_id: Scalars['String'];
};


export type MutationDeleteMachineArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteNotificationGroupArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeletePipelineArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeletePlanArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteRbacRoleArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteRbacRoleBindingArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationEventScanArgs = {
  customer_id: Scalars['String'];
  event_id?: Maybe<Scalars['String']>;
};


export type MutationForceRunPipelineArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationForceRunPlanArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type MutationGenerateActionLinkArgs = {
  input: ActionLinkInput;
};


export type MutationGenerateEventReportArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
};


export type MutationGenerateInstallerKeyArgs = {
  customer_id: Scalars['String'];
};


export type MutationMovePlanToPipelineArgs = {
  input: MovePlanToPipelineInput;
};


export type MutationOnboardCustomerArgs = {
  input: OnboardCustomerInput;
};


export type MutationPartialPatchArgs = {
  input: PartialPatchInput;
};


export type MutationPutApiKeyArgs = {
  input: ApiKeyPutInput;
};


export type MutationPutMachineArgs = {
  input: MachinePutInput;
};


export type MutationPutNotificationGroupArgs = {
  input: NotificationGroupPutInput;
};


export type MutationPutPipelineArgs = {
  input: PipelinePutInput;
};


export type MutationPutPlanArgs = {
  input: PlanPutInput;
};


export type MutationPutRbacRoleArgs = {
  input: RbacRolePutInput;
};


export type MutationPutRbacRoleBindingArgs = {
  input: RbacRoleBindingPutInput;
};


export type MutationRemoveMachineFromPlanArgs = {
  input: RemoveMachineFromPlanInput;
};


export type MutationSendBundlePdfReportArgs = {
  input: BundleReportInput;
};


export type MutationSendMachineStateReportArgs = {
  input: MachineStateReportInput;
};


export type MutationSendPdfReportArgs = {
  input: ReportInput;
};


export type MutationUpdateApiKeyArgs = {
  input: ApiKeyUpdateInput;
};


export type MutationUpdateCurrentApiKeyArgs = {
  input: CurrentApiKeyUpdateInput;
};


export type MutationUpdateEventArgs = {
  input: EventUpdateInput;
};


export type MutationUpdateMachineArgs = {
  input: MachineUpdateInput;
};


export type MutationUpdateNotificationGroupArgs = {
  input: NotificationGroupUpdateInput;
};


export type MutationUpdatePipelineArgs = {
  input: PipelineUpdateInput;
};


export type MutationUpdatePlanArgs = {
  input: PlanUpdateInput;
};


export type MutationUpdateRbacRoleArgs = {
  input: RbacRoleUpdateInput;
};


export type MutationUpdateRbacRoleBindingArgs = {
  input: RbacRoleBindingUpdateInput;
};

export type NotificationChannel = {
  __typename?: 'NotificationChannel';
  channel?: Maybe<Scalars['String']>;
  email_config?: Maybe<EmailConfig>;
  events?: Maybe<Array<NotificationEvent>>;
  levels?: Maybe<Array<NotificationLevel>>;
  override_settings?: Maybe<Scalars['Boolean']>;
  routing_key?: Maybe<Scalars['String']>;
  type: NotificationType;
  web_hook_url?: Maybe<Scalars['String']>;
};

export type NotificationChannelInput = {
  channel?: Maybe<Scalars['String']>;
  email_config?: Maybe<EmailConfigInput>;
  events?: Maybe<Array<NotificationEvent>>;
  levels?: Maybe<Array<NotificationLevel>>;
  override_settings?: Maybe<Scalars['Boolean']>;
  routing_key?: Maybe<Scalars['String']>;
  type: NotificationType;
  web_hook_url?: Maybe<Scalars['String']>;
};

export enum NotificationEvent {
  Error = 'ERROR',
  EventNew = 'EVENT_NEW',
  HookFinish = 'HOOK_FINISH',
  HookStart = 'HOOK_START',
  HostHookFinish = 'HOST_HOOK_FINISH',
  HostHookStart = 'HOST_HOOK_START',
  IncomingPatching = 'INCOMING_PATCHING',
  MachineAccessErr = 'MACHINE_ACCESS_ERR',
  MachineNotInWindow = 'MACHINE_NOT_IN_WINDOW',
  MachinePatchingFinish = 'MACHINE_PATCHING_FINISH',
  MachinePatchingStart = 'MACHINE_PATCHING_START',
  NotApprovedPatching = 'NOT_APPROVED_PATCHING',
  PartialPatchingFinish = 'PARTIAL_PATCHING_FINISH',
  PartialPatchingStart = 'PARTIAL_PATCHING_START',
  PatchingFinish = 'PATCHING_FINISH',
  PatchingStart = 'PATCHING_START',
  PipelineDeleted = 'PIPELINE_DELETED',
  PipelineModification = 'PIPELINE_MODIFICATION',
  PipelineNew = 'PIPELINE_NEW',
  PlanDeleted = 'PLAN_DELETED',
  PlanModification = 'PLAN_MODIFICATION',
  PlanNew = 'PLAN_NEW'
}

export type NotificationGroup = {
  __typename?: 'NotificationGroup';
  created_at?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  events?: Maybe<Array<NotificationEvent>>;
  id: Scalars['String'];
  levels?: Maybe<Array<NotificationLevel>>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<NotificationChannel>>;
  updated_at?: Maybe<Scalars['String']>;
};

export type NotificationGroupInput = {
  customer_id: Scalars['String'];
  events?: Maybe<Array<NotificationEvent>>;
  levels?: Maybe<Array<NotificationLevel>>;
  name: Scalars['String'];
  notifications?: Maybe<Array<NotificationChannelInput>>;
};

export type NotificationGroupPutInput = {
  customer_id: Scalars['String'];
  events?: Maybe<Array<NotificationEvent>>;
  id: Scalars['String'];
  levels?: Maybe<Array<NotificationLevel>>;
  name: Scalars['String'];
  notifications?: Maybe<Array<NotificationChannelInput>>;
};

export type NotificationGroupResults = {
  __typename?: 'NotificationGroupResults';
  continuation_token?: Maybe<Scalars['String']>;
  notification_groups: Array<NotificationGroup>;
};

export type NotificationGroupUpdateInput = {
  customer_id: Scalars['String'];
  events?: Maybe<Array<NotificationEvent>>;
  id: Scalars['String'];
  levels?: Maybe<Array<NotificationLevel>>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<NotificationChannelInput>>;
};

export enum NotificationLevel {
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Success = 'SUCCESS',
  Warn = 'WARN'
}

export enum NotificationType {
  Email = 'EMAIL',
  PagerDuty = 'PAGER_DUTY',
  Slack = 'SLACK'
}

export type OnboardCustomerInput = {
  account_id?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  customer_name: Scalars['String'];
  logs_encryption?: Maybe<Scalars['Boolean']>;
  logs_region?: Maybe<Scalars['String']>;
  trigger_installer_infra?: Maybe<Scalars['Boolean']>;
};

export enum OnboardingStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Partial = 'PARTIAL',
  Waiting = 'WAITING'
}

export type PartialMachineExecutionInput = {
  id: Scalars['String'];
  no_patch?: Maybe<Scalars['Boolean']>;
  post_host_hooks?: Maybe<Array<Scalars['Int']>>;
  pre_host_hooks?: Maybe<Array<Scalars['Int']>>;
};

export type PartialPatchInput = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  machines?: Maybe<Array<PartialMachineExecutionInput>>;
  post_hooks?: Maybe<Array<Scalars['Int']>>;
  pre_hooks?: Maybe<Array<Scalars['Int']>>;
};

export type PatchingEvent = {
  __typename?: 'PatchingEvent';
  customer_id?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  installed_patches?: Maybe<Array<Maybe<Scalars['String']>>>;
  start_time?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PatchingEventResults = {
  __typename?: 'PatchingEventResults';
  continuation_token?: Maybe<Scalars['String']>;
  patching_events: Array<PatchingEvent>;
};

export type PatchingEventsBatchInput = {
  continuation_token?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  start_time?: Maybe<Scalars['String']>;
};

export type PatchingStatusChangeInput = {
  action_taken?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  machine_id: Scalars['String'];
  manual_status?: Maybe<ManualStatusType>;
};

export type Pipeline = {
  __typename?: 'Pipeline';
  created_at?: Maybe<Scalars['String']>;
  cron_window_start: Scalars['String'];
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enable_baseline?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  metadata?: Maybe<Array<KeyValuePair>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  plans?: Maybe<Array<PipelinePlan>>;
  steps?: Maybe<Array<PipelineStep>>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type PipelineInfo = {
  __typename?: 'PipelineInfo';
  next?: Maybe<Scalars['String']>;
  prev?: Maybe<Scalars['String']>;
};

export type PipelineInfoInput = {
  next?: Maybe<Scalars['String']>;
  prev?: Maybe<Scalars['String']>;
};

export type PipelineInput = {
  cron_window_start: Scalars['String'];
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enable_baseline?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name: Scalars['String'];
  notification_groups?: Maybe<Array<Scalars['String']>>;
  plans?: Maybe<Array<PipelinePlanInput>>;
  steps?: Maybe<Array<PipelineStepInput>>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
};

export type PipelinePlan = {
  __typename?: 'PipelinePlan';
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachine>>;
  machines_tag?: Maybe<DynamicPlan>;
  name?: Maybe<Scalars['String']>;
  parallel?: Maybe<Scalars['Int']>;
  patch_delay?: Maybe<Scalars['Int']>;
  policy?: Maybe<PipelinePolicy>;
  post_hooks?: Maybe<Array<Hook>>;
  pre_hooks?: Maybe<Array<Hook>>;
  run_immediately?: Maybe<Scalars['Boolean']>;
  s3_custom_script?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  window_length: Scalars['Int'];
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type PipelinePlanInput = {
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachineInput>>;
  machines_tag?: Maybe<DynamicPlanInput>;
  name?: Maybe<Scalars['String']>;
  parallel?: Maybe<Scalars['Int']>;
  patch_delay?: Maybe<Scalars['Int']>;
  policy?: Maybe<PipelinePolicy>;
  post_hooks?: Maybe<Array<HookInput>>;
  pre_hooks?: Maybe<Array<HookInput>>;
  run_immediately?: Maybe<Scalars['Boolean']>;
  s3_custom_script?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  window_length: Scalars['Int'];
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export enum PipelinePolicy {
  ApprovalRequired = 'approval_required',
  PatchAnyway = 'patch_anyway',
  SuccessOrApproval = 'success_or_approval'
}

export type PipelinePutInput = {
  cron_window_start: Scalars['String'];
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enable_baseline?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name: Scalars['String'];
  notification_groups?: Maybe<Array<Scalars['String']>>;
  steps?: Maybe<Array<PipelineStepInput>>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
};

export type PipelineResults = {
  __typename?: 'PipelineResults';
  continuation_token?: Maybe<Scalars['String']>;
  pipelines: Array<Pipeline>;
};

export type PipelineStep = {
  __typename?: 'PipelineStep';
  description?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<PipelineStepPlan>>;
  policy?: Maybe<PipelinePolicy>;
  run_immediately?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<Scalars['String']>;
  step_delay?: Maybe<Scalars['Int']>;
};

export type PipelineStepInfo = {
  __typename?: 'PipelineStepInfo';
  current: Scalars['String'];
  first: Scalars['String'];
  next?: Maybe<Scalars['String']>;
  prev?: Maybe<Scalars['String']>;
};

export type PipelineStepInfoInput = {
  current: Scalars['String'];
  first: Scalars['String'];
  next?: Maybe<Scalars['String']>;
  prev?: Maybe<Scalars['String']>;
};

export type PipelineStepInput = {
  description?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<PipelineStepPlanInput>>;
  policy?: Maybe<PipelinePolicy>;
  run_immediately?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<Scalars['String']>;
  step_delay?: Maybe<Scalars['Int']>;
};

export type PipelineStepPlan = {
  __typename?: 'PipelineStepPlan';
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachine>>;
  machines_tag?: Maybe<DynamicPlan>;
  metadata?: Maybe<Array<KeyValuePair>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  override_notifications?: Maybe<Scalars['Boolean']>;
  parallel?: Maybe<Scalars['Int']>;
  post_hooks?: Maybe<Array<Hook>>;
  pre_hooks?: Maybe<Array<Hook>>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  window_length: Scalars['Int'];
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type PipelineStepPlanInput = {
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachineInput>>;
  machines_tag?: Maybe<DynamicPlanInput>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  override_notifications?: Maybe<Scalars['Boolean']>;
  parallel?: Maybe<Scalars['Int']>;
  post_hooks?: Maybe<Array<HookInput>>;
  pre_hooks?: Maybe<Array<HookInput>>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  window_length: Scalars['Int'];
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type PipelineUpdateInput = {
  cron_window_start?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enable_baseline?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  plans?: Maybe<Array<PipelinePlanInput>>;
  steps?: Maybe<Array<PipelineStepInput>>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
};

export type Plan = {
  __typename?: 'Plan';
  created_at?: Maybe<Scalars['String']>;
  /** @deprecated Use `window_length` instead */
  cron_window_end?: Maybe<Scalars['String']>;
  cron_window_start: Scalars['String'];
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachine>>;
  machines_tag?: Maybe<DynamicPlan>;
  manual_approval?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Array<KeyValuePair>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  on_hold_end?: Maybe<Scalars['String']>;
  on_hold_start?: Maybe<Scalars['String']>;
  parallel?: Maybe<Scalars['Int']>;
  post_hooks?: Maybe<Array<Hook>>;
  pre_hooks?: Maybe<Array<Hook>>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  webhook_inputs?: Maybe<Array<WebhookParam>>;
  window_length?: Maybe<Scalars['Int']>;
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type PlanInput = {
  cron_window_start: Scalars['String'];
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachineInput>>;
  machines_tag?: Maybe<DynamicPlanInput>;
  manual_approval?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name: Scalars['String'];
  notification_groups?: Maybe<Array<Scalars['String']>>;
  on_hold_end?: Maybe<Scalars['String']>;
  on_hold_start?: Maybe<Scalars['String']>;
  parallel?: Maybe<Scalars['Int']>;
  post_hooks?: Maybe<Array<HookInput>>;
  pre_hooks?: Maybe<Array<HookInput>>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
  webhook_inputs?: Maybe<Array<WebhookParamInput>>;
  window_length: Scalars['Int'];
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type PlanMachine = {
  __typename?: 'PlanMachine';
  exclude?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  post_host_hooks?: Maybe<Array<Hook>>;
  pre_host_hooks?: Maybe<Array<Hook>>;
  s3_custom_script?: Maybe<Scalars['String']>;
};

export type PlanMachineInput = {
  exclude?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  post_host_hooks?: Maybe<Array<HookInput>>;
  pre_host_hooks?: Maybe<Array<HookInput>>;
  s3_custom_script?: Maybe<Scalars['String']>;
};

export type PlanPutInput = {
  cron_window_start: Scalars['String'];
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachineInput>>;
  machines_tag?: Maybe<DynamicPlanInput>;
  manual_approval?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name: Scalars['String'];
  notification_groups?: Maybe<Array<Scalars['String']>>;
  on_hold_end?: Maybe<Scalars['String']>;
  on_hold_start?: Maybe<Scalars['String']>;
  parallel?: Maybe<Scalars['Int']>;
  post_hooks?: Maybe<Array<HookInput>>;
  pre_hooks?: Maybe<Array<HookInput>>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
  webhook_inputs?: Maybe<Array<WebhookParamInput>>;
  window_length: Scalars['Int'];
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export type PlanResults = {
  __typename?: 'PlanResults';
  continuation_token?: Maybe<Scalars['String']>;
  plans: Array<Plan>;
};

export type PlanUpdateInput = {
  cron_window_start?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  linux_security_only?: Maybe<Scalars['Boolean']>;
  machines?: Maybe<Array<PlanMachineInput>>;
  machines_tag?: Maybe<DynamicPlanInput>;
  manual_approval?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Array<KeyValuePairInput>>;
  name?: Maybe<Scalars['String']>;
  notification_groups?: Maybe<Array<Scalars['String']>>;
  on_hold_end?: Maybe<Scalars['String']>;
  on_hold_start?: Maybe<Scalars['String']>;
  parallel?: Maybe<Scalars['Int']>;
  post_hooks?: Maybe<Array<HookInput>>;
  pre_hooks?: Maybe<Array<HookInput>>;
  reboot_policy?: Maybe<RebootPolicy>;
  s3_custom_script?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  upcoming_notification_time?: Maybe<Scalars['Int']>;
  webhook_inputs?: Maybe<Array<WebhookParamInput>>;
  window_length?: Maybe<Scalars['Int']>;
  windows_update_category?: Maybe<Scalars['String']>;
  windows_update_severity?: Maybe<Scalars['String']>;
};

export enum Provider {
  Aws = 'AWS',
  Azure = 'Azure',
  Gcp = 'GCP',
  Ibm = 'IBM',
  Other = 'Other'
}

export type Query = {
  __typename?: 'Query';
  activation: Activation;
  allPipelines: Array<Pipeline>;
  apiKey: ApiKey;
  /** @deprecated Use `apiKeysBatch` instead */
  apiKeys: Array<ApiKey>;
  apiKeysBatch: ApiKeyResults;
  checkCustomerOnboarding: CustomerOnboarding;
  currentAPIKey: ApiKey;
  customerEC2Policy: Scalars['String'];
  customerServiceRolePolicy: Scalars['String'];
  customers: Array<Customer>;
  event: Event;
  eventById: Event;
  eventReport: Report;
  eventScanReport: Report;
  /** @deprecated Use `eventsBatch` instead */
  events: Array<Event>;
  eventsBatch: EventResults;
  eventsDashboard: Array<Event>;
  exportEvents: CsvReport;
  exportMachines: CsvReport;
  exportPlans: CsvReport;
  hookOutput: HookOutput;
  hostHookOutput: HookOutput;
  listInstallerKeys?: Maybe<Array<InstallerKeyMetadata>>;
  machine: Machine;
  /** @deprecated Use `machinesBatch` instead */
  machines: Array<Machine>;
  machinesBatch: MachineResults;
  notificationGroup: NotificationGroup;
  /** @deprecated Use `notificationGroupsBatch` instead */
  notificationGroups: Array<NotificationGroup>;
  notificationGroupsBatch: NotificationGroupResults;
  /** @deprecated Use `patchingEventsBatch` instead */
  patchingEvents?: Maybe<Array<PatchingEvent>>;
  patchingEventsBatch: PatchingEventResults;
  pipeline: Pipeline;
  pipelineById: Pipeline;
  /** @deprecated Use `pipelinesBatch` instead */
  pipelines: Array<Pipeline>;
  pipelinesBatch: PipelineResults;
  plan: Plan;
  /** @deprecated Use `plansBatch` instead */
  plans: Array<Plan>;
  plansBatch: PlanResults;
  rbacRole: RbacRole;
  rbacRoleBinding: RbacRoleBinding;
  rbacRoleBindingsBatch: RbacRoleBindingResults;
  rbacRolesBatch: RbacRoleResults;
  /** @deprecated Use `resourcesBatch` instead */
  resources?: Maybe<Array<Resource>>;
  resourcesBatch: ResourceResults;
  updateLog: UpdateLog;
  updateLogOutput: UpdateLogOutput;
  updateLogOutputWithPartials: UpdateLogOutput;
  updateLogsWithPartials: Array<UpdateLog>;
};


export type QueryActivationArgs = {
  customer_id: Scalars['String'];
};


export type QueryApiKeyArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type QueryApiKeysArgs = {
  customer_id: Scalars['String'];
};


export type QueryApiKeysBatchArgs = {
  input: BatchInput;
};


export type QueryCheckCustomerOnboardingArgs = {
  customer_id: Scalars['String'];
};


export type QueryCurrentApiKeyArgs = {
  customer_id?: Maybe<Scalars['String']>;
};


export type QueryCustomerEc2PolicyArgs = {
  customer_id: Scalars['String'];
};


export type QueryCustomerServiceRolePolicyArgs = {
  customer_id: Scalars['String'];
};


export type QueryEventArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
};


export type QueryEventByIdArgs = {
  id: Scalars['String'];
};


export type QueryEventReportArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
};


export type QueryEventScanReportArgs = {
  customer_id: Scalars['String'];
  event_id?: Maybe<Scalars['String']>;
};


export type QueryEventsArgs = {
  customer_id: Scalars['String'];
  end_date: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
  start_date: Scalars['String'];
};


export type QueryEventsBatchArgs = {
  input: EventsBatchInput;
};


export type QueryEventsDashboardArgs = {
  input: EventsDashboardInput;
};


export type QueryExportEventsArgs = {
  customer_id: Scalars['String'];
  customer_name: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type QueryExportMachinesArgs = {
  customer_id: Scalars['String'];
  customer_name: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type QueryExportPlansArgs = {
  customer_id: Scalars['String'];
  customer_name: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type QueryHookOutputArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  hook_name: Scalars['String'];
};


export type QueryHostHookOutputArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  hook_name: Scalars['String'];
  machine_id: Scalars['String'];
};


export type QueryListInstallerKeysArgs = {
  customer_id: Scalars['String'];
};


export type QueryMachineArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type QueryMachinesArgs = {
  customer_id: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
};


export type QueryMachinesBatchArgs = {
  input: BatchInput;
};


export type QueryNotificationGroupArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type QueryNotificationGroupsArgs = {
  customer_id: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
};


export type QueryNotificationGroupsBatchArgs = {
  input: BatchInput;
};


export type QueryPatchingEventsArgs = {
  customer_id?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
};


export type QueryPatchingEventsBatchArgs = {
  input?: Maybe<PatchingEventsBatchInput>;
};


export type QueryPipelineArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type QueryPipelineByIdArgs = {
  id: Scalars['String'];
};


export type QueryPipelinesArgs = {
  customer_id: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
};


export type QueryPipelinesBatchArgs = {
  input: BatchInput;
};


export type QueryPlanArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type QueryPlansArgs = {
  customer_id: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
};


export type QueryPlansBatchArgs = {
  input: BatchInput;
};


export type QueryRbacRoleArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type QueryRbacRoleBindingArgs = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
};


export type QueryRbacRoleBindingsBatchArgs = {
  input: BatchInput;
};


export type QueryRbacRolesBatchArgs = {
  input: BatchInput;
};


export type QueryResourcesArgs = {
  customer_id?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};


export type QueryResourcesBatchArgs = {
  input: ResourcesBatchInput;
};


export type QueryUpdateLogArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  machine_id: Scalars['String'];
};


export type QueryUpdateLogOutputArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  machine_id: Scalars['String'];
};


export type QueryUpdateLogOutputWithPartialsArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  machine_id: Scalars['String'];
};


export type QueryUpdateLogsWithPartialsArgs = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  machine_id: Scalars['String'];
};

export enum RbacAction {
  Any = 'any',
  Create = 'create',
  Delete = 'delete',
  Execute = 'execute',
  Get = 'get',
  List = 'list',
  Update = 'update'
}

export enum RbacResource {
  ActionLink = 'action_link',
  Activation = 'activation',
  Any = 'any',
  ApiKey = 'api_key',
  Customer = 'customer',
  DataReport = 'data_report',
  Event = 'event',
  InstallerKey = 'installer_key',
  Machine = 'machine',
  NotificationGroup = 'notification_group',
  Pipeline = 'pipeline',
  Plan = 'plan',
  Report = 'report',
  Role = 'role',
  RoleBinding = 'role_binding',
  UpdateLog = 'update_log'
}

export type RbacRole = {
  __typename?: 'RbacRole';
  created_at?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  rules: Array<RbacRoleRule>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RbacRoleBinding = {
  __typename?: 'RbacRoleBinding';
  created_at?: Maybe<Scalars['String']>;
  customer_id: Scalars['String'];
  id: Scalars['String'];
  roles: Array<RbacRoleBindingAssignedRole>;
  subject: Scalars['String'];
  type: RbacRoleBindingType;
  updated_at?: Maybe<Scalars['String']>;
};

export type RbacRoleBindingAssignedRole = {
  __typename?: 'RbacRoleBindingAssignedRole';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RbacRoleBindingAssignedRoleInput = {
  id: Scalars['String'];
};

export type RbacRoleBindingInput = {
  customer_id: Scalars['String'];
  roles: Array<RbacRoleBindingAssignedRoleInput>;
  subject: Scalars['String'];
  type: RbacRoleBindingType;
};

export type RbacRoleBindingPutInput = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
  roles: Array<RbacRoleBindingAssignedRoleInput>;
  subject: Scalars['String'];
  type: RbacRoleBindingType;
};

export type RbacRoleBindingResults = {
  __typename?: 'RbacRoleBindingResults';
  continuation_token?: Maybe<Scalars['String']>;
  rbac_role_bindings: Array<RbacRoleBinding>;
};

export enum RbacRoleBindingType {
  ApiKey = 'API_KEY',
  User = 'USER'
}

export type RbacRoleBindingUpdateInput = {
  customer_id: Scalars['String'];
  id: Scalars['String'];
  roles?: Maybe<Array<RbacRoleBindingAssignedRoleInput>>;
};

export type RbacRoleInput = {
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rules: Array<RbacRoleRuleInput>;
};

export type RbacRolePutInput = {
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  rules: Array<RbacRoleRuleInput>;
};

export type RbacRoleResults = {
  __typename?: 'RbacRoleResults';
  continuation_token?: Maybe<Scalars['String']>;
  rbac_roles: Array<RbacRole>;
};

export type RbacRoleRule = {
  __typename?: 'RbacRoleRule';
  actions: Array<RbacAction>;
  resource_ids?: Maybe<Array<Scalars['String']>>;
  resources: Array<RbacResource>;
};

export type RbacRoleRuleInput = {
  actions: Array<RbacAction>;
  resource_ids?: Maybe<Array<Scalars['String']>>;
  resources: Array<RbacResource>;
};

export type RbacRoleUpdateInput = {
  customer_id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<RbacRoleRuleInput>>;
};

export enum RebootPolicy {
  Always = 'ALWAYS',
  IfNeeded = 'IF_NEEDED',
  Never = 'NEVER'
}

export type RemoveMachineFromPlanInput = {
  customer_id: Scalars['String'];
  machine_id: Scalars['String'];
  plan_id: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  customer_id?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  dry_run?: Maybe<Scalars['Boolean']>;
  end_time?: Maybe<Scalars['String']>;
  hooks_results: ReportHookResults;
  id?: Maybe<Scalars['String']>;
  not_patched_machines?: Maybe<Array<ReportNotPatchedMachine>>;
  owner?: Maybe<Scalars['String']>;
  patched_machines?: Maybe<Array<ReportPatchedMachine>>;
  patching_status?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  window_end_time?: Maybe<Scalars['String']>;
  window_start_time?: Maybe<Scalars['String']>;
};

export type ReportHookResults = {
  __typename?: 'ReportHookResults';
  post_hooks?: Maybe<Array<Scalars['Int']>>;
  post_host_hooks?: Maybe<Array<ReportHostHookResult>>;
  pre_hooks?: Maybe<Array<Scalars['Int']>>;
  pre_host_hooks?: Maybe<Array<ReportHostHookResult>>;
};

export type ReportHostHookResult = {
  __typename?: 'ReportHostHookResult';
  machine_id: Scalars['String'];
  statuses?: Maybe<Array<Scalars['Int']>>;
};

export type ReportInput = {
  customer_id: Scalars['String'];
  event_id: Scalars['String'];
  user: Scalars['String'];
};

export type ReportNotPatchedMachine = {
  __typename?: 'ReportNotPatchedMachine';
  action_taken?: Maybe<Scalars['String']>;
  internal_status?: Maybe<Scalars['String']>;
  machine_id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ReportPatchedMachine = {
  __typename?: 'ReportPatchedMachine';
  action_taken?: Maybe<Scalars['String']>;
  available?: Maybe<Array<Scalars['String']>>;
  end_time?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  failed?: Maybe<Array<Scalars['String']>>;
  installed?: Maybe<Array<Scalars['String']>>;
  internal_status?: Maybe<Scalars['String']>;
  machine_id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  removed?: Maybe<Array<Scalars['String']>>;
  start_time?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Array<Scalars['String']>>;
};

export type Resource = {
  __typename?: 'Resource';
  customer_id?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  platform?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['String']>;
};

export type ResourceResults = {
  __typename?: 'ResourceResults';
  continuation_token?: Maybe<Scalars['String']>;
  resources: Array<Resource>;
};

export type ResourcesBatchInput = {
  continuation_token?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  platform?: Maybe<Scalars['String']>;
};

export type SsmMetadata = {
  __typename?: 'SSMMetadata';
  activation_id?: Maybe<Scalars['String']>;
  agent_version?: Maybe<Scalars['String']>;
  association_status?: Maybe<Scalars['String']>;
  computer_name?: Maybe<Scalars['String']>;
  iam_role?: Maybe<Scalars['String']>;
  instance_id?: Maybe<Scalars['String']>;
  ip_address?: Maybe<Scalars['String']>;
  is_latest_version?: Maybe<Scalars['Boolean']>;
  last_association_execution_date?: Maybe<Scalars['String']>;
  last_ping_date_time?: Maybe<Scalars['String']>;
  last_successful_association_execution_date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ping_status?: Maybe<Scalars['String']>;
  platform_name?: Maybe<Scalars['String']>;
  platform_type?: Maybe<Scalars['String']>;
  platform_version?: Maybe<Scalars['String']>;
  registration_date?: Maybe<Scalars['String']>;
  resource_type?: Maybe<Scalars['String']>;
};

export type SsmMetadataInput = {
  activation_id?: Maybe<Scalars['String']>;
  agent_version?: Maybe<Scalars['String']>;
  association_status?: Maybe<Scalars['String']>;
  computer_name?: Maybe<Scalars['String']>;
  iam_role?: Maybe<Scalars['String']>;
  instance_id?: Maybe<Scalars['String']>;
  ip_address?: Maybe<Scalars['String']>;
  is_latest_version?: Maybe<Scalars['Boolean']>;
  last_association_execution_date?: Maybe<Scalars['String']>;
  last_ping_date_time?: Maybe<Scalars['String']>;
  last_successful_association_execution_date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ping_status?: Maybe<Scalars['String']>;
  platform_name?: Maybe<Scalars['String']>;
  platform_type?: Maybe<Scalars['String']>;
  platform_version?: Maybe<Scalars['String']>;
  registration_date?: Maybe<Scalars['String']>;
  resource_type?: Maybe<Scalars['String']>;
};

export type TagCondition = {
  __typename?: 'TagCondition';
  expression: Scalars['String'];
  placeholders?: Maybe<Array<KeyValuePair>>;
  tree: Array<Array<BasicTagCondition>>;
};

export type TagConditionInput = {
  expression: Scalars['String'];
  placeholders?: Maybe<Array<KeyValuePairInput>>;
};

export type TagList = {
  __typename?: 'TagList';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type TagListInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type UpdateLog = {
  __typename?: 'UpdateLog';
  command_id: Scalars['String'];
  created_at?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  event_id: Scalars['String'];
  machine_id: Scalars['String'];
  partial_execution_id?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  start_time: Scalars['String'];
  status: Scalars['String'];
  timestamp: Scalars['Int'];
  updated_at?: Maybe<Scalars['String']>;
};

export type UpdateLogOutput = {
  __typename?: 'UpdateLogOutput';
  output: Scalars['String'];
};

export type WebhookParam = {
  __typename?: 'WebhookParam';
  payload: Scalars['String'];
  payload_type: Scalars['String'];
  trigger_type: WebhookTriggerType;
};

export type WebhookParamInput = {
  payload: Scalars['String'];
  payload_type: Scalars['String'];
  trigger_type: WebhookTriggerType;
};

export enum WebhookTriggerType {
  Event = 'event',
  Report = 'report'
}

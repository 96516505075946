/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from 'react';
import { Link } from 'react-router';
import { ButtonRemove } from '../../../commonComponents';
import { FormattedDate } from '../../../commonComponents';

export const Role = ({ item: role, actions }) => {
  const { removeItem } = actions;

  const href = `/permissions/roles/details/${role.id}`;
  return (
    <tr className="table-row-link" id={role.id}>
      <td>
        <Link to={href} className="table-cell">
          {role.name}
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          {role.description}
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          {role.id}
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          <FormattedDate>{role.created_at}</FormattedDate>
        </Link>
      </td>
      <td>
        <Link to={href} className="table-cell">
          <FormattedDate>{role.updated_at}</FormattedDate>
        </Link>
      </td>
      <td>
        <ButtonRemove
          removeAction={e => {
            e && e.stopPropagation && e.stopPropagation();
            removeItem(role.id);
          }}
          itemId={role.id}
          itemName={role.name}
          title="a role named"
        />
      </td>
    </tr>
  );
};

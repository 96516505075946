/**
 * Copyright 2020-2021 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { Component } from 'react';
import DateInput from './DateInput';
import { Select } from './Select';
import { Modal, FormButtonsContainer, FormButtonConfirm } from './Form';
import {
  CustomCheckboxWidget,
  CustomFieldTemplate,
  CustomTextWidget,
  ArrayFieldTemplate,
  ObjectFieldTemplate,
} from './Form';
import { RegionsInput } from './RegionsInput';
import Form from 'react-jsonschema-form';
import { stripUndefinedValues } from '../../utils';

const widgets = {
  TextWidget: CustomTextWidget,
  CheckboxWidget: CustomCheckboxWidget,
  DateWidget: DateInput,
  SelectWidget: Select,
};

const uiSchema = {
  regions: {
    'ui:field': 'regions',
    classNames: 'is-horizontal',
  },
  provider: {
    'ui:autofocus': true,
    'ui:emptyValue': '',
    'ui:readonly': true,
  },
  'ui:order': ['*', 'post_host_hooks', 'pre_host_hooks'],
};

const fields = {
  regions: RegionsInput,
};

const schema = {
  type: 'object',
  required: ['key', 'value'],
  properties: {
    tag_list: {
      type: 'array',
      title: 'Tag list',
      items: {
        type: 'object',
        properties: {
          key: {
            type: 'string',
            title: 'EC2 tag key',
          },
          values: {
            title: 'EC2 tag value',
            type: 'array',
            items: {
              type: 'string',
              title: 'value',
            },
          },
        },
      },
    },
    reboot_policy: {
      title: 'Reboot policy',
      type: 'string',
      anyOf: [
        { type: 'string', enum: ['NEVER'], title: 'Never' },
        { type: 'string', enum: ['IF_NEEDED'], title: 'If needed' },
        { type: 'string', enum: ['ALWAYS'], title: 'Always' },
      ],
    },
    provider: {
      title: 'Provider',
      type: 'string',
      default: 'aws',
      anyOf: [
        { type: 'string', enum: ['aws'], title: 'AWS' },
        // { type: 'string', enum: ['other'], title: 'Other' },
      ],
    },
    post_host_hooks: {
      type: 'array',
      title: 'Post hooks',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            title: 'Type',
            anyOf: [
              { type: 'string', enum: ['aws'], title: 'AWS Lambda ARN' },
              { type: 'string', enum: ['azure'], title: 'Azure Functions URL' },
            ],
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: { enum: ['aws'] },
                  source: {
                    type: 'string',
                    title: 'Value',
                  },
                },
              },
              {
                properties: {
                  type: { enum: ['azure'] },
                  source: {
                    type: 'string',
                    title: 'Value',
                  },
                  method: {
                    type: 'string',
                    title: 'Method',
                    anyOf: [{ type: 'string', enum: ['GET'] }, { type: 'string', enum: ['POST'] }],
                  },
                },
                required: ['method'],
              },
            ],
          },
        },
      },
    },
    pre_host_hooks: {
      type: 'array',
      title: 'Pre hooks',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            title: 'Type',
            anyOf: [
              { type: 'string', enum: ['aws'], title: 'AWS Lambda ARN' },
              { type: 'string', enum: ['azure'], title: 'Azure Functions URL' },
            ],
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: { enum: ['aws'] },
                  source: { type: 'string', title: 'Source' },
                },
              },
              {
                properties: {
                  type: { enum: ['azure'] },
                  source: { type: 'string', title: 'Source' },
                  method: {
                    type: 'string',
                    title: 'Method',
                    anyOf: [{ type: 'string', enum: ['GET'] }, { type: 'string', enum: ['POST'] }],
                  },
                },
                required: ['method'],
              },
            ],
          },
        },
      },
    },
    s3_custom_script: {
      type: 'string',
      title: 'Custom Update Command (S3 URL)',
    },
    update_existing: {
      type: 'boolean',
      title: 'Update existing',
      default: true,
      description: 'Set true to update existing machines.',
    },
  },
  dependencies: {
    provider: {
      oneOf: [
        {
          properties: {
            provider: { enum: ['aws'] },
            iam_role: {
              title: 'IAM Role',
              type: 'string',
            },
            regions: {
              title: 'Regions',
              type: 'array',
            },
          },
          required: ['iam_role', 'regions'],
        },
      ],
    },
  },
};

class MachineTagModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData ? props.formData : {},
    };
  }

  componentDidMount() {
    this.props.formData &&
      this.props.formData.iam_role &&
      this.setState({ formData: { ...this.props.formData, provider: 'aws' } });
  }

  handleChange = data => {
    this.setState({ formData: data.formData });
  };

  add = () => {
    const { provider, ...formData } = this.state.formData;
    this.props.addMachineTag(stripUndefinedValues(formData));
  };

  render() {
    const { formData } = this.state;
    const { hideModal, visible } = this.props;

    return (
      <Modal visible={visible} cancelAction={hideModal} title="Machines tag">
        <Form
          className=""
          schema={schema}
          uiSchema={uiSchema}
          fields={fields}
          FieldTemplate={CustomFieldTemplate}
          showErrorList={false}
          ArrayFieldTemplate={ArrayFieldTemplate}
          ObjectFieldTemplate={ObjectFieldTemplate}
          onChange={this.handleChange}
          formData={formData}
          widgets={widgets}
        >
          <FormButtonsContainer>
            {formData.tag_list && formData.tag_list.length > 0 && (
              <FormButtonConfirm onClick={this.add}>Add</FormButtonConfirm>
            )}
          </FormButtonsContainer>
        </Form>
      </Modal>
    );
  }
}

export { MachineTagModal };
